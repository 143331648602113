import * as React from 'react';
import './DropdownMultiplaSelecao.scss';
import { DropdownItem } from '../Dropdown/DropdownInterface';

interface Props {
  titulo: string,
  itens: DropdownItem[],
  filtrados: DropdownItem[],
  onFiltrarClick: Function,
  identificador?: string,
  btnID?: string;
}

const DropdownMultiplaSelecao = ({
  titulo,
  itens,
  filtrados,
  onFiltrarClick,
  identificador,
  btnID,
}: Props) => {
  const node = React.useRef<HTMLDivElement>(document.createElement('div'));

  const [estaAberto, setEstaAberto] = React.useState<boolean>(false);
  const [itensFiltrados, setItensFiltrados] = React.useState<DropdownItem[]>(itens);
  const [selecionados, setSelecionados] = React.useState<DropdownItem[]>([]);
  const [textoPesquisa, setTextoPesquisa] = React.useState<string>('');

  const montarDescricao = () => (filtrados.length === 0 ? 'Todas' : `${filtrados[0].texto} +`);

  const filtrarSelecao = (textoFiltragem: string) => {
    setTextoPesquisa(textoFiltragem);
    const itensFiltradosPorTexto = itens.filter(item => (
      item.texto.toUpperCase().includes(textoFiltragem.toUpperCase())));
    setItensFiltrados(itensFiltradosPorTexto);
  };

  const itemEstaSelecionado = (item: DropdownItem) => !!selecionados.find(
    selecionado => selecionado.valor === item.valor,
  );

  const removerSelecionado = (paraRemover: DropdownItem) => {
    const paraRemoverIndex = selecionados.findIndex(
      selecionado => paraRemover.valor === selecionado.valor,
    );

    setSelecionados([
      ...selecionados.slice(0, paraRemoverIndex),
      ...selecionados.slice(paraRemoverIndex + 1),
    ]);
  };

  const adicionarSelecionado = (novo: DropdownItem) => setSelecionados([...selecionados, novo]);

  const toggleDropdown = (aberto: boolean) => {
    setItensFiltrados(itens);
    setTextoPesquisa('');
    setSelecionados(filtrados);
    setEstaAberto(aberto);
  };

  const handleLimparPesquisa = () => {
    setTextoPesquisa('');
    setItensFiltrados(itens);
    setSelecionados([]);
  };

  const handleAlterarSelecao = (item: DropdownItem) => {
    if (itemEstaSelecionado(item)) removerSelecionado(item);
    else adicionarSelecionado(item);
  };

  const handleFiltrar = () => {
    toggleDropdown(false);
    onFiltrarClick(selecionados);
  };

  const handleCliqueFora = (e: any) => {
    if (!node.current.contains(e.target)) {
      toggleDropdown(false);
    }
  };

  React.useEffect(() => setItensFiltrados(itens), [itens]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleCliqueFora);
    return () => document.removeEventListener('mousedown', handleCliqueFora);
  }, [handleCliqueFora]);

  return (
    <div className='dropdown-multipla-selecao' ref={node}>
      <div
        className='cabecalho'
        onClick={() => toggleDropdown(!estaAberto)}
        onKeyDown={() => toggleDropdown(!estaAberto)}
        role='button'
        tabIndex={0}
      >
        <span className={`titulo ${estaAberto ? 'laranja' : ''}`}>{titulo}</span>
        <span
          className={
            `descricao ${estaAberto ? 'virado' : ''} ${filtrados.length > 0 ? 'laranja' : ''}`
          }
          id={`filtroExtrato${identificador}`}
        >
          {montarDescricao()}
        </span>
      </div>

      <div className={`dropdown ${estaAberto ? 'aberto' : ''}`} data-testid='dropdown'>
        <div className={`busca ${itens.length > 10 ? 'exibir' : 'esconder'}`}>
          <input
            type='text'
            value={textoPesquisa}
            placeholder='Buscar por'
            onChange={e => filtrarSelecao(e.target.value)}
          />
        </div>
        <ul className='placas'>
          {
            itensFiltrados.map(item => (
              <li key={item.valor}>
                <button
                  aria-checked={itemEstaSelecionado(item)}
                  className={itemEstaSelecionado(item) ? 'selecionado' : ''}
                  onClick={() => handleAlterarSelecao(item)}
                  onKeyDown={() => handleAlterarSelecao(item)}
                  role='checkbox'
                  type='button'
                  data-testid={item.texto}
                >
                  {item.icone ? (<img src={item.icone} alt='icone' />) : ''}
                  <span>{item.texto}</span>
                </button>
              </li>
            ))}
          { itensFiltrados.length === 0 ? (<li className='aviso'>Nenhum filtro encontrado</li>) : '' }
        </ul>
        <div className='acoes'>
          <button
            id={`btnLimparFiltros${btnID}`}
            className='botao-vazio limpar'
            type='button'
            onClick={() => handleLimparPesquisa()}
          >
            Limpar filtros
          </button>
          <button
            className={`filtrar ${selecionados.length > 0 ? 'botao-preenchido' : 'botao-vazio-borda'}`}
            id={`btnAplicarFiltro${btnID}`}
            type='button'
            onClick={() => handleFiltrar()}
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropdownMultiplaSelecao;
