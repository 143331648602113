import * as React from 'react';

interface Props {
  identificador?: string;
  texto: string,
  cheio?: boolean,
  largura?: string,
  onClick: Function
}

const { useState } = React;

const Botao = ({ texto, onClick, cheio, largura, identificador } : Props) => {
  const style = { width: largura };
  const [botaoEstaAtivo, setBotaoEstaAtivo] = useState<boolean>(cheio);
  const [textoBotao] = useState<string>(texto);

  const clicar = () => {
    onClick();
    setBotaoEstaAtivo(!botaoEstaAtivo);
  };

  return (
    <button
      id={identificador}
      className={cheio ? 'botao-preenchido' : 'botao-vazio-borda'}
      style={style}
      onClick={clicar}
      type='button'
    >
      {textoBotao}
    </button>
  );
};

export default Botao;
