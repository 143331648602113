import * as React from 'react';
import { DropdownItem } from './DropdownInterface';

import './Dropdown.scss';

interface Props {
  item: DropdownItem,
  onClick: Function,
  estaSelecionado?: boolean
}

const DropdownItemComponent = ({ item, onClick, estaSelecionado } : Props) => {
  const icone = () => {
    if (item.icone) {
      return <img src={item.icone} alt=' ' />;
    }
    return null;
  };

  return (
    <li
      onClick={() => onClick()}
      className={`${estaSelecionado ? 'selecionado' : ''}`}
      onKeyPress={() => onClick()}
      role='menuitem'
    >
      {icone()}
      {' '}
      {item.texto}
    </li>
  );
};

export default DropdownItemComponent;
