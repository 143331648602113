import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ValidarCampo, { formularioEstaValido, Validacao } from '../../../infrastructure/validador';

import Actions from '../CadastroActions';
import { Etapas, EtapasRotas, RepresentanteLegal } from '../CadastroInterfaces';
import { State } from '../../interfaces';

import CadastroCabecalho from '../CadastroCabecalho';
import CadastroFooter from '../CadastroFooter';
import CadastroResumo from '../CadastroResumo';
import Campo from '../../../components/Inputs/CampoTexto';
import CampoComLink from '../../../components/Inputs/CampoComLink';
import CampoData from '../../../components/Inputs/CampoData';
import Checkbox from '../../../components/Inputs/Checkbox';

import '../Cadastro.scss';
import './CadastroRepresentantePage.scss';
import CadastroErroServidor from '../CadastroErroServidor';
import ModalSlide from '../../../components/ModalSlide';
import { DadosIntegracaoPlataforma } from '../../../infrastructure/prospecto-api/rascunho/RascunhoInterfaces';

interface ValidacaoFormulario {
  nomeContatoComercial: Validacao,
  email: Validacao,
  telefone: Validacao,
  celular: Validacao,
  nomeRepresentanteLegal: Validacao,
  cpf: Validacao,
  representanteDesde: Validacao,
  cargo: Validacao,
}

const iconeInterrogacao = require('../../../assets/images/icones/interrogacao.svg');

export default () => {
  const {
    nomeContatoComercial,
    email,
    telefone,
    celular,
    nomeRepresentanteLegal,
    cpf,
    representanteDesde,
    cargo,
    pessoaPoliticamenteExposta,
  } = useSelector<State, RepresentanteLegal>(state => state.cadastro.representanteLegal);
  const etapaAtual = useSelector<State, Etapas>(state => state.cadastro.etapaAtual);
  const erroServidor = useSelector<State, string>(state => state.cadastro.erroServidor);
  const [modalEstaAberto, setModalEstaAberto] = React.useState<boolean>(false);
  const [validacao, setValidacao] = React.useState<ValidacaoFormulario>({
    nomeContatoComercial: { mensagem: '', estaValido: !ValidarCampo('nomeContatoComercial', nomeContatoComercial) },
    email: { mensagem: '', estaValido: !ValidarCampo('email', email) },
    telefone: { mensagem: '', estaValido: !ValidarCampo('telefone', telefone) },
    celular: { mensagem: '', estaValido: !ValidarCampo('celularObrigatorio', celular) },
    nomeRepresentanteLegal: { mensagem: '', estaValido: !ValidarCampo('nomeRepresentanteLegal', nomeRepresentanteLegal) },
    cpf: { mensagem: '', estaValido: !ValidarCampo('cpf', cpf) },
    representanteDesde: { mensagem: '', estaValido: !ValidarCampo('representanteDesde', representanteDesde === null ? '' : representanteDesde) },
    cargo: { mensagem: '', estaValido: !ValidarCampo('cargo', cargo) },
  });
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (etapaAtual < Etapas.Representante) history.push(EtapasRotas.get(Etapas.Plano)!);
    document.title = 'Contato e Representante - Cadastro - ConectCar';
  }, [dispatch, etapaAtual, history]);

  const {
    plataformaDescricao,
  } = useSelector<State, DadosIntegracaoPlataforma>(state => state.cadastro.dadosIntegracaoPlataforma);

  const validarCampo = (name, value) => {
    const mensagem = ValidarCampo(name, value);

    setValidacao({
      ...validacao,
      [name]: { mensagem, estaValido: !mensagem },
    });
  };

  const formularioNaoEstaValido = () => !formularioEstaValido(validacao) && !erroServidor;

  const handleChange = (name, value) => {
    dispatch(Actions.atribuirCampoRepresentanteLegal(name, value));
    validarCampo(name, value);
  };

  const handleChangeCelular = (name, value) => {
    dispatch(Actions.atribuirCampoRepresentanteLegal(name, value));

    const mensagem = ValidarCampo('celularObrigatorio', value);

    setValidacao({
      ...validacao,
      [name]: { mensagem, estaValido: !mensagem },
    });
  };

  const handleRepetirClick = () => {
    handleChange('nomeRepresentanteLegal', nomeContatoComercial);
  };

  const handlePessoaPoliticamenteExpostaChange = (ppe) => {
    dispatch(Actions.atribuirPessoaPoliticamenteExposta(ppe));
  };

  const avancarProximoPasso = () => {
    dispatch(Actions.avancarProximaEtapa());
    dispatch(Actions.enviarRascunho());
    history.push(EtapasRotas.get(Etapas.Empresa)!);
  };

  return (
    <section id='cadastro-representante-page' className='cadastro-page'>
      <section className='conteudo'>
        <CadastroCabecalho />
        <CadastroResumo />

        <section className='formulario'>
          <CadastroErroServidor />
          <div className='contato'>
            <Campo
              name='nomeContatoComercial'
              titulo='Nome de contato comercial'
              value={nomeContatoComercial}
              mensagemValidacao={validacao.nomeContatoComercial.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />

            <Campo
              name='email'
              titulo='E-mail'
              value={email}
              disabled={plataformaDescricao?.toLowerCase() === 'hubspot'}
              mensagemValidacao={validacao.email.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />

            <Campo
              name='telefone'
              titulo='Telefone'
              value={telefone}
              mensagemValidacao={validacao.telefone.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value)}
              meiaColuna
            />

            <Campo
              name='celular'
              titulo='Celular'
              value={celular}
              mensagemValidacao={validacao.celular.mensagem}
              onChange={e => handleChangeCelular(e.target.name, e.target.value)}
              meiaColuna
            />
          </div>

          <div className='representante'>
            <CampoComLink
              name='nomeRepresentanteLegal'
              titulo='Nome representante legal'
              value={nomeRepresentanteLegal}
              mensagemValidacao={validacao.nomeRepresentanteLegal.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value)}
              textoLink='Repetir'
              onClick={handleRepetirClick}
            />

            <Campo
              name='cpf'
              titulo='CPF'
              value={cpf}
              mensagemValidacao={validacao.cpf.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value)}
              meiaColuna
            />

            <CampoData
              name='representanteDesde'
              titulo='Representante desde'
              value={representanteDesde}
              mensagemValidacao={validacao.representanteDesde.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value ? new Date(e.target.value) : null)}
              meiaColuna
            />

            <Campo
              name='cargo'
              titulo='Cargo'
              value={cargo}
              mensagemValidacao={validacao.cargo.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />

            <Checkbox
              descricao='Sou uma pessoa politicamente exposta (PPE)'
              onCheckChange={handlePessoaPoliticamenteExpostaChange}
              estaChecado={pessoaPoliticamenteExposta}
            />

            <button type='button' id='botao-abrir-modal' title='botao-abrir-modal' onClick={() => setModalEstaAberto(true)}>
              <img
                src={iconeInterrogacao}
                alt='Pessoa Politicamente Exposta'
              />
            </button>
          </div>

          <button
            type='button'
            className='botao-preenchido-azul'
            title='Próximo passo'
            disabled={formularioNaoEstaValido()}
            onClick={avancarProximoPasso}
          >
            Próximo passo
          </button>

          <ModalSlide
            estaAberto={modalEstaAberto}
            onCloseClick={() => setModalEstaAberto(false)}
            botao
            botaoTexto='Ok'
          >
            <section id='conteudo-ppe'>
              <h1>Pessoa politicamente exposta</h1>
              <p>
                Consideram-se PPE os agentes públicos que desempenham ou tenham desempenhado,
                nos últimos cinco anos, no Brasil ou em países, territórios e dependências estrangeiros,
                cargos, empregos ou funções públicas relevantes, assim como seus representantes, familiares e
                outras pessoas de seu relacionamento próximo
              </p>
            </section>
          </ModalSlide>
          <CadastroFooter />
        </section>
      </section>
    </section>
  );
};
