const obterParametro = (parametro) => {
  const { location } = window;
  return new URLSearchParams(location.search).get(parametro);
};

const removerTodosParametros = () => {
  const { location } = window;

  let url = window.location.href;
  url = url.replace(`${location.search}`, '');

  window.history.replaceState({}, document.title, url);
};

const removerParametro = (parametro, valor) => {
  if (!parametro || !valor) return;

  const { location } = window;

  let url = window.location.href;

  if (location.search === `?${parametro}=${valor}`) {
    url = url.replace(`?${parametro}=${valor}`, '');
  } else if (location.search.includes(`?${parametro}=${valor}`)) {
    url = url.replace(`${parametro}=${valor}&`, '');
  } else {
    url = url.replace(`&${parametro}=${valor}`, '');
  }

  window.history.replaceState({}, document.title, url);
};

const atualizarParametro = (parametro, valor, novoValor) => {
  if (!parametro || !valor || !novoValor) return;

  const { location } = window;

  let url = window.location.href;

  if (location.search === `?${parametro}=${valor}`) {
    url = url.replace(`?${parametro}=${valor}`, `?${parametro}=${novoValor}`);
  } else if (location.search.includes(`?${parametro}=${valor}`)) {
    url = url.replace(`${parametro}=${valor}&`, `${parametro}=${novoValor}&`);
  } else {
    url = url.replace(`&${parametro}=${valor}`, `&${parametro}=${novoValor}`);
  }

  window.history.replaceState({}, document.title, url);
};

export default {
  obterParametro,
  removerTodosParametros,
  removerParametro,
  atualizarParametro,
};
