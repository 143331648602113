// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/icones/mercosul-placa.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#substituicao-troca-veiculo-placa{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:transparent;background-repeat:no-repeat;background-size:contain;background-position-x:center;border:none;color:#2a3037;font-size:24px;font-weight:700;height:70px;text-align:center;text-transform:uppercase;width:155px}#substituicao-troca-veiculo-placa:focus{background-color:transparent;border:none}.substituicao-troca-veiculo-dados-novo-veiculo{color:#fff;font-size:18px !important;font-weight:700 !important}#substituicao-formulario-grid-item-concluir-troca{margin-bottom:36px}", ""]);
// Exports
module.exports = exports;
