import React, { memo } from 'react';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import { buttonStyles } from './styles';
import { useAvisoSaldo } from '../../../../../Context/AvisoSaldoContexto';

const ButtonInativarEmail: React.FC<ButtonBaseProps> = (props) => {
  const styles = buttonStyles();
  const { flagReceberAvisoSaldo } = useAvisoSaldo();

  if (!flagReceberAvisoSaldo) return null;

  return (
    <ButtonBase
      {...props}
      classes={styles}
    >
      <Remove />
    </ButtonBase>
  );
};

export default memo(ButtonInativarEmail);
