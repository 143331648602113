import * as React from 'react';
import Campo, { PropsBase } from '../CampoBase';
import './CampoData.scss';

interface Props extends PropsBase {}

const dataAtual = new Date();

export default ({ name, titulo, value, onChange, meiaColuna, onBlur, mensagemValidacao }: Props) => (
  <Campo
    classeExtra={(!value ? 'nulo' : '')}
    name={name}
    titulo={titulo}
    value={value === null ? '' : value.toISOString().substring(0, 10)}
    type='date'
    meiaColuna={meiaColuna}
    onChange={onChange}
    onBlur={onBlur}
    mensagemValidacao={mensagemValidacao}
    maxDate={dataAtual}
  />
);
