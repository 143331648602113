import React from 'react';
import './style.scss';
import { CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';

interface Props {
  id: any;
  valor: number;
  valorSelecionado: number;
  className: string;
  onClick: () => void;
}

const CartaoValor = ({ id, valor, valorSelecionado, className, onClick }: Props) => (
  <Grid item id={id} className={className} lg={2} md={2} sm={4} xs={4}>
    <CardActionArea
      onClick={onClick}
      style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.04), 0px 2px 5px rgba(0, 0, 0, 0.08)', borderRadius: '12px', border: valorSelecionado === valor ? '1px solid #3B5CB0' : '1px solid transparent' }}
    >
      <CardContent>
        <div className={`card-valores-interno-fonte ${valorSelecionado === valor ? 'card-valores-interno-fonte-valor' : ''}`}>
          Valor
        </div>
        <Typography variant='body2' className='card-valores-interno-fonte-valor'>
          R$ {valor}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Grid>
);

export default CartaoValor;
