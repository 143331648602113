import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Carro from '../../../assets/images/icones/erro-recarga-svg.svg';
import Circulo from '../../../assets/images/icones/erro-recarga-circulo.svg';
import ResultadoStyles from './ResultadoStyles';


const ErroRecargaPage = () => {
  const styles = ResultadoStyles();
  return (
    <Box component='div' style={{ padding: '2rem', display: 'flex', alignContent: 'center' }}>
      <Grid container component='div' className={styles.gridContainer} direction='row' alignContent='center' spacing={3}>
        <Grid item lg={8} md={8} xs={12} sm={8} className={styles.gridItem}>
          <img src={Circulo} alt='' />
          <p className={styles.tituloSucesso}>
            Tivemos problemas para realizar a recarga. Tente novamente.
          </p>
          <p className={styles.subtituloSucesso}>
            Obrigado por usar ConectCar. Espere alguns segundos que seu saldo logo estará atualizado.
          </p>
          <a
            type='button'
            className={styles.botaoSucesso}
            href='/home'
            style={{ color: '#FFFFFF' }}
          >
            Realizar nova recarga
          </a>
        </Grid>
        <Grid item lg={4} md={4} xs={12} sm={4} className={styles.gridItem}>
          <img src={Carro} alt='' style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErroRecargaPage;
