
import React, { Fragment, memo, useCallback } from 'react';

import { Typography, Box, Button } from '@material-ui/core';

import { colors } from '../../../styles/theme';
import './styles.scss';

const PainelPlano: React.FC = () => {
  const acessarPaginaAtivarAdesivo = useCallback(() => {
    window.parent.location.href = `${(window as any).config.url_portal_cliente}/AdesaoEmLote/AtivacaoManual`;
  }, []);


  return (
    <Fragment>
      <Box className='containerPlano'>
        <Box className='planoBox'>
          <Box className='grupoEsquerda'>

            <Typography>
              Navegue pelas opções abaixo e solicite seus novos adesivos.
            </Typography>
          </Box>

          <Box className='grupoDireita'>
            <Typography className='textoAdesivosEmMaos'>
              Tenho meus adesivos em mãos!
            </Typography>

            <Button
              variant='outlined'
              style={{
                border: `1px solid ${colors.primary.PJ}`,
                borderRadius: 100,
                textTransform: 'none',
                color: colors.primary.PJ,
              }}
              onClick={acessarPaginaAtivarAdesivo}
            >
              Ativar adesivo
            </Button>
          </Box>
        </Box>

        <Box className='grupoDireitaMobile'>
          <Typography
            className='textoAdesivosEmMaos'
            style={{ marginRight: 20 }}
          >
            Tenho meus adesivos em mãos!
          </Typography>

          <Box className='ativarAdesivoContainer'>
            <Button
              variant='outlined'
              className='ativarAdesivoContainer'
              style={{
                border: `1px solid ${colors.primary.PJ}`,
                borderRadius: 100,
                textTransform: 'none',
                color: colors.primary.PJ,
              }}
            >
              Ativar adesivo
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default memo(PainelPlano);
