import { Grid } from '@material-ui/core';
import React from 'react';
import Arquivo from '../../assets/images/icones/arquivo.svg';
import Download from '../../assets/images/icones/download_arquivo.svg';
import Spinner from '../../components/Spinner/index';
import { download } from './downloadArquivo';

interface Props {
  id: string;
  selected: boolean;
  idArquivo: number;
  nomeArquivo: string;
  descricao: string
}

const ArquivoItem = ({ id, selected, idArquivo, nomeArquivo, descricao }: Props) => {
  const [mostrarSpinner, setMostrarSpinner] = React.useState<boolean>(false);

  let andamento = false;

  const iniciarDownload = async () => {
    if (andamento) return;
    andamento = true;
    setMostrarSpinner(true);
    download(idArquivo, nomeArquivo).finally(() => {
      setMostrarSpinner(false);
      andamento = false;
    });
  };

  return (
    <Grid item component='div' lg={3} md={3} sm={6} xs={12} id={id} className='grid-item-download' onClick={() => iniciarDownload()} style={selected ? {} : { display: 'none' }}>
      <div className='card-item-download'>
        { mostrarSpinner ? <Spinner /> : <><img src={Arquivo} alt='' /><p className='descricao-card'>{descricao}</p><img src={Download} alt='' /></> }
      </div>
    </Grid>
  );
};

export default ArquivoItem;
