import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../interfaces';
import ModalRecargaPix from '../../Components/Pix/ModalRecargaPix';
import { RecargaState } from '../../RecargaInterfaces';
import Actions from '../../RecargaActions';

const CarregarRetornoPix = () => {
  const {
    dadosRecargaPix,
  } = useSelector<State, RecargaState>(state => ({
    dadosRecargaPix: state.recarga.dadosRecargaPix,
  }));

  const [open, setOpen] = React.useState(true);

  const dispatch = useDispatch();

  const handleOnCloseModal = () => {
    setOpen(false);
    dispatch(Actions.descarregarParametrosModalPix());
  };

  return (
    <ModalRecargaPix
      onCloseModal={handleOnCloseModal}
      open={open}
      qrCode={dadosRecargaPix?.dados?.qrCode}
      textoDoQrCode={dadosRecargaPix?.dados?.textoDoQrCode}
    />
  );
};

export default CarregarRetornoPix;
