import { RespostaApi } from '../../infrastructure/ApiInterfaces';
import { Action } from '../interfaces';
import { FiltrosHistoricoRecargaSelecionados, RecargasFiltradas, RetornoHistoricoUltimoPedido } from './HistoricoRecargaInterfaces';

export enum Types {
  InicializarHistoricoRecarga = 'INICIALIZAR_HISTORICO_RECARGA',
  AtribuirDocumento = 'ATRIBUIR_DOCUMENTO',
  BuscarHistoricoRecargaFiltroRequisicao = 'BUSCAR_HISTORICO_RECARGA_FILTRO_REQUISICAO',
  BuscarHistoricoRecargaFiltroSucesso = 'BUSCAR_HISTORICO_RECARGA_FILTRO_SUCESSO',
  BuscarHistoricoRecargaFiltroError = 'BUSCAR_HISTORICO_RECARGA_FILTRO_ERROR',
  ObterUltimasRecargaRequisicao = 'BUSCAR_ULTIMAS_RECARGA_REQUISICAO',
  ObterUltimasRecargaSucesso = 'BUSCAR_ULTIMAS_RECARGA_SUCESSO',
  ObterUltimasRecargaError = 'BUSCAR_ULTIMAS_RECARGA_ERROR',
  ModificarPagina = 'MODIFICAR_PAGINA',
  ObterHistoricoUltimoPedidoRequisicao = 'BUSCAR_ULTIMO_PEDIDO_REQUISICAO',
  ObterHistoricoUltimoPedidoSucesso = 'BUSCAR_ULTIMO_PEDIDO_SUCESSO',
  ObterHistoricoUltimoPedidoError = 'BUSCAR_ULTIMO_PEDIDO_ERROR',
  AtualizarTotalPaginas = 'ATUALIZANDO_PAGINACAO',
  ResetarPaginacao = 'RESETAR_PAGINACAO',
  LimparTabela = 'LIMPAR_TABELA',
}
export interface InicializarHistoricoRecarga extends Action { }
export interface AtribuirDocumentoAction extends Action {
  documento: string,
  clienteId: string,
  token: string,
}
export interface ObterUltimasRecargaRequisicaoAction extends Action { }
export interface ObterUltimasRecargaSucessoAction extends Action { historicoRecargaFiltro: RespostaApi<RecargasFiltradas[]> }
export interface ObterUltimasRecargaErrorAction extends Action { }
export interface BuscarHistoricoRecargaFiltroRequisicaoAction extends Action { filtrosRecargaSelecionados: FiltrosHistoricoRecargaSelecionados }
export interface BuscarHistoricoRecargaFiltroSucessoAction extends Action { historicoRecargaFiltro: RespostaApi<RecargasFiltradas[]> }
export interface BuscarHistoricoRecargaFiltroErrorAction extends Action { }
export interface ObterHistoricoUltimoPedidoRequisicaoAction extends Action { }
export interface ObterHistoricoUltimoPedidoSucessoAction extends Action { historicoUltimoPedido: RespostaApi<RetornoHistoricoUltimoPedido> }
export interface ObterHistoricoUltimoPedidoErrorAction extends Action { }
export interface AtualizarTotalPaginasAction extends Action { totalPaginas: number }
export interface ResetarPaginacaoAction extends Action { }
export interface LimparTabelaAction extends Action { }

export interface ModificarPaginaAction extends Action {
  registrosPorPagina: number,
  paginaSelecionada: number
}

export default {
  inicializarHistoricoRecarga: (): InicializarHistoricoRecarga => ({ type: Types.InicializarHistoricoRecarga }),
  atribuirDocumento: (documento: string, clienteId: string, token: string): AtribuirDocumentoAction => ({ type: Types.AtribuirDocumento, documento, clienteId, token }),
  obterUltimasRecargaRequest: (): ObterUltimasRecargaRequisicaoAction => ({ type: Types.ObterUltimasRecargaRequisicao }),
  obterUltimasRecargaSucesso: (historicoRecargaFiltro: RespostaApi<RecargasFiltradas[]>): ObterUltimasRecargaSucessoAction => ({ type: Types.ObterUltimasRecargaSucesso, historicoRecargaFiltro }),
  obterUltimasRecargaError: (): ObterUltimasRecargaErrorAction => ({ type: Types.ObterUltimasRecargaError }),
  buscarhistoricoRecargaFiltroRequest: (filtrosRecargaSelecionados: FiltrosHistoricoRecargaSelecionados): BuscarHistoricoRecargaFiltroRequisicaoAction => ({ type: Types.BuscarHistoricoRecargaFiltroRequisicao, filtrosRecargaSelecionados }),
  buscarhistoricoRecargaFiltroSucesso: (historicoRecargaFiltro: RespostaApi<RecargasFiltradas[]>): BuscarHistoricoRecargaFiltroSucessoAction => ({ type: Types.BuscarHistoricoRecargaFiltroSucesso, historicoRecargaFiltro }),
  buscarhistoricoRecargaFiltroError: (): BuscarHistoricoRecargaFiltroErrorAction => ({ type: Types.BuscarHistoricoRecargaFiltroError }),
  modificarPagina: (paginaSelecionada: number, registrosPorPagina: number): ModificarPaginaAction => ({ type: Types.ModificarPagina, registrosPorPagina, paginaSelecionada }),
  obterHistoricoUltimoPedidoRequest: (): ObterHistoricoUltimoPedidoRequisicaoAction => ({ type: Types.ObterHistoricoUltimoPedidoRequisicao }),
  obterHistoricoUltimoPedidoSucesso: (historicoUltimoPedido: RespostaApi<RetornoHistoricoUltimoPedido>): ObterHistoricoUltimoPedidoSucessoAction => ({ type: Types.ObterHistoricoUltimoPedidoSucesso, historicoUltimoPedido }),
  obterHistoricoUltimoPedidoError: (): ObterHistoricoUltimoPedidoErrorAction => ({ type: Types.ObterHistoricoUltimoPedidoRequisicao }),
  atualizarTotalPaginas: (totalPaginas: number): AtualizarTotalPaginasAction => ({ type: Types.AtualizarTotalPaginas, totalPaginas }),
  resetarPaginacao: (): ResetarPaginacaoAction => ({ type: Types.ResetarPaginacao }),
  limparTabela: (): LimparTabelaAction => ({ type: Types.LimparTabela }),
};
