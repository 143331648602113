import * as React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CadastroState, Etapas, EtapasRotas, StatusCadastro } from '../CadastroInterfaces';
import { State } from '../../interfaces';
import CadastroResumo from '../CadastroResumo/CadastroResumo';
import CadastroCabecalho from '../CadastroCabecalho/CadastroCabecalho';
import CadastroFooter from '../CadastroFooter/CadastroFooter';
import CadastroConcluidoAtivacaoPage from '../CadastroConcluidoAtivacaoPage/CadastroConcluidoAtivacaoPage';
import CadastroConcluidoPedidoPage from '../CadastroConcluidoPedidoPage';
import AguardandoAprovacaoPage from '../AguardandoAprovacaoPage/AguardandoAprovacaoPage';

import '../Cadastro.scss';
import './CadastroConcluidoLayout.scss';

export default () => {
  const etapaAtual = useSelector<State, Etapas>(state => state.cadastro.etapaAtual);

  const {
    statusCadastro,
    plano: { possuiAdesivos },
  } = useSelector<State, CadastroState>(state => state.cadastro);

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (etapaAtual < Etapas.Concluido) history.push(EtapasRotas.get(Etapas.Plano)!);
    document.title = 'Cadastro Concluído - ConectCar';
  }, [dispatch, etapaAtual, history, possuiAdesivos, statusCadastro]);

  return (
    <section id='cadastro-concluido-page' className='cadastro-page'>
      <section className='conteudo'>
        <CadastroCabecalho />
        <CadastroResumo />

        <section className='conteudo-concluido'>
          <h2>Cadastro concluído :)</h2>
          <h1>Obrigada por escolher a ConectCar.</h1>

          <div className='conteudo-proximos-passos'>
            <div className='proximos-passos'>
              {possuiAdesivos && <CadastroConcluidoAtivacaoPage />}
              {!possuiAdesivos && <CadastroConcluidoPedidoPage />}
              {statusCadastro === StatusCadastro.Processando && <AguardandoAprovacaoPage />}
            </div>
          </div>
          <CadastroFooter />
        </section>
      </section>
    </section>
  );
};
