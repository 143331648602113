import * as React from 'react';
import './HistoricoRecargaPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ocultadorChatbot from '../../../infrastructure/ocultadorChatbot';
import HistoricoRecargaTabela from '../HistoricoRecagarTabela/HistoricoRecargaTabela';
import Paginacao from '../../../components/Paginacao';
import Actions from '../HistoricoRecargaActions';
import { FiltrosHistoricoRecargaSelecionados, HistoricoRecargaState } from '../HistoricoRecargaInterfaces';
import HistoricoRecargaFiltros from '../HistoricoRecargaFiltros';
import Utils from '../../../common/Utils';
import { AppMessages } from '../../../common/AppMessages.enum';
import { State } from '../../interfaces';
import HistoricoUltimoPedido from '../HistoricoUltimoPedido';

const HistoricoRecargaPage = () => {
  const {
    filtrosHistoricoRecargaSelecionados,
    carregando,
    historicoRecargaFiltro,
    paginaSelecionada,
    registrosPorPagina,
    historicoUltimoPedido,
  } = useSelector<State, HistoricoRecargaState>(state => ({
    documento: state.extrato.documento,
    historicoRecargaFiltro: state.historicoRecarga.historicoRecargaFiltro,
    carregando: state.historicoRecarga.historicoRecargaFiltro.carregando,
    erro: state.historicoRecarga.historicoRecargaFiltro.erro,
    filtrosHistoricoRecargaSelecionados: state.historicoRecarga.filtrosHistoricoRecargaSelecionados,
    totalPaginas: state.historicoRecarga.totalPaginas,
    paginaSelecionada: state.historicoRecarga.paginaSelecionada,
    registrosPorPagina: state.historicoRecarga.registrosPorPagina,
    historicoUltimoPedido: state.historicoRecarga.historicoUltimoPedido,
  }));

  const [state, setState] = useState({ ...filtrosHistoricoRecargaSelecionados });

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [filtrosAplicado, setFiltrosAplicado] = useState(false);
  const [filtrosIsValid, setfiltrosIsValid] = useState(true);

  const handlePeriodosChange = (prop: keyof FiltrosHistoricoRecargaSelecionados) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const noventaDias = 90;
  const handlePesquisarClick = () => {
    const hasErrors = getErrorMessages();
    if (!hasErrors) {
      setFiltrosAplicado(true);
      dispatch(Actions.modificarPagina(1, 10));
      dispatch(Actions.buscarhistoricoRecargaFiltroRequest(state));
    }
  };

  const resultadoInicialBusca = () => {
    setfiltrosIsValid(true);
    setErrorMessage('');
    dispatch(Actions.obterUltimasRecargaRequest());
  };

  const handleLimparFiltrosClick = () => {
    setFiltrosAplicado(false);
    setState({ status: '', dataFim: '', dataInicio: '' });
    dispatch(Actions.resetarPaginacao());
    resultadoInicialBusca();
  };

  const getErrorMessages = (): boolean => {
    const isDateValid = Utils.compareDate(state.dataInicio, state.dataFim)
      && state.dataInicio !== state.dataFim;
    const camposDataObrigatorio = Utils.hasInputError(
      [state?.dataInicio, state?.dataFim],
      true,
    );
    const statusInvalido = state.status === '';

    const diferencaMeses = Utils.getDiferencaDeDias(new Date(state.dataInicio), new Date(state.dataFim));
    const isConsultaValid = diferencaMeses > noventaDias;

    setErrorMessage(isDateValid
      ? AppMessages.MSG_VALIDACAO_DE_DATA
      : isConsultaValid ? 'Selecione um período com no máximo 90 dias'
        : camposDataObrigatorio ? 'Os campos períodos são obrigatórios' : statusInvalido ? 'Selecione um status para pesquisar' : '');

    if (isConsultaValid) {
      setFiltrosAplicado(true);
      dispatch(Actions.limparTabela());
      setfiltrosIsValid(false);
    }

    return [!statusInvalido, !isDateValid, !camposDataObrigatorio, !isConsultaValid].some(e => !e);
  };

  const handleMudarPaginaChange = (pagina: number, quantidadeRegistros: number) => {
    dispatch(Actions.modificarPagina(pagina, quantidadeRegistros));

    if (state.dataInicio && state.dataFim) {
      dispatch(Actions.buscarhistoricoRecargaFiltroRequest(state));
    } else {
      setErrorMessage('');
      dispatch(Actions.obterUltimasRecargaRequest());
    }
  };

  React.useEffect(() => {
    ocultadorChatbot();
    dispatch(Actions.inicializarHistoricoRecarga());
  }, [dispatch]);

  React.useEffect(() => {
    setState({ ...filtrosHistoricoRecargaSelecionados });
  }, [filtrosHistoricoRecargaSelecionados]);

  return (
    <section className='page iframe-resizable'>
      <section className='historico-recarga-container historico-ultimo-pedido'>
        <HistoricoUltimoPedido historicoUltimoPedido={historicoUltimoPedido} />
      </section>

      <section id='historico-fatura-page'>
        <HistoricoRecargaFiltros
          handlePeriodosChange={handlePeriodosChange}
          onPesquisarClick={handlePesquisarClick}
          carregando={carregando}
          menssagemErro={errorMessage}
          onLimparFiltrosClick={handleLimparFiltrosClick}
          filtrosAplicado={filtrosAplicado}
          mesInicioSelecionado={state?.dataInicio}
          mesFimSelecionado={state?.dataFim}
        />
        {filtrosIsValid && (
          <section className='historico-recarga-container historico-recarga-page-tabela'>
            <HistoricoRecargaTabela historicofiltro={historicoRecargaFiltro} />
          </section>
        )}
        <section className='historico-recarga-container historico-recarga-page-paginacao'>
          {historicoRecargaFiltro.dados && historicoRecargaFiltro.dados.qntRegistros > 0 && historicoRecargaFiltro.dados.qntRegistros > 10 && (
            <Paginacao
              totalPaginas={(historicoRecargaFiltro.dados.qntRegistros / 10)}
              paginaSelecionada={paginaSelecionada}
              registrosPorPagina={registrosPorPagina}
              onMudarPaginacaoChange={handleMudarPaginaChange}
            />
          )}
        </section>
      </section>
    </section>
  );
};

export default HistoricoRecargaPage;
