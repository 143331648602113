import {
  AtribuirDocumentoAction,
  AtribuirFiltrosAction,
  AtribuirSaldoAction,
  AtribuirTabelaAction,
  FiltrarPorCentrosCustoAction,
  FiltrarPorPeriodoAction,
  FiltrarPorPlacasAction,
  FiltrarPorTiposTransacaoAction,
  MensagemErroFiltroAction,
  ModificarPaginaAction,
  OrdenarAction,
  Types,
} from "./ExtratoActions";
import { ExtratoState, Ordenacao, TiposTransacao } from "./ExtratoInterfaces";

import { Action } from "../interfaces";
import { DropdownItem } from "../../components/Inputs/Dropdown/DropdownInterface";

const obterFiltrosLimpos = () =>
  Object.assign({
    placas: [],
    centrosCusto: [],
    tiposTransacao: [],
    dataInicio: new Date(new Date().setDate(new Date().getDate() - 6)),
    dataFim: new Date(),
    ordem: Ordenacao.Descendente,
  });

const INITIAL_STATE = {
  documento: "",
  saldo: 0,
  filtros: { placas: [], centrosCusto: [], tiposTransacao: TiposTransacao },
  filtrosSelecionados: obterFiltrosLimpos(),
  tabela: [],
  totalPaginas: 0,
  paginaSelecionada: 1,
  registrosPorPagina: 10,
  mensagemErro: "",
} as ExtratoState;

const atribuirDocumento = (
  state: ExtratoState,
  action: AtribuirDocumentoAction
) => ({
  ...state,
  documento: action.documento,
  clienteId: action.clienteId,
  token: action.token,
});

const atribuirSaldo = (state: ExtratoState, action: AtribuirSaldoAction) => ({
  ...state,
  saldo: action.saldo,
});

const atribuirTabela = (state: ExtratoState, action: AtribuirTabelaAction) => ({
  ...state,
  tabela: action.tabela,
  totalPaginas: action.totalPaginas,
});

const atribuirFiltros = (
  state: ExtratoState,
  action: AtribuirFiltrosAction
) => {
  const placas = action.placas.map(
    (placa: string, index: number): DropdownItem => ({
      valor: index + 1,
      texto: placa,
    })
  );

  const centrosCusto = action.centrosCusto.map(
    (centroCusto: number, index: number): DropdownItem => ({
      valor: index + 1,
      texto: centroCusto.toString(),
    })
  );

  return { ...state, filtros: { ...state.filtros, placas, centrosCusto } };
};

const atribuirMensagemErro = (
  state: ExtratoState,
  action: MensagemErroFiltroAction
) => ({ ...state, mensagemErro: action.mensagem });

const filtrarPorPlaca = (
  state: ExtratoState,
  action: FiltrarPorPlacasAction
) => ({
  ...state,
  filtrosSelecionados: {
    ...state.filtrosSelecionados,
    placas: action.placas,
  },
  paginaSelecionada: 1,
});

const filtrarPorPeriodo = (
  state: ExtratoState,
  action: FiltrarPorPeriodoAction
) => ({
  ...state,
  filtrosSelecionados: {
    ...state.filtrosSelecionados,
    dataInicio: action.dataInicio,
    dataFim: action.dataFim,
  },
  paginaSelecionada: 1,
});

const filtrarPorTipoTransacao = (
  state: ExtratoState,
  action: FiltrarPorTiposTransacaoAction
) => ({
  ...state,
  filtrosSelecionados: {
    ...state.filtrosSelecionados,
    tiposTransacao: action.tiposTransacao,
  },
  paginaSelecionada: 1,
});

const filtrarPorCentroCustos = (
  state: ExtratoState,
  action: FiltrarPorCentrosCustoAction
) => ({
  ...state,
  filtrosSelecionados: {
    ...state.filtrosSelecionados,
    centrosCusto: action.centrosCusto,
  },
  paginaSelecionada: 1,
});

const ordenar = (state: ExtratoState, action: OrdenarAction) => ({
  ...state,
  filtrosSelecionados: {
    ...state.filtrosSelecionados,
    ordenacao: action.ordenacao,
    ordenarPor: action.ordenarPor,
  },
  paginaSelecionada: 1,
});

const modificarPagina = (
  state: ExtratoState,
  action: ModificarPaginaAction
) => ({
  ...state,
  paginaSelecionada: action.paginaSelecionada,
  registrosPorPagina: action.registrosPorPagina,
});

const limparFiltros = (state: ExtratoState) => ({
  ...state,
  filtrosSelecionados: obterFiltrosLimpos(),
});

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.AtribuirDocumento:
      return atribuirDocumento(state, action as AtribuirDocumentoAction);
    case Types.AtribuirSaldo:
      return atribuirSaldo(state, action as AtribuirSaldoAction);

    case Types.AtribuirTabela:
      return atribuirTabela(state, action as AtribuirTabelaAction);

    case Types.AtribuirFiltros:
      return atribuirFiltros(state, action as AtribuirFiltrosAction);

    case Types.FiltrarPorPlacas:
      return filtrarPorPlaca(state, action as FiltrarPorPlacasAction);

    case Types.FiltrarPorPeriodo:
      return filtrarPorPeriodo(state, action as FiltrarPorPeriodoAction);

    case Types.FiltrarPorTipoTransacao:
      return filtrarPorTipoTransacao(
        state,
        action as FiltrarPorTiposTransacaoAction
      );

    case Types.FiltrarPorCentrosCusto:
      return filtrarPorCentroCustos(
        state,
        action as FiltrarPorCentrosCustoAction
      );

    case Types.Ordenar:
      return ordenar(state, action as OrdenarAction);

    case Types.ModificarPagina:
      return modificarPagina(state, action as ModificarPaginaAction);

    case Types.LimparFiltros:
      return limparFiltros(state);

    case Types.MensagemFiltro:
      return atribuirMensagemErro(state, action as MensagemErroFiltroAction);

    default:
      return state;
  }
};
