import * as React from 'react';
import Campo, { PropsBase } from '../CampoBase';
import './CampoComLink.scss';

interface Props extends PropsBase {
  textoLink: string,
  onClick: Function,
}

export default ({ name, titulo, value, mensagemValidacao, onChange, onBlur, textoLink, onClick, children }: Props) => (
  <Campo
    name={name}
    titulo={titulo}
    type='text'
    value={value}
    mensagemValidacao={mensagemValidacao}
    classeExtra='campo-com-link'
    onChange={onChange}
    onBlur={onBlur}
  >
    {children}
    <button className='botao-link' type='button' onClick={() => onClick()}>{textoLink}</button>
  </Campo>
);
