import axios from 'axios';

const urlBase = 'seguranca/usuario/api/';

const montarParametros = (senhaAtual: string, senhaNova: string) => ({
  senhaAtual,
  novaSenha: senhaNova,
  confirmacaoNovaSenha: senhaNova,
}
);

export default {
  alterarSenha: (login: string, senhaAtual: string, senhaNova: string) => {
    const parametros = montarParametros(senhaAtual, senhaNova);
    return axios.post(`${(window as any).config.url_api_management}${urlBase}v1/${login}/alterarsenha`, parametros, {
      headers: { 'Ocp-Apim-Subscription-Key': `${(window as any).config.subscription_api_management}` },
    });
  },
};
