import {
  call, takeEvery, put, take, select,
} from 'redux-saga/effects';
import Actions, { Types, AtualizarContatoAction, AtualizarEnderecoAction, TrocarSenhaAction } from './DadosContaActions';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../autenticacao/AutenticacaoActions';
import ClienteApi from '../../infrastructure/cliente-api';
import SegurancaApi from '../../infrastructure/seguranca-api';
import { State } from '../interfaces';
import GeneralActions from '../actions';

const obterClienteId = (state: State): number => state.autenticacao.clienteId;
const obterBearerToken = (state: State): string => state.autenticacao.bearerToken;
const obterDocumento = (state: State): string => state.autenticacao.documento;

const formatarMensagemErro = (erro) => {
  if (erro.response && erro.response.data.notificacoes) return erro.response.data.notificacoes[0];
  return erro.message;
};

function* buscarDadosConta() {
  try {
    const bearerToken = yield select(obterBearerToken);

    const { data } = yield call(ClienteApi.buscarDadosConta, bearerToken);

    yield put(Actions.atribuirDadosConta(data.dados.contato, data.dados.endereco));
  } catch (erro) {
    alert(erro);
  }
}

function* atualizarContato(action: AtualizarContatoAction) {
  const { contato } = action;

  try {
    const clienteId = yield select(obterClienteId);
    const bearerToken = yield select(obterBearerToken);

    yield put(Actions.iniciarCarregamento());
    yield call(ClienteApi.atualizarDadosConta, clienteId, bearerToken, contato);
    yield put(Actions.atribuirContato(contato));
    yield put(Actions.finalizarCarregamento());
    yield put(Actions.atribuirMensagemErro(''));
  } catch (erro) {
    yield put(Actions.atribuirMensagemErro(formatarMensagemErro(erro)));
    yield put(Actions.finalizarCarregamento());
  }
}

function* atualizarEndereco(action: AtualizarEnderecoAction) {
  const { endereco } = action;

  try {
    const documento = yield select(obterDocumento);
    const bearerToken = yield select(obterBearerToken);

    yield put(Actions.iniciarCarregamento());
    yield call(ClienteApi.atualizarDadosEndereco, documento, bearerToken, endereco);
    yield put(Actions.atribuirEndereco(endereco));
    yield put(Actions.finalizarCarregamento());
    yield put(Actions.atribuirMensagemErro(''));
  } catch (erro) {
    yield put(Actions.atribuirMensagemErro(formatarMensagemErro(erro)));
    yield put(Actions.finalizarCarregamento());
  }
}

function* trocarSenha(action: TrocarSenhaAction) {
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const documento = yield select(obterDocumento);
    const { senhaAtual, senhaNova } = action;

    yield call(SegurancaApi.alterarSenha, documento, senhaAtual, senhaNova);
    yield put(GeneralActions.terminarRequisicao());
    yield put(Actions.toggleFormularioSenha(false));
  } catch (erro) {
    alert(erro);
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarDadosConta() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield call(buscarDadosConta);
}

export default function* () {
  yield takeEvery(Types.InicializarDadosConta, inicializarDadosConta);
  yield takeEvery(Types.AtualizarContato, atualizarContato);
  yield takeEvery(Types.AtualizarEndereco, atualizarEndereco);
  yield takeEvery(Types.TrocarSenha, trocarSenha);
}
