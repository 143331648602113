import { Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import './NovaRecarga.scss';

import NovaRecargaStyles from './NovaRecargaStyles';

const NovaRecarga = () => {
  const styles = NovaRecargaStyles();

  return (
    <section>
      <div className={styles.GridBottomSpacing}>
        <h2>Nova Recarga</h2>
      </div>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.BoxNovaRecarga}>
        <div id='icone_nova_recarga' className='marginLeftDefault marginRightDefault'>
          <span className={styles.ImagemNovaRecarga} />
        </div>
        <Grid id='grid_tipografia' item xs={9} sm={9} md={10} lg={10} xl={10}>
          <Typography
            className={styles.TextoNovaRecarga}
          >
            Logo após a atualização do saldo, o seu ConectCar estará liberado para uso em rodovias estaduais do Estado de São Paulo, shoppings e estacionamentos.
          </Typography>
          <Typography
            className={styles.TextoNovaRecarga}
          >
            Para uso nos demais pedágios, pedimos que aguarde por um período de até 6 horas.
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default NovaRecarga;
