
import React from 'react';

import { DadosEnderecoProvider } from './useControleBoxDadosEndereco/useControleBoxDadosEndereco';
import { EnderecoSelecionadoProvider } from './useControleEnderecoSelecionado/useControleEnderecoSelecionado';
import { ControleModalConfirmarPedidoProvider } from './useControleModalConfirmarPedido/useControleModalConfirmarPedido';
import { ControleModalErroProvider } from './useControleModalErro/useControleModalErro';
import { LoadingControleProvider } from './useLoadingControle/useLoadingControle';

export const ContextoGlobal: React.FC = ({ children }) => (
  <ControleModalErroProvider>
    <LoadingControleProvider>
      <DadosEnderecoProvider>
        <ControleModalConfirmarPedidoProvider>
          <EnderecoSelecionadoProvider>
            {children}
          </EnderecoSelecionadoProvider>
        </ControleModalConfirmarPedidoProvider>
      </DadosEnderecoProvider>
      ;
    </LoadingControleProvider>
  </ControleModalErroProvider>
);
