export function downloadFile(data, name = 'arquivo-conectcar.pdf') {
  const byteCharacters = atob(data);

  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  fetch(name).then((response) => {
    response.blob().then(() => {
      const blob = new Blob([byteArray]);
      const fileURL = URL.createObjectURL(blob);
      const alink = document.createElement('a');

      alink.href = fileURL;
      alink.download = name;
      alink.click();

      URL.revokeObjectURL(fileURL);
    }).catch(error => console.log(error));
  })
    .catch((error) => {
      console.log(error);
    });
}
