import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvioRecargaRequest, FormaPagamentoOnline, RecargaState } from '../../../RecargaInterfaces';
import MetodosPagamentoStyles from '../MetodosPagamentoStyles';
import Actions from '../../../RecargaActions';
import { State } from '../../../../interfaces';

import InputRecarga from '../../../Components/InputValorRecarga/InputRecarga';
import BoxPagamentoControlado from '../../../../../components/BoxPagamentoControlado';
import CarregarRetornoPix from '../../../Acoes/Pix/CarregarRetornoPix';

const BoxPagamentoPix = () => {
  const {
    dadosRecargaPix,
    documento,
    adesaoId,
    boxPagamento,
  } = useSelector<State, RecargaState>(state => ({
    dadosRecargaPix: state.recarga.dadosRecargaPix,
    documento: state.recarga.documento,
    adesaoId: state.recarga.adesaoId,
    boxPagamento: state.recarga.boxPagamento,
  }));

  const dispatch = useDispatch();
  const metodosPagamentoStyles = MetodosPagamentoStyles();

  const [valorCredito, setValorCredito] = React.useState(0);

  const setBoxPixOpen = () => {
    dispatch(Actions.atualizarStateAccordion('pix', !(boxPagamento.pix)));
  };

  const handlerValorPixChange = (valor) => {
    setValorCredito(valor.floatValue);
  };

  const configurarInputValorPix = () => (
    <InputRecarga
      id='Recarga_Input_Valor_Pix'
      onChange={handlerValorPixChange}
    />
  );

  const handlerPagamentoPix = () => {
    const invalido = valorCredito <= 0;

    if (!invalido) {
      const novaRecarga: EnvioRecargaRequest = {
        AdesaoId: adesaoId,
        FormaPagamentoOnline: FormaPagamentoOnline.Pix,
        Documento: documento,
        ServicoRecargaId: 0,
        ValorDeCredito: valorCredito,
      };

      dispatch(Actions.carregarParametrosRecargaRequest(novaRecarga));
      dispatch(Actions.cadastrarRecarga());
    } else {
      dispatch(Actions.atualizarAvisoRecarga('erro', 'Favor selecionar um valor válido para o Pix.', true));
    }
  };

  return (
    <section id='section-pix'>
      <div>
        <BoxPagamentoControlado
          botaoValorTexto='Pagar'
          descricaoTitulo='Aprovação imediata todos os dias, incluindo feriados.'
          id='Recarga_Rb_Pix'
          btnID='Recarga_Bt_PixPagar'
          inputValor={configurarInputValorPix()}
          labelValor='Informe o valor da recarga'
          logotipo={`${metodosPagamentoStyles.logotiposBaseCss} ${metodosPagamentoStyles.pixLogotipo}`}
          onButtonClick={handlerPagamentoPix}
          titulo='Pix'
          boxOpen={boxPagamento?.pix}
          setBoxOpen={setBoxPixOpen}
        />

      </div>
      <div id='pix-pagamento-complementos'>
        {dadosRecargaPix?.dados != null
          && <CarregarRetornoPix />}
      </div>
    </section>
  );
};

export default BoxPagamentoPix;
