import * as React from 'react';
import './Checkbox.scss';
import { DescricaoLink } from './CheckboxInterfaces';

interface Props {
  descricao: string,
  onCheckChange: Function,
  estaChecado?: boolean,
  links?: DescricaoLink[]
}

const Checkbox = ({ descricao, estaChecado, onCheckChange, links }: Props) => {
  const [checked, setChecked] = React.useState<boolean>(estaChecado === null || estaChecado === undefined ? false : estaChecado);

  const onItemSelecionado = () => {
    const updatedCheck = !checked;
    setChecked(updatedCheck);
    onCheckChange(updatedCheck);
  };

  const obterElementoSpanComLinks = () => {
    let descricaoSpan = descricao;
    let descricaoIndexAtual = 0;

    const obterElementoLink = link => (<a href={link.url} key={`link${descricaoIndexAtual}`} target='_blank' rel='noopener noreferrer'>{link.texto}</a>);

    const obterTrechoSpan = trecho => (<span key={`desc${descricaoIndexAtual}`}>{trecho}</span>);

    const obterLinkNaDescricao = (link) => {
      const trechos = descricaoSpan.split(`{${link.chave}}`);
      descricaoSpan = trechos.join('');
      return { index: trechos[0].length, link };
    };

    const obterTrechoSpanComLink = (linkNaDescricao) => {
      const trecho = descricaoSpan.substring(descricaoIndexAtual, linkNaDescricao.index);
      descricaoIndexAtual = linkNaDescricao.index;

      return [obterTrechoSpan(trecho), obterElementoLink(linkNaDescricao.link)];
    };

    const linksNaDescricao = links.map(link => obterLinkNaDescricao(link));
    const conteudos = linksNaDescricao.map(linkNaDescricao => obterTrechoSpanComLink(linkNaDescricao));
    conteudos.push(obterTrechoSpan(descricaoSpan.substring(descricaoIndexAtual, descricaoSpan.length)) as any);

    return (<span className='checkbox-label'>{conteudos}</span>);
  };

  const obterElementoSpan = () => {
    if (links && links.length > 0) return obterElementoSpanComLinks();

    return (<span className='checkbox-label'>{descricao}</span>);
  };

  return (
    <div id='checkbox' data-testid='checkbox'>
      <input
        data-testid='checkbox-input'
        type='checkbox'
        name={descricao}
        checked={checked}
        title={descricao}
        onChange={() => onItemSelecionado()}
      />
      {obterElementoSpan()}
    </div>
  );
};

export default Checkbox;
