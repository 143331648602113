import { RespostaApi } from '../../../infrastructure/ApiInterfaces';
import { Action } from '../../interfaces';
import { PedidosCompras } from './MeusPedidosInterfaces';

export enum Types {
  InicializarMeusPedidos = 'INICIALIZAR_MEUS_PEDIDOS',
  AtribuirDocumento = 'ATRIBUIR_DOCUMENTO',
  BuscarMeusPedidosRequisicao = 'BUSCAR_MEUS_PEDIDOS_REQUISICAO',
  BuscarMeusPedidosSucesso = 'BUSCAR_MEUS_PEDIDOS_SUCESSO',
  BuscarMeusPedidosError = 'BUSCAR_MEUS_PEDIDOS_ERROR',
  ModificarPagina = 'MODIFICAR_PAGINA',
  AtualizarTotalPaginas = 'ATUALIZANDO_PAGINACAO',
  ResetarPaginacao = 'RESETAR_PAGINACAO',
  LimparTabela = 'LIMPAR_TABELA',
}
export interface InicializarMeusPedidos extends Action { }
export interface AtribuirDocumentoAction extends Action {
  documento: string,
  clienteId: string,
  token: string,
}
export interface ObterMeusPedidosRequisicaoAction extends Action { }
export interface ObterMeusPedidosSucessoAction extends Action { meusPedidos: RespostaApi<PedidosCompras[]> }
export interface ObterMeusPedidosErrorAction extends Action { }


export interface AtualizarTotalPaginasAction extends Action { totalPaginas: number }
export interface ResetarPaginacaoAction extends Action { }
export interface LimparTabelaAction extends Action { }

export interface ModificarPaginaAction extends Action {
  registrosPorPagina: number,
  paginaSelecionada: number
}

export default {
  inicializarMeusPedidos: (): InicializarMeusPedidos => ({ type: Types.InicializarMeusPedidos }),
  atribuirDocumento: (documento: string, clienteId: string, token: string): AtribuirDocumentoAction => ({ type: Types.AtribuirDocumento, documento, clienteId, token }),
  buscarMeusPedidosRequest: (): ObterMeusPedidosRequisicaoAction => ({ type: Types.BuscarMeusPedidosRequisicao }),
  buscarMeusPedidosSucesso: (meusPedidos: RespostaApi<PedidosCompras[]>): ObterMeusPedidosSucessoAction => ({ type: Types.BuscarMeusPedidosSucesso, meusPedidos }),
  buscarMeusPedidosError: (): ObterMeusPedidosErrorAction => ({ type: Types.BuscarMeusPedidosError }),

  atualizarTotalPaginas: (totalPaginas: number): AtualizarTotalPaginasAction => ({ type: Types.AtualizarTotalPaginas, totalPaginas }),
  resetarPaginacao: (): ResetarPaginacaoAction => ({ type: Types.ResetarPaginacao }),
  limparTabela: (): LimparTabelaAction => ({ type: Types.LimparTabela }),
};
