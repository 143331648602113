export default async () => {
  const config = {
    prospeccao_api_url: process.env.PROSPECCAO_API_URL,
    prospeccao_api_key: process.env.PROSPECCAO_API_KEY,
    cep_api_url: process.env.CEP_API_URL,
    cep_api_key: process.env.CEP_API_KEY,
    codigo_ga: process.env.CODIGO_GA,
    codigo_gtm: process.env.CODIGO_GTM,
    canalVendasId: process.env.CANALVENDASID,
    urlTelevendas: process.env.URLTELEVENDAS,
    url_api_management: process.env.URL_API_MANAGEMENT,
    url_portal_cliente: process.env.URL_PORTAL_CLIENTE,
    subscription_api_management: process.env.SUBSCRIPTION_API_MANAGEMENT,
    txt_opt_in_aceite_itau: process.env.TXT_OPT_IN_ACEITE_ITAU,
    split_io_authorization_key: process.env.SPLIT_IO_AUTHORIZATION_KEY,
    pedido_api_key: process.env.PEDIDO_API_KEY,
    promocoes_ativas: process.env.PROMOCOES_ATIVAS,
    validar_api_identidade: process.env.VALIDAR_API_IDENTIDADE,
    parceiroId: process.env.PARCEIROID,
    CodigoGTMEmpresasConectCarFrotas: process.env.CodigoGTMEmpresasConectCarFrotas,
  };

  (window as any).config = config;
};
