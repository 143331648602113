import React from 'react';
import InputValor from '../../../../components/Inputs/InputValor/InputValor';
import InputRecargaStyles from './InputRecargaStyles';

interface Props {
  id?: string;
  onChange?: any;
}

const InputRecarga = ({ onChange, id } : Props) => {
  const style = InputRecargaStyles().inputValorMetodoPagamento;

  return (
    <InputValor
      identificador={id}
      inputstyle={style}
      onChange={onChange}
      prefixo='R$'
    />
  );
};

export default InputRecarga;
