import {
  Divider, InputBase, makeStyles, Paper, TextField, withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import InputMask from 'react-input-mask';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface SubstituicaoAutocompleteProps {
  parameters: {
    className: any;
    getOptionLabel: any;
    id: any;
    onChange: any;
    options: any;
    renderOption: any;
    key?: any;
    noOptionsText: string;
  },
  placeholder?: any;
}

export const SubstituicaoAutocomplete = ({ parameters, placeholder }: SubstituicaoAutocompleteProps) => {
  const CustomPaper = props => <Paper elevation={8} {...props} />;

  const autoCompleteClasses = makeStyles(() => ({
    inputRoot: {
      background: 'white !important',
      borderRadius: 10,
    },
    option: {
      '&:hover': {
        backgroundColor: '#f8f8f8',
      },
      '&:active': {
        backgroundColor: '#e4e4e4',
      },
    },
  }))();

  const textfieldClasses = makeStyles(() => ({
    inputBase: {
      fontSize: 16,
      padding: '8px 0 7px',
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: '#2A3037',
      },
    },
  }))();

  return (
    <React.Fragment>
      <Autocomplete
        {...parameters}
        data-testid={parameters.id}
        popupIcon={<KeyboardArrowDownIcon />}
        disableClearable
        ListboxProps={{
          style: {
            maxHeight: '250px',
            overflow: 'auto !important',
          },
        }}
        PaperComponent={CustomPaper}
        classes={autoCompleteClasses}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            className={textfieldClasses.inputBase}
            variant='outlined'
          />
        )}
      />
    </React.Fragment>
  );
};

interface SubstituicaoMaskInputProps {
  className?: string;
  id?: any;
  mask: string;
  onChange: any;
  placeholder: string;
  value: any;
  autoComplete: string;
}

export const SubstituicaoMaskInput = ({ value, onChange, placeholder, className, id, mask, autoComplete }: SubstituicaoMaskInputProps) => (
  <InputMask
    mask={mask}
    maskChar={null}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
  >
    {inputProps => <SubstituicaoInput {...inputProps} autoComplete={autoComplete} className={className} id={id} />}
  </InputMask>
);

export const SubstituicaoInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    height: '30px',
    fontSize: 14,
    padding: '10px 12px 10px 12px',
    fontFamily: [
      '-apple-system',
      'Lato',
      'Arial',
      'sans-serif',
    ].join(','),
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: 10,
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'black',
    },
  },
}))(InputBase);

export const SubstituicaoDivider = withStyles(() => ({
  root: {
    background: 'white !important',
    width: '50%',
  },

}))(Divider);
