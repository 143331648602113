import * as React from 'react';
import './PlanoPageSimulador.scss';
import { useSelector } from 'react-redux';
import { formatarDinheiro } from '../../../../infrastructure/formatador-string';
import { State } from '../../../interfaces';
import { DadosIntegracaoPlataforma } from '../../../../infrastructure/prospecto-api/rascunho/RascunhoInterfaces';

const SimuladorInvestimento = () => {
  const precoVeiculoLeve = useSelector<State, number>(state => state.cadastro.precoVeiculoLeve);
  const precoVeiculoPesado = useSelector<State, number>(state => state.cadastro.precoVeiculoPesado);
  const quantidadeVeiculosLeves = useSelector<State, number>(state => state.cadastro.plano.veiculosLeves);
  const quantidadeVeiculosPesados = useSelector<State, number>(state => state.cadastro.plano.veiculosPesados);
  const mesesIsencaoMensalidade = useSelector<State, number>(state => state.cadastro.mesesIsencaoMensalidade);
  const percentualIsencaoMensalidade = useSelector<State, number>(state => state.cadastro.percentualIsencaoMensalidade);

  const {
    plataformaDescricao,
  } = useSelector<State, DadosIntegracaoPlataforma>(state => state.cadastro.dadosIntegracaoPlataforma);

  const validarSeOrigemLandingPageHubspot = () => plataformaDescricao?.toLowerCase() === 'hubspot';

  const ofertaAtiva = true;

  const valorTotalVeiculosLeves = () => quantidadeVeiculosLeves * precoVeiculoLeve;

  const valorTotalVeiculosPesados = () => quantidadeVeiculosPesados * precoVeiculoPesado;

  const valorTotalMensalidade = () => valorTotalVeiculosLeves() + valorTotalVeiculosPesados();

  const valorComPercentualIsencao = () => valorTotalMensalidade() * (1 - (percentualIsencaoMensalidade / 100));

  const descricaoSuperOferta = () => (mesesIsencaoMensalidade > 1
    ? `Nos primeiros ${mesesIsencaoMensalidade} meses`
    : 'No primeiro mês');

  return (
    <section id='simulador-investimento' className={`${ofertaAtiva ? 'mostrar-oferta' : ''}`}>
      <section className={`informacoes-mensalidade ${validarSeOrigemLandingPageHubspot() && 'hubspot'}`}>
        <span className='titulo'>Mensalidade</span>
        {!validarSeOrigemLandingPageHubspot()
          && (
            <React.Fragment>
              <div className='informacoes'>
                <div className='legenda'>
                  <span className='rotulo'>Veículos leves</span>
                  <span className='informacao-adicional'>
                    {formatarDinheiro(precoVeiculoLeve)}
                    /veículo
                  </span>
                </div>
                <div className={`informacoes-oferta ${ofertaAtiva === true ? 'mostrar' : ''}`}>
                  <span className='valor-mensalidade--aplciar-promocao'>{formatarDinheiro(valorTotalVeiculosLeves())}</span>
                </div>
              </div>
              <div className='informacoes'>
                <div className='legenda'>
                  <span className='rotulo-item'>Veículos pesados</span>
                  <span className='informacao-adicional'>
                    {formatarDinheiro(precoVeiculoPesado)}
                    /veículo
                  </span>
                </div>
                <div>
                  <span>{formatarDinheiro(valorTotalVeiculosPesados())}</span>
                </div>
              </div>
            </React.Fragment>
          )
        }
        <hr />
        <div className='informacoes'>
          <span className='rotulo'>Mensalidade Total</span>
          <span className='total'>{formatarDinheiro(valorTotalMensalidade())}</span>
        </div>

        {mesesIsencaoMensalidade > 0
          && (
            <div className='informacoes'>
              <span className='rotulo rotulo-promocao'>Super Oferta Conectcar</span>
              <div className='promocao-valores'>
                <span className='total total-promocao'>{formatarDinheiro(valorComPercentualIsencao())}</span>
                <span className='total meses-promocao'>{descricaoSuperOferta()}</span>
              </div>
            </div>
          )
        }
      </section>
    </section>
  );
};
export default SimuladorInvestimento;
