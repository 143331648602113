import React, {
  createContext, ReactChild, ReactChildren, useContext, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLoadingControle } from '../../../hooks/useLoadingControle/useLoadingControle';
import { IContatoAdicionalCliente } from '../../../models/IContatoAdicionalCliente';
import { TipoContato } from '../../../models/TipoContato';
import { atualizarContatoAdicional } from '../../../services/requests/AvisoSaldo/atualizarContatoAdicional';
import { AtualizarContatoAdicionalRequest } from '../../../services/requests/AvisoSaldo/atualizarContatoAdicional/interface';
import { cadastrarAlertaSaldo } from '../../../services/requests/AvisoSaldo/cadastrarAlertaSaldo';
import { CadastrarAlertaSaldoRequest } from '../../../services/requests/AvisoSaldo/cadastrarAlertaSaldo/interface';
import { cadastrarContatoAdicional } from '../../../services/requests/AvisoSaldo/cadastrarContatoAdicional';
import { CadastrarContatoAdicionalRequest } from '../../../services/requests/AvisoSaldo/cadastrarContatoAdicional/interface';
import { obterContatosAdicionais } from '../../../services/requests/AvisoSaldo/obterContatosAdicionais';
import { obterLimiteContatosAdicionais } from '../../../services/requests/AvisoSaldo/obterLimiteContatosAdicionais';
import { State } from '../../interfaces';
import { RecargaState } from '../RecargaInterfaces';
import { IAvisoSaldoContexto } from './IAvisoSaldoContexto';

interface ChildrenProps {
  children: ReactChild | ReactChildren;
}

const tipoContatoAlertaSaldo: number = TipoContato.AlertaSaldo;

const AvisoSaldoContexto = createContext<IAvisoSaldoContexto>({} as IAvisoSaldoContexto);

const AvisoSaldoProvider = ({ children }: ChildrenProps) => {
  const {
    dadosIndex,
  } = useSelector<State, RecargaState>(state => ({
    dadosIndex: state.recarga.dadosIndex,
  }));

  const [valorAviso, setValorAviso] = useState<number>(dadosIndex?.dados?.valores?.recargaOnline?.configuracaoPlano?.avisoSaldo?.valor || 0);
  const [valorAvisoNoLoadTela, setValorAvisoNoLoadTela] = useState<number>(valorAviso);
  const [contatosAdicionais, setContatosAdicionais] = useState<IContatoAdicionalCliente[]>([]);
  const [flagReceberAvisoSaldo, toggleFlagReceberAvisoSaldo] = useState<boolean>(dadosIndex?.dados?.valores?.avisaSaldoMinimo || true);
  const [mensagemErro, setMensagemErro] = useState<string>('');
  const [quantidadeLimiteContatosAdicionais, setQuantidadeLimiteContatosAdicionais] = useState<number>(1);
  const [desabilitarBotaoAdicionarEmail, setDesabilitarBotaoAdicionarEmail] = useState<boolean>(contatosAdicionais.length >= quantidadeLimiteContatosAdicionais);

  const { alterarEstadoCarregando } = useLoadingControle();

  const handleAdicionarNovoContato = () => {
    const novoContato: IContatoAdicionalCliente = {
      contactClientId: null,
      email: '',
    };

    setContatosAdicionais([...contatosAdicionais, novoContato]);
  };

  const handleAlterarValorSaldo = (request: CadastrarAlertaSaldoRequest) => {
    alterarEstadoCarregando(true);

    cadastrarAlertaSaldo(request)
      .then(() => setValorAvisoNoLoadTela(valorAviso))
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes))
      .finally(() => alterarEstadoCarregando(false));
  };

  const handleToggleAvisoSaldoCheckbox = (request: CadastrarAlertaSaldoRequest) => {
    alterarEstadoCarregando(true);

    cadastrarAlertaSaldo(request)
      .then(() => toggleFlagReceberAvisoSaldo(!flagReceberAvisoSaldo))
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes))
      .finally(() => alterarEstadoCarregando(false));
  };

  const handleInativarContato = (request: AtualizarContatoAdicionalRequest) => {
    alterarEstadoCarregando(true);

    atualizarContatoAdicional(request)
      .then(() => setContatosAdicionais(contatosAdicionais.filter(c => c.contactClientId !== request.contatoId)))
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes))
      .finally(() => alterarEstadoCarregando(false));
  };

  const handleSalvarNovoContato = (request: CadastrarContatoAdicionalRequest, callback: Function) => {
    alterarEstadoCarregando(true);

    cadastrarContatoAdicional(request)
      .then((response) => {
        const arrayContatosAtualizado = contatosAdicionais.map((c: IContatoAdicionalCliente) => (!c.contactClientId
          ? { email: response.data.dados.email, contactClientId: response.data.dados.contactClientId }
          : c));

        setContatosAdicionais(arrayContatosAtualizado);
        callback();
      })
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes))
      .finally(() => alterarEstadoCarregando(false));
  };

  const handleAtualizarContato = (request: AtualizarContatoAdicionalRequest, callback: Function) => {
    alterarEstadoCarregando(true);

    atualizarContatoAdicional(request)
      .then(() => {
        const arrayContatosAtualizado = contatosAdicionais.map((c: IContatoAdicionalCliente) => (c.contactClientId === request.contatoId
          ? { ...c, email: request.email as string }
          : c));

        setContatosAdicionais(arrayContatosAtualizado);
        callback();
      })
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes))
      .finally(() => alterarEstadoCarregando(false));
  };

  useEffect(() => {
    if (contatosAdicionais.length >= quantidadeLimiteContatosAdicionais || contatosAdicionais.some(c => !c.contactClientId)) {
      setDesabilitarBotaoAdicionarEmail(true);
    } else {
      setDesabilitarBotaoAdicionarEmail(false);
    }
  }, [contatosAdicionais, contatosAdicionais.length, quantidadeLimiteContatosAdicionais]);

  useEffect(() => {
    obterLimiteContatosAdicionais()
      .then(response => setQuantidadeLimiteContatosAdicionais(response.data.dados))
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes));
  }, []);

  useEffect(() => {
    alterarEstadoCarregando(true);

    obterContatosAdicionais(tipoContatoAlertaSaldo)
      .then(response => setContatosAdicionais(response.data.dados))
      .catch(error => setMensagemErro(error?.response?.data?.notificacoes))
      .finally(() => alterarEstadoCarregando(false));
  }, [alterarEstadoCarregando]);

  return (
    <AvisoSaldoContexto.Provider value={{
      contatosAdicionais,
      desabilitarBotaoAdicionarEmail,
      flagReceberAvisoSaldo,
      mensagemErro,
      quantidadeLimiteContatosAdicionais,
      valorAviso,
      valorAvisoNoLoadTela,
      handleAdicionarNovoContato,
      handleInativarContato,
      handleAtualizarContato,
      handleSalvarNovoContato,
      handleAlterarValorSaldo,
      handleToggleAvisoSaldoCheckbox,
      setDesabilitarBotaoAdicionarEmail,
      setMensagemErro,
      setValorAviso,
      toggleFlagReceberAvisoSaldo,
    }}
    >
      {children}
    </AvisoSaldoContexto.Provider>
  );
};

const useAvisoSaldo = () => {
  const context = useContext(AvisoSaldoContexto);
  return context;
};

export { AvisoSaldoContexto, AvisoSaldoProvider, useAvisoSaldo };
