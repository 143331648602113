import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';

import './SubstituicaoTitulo.scss';
import './SubstituicaoTituloResponsive.scss';

const SubstituicaoAdesivoTitulo = () => (
  <Grid container className='substituicao-titulo' spacing={1} alignContent='center' alignItems='center'>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography
        variant='h3'
        className='substituicao-titulo-texto'
      >
        Aqui vamos te ajudar a realizar a troca de seus adesivos!
      </Typography>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography
        variant='subtitle1'
        className='substituicao-titulo-subtitulo'
      >
        Selecione a placa e o número do adesivo que deseja trocar
      </Typography>
    </Grid>
  </Grid>
);

export default memo(SubstituicaoAdesivoTitulo);
