import { FormControl, makeStyles, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Utils from '../../../common/Utils';
import './HistoricoRecargaFiltros.scss';


interface Props {
  handlePeriodosChange: any,
  onPesquisarClick: any,
  onLimparFiltrosClick: any,
  carregando: boolean,
  menssagemErro: string,
  filtrosAplicado: boolean,
  mesInicioSelecionado: string,
  mesFimSelecionado: string,
}

const UseStylesField = makeStyles({
  root: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff6339',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'ff6339',
    },
    '& .MuiInputLabel-outlined': {
      color: 'black',
    },
  },
});

const recargaStatus = [{ id: 'AguardandoPagamento', nome: 'Aguardando Pagamento' }, { id: 'RecargaRealizada', nome: 'Recarga Creditada' }];

const HistoricoRecargaFiltros = ({
  handlePeriodosChange,
  onPesquisarClick,
  onLimparFiltrosClick,
  carregando,
  menssagemErro,
  filtrosAplicado,
  mesInicioSelecionado,
  mesFimSelecionado,

}: Props) => {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const useStylesField = UseStylesField();


  React.useEffect(() => {
    setLabelWidth(inputLabel?.current?.offsetWidth || 0);
  }, []);

  return (
    <section className='filtro-recarga-container recarga-page-historico'>
      <section className='filtros-container'>
        <div className='campos-filtro-status'>
          <div className='label-status'><label className='labels-historico-recarga'>Status: </label></div>
          <div className='filtro-selecionar-status'>
            <FormControl fullWidth variant='outlined' className={useStylesField.root}>
              <Select
                required
                defaultValue=''
                native
                id='status'
                labelWidth={labelWidth}
                onChange={handlePeriodosChange('status')}
              >
                <option value=''>
                  Selecione
                </option>
                {recargaStatus.map(method => (
                  <option key={method.id} value={method.id}>
                    {method.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {!filtrosAplicado && (
          <div className='filtros-nao-aplicado'>
            <div className='label-status'><label className='labels-historico-recarga'>Período: </label></div>
            <div className='form-periodo-recarga'>
              <TextField
                className={useStylesField.root}
                required
                type='date'
                id='dataInicio'
                onChange={handlePeriodosChange('dataInicio')}
                variant='outlined'
                fullWidth
              />
            </div>
            <div className='form-periodo-recarga'>
              <TextField
                className={useStylesField.root}
                required
                type='date'
                id='dataFim'
                fullWidth
                onChange={handlePeriodosChange('dataFim')}
                variant='outlined'
              />
            </div>
          </div>
        )
        }
        <div className='container-form-filtros-aplicado'>
          {filtrosAplicado && (
            <div className='form-filtros-aplicado'>
              <div>
                <p className='labels-historico-recarga'>Período</p>
                <p className='label-filtros-aplicados'>
                  {Utils.formatDate(mesInicioSelecionado)}
                  {' '}
                  à
                  {' '}
                  {Utils.formatDate(mesFimSelecionado)}
                  {' '}
                </p>
              </div>

            </div>
          )}

          {filtrosAplicado && (
            <div className='botao-limpar-filtros'>
              <button
                className='limpar-filtros botao-vazio'
                type='button'
                title='limpar-filtros'
                data-testid='limpar-filtros'
                onClick={() => onLimparFiltrosClick()}
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>
        <div className='form-botao-pesquisar'>
          <button
            type='button'
            className='botao-pesquisar'
            data-testid='botao-pesquisar'
            onClick={() => onPesquisarClick()}
            disabled={carregando}
          >
            {' '}
            {carregando ? 'Pesquisando...' : 'Pesquisar'}
          </button>
        </div>
      </section>
      <section className='filtros-container-msg'>
        <div className='divMensagem'>
          <div className='spanMensagem'>{menssagemErro}</div>
        </div>
      </section>
    </section>
  );
};
export default HistoricoRecargaFiltros;
