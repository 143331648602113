import axios from 'axios';
import { RespostaApi, IdApi } from '../../ApiInterfaces';
import { RepresentanteLegal, Plano, DadosEmpresa, StatusCadastro } from '../../../features/cadastro/CadastroInterfaces';
import { removerCaracteresNaoNumericos } from '../../mascara';
import { parsearFaturamento, mapearTelefone } from '../utils';

interface IdApiComAprovacao extends IdApi<string> {
  status: StatusCadastro,
}
const prospectoBffEndpoint = 'landingpage-bff/api/v1/Prospeccao';

export const clienteNaoEstaCadastrado = resposta => resposta.status === 404 && resposta.data;
export const representaErroServidor = resposta => resposta.status >= 500 && resposta.status <= 599;

const converterCadastroParaEnvio = (
  canalVendasId: number,
  representanteLegal: RepresentanteLegal,
  plano: Plano,
  dadosEmpresa: DadosEmpresa,
  aceitaReceberOfertas: boolean,
  concordaTermosAdesao: boolean,
  compartilharDados: boolean,
  compartilharDadosItau: boolean,
  senha: string,
  codigoPlano: string,
) => {
  const telefone = mapearTelefone(representanteLegal.telefone);
  const celular = mapearTelefone(representanteLegal.celular);

  return {
    canalVendasId,
    cnpj: removerCaracteresNaoNumericos(dadosEmpresa.cnpj),
    razaoSocial: dadosEmpresa.razaoSocial,
    emailsPromocionais: +aceitaReceberOfertas,
    concordaTermosAdesao: +concordaTermosAdesao,
    compartilharDados: +compartilharDados,
    compartilharDadosItau: +compartilharDadosItau,
    quantidadeAdesivos: 0,
    codigoPlano,
    enderecos: [
      {
        tipoEnderecoId: 1,
        cep: removerCaracteresNaoNumericos(dadosEmpresa.cep),
        logradouro: dadosEmpresa.logradouro,
        numero: dadosEmpresa.numero,
        bairro: dadosEmpresa.bairro,
        cidade: dadosEmpresa.cidade,
        uf: dadosEmpresa.estado,
        complemento: dadosEmpresa.complemento,
        codigoIbge: dadosEmpresa.codigoIbge,
      },
    ],
    contatos: [
      {
        principal: 1,
        nome: representanteLegal.nomeContatoComercial,
        dddTelefone: telefone.ddd,
        telefone: telefone.numero,
        dddCelular: celular.ddd,
        celular: celular.numero,
        email: representanteLegal.email,
      },
    ],
    naturezaJuridica: dadosEmpresa.naturezaJuridica,
    faturamentoMedioMensal: parsearFaturamento(dadosEmpresa.faturamentoMedioMensal),
    nomeRepresentante: representanteLegal.nomeRepresentanteLegal,
    cpf: removerCaracteresNaoNumericos(representanteLegal.cpf),
    dataRepresentante: representanteLegal.representanteDesde,
    dataConstituicao: dadosEmpresa.dataConstituicao,
    cargo: representanteLegal.cargo,
    expostaPublicamente: representanteLegal.pessoaPoliticamenteExposta,
    senha,
    dadosAdicionais: {
      quantidadeVeiculosLeves: plano.possuiAdesivos ? 0 : plano.veiculosLeves,
      quantidadeVeiculosPesados: plano.possuiAdesivos ? 0 : plano.veiculosPesados,
      possuiAdesivos: plano.possuiAdesivos,
    },
  };
};

export default {
  cadastroJaExistente: async (documento: string): Promise<RespostaApi<string>> => {
    const apiUrl = `${(window as any).config.url_api_management}${prospectoBffEndpoint}/ClientePossuiCadastro/${documento}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    try {
      const resposta = await axios.get(apiUrl, {
        headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
      });

      return resposta;
    } catch (error) {
      const { response } = error;
      throw response;
    }
  },

  enviar: async (
    canalVendasId: number,
    representanteLegal: RepresentanteLegal,
    plano: Plano,
    dadosEmpresa: DadosEmpresa,
    aceitaReceberOfertas: boolean,
    concordaTermosAdesao: boolean,
    compartilharDados: boolean,
    compartilharDadosItau: boolean,
    senha: string,
    codigoPlano: string,
  ): Promise<RespostaApi<IdApiComAprovacao>> => {
    const apiUrl = `${(window as any).config.url_api_management}${prospectoBffEndpoint}/CadastrarCliente`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const prospectoEnvio = converterCadastroParaEnvio(
      canalVendasId,
      representanteLegal,
      plano,
      dadosEmpresa,
      aceitaReceberOfertas,
      concordaTermosAdesao,
      compartilharDados,
      compartilharDadosItau,
      senha,
      codigoPlano,
    );

    return axios.post(apiUrl, prospectoEnvio, { headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` } });
  },
};
