import { FilledInput, Typography } from '@material-ui/core';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { EmailOutlined } from '@material-ui/icons';
import { emailRegex } from '../../../../../../../utils/regex';
import { useAvisoSaldo } from '../../../../../Context/AvisoSaldoContexto';
import { IContatoAdicionalCliente } from '../../../../../../../models/IContatoAdicionalCliente';
import ButtonSalvarEmail from '../../Buttons/SalvarEmail/ButtonSalvarEmail';
import ButtonInativarEmail from '../../Buttons/InativarEmail/ButtonInativarEmail';
import InputStyles from '../../../Styles/InputStyles';
import { TipoContato } from '../../../../../../../models/TipoContato';
import { IconStyles, TypographyStyles } from './styles';

import './ContatoAdicionalItem.scss';

interface ContatoAdicionalItemProps {
  contatoItem: IContatoAdicionalCliente;
}

const AlertaSaldoTipoContato: number = TipoContato.AlertaSaldo as number;

const ContatoAdicionalItem: React.FC<ContatoAdicionalItemProps> = (props) => {
  const { flagReceberAvisoSaldo,
    handleInativarContato,
    handleAtualizarContato,
    handleSalvarNovoContato } = useAvisoSaldo();

  const inputStyles = InputStyles();
  const iconStyles = IconStyles(flagReceberAvisoSaldo);
  const typographyStyles = TypographyStyles();

  const { contatoItem } = props;

  const [contato, setContato] = useState<string>(contatoItem.email);
  const [contatoOnLoad, setContatoOnLoad] = useState<string>(contatoItem.email);

  const [emailNovoOuAtualizado, setEmailNovoOuAtualizado] = useState<boolean>(false);
  const [emailInvalido, setEmailInvalido] = useState<boolean>(false);

  const handleContatoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setContato(value);

    if (!emailRegex.test(value)) setEmailInvalido(true);
    else setEmailInvalido(false);
  };

  const handleButtonSalvarEmailClick = () => {
    if (contatoItem.contactClientId) {
      handleAtualizarContato({
        ativo: 1,
        contatoId: contatoItem.contactClientId,
        email: contato,
        tipoContatoId: AlertaSaldoTipoContato,
      }, () => setContatoOnLoad(contato));
    } else {
      handleSalvarNovoContato({
        email: contato,
        tipoContatoId: AlertaSaldoTipoContato,
      }, () => setContatoOnLoad(contato));
    }
  };

  const handleButtonInativarEmailClick = () => {
    handleInativarContato({
      ativo: 0,
      contatoId: contatoItem.contactClientId as number,
      email: contato,
      tipoContatoId: AlertaSaldoTipoContato,
    });
  };

  useEffect(() => setEmailNovoOuAtualizado(!contatoItem.contactClientId || contato !== contatoOnLoad),
    [contato, contatoOnLoad, contatoItem.contactClientId]);

  return (
    <div id='aviso-saldo-contato-adicional-wrapper'>
      <div id='aviso-saldo-contato-adicional-input-wrapper'>
        <FilledInput
          classes={inputStyles}
          data-testid='aviso-saldo-contato-adicional-input'
          disabled={!flagReceberAvisoSaldo}
          disableUnderline
          error={emailInvalido}
          onChange={handleContatoChange}
          placeholder='username@conectcar.com'
          startAdornment={<EmailOutlined classes={iconStyles()} />}
          value={contato}
        />

        {emailNovoOuAtualizado
          ? (
            <ButtonSalvarEmail
              data-testid='aviso-saldo-contato-adicional-btn-salvar'
              disabled={emailInvalido || contato === ''}
              onClick={handleButtonSalvarEmailClick}
            />
          )
          : (
            <ButtonInativarEmail
              data-testid='aviso-saldo-contato-adicional-btn-inativar'
              onClick={handleButtonInativarEmailClick}
            />
          )}
      </div>
      {emailInvalido
        && (
          <div style={{ display: 'flex' }}>
            <Typography
              classes={typographyStyles}
              data-testid='aviso-saldo-contato-adicional-texto-invalido'
            >Insira um e-mail válido
            </Typography>
          </div>
        )
      }
    </div>
  );
};

export default memo(ContatoAdicionalItem);
