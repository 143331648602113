import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import CadastroAvisoLayout from './CadastroAvisoLayout';
import Actions from '../CadastroActions';
import { CadastroState } from '../CadastroInterfaces';
import { State } from '../../interfaces';

const paginaVazia = (<div data-testid='pagina-vazia' />);

const paginaClienteExistente = (
  <CadastroAvisoLayout
    titulo='Seja bem-vindo de volta'
    botao={{ texto: 'Fazer login', acao: () => window.location.replace(`${(window as any).config.url_portal_cliente}/Autenticacao/Autenticar`) }}
  >
    <span>
      Identificamos que o CNPJ informado já é cliente
      {' '}
      <span className='negrito'>ConectCar Empresas.</span>
      <br />
      <br />
      Para acessar a plataforma, faça seu login.
    </span>
  </CadastroAvisoLayout>
);

export default () => {
  const dispatch = useDispatch();
  const { jaExistente } = useSelector<State, CadastroState>(state => state.cadastro);

  React.useEffect(() => {
    dispatch(Actions.inicializarPaginaClienteJaCadastrado());
  }, [dispatch]);

  const renderizarPagina = React.useCallback(() => {
    if (jaExistente === null) return paginaVazia;
    if (jaExistente) return paginaClienteExistente;
    if (!jaExistente) return (<Redirect push to='/cadastro/plano' />);

    return paginaVazia;
  }, [jaExistente]);

  return renderizarPagina();
};
