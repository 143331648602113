import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvioRecargaRequest, FormaPagamentoOnline, RecargaState } from '../../../RecargaInterfaces';
import MetodosPagamentoStyles from '../MetodosPagamentoStyles';
import Actions from '../../../RecargaActions';
import { State } from '../../../../interfaces';

import SelectRecarga from '../../../Components/SelectValorRecarga/SelectRecarga';
import BoxPagamentoControlado from '../../../../../components/BoxPagamentoControlado';
import CarregarRetornoShopline from '../../../Acoes/Shopline/CarregarRetornoShopline';

const BoxTransferenciaItau = () => {
  const {
    dadosRecargaTef,
    documento,
    adesaoId,
    servicos,
    boxPagamento,
  } = useSelector<State, RecargaState>(state => ({
    dadosRecargaTef: state.recarga.dadosRecargaTef,
    documento: state.recarga.documento,
    adesaoId: state.recarga.adesaoId,
    servicos: state.recarga.servicos,
    boxPagamento: state.recarga.boxPagamento,
  }));

  const dispatch = useDispatch();
  const metodosPagamentoStyles = MetodosPagamentoStyles();

  const [valorTefItau, setValorTefItau] = React.useState(0);
  const [servicoRecargaId, setServicoRecargaId] = React.useState(0);

  const setBoxTefOpen = () => {
    dispatch(Actions.atualizarStateAccordion('tef', !(boxPagamento.tef)));
  };

  const handlerValorTefItauChange = (event) => {
    setValorTefItau(event.target.value);
    setServicoRecargaId(event.currentTarget.id);
  };

  const configurarSelectTefItau = () => (
    <SelectRecarga
      idSelect='Recarga_Input_Valor_Tef'
      possuiLimitacaoValor
      limitacaoValor={5000}
      setValorSelect={handlerValorTefItauChange}
      valoresRecarga={servicos}
    />
  );

  const handlePagamentoTransferencia = () => {
    const invalido = valorTefItau === 0 || servicoRecargaId === 0;

    if (!invalido) {
      const novaRecarga: EnvioRecargaRequest = {
        AdesaoId: adesaoId,
        FormaPagamentoOnline: FormaPagamentoOnline.ItauTEF,
        Documento: documento,
        ServicoRecargaId: servicoRecargaId,
        ValorDeCredito: valorTefItau,
      };

      dispatch(Actions.carregarParametrosRecargaRequest(novaRecarga));
      dispatch(Actions.cadastrarRecarga());
    } else {
      dispatch(Actions.atualizarAvisoRecarga('erro', 'Favor selecionar um valor válido para a Transferência.', true));
    }
  };

  return (
    <section id='section-tef-itau'>
      <div id='boxTefPagto'>
        <BoxPagamentoControlado
          botaoValorTexto='Transferir'
          descricaoTitulo='Limite diário de R$5.000,00 para recargas. Crédito em até 2 horas no seu saldo ConectCar'
          id='Recarga_Rb_TEF'
          btnID='Recarga_Bt_TEFTransferir'
          inputValor={configurarSelectTefItau()}
          labelValor='Valor'
          logotipo={`${metodosPagamentoStyles.logotiposBaseCss} ${metodosPagamentoStyles.itauLogotipo}`}
          onButtonClick={handlePagamentoTransferencia}
          titulo='Transferência Bancária Itaú'
          boxOpen={boxPagamento?.tef}
          setBoxOpen={setBoxTefOpen}
        />

      </div>

      <div id='tef-pagamento-complementos'>
        {dadosRecargaTef?.dados != null
          && (
            <CarregarRetornoShopline
              action={dadosRecargaTef?.dados?.valores?.dadosRecarga?.url}
              dadosCriptografados={dadosRecargaTef?.dados?.valores?.dadosRecarga?.dadosCriptografados}
              dispatchAction={Actions.descarregarTef}
            />
          )
        }
      </div>
    </section>
  );
};

export default BoxTransferenciaItau;
