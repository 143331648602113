import axios from 'axios';
import ConverterParaCamelCase from 'camelcase-object';
import { removerCaracteresNaoNumericos } from '../mascara';
import { RespostaApi } from '../ApiInterfaces';
import { Contato, Endereco } from '../../features/dados-conta/DadosContaInterfaces';

const urlBase = 'cadastro/cadastro-cliente-backend/api/';

interface Parametros {
  clienteId: number,
  nomeContatoComercial: string,
  dddTelefone: string,
  numeroTelefone: string,
  dddCelular: string,
  numeroCelular: string,
  email: string,
}

interface ContatoApi {
  nome: string,
  email: string,
  dddTelefone: string,
  telefone: string,
  dddCelular: string,
  celular: string,
}

interface EnderecoApi {
  cep: string,
  logradouro: string,
  numero: string,
  complemento?: string,
  bairro: string,
  estado: string,
  uf: string,
  nomeCidade: string,
  enderecoId: number
}

interface ParametrosEnderecoApi {
  EnviarJde: boolean,
  CEP: string,
  Endereco: string,
  Numero: string,
  Complemento: string,
  Bairro: string,
  Cidade: string,
  Estado: string,
  Padrao: boolean,
  TipoEndereco: string
}

interface RetornoDadosConta {
  contato: Contato,
  endereco: Endereco
}

const mapearTelefonesRecebidos = (contato: ContatoApi): Contato => ({
  nome: contato.nome,
  email: contato.email,
  telefone: `${contato.dddTelefone}${contato.telefone}`,
  celular: `${contato.dddCelular}${contato.celular}`,
});

const mapearEnderecoRecebido = (endereco: EnderecoApi): Endereco => ({
  cep: endereco.cep,
  logradouro: endereco.logradouro,
  numero: endereco.numero,
  nomeCidade: endereco.nomeCidade,
  estado: endereco.uf,
  complemento: endereco.complemento,
  bairro: endereco.bairro,
  enderecoId: endereco.enderecoId,
});


const mapearParametrosEnvioEndereco = (endereco: Endereco): ParametrosEnderecoApi => ({
  EnviarJde: true,
  CEP: endereco.cep.replace('-', ''),
  Endereco: endereco.logradouro,
  Numero: endereco.numero,
  Complemento: endereco.complemento,
  Bairro: endereco.bairro,
  Cidade: endereco.nomeCidade,
  Estado: endereco.estado,
  Padrao: true,
  TipoEndereco: 'Principal',
});

const mapearParametros = (clienteId: number, contato: Contato): Parametros => {
  const telefone = removerCaracteresNaoNumericos(contato.telefone);
  const celular = removerCaracteresNaoNumericos(contato.celular);

  const obterNumero = (value: string) => value.substring(2);
  const obterDdd = (value: string) => value.substring(0, 2);

  const objeto = {
    clienteId,
    nomeContatoComercial: contato.nome,
    dddTelefone: obterDdd(telefone),
    numeroTelefone: obterNumero(telefone),
    dddCelular: celular.length > 0 ? obterDdd(celular) : '',
    numeroCelular: celular.length > 0 ? obterNumero(celular) : '',
    email: contato.email,
  };
  return objeto;
};

export default {
  buscarDadosConta: async (bearerToken: string): Promise<RespostaApi<RetornoDadosConta>> => {
    let resposta = await axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/CadastroCliente`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': `${(window as any).config.subscription_api_management};product=Recarga`,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );

    resposta = ConverterParaCamelCase(resposta);
    resposta.data.dados.contato = mapearTelefonesRecebidos(resposta.data.dados.contato);
    resposta.data.dados.endereco = mapearEnderecoRecebido(resposta.data.dados.endereco);

    return resposta;
  },
  atualizarDadosConta: (clienteId: number, bearerToken: string, contato: Contato) => {
    const parametros = mapearParametros(clienteId, contato);

    return axios.put(
      `${(window as any).config.url_api_management}${urlBase}v2/clientes/pj/`,
      parametros,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': `${(window as any).config.subscription_api_management};product=Recarga`,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
  },
  atualizarDadosEndereco: (documento: number, bearerToken: string, endereco: Endereco) => {
    const parametros = mapearParametrosEnvioEndereco(endereco);

    return axios.put(
      `${(window as any)
        .config.url_api_management}${urlBase}v2/clientes/${documento}/enderecos/${endereco.enderecoId}`,
      parametros,
      {
        headers: {
          'Ocp-Apim-Subscription-Key': `${(window as any).config.subscription_api_management};product=Recarga`,
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    );
  },
};
