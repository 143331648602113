import { createStyles, makeStyles } from '@material-ui/core';

const ResultadoStyles = makeStyles(() => createStyles({
  gridContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '48px',
    maxWidth: '1110px',
    borderRadius: '12px',
    margin: 'auto',
    border: '1px solid #EDEDED',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '24px',
  },
  tituloSucesso: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-0.02em',
    color: '#3B5CB0',
  },
  subtituloSucesso: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#4D5761',
  },
  botaoSucesso: {
    padding: '12px',
    gap: '10px',
    background: '#3B5CB0',
    borderRadius: '100px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
    maxWidth: '400px',
    width: '80%',
  },
}));

export default ResultadoStyles;
