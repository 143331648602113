import { Checkbox } from '@material-ui/core';
import React, { ChangeEvent, memo } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../../interfaces';
import { useAvisoSaldo } from '../../../../Context/AvisoSaldoContexto';
import { FormaRecebimentoEnum, RecargaState } from '../../../../RecargaInterfaces';
import { checkboxStyles } from './styles';

const CheckboxInput: React.FC = () => {
  const styles = checkboxStyles();
  const { flagReceberAvisoSaldo, valorAviso, handleToggleAvisoSaldoCheckbox } = useAvisoSaldo();

  const {
    dadosIndex,
  } = useSelector<State, RecargaState>(state => ({
    dadosIndex: state.recarga.dadosIndex,
  }));

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleToggleAvisoSaldoCheckbox({
      desligarAlerta: !e.target.checked,
      configuracaoPlanoId: dadosIndex?.dados?.valores?.recargaOnline?.configuracaoPlano?.id as number,
      formaRecebimentoId: FormaRecebimentoEnum.Email as number,
      saldoCompartilhado: dadosIndex?.dados?.valores?.recargaOnline?.saldo?.saldoCompartilhado as boolean,
      valorAviso: valorAviso as number,
    });
  };

  return (
    <Checkbox
      checked={flagReceberAvisoSaldo}
      classes={styles}
      data-testid='aviso-saldo-checkbox-input'
      disableRipple
      id='Recarga_Tg_AlertaSaldo'
      onChange={handleCheckboxChange}
    />
  );
};

export default memo(CheckboxInput);
