import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './MeusPedidosPage.scss';
import { Typography } from '@material-ui/core';
import Paginacao from '../../../../components/SolicitacaoAdesivo/Paginacao/Paginacao';
import MeusPedidosTabela from '../MeusPedidosTabela';
import { obterMeusPedidos } from '../../../../services/requests/SolicitacaoAdesivo/obterMeusPedidosRequest';
import { useLoadingControle } from '../../../../hooks/useLoadingControle/useLoadingControle';
import { useControleModalErro } from '../../../../hooks/useControleModalErro/useControleModalErro';
import ModalErro from '../../../../components/ModalErro';
import { colors } from '../../../../styles/theme';
import { PedidosCompras } from '../MeusPedidosInterfaces';

const MeusPedidosPage = () => {
  const [pedidos, setPedidos] = useState<PedidosCompras>();
  const [paginaSelecionada, setPaginaSelecionada] = useState<number>(1);
  const [registrosPorPagina] = useState<number>(3);

  const { alterarEstadoCarregando } = useLoadingControle();
  const { abrirModal, fecharModal } = useControleModalErro();

  const executarRequest = useCallback(async () => {
    alterarEstadoCarregando(true);
    await obterMeusPedidos({ paginaSelecionada, registrosPorPagina }).then((response) => {
      setPedidos(response.data.dados);
    }).catch(() => abrirModal())
      .finally(() => alterarEstadoCarregando(false));
  }, [abrirModal, alterarEstadoCarregando, paginaSelecionada, registrosPorPagina]);

  useEffect(() => {
    executarRequest();
  }, [executarRequest, paginaSelecionada, registrosPorPagina]);


  const alterarPaginaSelecionada = useCallback((tipo: 'avancar' | 'voltar') => {
    if (tipo === 'avancar') {
      setPaginaSelecionada(paginaSelecionada + 1);
    } else {
      setPaginaSelecionada(paginaSelecionada - 1);
    }
  }, [paginaSelecionada]);


  const alterarPaginaSelecionadaManual = useCallback((paginaSelecionadaParam: number) => {
    setPaginaSelecionada(paginaSelecionadaParam);
  }, []);

  return (
    <Fragment>
      <ModalErro
        acaoAoFecharModal={() => {
          fecharModal();
          executarRequest();
        }}
      />

      <section id='meus-pedidos-page' style={{ margin: '0 auto' }}>
        {pedidos
          && (
            <Typography style={{
              fontSize: 20,
              fontWeight: 600,
              color: colors.primary.PJ,
              marginTop: 40,
              marginBottom: 10,
            }}
            >
              Acompanhe aqui seus últimos pedidos
            </Typography>
          )
        }
        <section className='meus-pedidos-container meus-pedidos-page-tabela'>
          {!pedidos ? (
            <Fragment />
          ) : pedidos.rastreioPedidos?.length <= 0 ? (
            <Typography style={{ fontSize: '2rem' }}>
              Voce ainda não realizou nenhum pedido
            </Typography>
          ) : (
            <>
              <MeusPedidosTabela meusPedidos={pedidos.rastreioPedidos} />
            </>
          )}
        </section>
        <section className='meus-pedidos-container meus-pedidos-page-paginacao'>
          {pedidos && (
            <Paginacao
              totalPaginas={(Math.ceil(pedidos.quantidadeRegistros / registrosPorPagina))}
              paginaSelecionada={paginaSelecionada}
              registrosPorPagina={registrosPorPagina}
              onMudarPaginacaoChange={() => alterarPaginaSelecionada('avancar')}
              onVoltarPagina={() => alterarPaginaSelecionada('voltar')}
              alterarPaginaSelecionadaManual={(paginaSelecionadaParam: number) => alterarPaginaSelecionadaManual(paginaSelecionadaParam)}
            />
          )}
        </section>
      </section>
    </Fragment>
  );
};

export default MeusPedidosPage;
