import * as React from 'react';
import {
  Card, CardActions, CardContent, CardHeader, Collapse, Divider, IconButton, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import CardFaturaStyle from './CardFaturaStyle';
import { ItemCardFatura } from '../../../features/fatura-pos-pago/FaturaInterfaces';
import Utils from '../../../common/Utils';

interface Props { itemCardFatura: ItemCardFatura[] }
const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
const locale = 'pt-BR';

const CardFatura = ({ itemCardFatura }: Props) => {
  const classes = CardFaturaStyle();

  const [expanded, setExpanded] = React.useState(false);
  const [competenciaMesAno, setcompetenciaMesAno] = React.useState('');

  React.useEffect(() => {
    mesAnoCompetencia();
  });

  const mesAnoCompetencia = () => {
    if (itemCardFatura[0]?.dataVencimento) {
      const mesAtual = new Date(itemCardFatura[0]?.dataVencimento).toLocaleDateString(locale, { month: 'numeric' });
      const anoAtual = new Date(itemCardFatura[0]?.dataVencimento).toLocaleDateString(locale, { year: 'numeric' });

      setcompetenciaMesAno(`${mesAtual} / ${anoAtual}`);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const abreBoleto = () => {
    const urlBoleto = `data:application/pdf;base64,${encodeURI(itemCardFatura[0]?.boleto)}`;
    const iframe = `<iframe width='100%' height='100%' src='${urlBoleto}'></iframe>`;
    const x = window.open();
    x.document.open();
    x.document.write(iframe);
    x.document.close();
  };

  const baixarFatura = (arquivo: string) => {
    const urlArquivo = `data:application/pdf;base64,${encodeURI(arquivo)}`;
    const iframe = `<iframe width='100%' height='100%' src='${urlArquivo}'></iframe>`;
    const x = window.open();
    x.document.write(iframe);
    x.document.close();
  };

  const renderizarStatus = (status: string) => {
    const dataAtual = new Date();
    const dataVencimento = new Date(itemCardFatura[0]?.dataVencimento);
    const statusFatura = status;

    if (dataAtual > dataVencimento && status === 'EMABERTO') {
      return Utils.formatarStatusFatura('ATRASADO');
    }
    return Utils.formatarStatusFatura(statusFatura);
  };

  const vencimentoInfo = (
    <div className={classes.displayDireita}>
      <span className={classes.headerSubColorText}>Vencimento</span>
      <p className={classes.dataVencimento}>{new Date(itemCardFatura[0]?.dataVencimento).toLocaleDateString(locale)}</p>
    </div>
  );

  return (

    <Card className={classes.root}>
      <CardHeader title='Fatura Atual' action={vencimentoInfo} classes={{ title: classes.headerColorText }} className={classes.header} />

      <CardContent className={classes.contentMain}>

        <CardContent className={classes.content}>
          <Typography className={classes.labelValorPagamento}>Valor de Pagamento</Typography>
          <Typography className={classes.valorPagamento} variant='caption'>{itemCardFatura[0]?.valorOriginal?.toLocaleString('pt-BR', formato)}</Typography>
          <Divider className={classes.divider} light />
        </CardContent>

        <div className={classes.contentInformacao}>
          <div>
            <span className={classes.labelInformacao}>Forma de pagamento:</span>
          </div>
          <div className={classes.divFormaPagamento}>
            <span className={classes.labelInformacao}>
              Boleto:
              {' '}
              {itemCardFatura[0]?.boleto === null
                ? <span className={classes.labelStatusPagamento}>Aguardando Fechamento</span>
                : <button className={classes.btnFormaPagamento} type='button' onClick={() => abreBoleto()} />}
            </span>
          </div>
        </div>

        <CardContent className={classes.content}>
          <Divider className={classes.divider} light />
        </CardContent>

        <CardActions className={classes.contentInformacao}>
          <Typography className={classes.labelInformacao}>Status:</Typography>
          <Typography className={classes.labelStatusPagamento}>{renderizarStatus(itemCardFatura[0]?.status)}</Typography>
        </CardActions>

        <CardContent className={classes.content}>
          <Divider className={classes.divider} light />
        </CardContent>

        <CardActions className={classes.contentInformacao}>
          <Typography className={classes.labelInformacao}>Mais detalhes:</Typography>
          <IconButton
            className={clsx(classes.expand, { [classes.expandOpen]: expanded }, classes.colorBtnColapse)}
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>

        <CardContent className={classes.content}>
          <Divider className={classes.divider} light />
        </CardContent>

        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent className={classes.contentColapse}>
            <CardActions className={classes.contentInformacao}>
              <Typography className={classes.labelInformacao}>Número da fatura:</Typography>
              <Typography className={classes.labelInformacao}>{itemCardFatura[0]?.identificadorFatura}</Typography>
            </CardActions>
            <Divider className={classes.divider} light />

            <CardActions className={classes.contentInformacao}>
              <Typography className={classes.labelInformacao}>Competência:</Typography>
              <Typography className={classes.labelInformacao}>{competenciaMesAno}</Typography>
            </CardActions>

          </CardContent>
        </Collapse>
        {itemCardFatura[0]?.arquivo && itemCardFatura[0]?.arquivo !== null && (
          <CardActions className={classes.contentImgExportar}>
            <button className={classes.btnExportarPdf} type='button' onClick={() => baixarFatura(itemCardFatura[0]?.arquivo)} />
          </CardActions>
        )
        }
      </CardContent>
    </Card>
  );
};

export default CardFatura;
