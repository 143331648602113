import { makeStyles } from '@material-ui/core';

export const TooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#4D5761',
    borderRadius: '12px',
    color: '#fff',
    fontWeight: 400,
    fontSize: '14px',
    padding: '16px',
  },
}));

export const IconStyles = (flag: boolean) => makeStyles({
  root: {
    color: !flag ? '#fff' : '#979797',
  },
});
