import React, { useState, useCallback, memo } from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import CarrinhoAtivo from '../../../assets/images/icones/icone_carrinho_compras_ativo.svg';
import CarrinhoInativo from '../../../assets/images/icones/icone_carrinho_compras_inativo.svg';
import PedidoAtivo from '../../../assets/images/icones/icone_meus_pedidos_ativo.svg';
import PedidoInativo from '../../../assets/images/icones/icone_meus_pedidos_inativo.svg';

import PainelPlano from '../../../components/SolicitacaoAdesivo/PainelPlano/PainelPlano';
import InformacoesPedido from '../../../components/SolicitacaoAdesivo/InformacoesPedido/InformacoesPedido';

import './styles.scss';
import Loading from '../../../components/Loading';
import { useLoadingControle } from '../../../hooks/useLoadingControle/useLoadingControle';
import MeusPedidosPage from '../meus-pedidos/MeusPedidosPage';
import { colors } from '../../../styles/theme';

import { PedidoProvider } from './context/SolicitacaoAdesivoContext';
import { EnderecoProvider } from './context/SolicitacaoAdesivoEnderecoContext';

type TTabOpcoes = 'Solicitar adesivos' | 'Meus pedidos';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      center: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const SolicitacaoAdesivo: React.FC = () => {
  const [tabSelecionada, setTabSelecionada] = useState<TTabOpcoes>('Solicitar adesivos');
  const [tabEsquerdaHover, setTabEsquerdaHover] = useState<boolean>(false);
  const [tabDireitaHover, setTabDireitaHover] = useState<boolean>(false);

  const alterarTabSelecionada = useCallback((tabSelecionadaParam: TTabOpcoes) => {
    setTabSelecionada(tabSelecionadaParam);
  }, []);

  const { carregando } = useLoadingControle();

  return (
    <>
      <Box className='container'>
        <Loading carregando={carregando} />
        <Box className='background' style={{ background: '#fbfafacb', padding: 25 }}>
          <PainelPlano />

          <Box className='dividerDeskSolicitacaoContainer'>
            <Divider className='dividerDeskSolicitacao' />
          </Box>

          <center>
            <Box className='grupoTabs'>
              <Box
                className={`tabEsquerda ${tabSelecionada === 'Solicitar adesivos' && 'tabSelecionada'}`}
                onMouseOver={() => setTabEsquerdaHover(true)}
                onMouseOut={() => setTabEsquerdaHover(false)}
                onClick={() => alterarTabSelecionada('Solicitar adesivos')}
                id='boxSolicitarAdesivos'
              >
                <img
                  src={
                    tabSelecionada === 'Solicitar adesivos' || tabEsquerdaHover
                      ? CarrinhoAtivo
                      : CarrinhoInativo
                  }
                  alt={
                    tabSelecionada === 'Solicitar adesivos'
                      ? 'icone_carrinho_ativo'
                      : 'icone_carrinho_inativo'
                  }
                  className='imgIconeTab'
                />
                <Typography
                  variant='h6'
                  style={{
                    color: tabSelecionada === 'Solicitar adesivos' || tabEsquerdaHover
                      ? colors.primary.PF
                      : colors.terciary.gray.default,
                  }}
                >
                  Solicitar adesivos
                </Typography>
              </Box>

              <Box
                className={`tabDireita ${tabSelecionada === 'Meus pedidos' && 'tabSelecionada'}`}
                onMouseOver={() => setTabDireitaHover(true)}
                onMouseOut={() => setTabDireitaHover(false)}
                onClick={() => alterarTabSelecionada('Meus pedidos')}
                id='boxMeusPedidos'
              >
                <img
                  src={
                    tabSelecionada === 'Meus pedidos' || tabDireitaHover
                      ? PedidoAtivo
                      : PedidoInativo
                  }
                  alt={
                    tabSelecionada === 'Meus pedidos'
                      ? 'icone_meus_pedidos_ativo'
                      : 'icone_meus_pedidos_inativo'
                  }
                  className='imgIconeTab'
                />
                <Typography
                  variant='h6'
                  color={
                    tabSelecionada === 'Meus pedidos' || tabDireitaHover
                      ? 'primary'
                      : 'textPrimary'
                  }
                  style={{
                    color: tabSelecionada === 'Meus pedidos' || tabDireitaHover
                      ? colors.primary.PF
                      : colors.terciary.gray.default,
                  }}
                >
                  Meus pedidos
                </Typography>
              </Box>
            </Box>
          </center>
        </Box>
        {tabSelecionada === 'Solicitar adesivos' ? (
          <PedidoProvider>
            <EnderecoProvider>
              <InformacoesPedido />
            </EnderecoProvider>
          </PedidoProvider>
        ) : (
          <MeusPedidosPage />
        )}
      </Box>
    </>
  );
};

export default memo(SolicitacaoAdesivo);
