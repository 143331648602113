import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';

interface Props {
  data: string,
  formaPagamento: string,
  valor: string,
}

const numero = require('numero-por-extenso');

const styles = StyleSheet.create({
  informacoes: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '6px',
    alignSelf: 'flex-start',
    lineHeight: '0',
  },
  informacoes_menor: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '5.5px',
    alignSelf: 'flex-start',
    lineHeight: '0',
    padding: '2px 0 2px 0',
  },
  linha_debaixo: {
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '1.6',
    width: '100%',
    borderBottom: '1pt solid #000000',
    borderLeft: '1pt solid #000000',
    borderRight: '1pt solid #000000',
  },
  quadrado: {
    width: '100%',
  },
  caixa: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.4',
    fontSize: '6px',
    width: '100%',
    borderBottom: '1pt solid #000000',
    borderLeft: '1pt solid #000000',
    borderRight: '1pt solid #000000',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    paddingBottom: '40px',
  },
  negrito: {
    fontFamily: 'Helvetica-Bold',
  },
  negrito_borda: {
    fontFamily: 'Helvetica-Bold',
    borderBottom: '1pt solid #000000',
    borderRight: '1pt solid #000000',
    paddingTop: '1px',
    paddingLeft: '1px',
  },
  linha_imposto: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  coluna: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '6px',
    width: '100%',
  },
  fundo: {
    alignSelf: 'flex-start',
    borderRight: '1pt solid #000000',
    width: '100%',
    lineHeight: '0',
    paddingTop: '3px',
    paddingLeft: '1px',
  },
  fundo_direita: {
    textAlign: 'right',
    borderRight: '1pt solid #000000',
    width: '100%',
    lineHeight: '0',
    paddingTop: '3px',
    paddingRight: '1px',
  },
  valor_total: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '7px',
    color: '#000000',
    textAlign: 'center',
    padding: '5px 0px 5px 0px',
  },
  linha_valor_total: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1pt solid #000000',
    borderLeft: '1pt solid #000000',
    borderRight: '1pt solid #000000',
    backgroundColor: '#e3e3e3',
  },
  legenda: {
    fontSize: '5px',
    alignSelf: 'baseline',
    fontFamily: 'Helvetica-Bold',
  },
  valor_extenso: {
    fontSize: '6px',
    alignSelf: 'flex-start',
    marginLeft: '50px',
  },
  espacador: {
    height: '3px',
    borderBottom: '1pt solid #000000',
    borderLeft: '1pt solid #000000',
    borderRight: '1pt solid #000000',
    width: '100%',
  },
});

const Informacoes = ({ data, valor, formaPagamento }: Props) => {
  const valorExtenso = numero.porExtenso(parseFloat(valor.replace('.', '')), numero.estilo.monetario);

  return (
    <View id='informacoes'>
      <View style={styles.linha_debaixo}>
        <View style={styles.quadrado}>
          <Text style={styles.informacoes}>
            INFORMAÇÕES RELEVANTES
          </Text>
        </View>
      </View>
      <View style={styles.caixa}>
        <Text>TOTAL DA RECARGA REALIZADA NA CONECTCAR</Text>
        <Text style={styles.negrito}>VALORES TRIBUTÁVEIS</Text>
        <Text>ISS(%) R$0.00</Text>
        <Text>PIS/COFINS(%) R$0.00</Text>
        <Text style={styles.negrito}>VALORES NÃO TRIBUTÁVEIS</Text>
        <Text>Recarga R$ {valor}</Text>
        <Text style={styles.negrito}>Total Valores Não Tributáveis: R$ {valor}</Text>
        <Text>Plano - Pré PJ Empresarial</Text>
      </View>
      <View id='imposto' style={styles.linha_debaixo}>
        <View style={styles.linha_imposto}>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>IRRF</Text><Text style={styles.fundo}>0.00</Text></View>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>PIS/PASEP</Text><Text style={styles.fundo}>0.00</Text></View>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>COFINS</Text><Text style={styles.fundo}>0.00</Text></View>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>CSLL</Text><Text style={styles.fundo}>0.00</Text></View>
        </View>
        <View style={styles.quadrado} />
      </View>
      <View style={styles.linha_valor_total}>
        <Text style={styles.valor_total}>VALOR TOTAL DA RECARGA {valor}</Text>
      </View>
      <View id='valor' style={styles.linha_debaixo}>
        <View style={styles.linha_imposto}>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>Valor da Recarga:</Text><Text style={styles.fundo_direita}>{valor}</Text></View>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>Forma de Pagamento:</Text><Text style={styles.fundo}>{formaPagamento}</Text></View>
          <View style={styles.coluna}><Text style={styles.negrito_borda}>Data:</Text><Text style={styles.fundo}>{data}</Text></View>
        </View>
        <View style={styles.quadrado} />
      </View>
      <View id='extenso' style={styles.linha_debaixo}>
        <View style={styles.quadrado}>
          <Text style={styles.legenda}>
            Valor por extenso:
          </Text>
          <Text style={styles.valor_extenso}>
            {valorExtenso.charAt(0).toUpperCase() + valorExtenso.slice(1)}
          </Text>
        </View>
      </View>
      <View style={styles.espacador} />
      <View style={styles.linha_debaixo}>
        <View style={styles.quadrado}>
          <Text style={styles.informacoes_menor}>
            *ESSE DOCUMENTO NÃO GERA PAGAMENTO, É APENAS UM DEMONSTRATIVO DA REALIZAÇÃO DE SUAS RECARGAS PARA UTILIZAÇÃO DOS SERVIÇOS DA CONECTCAR INSTITUIÇÃO DE PAGAMENTOS S.A
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Informacoes;
