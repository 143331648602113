import * as React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import MetodosPagamentoStyles from './MetodosPagamentoStyles';
import BoxPagamentoPix from './Boxes/BoxPagamentoPix';
import BoxTransferenciaItau from './Boxes/BoxTransferenciaItau';
import BoxBoletoFlash from './Boxes/BoxBoletoFlash';
import BoxBoletoItau from './Boxes/BoxBoletoItau';
import { RecargaState } from '../../RecargaInterfaces';
import { State } from '../../../interfaces';
import BoxCartaoCredito from './Boxes/BoxCartaoCredito';

const MetodosPagamento = () => {
  const metodosPagamentoStyles = MetodosPagamentoStyles();
  const history = useHistory();
  const {
    dadosUltimaRecarga,
  } = useSelector<State, RecargaState>(state => ({
    dadosUltimaRecarga: state.recarga.dadosUltimaRecarga,
  }));

  const handleRedirectHistoricoRecarga = () => {
    history.push('/recarga/historico');
  };

  return (
    <section id='metodos-pagamento'>
      <div className={`${metodosPagamentoStyles.metodosPagamentoTitulo} marginBottomDefault`}>
        <h2>Métodos de Pagamento</h2>
        <Button
          className={metodosPagamentoStyles.botaoHistoricoRecarga}
          onClick={() => handleRedirectHistoricoRecarga()}
          variant='contained'
          style={dadosUltimaRecarga?.valores?.dados === null ? { display: 'none' } : {}}
        >
          Histórico de Recargas
        </Button>
      </div>

      <div className={metodosPagamentoStyles.boxPagamentos}>
        <BoxCartaoCredito />

        <BoxPagamentoPix />

        <BoxTransferenciaItau />

        <BoxBoletoFlash />

        <BoxBoletoItau />
      </div>
    </section>
  );
};

export default MetodosPagamento;
