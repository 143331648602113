import axios from 'axios';
import ConverterParaCamelCase from 'camelcase-object';
import { RespostaApi } from '../ApiInterfaces';
import { EnderecoApiCep } from '../../features/dados-conta/DadosContaInterfaces';
import { removerCaracteresNaoNumericos } from '../mascara';

const cepEndpoint = 'landingpage-bff/api/v1/Cep';

export default {
  consultarCep: async (cep: string) : Promise<RespostaApi<EnderecoApiCep>> => {
    const cepSemMascara = removerCaracteresNaoNumericos(cep);
    const cepApiUrl = `${(window as any).config.url_api_management}${cepEndpoint}/ObterEnderecoPorCep/${cepSemMascara}`;
    const subscriptionKey = (window as any).config.subscription_api_management;

    let resposta = await axios.get(cepApiUrl, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionKey}` },
    });

    resposta = ConverterParaCamelCase(resposta);
    return resposta;
  },
};
