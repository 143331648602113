import { Grid, Typography, Box, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ConectcarLogo from '../../../assets/images/icones/conectCarSimplificado.svg';
import Loading from '../../../assets/images/loading.svg';
import ResumoRecargaStyles from './ResumoRecargaStyles';

interface Props {
    valorRecargaDigitado: number
    renderizarBotaoPagamento: boolean;
    handleBotaoPagamento?: () => void;
    buttonEstado?: boolean;
}

const ResumoRecarga = ({
  valorRecargaDigitado,
  renderizarBotaoPagamento,
  handleBotaoPagamento,
  buttonEstado,
}: Props) => {
  const styles = ResumoRecargaStyles();

  const [valorTaxaRecarga, setValorTaxaRecarga] = useState(0);
  const [valorRecargaCalculado, setValorRecargaCalculado] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    calcularValorTaxaEValorFinal(valorRecargaDigitado);
  }, [valorRecargaDigitado]);

  const calcularValorTaxaEValorFinal = (valorRecarga: number) => {
    const valorTaxa = parseFloat((Math.fround(valorRecarga * 1.2) / 100).toFixed(2));
    setValorTaxaRecarga(valorTaxa);

    const valorFinal = parseFloat(Math.fround(valorRecarga - valorTaxa).toFixed(2));
    setValorRecargaCalculado(valorFinal);
  };

  return (
    <Grid item lg={4} md={4} xs={12} sm={12}>
      <Paper elevation={0} square className={styles.cardResumo}>
        <Box className={styles.tituloResumo} component='div'>
          <img src={ConectcarLogo} alt='' />
          Resumo da recarga
        </Box>
        {renderizarBotaoPagamento === true
          ? (
            <Box component='div' className={styles.boxValorRecarga}>
              <Box component='div' className={styles.valorRecarga}>
                <NumberFormat
                  displayType='text'
                  value={valorRecargaDigitado.toString() === '' || valorRecargaDigitado === 0 ? '-' : valorRecargaDigitado}
                  decimalSeparator=','
                  thousandSeparator='.'
                  prefix='R$ '
                  fixedDecimalScale
                  decimalScale={2}
                  className={styles.valorRecargaNumero}
                />
                Valor da recarga
              </Box>
            </Box>
          ) : null}
        <Box component='div' className={styles.boxContainerDetalhamento}>
          <Box className={styles.boxDetalhamento}>
            <Box className={styles.boxDetalhamentoInterno}>
              <Typography component='p' className={styles.textoDetalhamento}>
                Valor solicitado
              </Typography>
              <NumberFormat
                displayType='text'
                value={valorRecargaDigitado.toString() === '' || valorRecargaDigitado === 0 ? '-' : valorRecargaDigitado}
                decimalSeparator=','
                thousandSeparator='.'
                prefix='R$ '
                fixedDecimalScale
                decimalScale={2}
                className={styles.numeroDetalhamento}
              />
            </Box>
          </Box>
          <Box className={styles.boxDetalhamento}>
            <Box className={styles.boxDetalhamentoInterno}>
              <Typography component='p' className={styles.textoDetalhamento}>
                Taxa de serviço
              </Typography>
              <NumberFormat
                displayType='text'
                value={Number.isNaN(valorTaxaRecarga) === true || valorTaxaRecarga === 0 ? '-' : valorTaxaRecarga}
                decimalSeparator=','
                thousandSeparator='.'
                prefix='-R$ '
                fixedDecimalScale
                decimalScale={2}
                className={styles.numeroDetalhamentoNegativo}
              />
            </Box>
            <Typography className={styles.descricaoTaxaDetalhamento}>
              Todas as recargas realizadas com cartão de crédito possuem uma taxa de 1,2% que será descontado do valor de recarga solicitado.
            </Typography>
          </Box>
          <Box className={styles.boxDetalhamento} style={{ borderTop: '1px solid #EDEDED' }}>
            <Box className={styles.boxDetalhamentoInterno}>
              <Typography className={styles.textoDetalhamento}>
                Recarga
              </Typography>
              <NumberFormat
                displayType='text'
                value={Number.isNaN(valorRecargaCalculado) === true || valorRecargaCalculado === 0 ? '-' : valorRecargaCalculado}
                decimalSeparator=','
                thousandSeparator='.'
                prefix='R$ '
                className={styles.numeroDetalhamentoPositivo}
              />
            </Box>
          </Box>
        </Box>
        {renderizarBotaoPagamento === true
          ? loading ? <img src={Loading} style={{ width: 'auto', height: '44px' }} alt='loading' /> : (
            <button
              type='button'
              className={styles.botaoAtivo}
              style={{ backgroundColor: buttonEstado ? '#3B5CB0' : '#BEC4CC' }}
              onClick={() => { handleBotaoPagamento(); setLoading(true); }}
              disabled={!buttonEstado}
            >
              Continuar
            </button>
          )
          : null
            }
      </Paper>
    </Grid>
  );
};
export default ResumoRecarga;
