import { combineReducers } from 'redux';
import { Types } from './actions';
import { Action } from './interfaces';
import AutenticacaoReducers from './autenticacao/AutenticacaoReducers';
import ExtratoReducers from './extrato/ExtratoReducers';
import DadosContaReducers from './dados-conta/DadosContaReducers';
import CadastroReducers from './cadastro/CadastroReducers';
import HomeReducers from './home/HomeReducers';
import FaturaReducers from './fatura-pos-pago/FaturaReducers';
import HistoricoRecargaReducers from './historico-recarga/HistoricoRecargaReducers';
import RecargaReducers from './recarga/RecargaReducers';
import MeusPedidosReducers from './solicitacao-adesivo/meus-pedidos/MeusPedidosReducers';
import SubstituicaoAdesivoReducers from './substituicao-adesivo/SubstituicaoAdesivoReducers';

const LoadingReducer = (state: number = 0, action: Action) => {
  switch (action.type) {
    case Types.IniciarRequisicao:
      return state + 1;

    case Types.TerminarRequisicao:
      return state > 0 ? state - 1 : state;

    default:
      return state;
  }
};

export default combineReducers({
  autenticacao: AutenticacaoReducers,
  cadastro: CadastroReducers,
  dadosConta: DadosContaReducers,
  extrato: ExtratoReducers,
  requisicoes: LoadingReducer,
  home: HomeReducers,
  fatura: FaturaReducers,
  historicoRecarga: HistoricoRecargaReducers,
  recarga: RecargaReducers,
  meusPedidos: MeusPedidosReducers,
  substituicaoAdesivo: SubstituicaoAdesivoReducers,
});
