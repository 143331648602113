import { DropdownItem } from '../../components/Inputs/Dropdown/DropdownInterface';

const iconeRecarga = require('../../assets/images/icones/recarga.svg');
const iconePassagem = require('../../assets/images/icones/passagem.svg');
const iconeEstacionamento = require('../../assets/images/icones/estacionamento.svg');
const iconeMensalidade = require('../../assets/images/icones/mensalidade.svg');

export const TiposTransacao = [
  { valor: 1, origem: null, texto: 'Recarga', icone: iconeRecarga },
  { valor: 44, origem: 10, texto: 'Recarga Vale Pedágio', icone: iconeRecarga },
  { valor: 7, origem: null, texto: 'Passagem', icone: iconePassagem },
  { valor: 20, origem: null, texto: 'Passagem Vale Pedágio', icone: iconePassagem },
  { valor: 14, origem: null, texto: 'Estacionamento', icone: iconeEstacionamento },
  { valor: 43, origem: null, texto: 'Mensalidade', icone: iconeMensalidade },
];

export interface Parametros {
  filtrosSelecionados: FiltrosSelecionados,
  paginaSelecionada: number,
  registrosPorPagina: number,
}

export enum Ordenacao {
  Ascendente = 'ASC',
  Descendente = 'DESC',
}

export interface TipoTranscao {
  id: number,
  descricao?: string,
}

export interface ItemTabela {
  id: number,
  dataCobranca: Date,
  placa?: string,
  dataPassagem?: Date,
  categoria?: string,
  codigoCategoria?: number,
  tipoTransacao: TipoTranscao,
  centroCusto?: number,
  operacaoCredito: boolean,
  valor: number,
  saldo: number,
  cnpjEmbarcador?: string,
  razaoSocialEmbarcador?: string,
  numeroViagem?: number,
}

export interface Filtros {
  placas: DropdownItem[],
  tiposTransacao?: DropdownItem[],
  centrosCusto: DropdownItem[],
}

export interface FiltrosSelecionados {
  placas: DropdownItem[],
  dataInicio: Date,
  dataFim: Date,
  tiposTransacao: DropdownItem[],
  centrosCusto: DropdownItem[],
  ordem: Ordenacao,
  ordenarPor?: string,
}

export interface ExtratoState {
  documento: string,
  saldo: number,
  tabela: ItemTabela[],
  filtros: Filtros,
  filtrosSelecionados: FiltrosSelecionados,
  totalPaginas: number,
  paginaSelecionada: number,
  registrosPorPagina: number,
  mensagemErro: string
}
