import {
  Button, Collapse, Grid, InputBase, makeStyles, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { PedidoActions, usePedido } from '../../../features/solicitacao-adesivo/solicitacaoAdesivo/context/SolicitacaoAdesivoContext';
import { EnderecoActions, useEndereco } from '../../../features/solicitacao-adesivo/solicitacaoAdesivo/context/SolicitacaoAdesivoEnderecoContext';
import { useLoadingControle } from '../../../hooks/useLoadingControle/useLoadingControle';
import { criarEnderecoCliente } from '../../../services/requests/SolicitacaoAdesivo/criarEnderecoCliente';
import { obterCidadeIdPorCodigoIbge } from '../../../services/requests/SolicitacaoAdesivo/obterCidadeIdPorCodigoIbge';
import { obterEnderecoPorCepRequest } from '../../../services/requests/SolicitacaoAdesivo/obterEnderecoPorCep';
import { alterarEnderecoClienteRequest } from '../../../services/requests/SolicitacaoAdesivo/alterarEnderecoCliente';
import { useControleModalErro } from '../../../hooks/useControleModalErro/useControleModalErro';

import './Endereco.scss';

const Endereco = () => {
  const inputBase = makeStyles(() => ({
    input: {
      padding: 0,
    },
  }))(InputBase);

  const [habilitarBtnSalvarEndereco, setHabilitarBtnSalvarEndereco] = useState<boolean>(false);
  const [habilitarCamposDadosEndereco, setHabilitarCamposDadosEndereco] = useState<boolean>(true);

  const { alterarEstadoCarregando } = useLoadingControle();
  const { abrirModal, alterarMensagemCustomizada } = useControleModalErro();
  const { pedidoState, pedidoDispatch } = usePedido();
  const { enderecoState, enderecoDispatch } = useEndereco();

  const handleSalvarEnderecoClick = async () => {
    alterarEstadoCarregando(true);

    if (enderecoState.enderecoId) await alterarEnderecoClienteRequest(enderecoState);
    else await criarEnderecoCliente(enderecoState);

    alterarEstadoCarregando(false);

    window.location.reload();
  };

  const handlePopularCamposObterEnderecoPorCep = (sucesso: boolean, dados?: any) => {
    enderecoDispatch({ type: EnderecoActions.setLogradouro, payload: sucesso ? dados.logradouro : '' });
    enderecoDispatch({ type: EnderecoActions.setCodigoIbge, payload: sucesso ? dados.ibge : '' });
    enderecoDispatch({ type: EnderecoActions.setIbge, payload: sucesso ? dados.ibge : '' });
    enderecoDispatch({ type: EnderecoActions.setBairro, payload: sucesso ? dados.bairro : '' });
    enderecoDispatch({ type: EnderecoActions.setCidade, payload: sucesso ? dados.cidade : '' });
    enderecoDispatch({ type: EnderecoActions.setEstado, payload: sucesso ? dados.estado : '' });
  };

  const handleCepOnBlur = async () => {
    const { cep } = enderecoState;

    if (!cep || cep.length < 8) return;

    alterarEstadoCarregando(true);
    await obterEnderecoPorCepRequest(cep)
      .then((response) => {
        const { dados } = response.data;

        if (typeof dados === 'string') {
          alterarMensagemCustomizada(dados);
          abrirModal();
          handlePopularCamposObterEnderecoPorCep(false);
        } else {
          handlePopularCamposObterEnderecoPorCep(true, dados);

          if (!dados.logradouro) setHabilitarCamposDadosEndereco(true);
          else setHabilitarCamposDadosEndereco(false);

          handleObterCidadeIdPorCodigoIbge(dados.ibge);
        }
      })
      .catch(() => {
        abrirModal();
        handlePopularCamposObterEnderecoPorCep(false);
      })
      .finally(() => alterarEstadoCarregando(false));
  };

  const handleCloseEnderecoEmEdicao = () => pedidoDispatch({ type: PedidoActions.setModalEnderecoEmEdicaoAberta, payload: false });

  const handleObterCidadeIdPorCodigoIbge = (codigoIbge: any) => {
    obterCidadeIdPorCodigoIbge(codigoIbge.toString())
      .then(response => enderecoDispatch({ type: EnderecoActions.setCidadeId, payload: response.data.dados.Dados.CidadeId }));
  };

  useEffect(() => {
    const { cep, logradouro, numero, bairro, cidade, estado } = enderecoState;

    const camposValidos = cep !== '' && cep.length === 8 && logradouro !== '' && numero !== '' && bairro !== '' && cidade !== '' && estado !== '';
    setHabilitarBtnSalvarEndereco(camposValidos);
  }, [enderecoState, enderecoState.bairro, enderecoState.cep, enderecoState.logradouro, enderecoState.numero]);

  return (
    <>
      <Collapse in={pedidoState.modalEnderecoEmEdicaoAberta}>
        <Grid container spacing={0} className='dados-endereco-container'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='dados-endereco-item dados-endereco-icone-fechar'>
            <Close onClick={handleCloseEnderecoEmEdicao} fontSize='medium' className='dados-endereco-icone-fechar' />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className='dados-endereco-item'>
            <Typography>CEP</Typography>
            <InputMask
              data-testid='dados-endereco-input-cep'
              mask='99999-999'
              maskChar={null}
              className='dados-endereco-input'
              onBlur={handleCepOnBlur}
              value={enderecoState.cep}
              onChange={(e: ChangeEvent<HTMLInputElement>) => enderecoDispatch({ type: EnderecoActions.setCep, payload: e.target.value?.replace('-', '') })}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className='dados-endereco-item'>
            <Typography>Endereço</Typography>
            <InputBase
              classes={inputBase}
              className='dados-endereco-input'
              data-testid='dados-endereco-input-endereco'
              disabled={!habilitarCamposDadosEndereco}
              value={enderecoState.logradouro}
              onChange={(e: ChangeEvent<HTMLInputElement>) => enderecoDispatch({ type: EnderecoActions.setLogradouro, payload: e.target.value })}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={8} className='dados-endereco-item'>
            <Typography>Complemento</Typography>
            <InputBase
              classes={inputBase}
              className='dados-endereco-input'
              data-testid='dados-endereco-input-complemento'
              value={enderecoState.complemento}
              onChange={(e: ChangeEvent<HTMLInputElement>) => enderecoDispatch({ type: EnderecoActions.setComplemento, payload: e.target.value })}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={4} className='dados-endereco-item'>
            <Typography>Número</Typography>
            <InputMask
              data-testid='dados-endereco-input-numero'
              mask='9999999'
              maskChar={null}
              className='dados-endereco-input'
              value={enderecoState.numero}
              onChange={(e: ChangeEvent<HTMLInputElement>) => enderecoDispatch({ type: EnderecoActions.setNumero, payload: e.target.value })}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className='dados-endereco-item'>
            <Typography>Bairro</Typography>
            <InputBase
              classes={inputBase}
              className='dados-endereco-input'
              data-testid='dados-endereco-input-bairro'
              disabled={!habilitarCamposDadosEndereco}
              value={enderecoState.bairro}
              onChange={(e: ChangeEvent<HTMLInputElement>) => enderecoDispatch({ type: EnderecoActions.setBairro, payload: e.target.value })}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={6} className='dados-endereco-item'>
            <Typography>Estado</Typography>
            <InputBase
              classes={inputBase}
              data-testid='dados-endereco-input-estado'
              disabled
              className='dados-endereco-input'
              value={enderecoState.estado}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={6} className='dados-endereco-item'>
            <Typography>Cidade</Typography>
            <InputBase
              classes={inputBase}
              data-testid='dados-endereco-input-cidade'
              disabled
              className='dados-endereco-input'
              value={enderecoState.cidade}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='dados-endereco-item dados-endereco-item-flex'>
            <Button
              className={habilitarBtnSalvarEndereco ? 'dados-endereco-btn-salvar' : 'dados-endereco-btn-salvar-disabled'}
              disabled={!habilitarBtnSalvarEndereco}
              onClick={handleSalvarEnderecoClick}
              variant='outlined'
              id='btn-salvar-endereco-pedido'
            >Salvar
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default memo(Endereco);
