import { makeStyles, Theme } from '@material-ui/core';

const iconExportarPagamento = require('../../../assets/images/icones/exportar_pagamento.png');
const iconBotaoExpotarPdf = require('../../../assets/images/pdf.svg');

const CardFaturaStyle = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 400,
    margin: '1.25rem auto',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    background: '#FF6338',
    borderRadius: '8px 8px 0px 0px',
  },
  headerColorText: {
    width: 176.5,
    height: 20,
    top: 14,
    left: 16,
    fontFamily: 'Lato',
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#fff',
  },
  headerSubColorText: {
    width: 176.5,
    height: 32,
    top: 8,
    left: 207.5,
    fontFamily: 'Lato',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#fff',
  },
  displayDireita: {
    margin: '0 10',
    marginLeft: 'auto',
    alignItems: 'Center',
    textAlign: 'left',
  },
  divFormaPagamento: {
    margin: '0 10px',
    marginLeft: '100px',
    alignItems: 'Center',
    textAlign: 'left',
  },
  dataVencimento: {
    top: 8,
    fontFamily: 'Lato',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#fff',
  },
  content: {
    textAlign: 'left',
  },
  contentMain: {
    border: '0.5px solid #FF6338',
    padding: 20,
  },
  labelValorPagamento: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
  },
  valorPagamento: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 22,
    color: '#FF6338',
  },
  displayValorPagamento: {
    margin: '0 10px',
  },
  divider: {
    width: 'auto',
    height: 1,
    left: 0,
    top: 57,
    background: '#EDEDED',
  },
  contentInformacao: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 20,
    padding: 20,
  },
  labelInformacao: {
    fontFamily: 'Lato',
    fontSyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
  },
  btnFormaPagamento: {
    width: 20,
    height: 18,
    backgroundImage: `url(${iconExportarPagamento})`,
    backgroundPosition: 'center',
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    padding: 0,
    border: 'none',
    background: 'none',
  },
  labelStatusPagamento: {
    fontFamily: 'Lato',
    fontSyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#FF6338',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  contentColapse: {
    background: '#FBFAFA',
    borderRadius: '6px',
  },
  colorBtnColapse: {
    color: '#FF6338',
  },
  contentImgExportar: {
    width: '100%',
    margin: '2px auto',
    maxWidth: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  btnExportarPdf: {
    backgroundColor: '#fff7f7',
    backgroundImage: `url(${iconBotaoExpotarPdf})`,
    '&:hover': {
      backgroundColor: '#fceded',
    },
    width: '100%',
    border: 'none',
    backgroundPosition: 'center',
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    transition: '200ms',
    minHeight: 70,
  },
}));

export default CardFaturaStyle;
