import React from 'react';
import { makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';


interface Props {
  urlBoleto: string,
  deveExibir: boolean
}

const BotaoImprimirBoleto = ({ urlBoleto, deveExibir = false }: Props) => {
  const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
      margin: theme.spacing(1),
      borderRadius: 20,
      borderColor: '#244793',
      color: '#244793',
      backgroundColor: '#fff',
      fontSize: 16,
      transition: 'all 0.7s',
      height: 50,
      width: 220,
      borderStyle: 'solid',
      borderWidth: 'thin',
      fontFamily: 'Lato, Helvetica, Arial, Verdana, Tahoma, sans-serif',
      textTransform: 'none',
      '&:hover': { 'background-color': '#f7faff' },
    },
  }));

  function handlerImprimirBoleto(): void {
    window.location.href = urlBoleto;
  }

  const classes = useStyles();
  const existeBoleto = deveExibir || urlBoleto?.length > 0;
  const botao = (
    <Button id='btnImprimirBoletoUltimoPedido' className={classes.button} startIcon={<PrintIcon />} onClick={() => handlerImprimirBoleto()}>
      Imprimir boleto
    </Button>
  );

  return (
    <div id='divBotaoImprimir'>
      {
        existeBoleto
          ? (
            botao
          )
          : <div />}
    </div>
  );
};

export default BotaoImprimirBoleto;
