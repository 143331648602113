import axios from 'axios';
import { ParametrosPaginacaoMeusPedidos, RetornoMeusPedidos } from '../../../features/solicitacao-adesivo/meus-pedidos/MeusPedidosInterfaces';
import { RespostaApi } from '../../ApiInterfaces';

const obterParametrosFormatadosMeusPedidos = (parametros: ParametrosPaginacaoMeusPedidos) => {
  let parametrosFormatados = `NumeroPagina=${parametros.paginaSelecionada}`;
  parametrosFormatados += `&TamanhoPagina=${parametros.registrosPorPagina}`;
  parametrosFormatados += `&RetornarQtdRegistros=${true}`;
  parametrosFormatados += `&OrdenarDecrescente=${true}`;
  return parametrosFormatados;
};

export default {

  obterMeusPedidos: (paramentros: ParametrosPaginacaoMeusPedidos, token: string)
    : Promise<RespostaApi<RetornoMeusPedidos[]>> => {
    const parametrosFormatados = obterParametrosFormatadosMeusPedidos(paramentros);
    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/Pedido/buscarPedidosPaginados?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
};
