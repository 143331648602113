import { ParametrizacaoPlanoPj } from '../../infrastructure/prospecto-api/planospj';
import { Action } from '../interfaces';
import { RascunhoProspecto } from '../../infrastructure/prospecto-api/rascunho/RascunhoInterfaces';
import { Etapas, StatusCadastro } from './CadastroInterfaces';
import { EnderecoApiCep } from '../dados-conta/DadosContaInterfaces';

export enum Types {
  AtribuirPlano = 'ATRIBUIR_PLANO',
  AtribuirPedidoId = 'ATRIBUIR_PEDIDO_ID',
  ObterPlano = 'OBTER_PLANO',
  ObterRascunho = 'OBTER_RASCUNHO',
  EnviarRascunho = 'ENVIAR_RASCUNHO',
  EnviarProspecto = 'ENVIAR_PROSPECTO',
  EnviarPedido = 'ENVIAR_PEDIDO',
  AtribuirRascunho = 'ATRIBUIR_RASCUNHO',
  AtribuirIdRascunho = 'ATRIBUIR_ID_RASCUNHO',
  AvancarProximaEtapa = 'AVANCAR_PROXIMA_ETAPA',
  AtribuirEtapaAtual = 'ATRIBUIR_ETAPA_ATUAL',
  InicializarPaginaClienteJaCadastrado = 'INICIALIZAR_PAGINA_CLIENTE_JA_CADASTRADO',
  ValidarCnpjEhClienteValidandoIdentidade = 'VALIDAR_CNPJ_EH_CLIENTE_VALIDANDO_IDENTIDADE',
  AtribuirVeiculosLeves = 'ATRIBUIR_VEICULOS_LEVES',
  AtribuirVeiculosPesados = 'ATRIBUIR_VEICULOS_PESADOS',
  AtribuirPossuiAdesivos = 'ATRIBUIR_POSSUI_ADESIVOS',
  AtribuirDadosQueryString = 'ATRIBUIR_DADOS_QUERY_STRING',
  AtribuirCadastroExistente = 'ATRIBUIR_CADASTRO_EXISTENTE',
  AtribuirCampoRepresentanteLegal = 'ATRIBUIR_CAMPO_REPRESENTANTE_LEGAL',
  AtribuirCampoDadosEmpresa = 'ATRIBUIR_CAMPO_DADOS_EMPRESA',
  AtribuirDadosEnderecoApiCep = 'ATRIBUIR_DADOS_ENDERECO_RECUPERADOS_API_CEP',
  AtribuirSenha = 'ATRIBUIR_SENHA',
  AtribuirConsultandoCnpj = 'ATRIBUIR_CONSULTANDO_CNPJ',
  AtribuirConcordaTermosAdesao = 'ATRIBUIR_CONCORDA_TERMOS_ADESAO',
  AtribuirAceitaReceberOfertas = 'ATRIBUIR_ACEITA_RECEBER_OFERTAS',
  AtribuirCompartilharDados = 'ATRIBUIR_COMPARTILHAR_DADOS',
  AtribuirCompartilharDadosItau = 'ATRIBUIR_COMPARTILHAR_DADOS_ITAU',
  AtribuirStatusCadastro = 'ATRIBUIR_STATUS_CADASTRO',
  AtribuirPessoaPoliticamenteExposta = 'ATRIBUIR_PESSOA_POLITICAMENTE_EXPOSTA',
  AtribuirErroServidor = 'ATRIBUIR_ERRO_SERVIDOR',
  AtribuirPedidoResponseBoleto = 'ATRIBUIR_PEDIDO_RESPONSE_BOLETO',
}

export interface AtribuirPlanoAction extends Action { plano: ParametrizacaoPlanoPj }
export interface AtribuirIdRascunhoAction extends Action { idRascunho: string }
export interface AtribuirPedidoIdAction extends Action { pedidoId: string }
export interface AtribuirVeiculosLevesAction extends Action { veiculosLeves: number }
export interface AtribuirVeiculosPesadosAction extends Action { veiculosPesados: number }
export interface AtribuirRascunhoAction extends Action { rascunho: RascunhoProspecto }
export interface AtribuirDadosQueryStringAction extends Action { veiculosLeves: number, veiculosPesados: number, nome: string, email: string, telefone: string, cnpj: string }
export interface ObterPlanoAction extends Action { canalVendasId: string }
export interface ObterRascunhoAction extends Action { idRascunho: string }
export interface EnviarRascunhoAction extends Action { }
export interface EnviarProspectoAction extends Action { }
export interface EnviarPedidoAction extends Action { }
export interface AvancarProximaEtapaAction extends Action { }
export interface AtribuirEtapaAtualAction extends Action { novaEtapa: Etapas }
export interface InicializarPaginaClienteJaCadastradoAction extends Action { }
export interface ValidarCnpjEhClienteValidandoIdentidadeAction extends Action { }
export interface AtribuirConcordaTermosAdesaoAction extends Action { concordaTermosAdesao: boolean }
export interface AtribuirAceitaReceberOfertasAction extends Action { aceitaReceberOfertas: boolean }
export interface AtribuirCompartilharDadosAction extends Action { compartilharDados: boolean }
export interface AtribuirCompartilharDadosItauAction extends Action { compartilharDadosItau: boolean }
export interface AtribuirPossuiAdesivosAction extends Action { possuiAdesivos: Boolean }
export interface AtribuirCadastroExistenteAction extends Action { jaExistente: Boolean }
export interface AtribuirSenhaAction extends Action { senha: string }
export interface AtribuirCampoRepresentanteLegalAction extends Action { nomeCampo: string, novoValor: string }
export interface AtribuirCampoDadosEmpresaAction extends Action { nomeCampo: string, novoValor: string }
export interface AtribuirDadosEnderecoApiCepAction extends Action { enderecoApiCep: EnderecoApiCep }
export interface AtribuirConsultandoCnpjAction extends Action { consultandoCnpj: boolean }
export interface AtribuirStatusCadastroAction extends Action { statusCadastro: StatusCadastro }
export interface AtribuirPessoaPoliticamenteExpostaAction extends Action { pessoaPoliticamenteExposta: boolean }
export interface AtribuirErroServidorAction extends Action { erroServidor: string }
export interface AtribuirPedidoResponseBoletoAction extends Action { possuiBoleto: Boolean, hashBytesBoleto: string, urlBoleto: string }

export default {
  atribuirPlano: (plano: ParametrizacaoPlanoPj): AtribuirPlanoAction => ({
    type: Types.AtribuirPlano,
    plano,
  }),

  atribuirPedidoId: (pedidoId: string): AtribuirPedidoIdAction => ({
    type: Types.AtribuirPedidoId,
    pedidoId,
  }),

  atribuirRascunho: (rascunho: RascunhoProspecto): AtribuirRascunhoAction => ({
    type: Types.AtribuirRascunho,
    rascunho,
  }),

  atribuirIdRascunho: (idRascunho: string): AtribuirIdRascunhoAction => ({
    type: Types.AtribuirIdRascunho,
    idRascunho,
  }),

  atribuirPossuiAdesivos: (possuiAdesivos: Boolean): AtribuirPossuiAdesivosAction => ({
    type: Types.AtribuirPossuiAdesivos,
    possuiAdesivos,
  }),

  obterPlano: (canalVendasId: string): ObterPlanoAction => ({
    type: Types.ObterPlano,
    canalVendasId,
  }),

  obterRascunho: (idRascunho: string): ObterRascunhoAction => ({ type: Types.ObterRascunho, idRascunho }),

  enviarRascunho: (): EnviarRascunhoAction => ({ type: Types.EnviarRascunho }),

  enviarProspecto: (): EnviarProspectoAction => ({ type: Types.EnviarProspecto }),

  enviarPedido: (): EnviarPedidoAction => ({ type: Types.EnviarPedido }),

  inicializarPaginaClienteJaCadastrado: (): InicializarPaginaClienteJaCadastradoAction => ({ type: Types.InicializarPaginaClienteJaCadastrado }),

  validarCnpjEhClienteValidandoIdentidade: (): ValidarCnpjEhClienteValidandoIdentidadeAction => ({ type: Types.ValidarCnpjEhClienteValidandoIdentidade }),

  avancarProximaEtapa: (): AvancarProximaEtapaAction => ({ type: Types.AvancarProximaEtapa }),

  atribuirEtapaAtual: (novaEtapa: Etapas): AtribuirEtapaAtualAction => ({ type: Types.AtribuirEtapaAtual, novaEtapa }),

  atribuirConcordaTermosAdesao: (concordaTermosAdesao: boolean): AtribuirConcordaTermosAdesaoAction => ({ type: Types.AtribuirConcordaTermosAdesao, concordaTermosAdesao }),

  atribuirAceitaReceberOfertas: (aceitaReceberOfertas: boolean): AtribuirAceitaReceberOfertasAction => ({ type: Types.AtribuirAceitaReceberOfertas, aceitaReceberOfertas }),

  atribuirCompartilharDados: (compartilharDados: boolean): AtribuirCompartilharDadosAction => ({ type: Types.AtribuirCompartilharDados, compartilharDados }),

  atribuirCompartilharDadosItau: (compartilharDadosItau: boolean): AtribuirCompartilharDadosItauAction => ({ type: Types.AtribuirCompartilharDadosItau, compartilharDadosItau }),

  atribuirVeiculosLeves: (veiculosLeves: number): AtribuirVeiculosLevesAction => ({ type: Types.AtribuirVeiculosLeves, veiculosLeves }),

  atribuirVeiculosPesados: (veiculosPesados: number): AtribuirVeiculosPesadosAction => ({ type: Types.AtribuirVeiculosPesados, veiculosPesados }),

  atribuirCadastroExistente: (jaExistente: Boolean): AtribuirCadastroExistenteAction => ({ type: Types.AtribuirCadastroExistente, jaExistente }),

  atribuirSenha: (senha: string): AtribuirSenhaAction => ({ type: Types.AtribuirSenha, senha }),

  atribuirDadosQueryString: (
    veiculosLeves: number,
    veiculosPesados: number,
    nome: string,
    email: string,
    telefone: string,
    cnpj: string,
  ): AtribuirDadosQueryStringAction => ({ type: Types.AtribuirDadosQueryString, veiculosLeves, veiculosPesados, nome, email, telefone, cnpj }),

  atribuirCampoRepresentanteLegal: (nomeCampo: string, novoValor: string): AtribuirCampoRepresentanteLegalAction => (
    { type: Types.AtribuirCampoRepresentanteLegal, nomeCampo, novoValor }
  ),

  atribuirCampoDadosEmpresa: (nomeCampo: string, novoValor: string): AtribuirCampoDadosEmpresaAction => (
    { type: Types.AtribuirCampoDadosEmpresa, nomeCampo, novoValor }
  ),

  atribuirDadosEnderecoApiCep: (enderecoApiCep: EnderecoApiCep): AtribuirDadosEnderecoApiCepAction => (
    { type: Types.AtribuirDadosEnderecoApiCep, enderecoApiCep }
  ),

  atribuirConsultandoCnpj: (consultandoCnpj: boolean): AtribuirConsultandoCnpjAction => (
    { type: Types.AtribuirConsultandoCnpj, consultandoCnpj }
  ),

  atribuirStatusCadastro: (statusCadastro: StatusCadastro): AtribuirStatusCadastroAction => (
    { type: Types.AtribuirStatusCadastro, statusCadastro }),

  atribuirPessoaPoliticamenteExposta: (pessoaPoliticamenteExposta: boolean): AtribuirPessoaPoliticamenteExpostaAction => ({ type: Types.AtribuirPessoaPoliticamenteExposta, pessoaPoliticamenteExposta }),
  atribuirErroServidor: (erroServidor: string): AtribuirErroServidorAction => (
    { type: Types.AtribuirErroServidor, erroServidor }
  ),

  atribuirPedidoResponseBoleto: (possuiBoleto: boolean, hashBytesBoleto: string, urlBoleto: string): AtribuirPedidoResponseBoletoAction => (
    { type: Types.AtribuirPedidoResponseBoleto, possuiBoleto, hashBytesBoleto, urlBoleto }),
};
