import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Actions from '../CadastroActions';
import CadastroCabecalho from '../CadastroCabecalho';
import CadastroResumo from '../CadastroResumo';
import Campo from '../../../components/Inputs/CampoTexto';
import CampoCep from '../../../components/Inputs/CampoCep';
import CadastroFooter from '../CadastroFooter';
import { DadosEmpresa, Etapas, CadastroState, EtapasRotas } from '../CadastroInterfaces';
import { State } from '../../interfaces';
import Dropdown from '../../../components/Inputs/Dropdown';
import '../Cadastro.scss';
import './CadastroEmpresaPage.scss';
import CampoData from '../../../components/Inputs/CampoData';
import Estados from '../../../infrastructure/listas-fixas/estados.json';
import NaturezasJuridicas from '../../../infrastructure/listas-fixas/natureza-juridica.json';
import { EnderecoApiCep } from '../../dados-conta/DadosContaInterfaces';
import ValidarCampo, { formularioEstaValido, Validacao } from '../../../infrastructure/validador';
import AplicarMascara, { removerCaracteresNaoNumericos } from '../../../infrastructure/mascara';
import CadastroErroServidor from '../CadastroErroServidor';

interface ValidacaoFormulario {
  cnpj: Validacao,
  razaoSocial: Validacao,
  faturamentoMedioMensal: Validacao,
  cep: Validacao,
  logradouro: Validacao,
  numero: Validacao,
  cidade: Validacao,
  bairro: Validacao,
  estado: Validacao,
  dataConstituicao: Validacao,
}

export default () => {
  const { cnpj,
    razaoSocial,
    faturamentoMedioMensal,
    dataConstituicao,
    naturezaJuridica,
    cep,
    logradouro,
    numero,
    cidade,
    bairro,
    estado,
    complemento,
    consultandoCnpj } = useSelector<State, DadosEmpresa>(state => state.cadastro.dadosEmpresa);

  const [validacao, setValidacao] = React.useState<ValidacaoFormulario>({
    cnpj: { mensagem: '', estaValido: !ValidarCampo('cnpj', cnpj) },
    razaoSocial: { mensagem: '', estaValido: !ValidarCampo('razaoSocial', razaoSocial) },
    faturamentoMedioMensal: { mensagem: '', estaValido: !ValidarCampo('faturamentoMedioMensal', faturamentoMedioMensal) },
    cep: { mensagem: '', estaValido: !ValidarCampo('cep', cep) },
    logradouro: { mensagem: '', estaValido: !ValidarCampo('logradouro', logradouro) },
    numero: { mensagem: '', estaValido: !ValidarCampo('numero', numero) },
    cidade: { mensagem: '', estaValido: !ValidarCampo('cidade', cidade) },
    bairro: { mensagem: '', estaValido: !ValidarCampo('bairro', bairro) },
    estado: { mensagem: '', estaValido: !ValidarCampo('estado', estado) },
    dataConstituicao: { mensagem: '', estaValido: !ValidarCampo('dataConstituicao', dataConstituicao) },
  });

  const etapaAtual = useSelector<State, Etapas>(state => state.cadastro.etapaAtual);
  const { jaExistente } = useSelector<State, CadastroState>(state => state.cadastro);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    document.title = 'Dados da empresa - Cadastro - ConectCar';
    if (etapaAtual < Etapas.Empresa) history.push(EtapasRotas.get(Etapas.Plano)!);
  }, [dispatch, etapaAtual, history]);

  React.useEffect(() => {
    if (jaExistente) {
      history.push(`/cadastro?cnpj=${removerCaracteresNaoNumericos(cnpj)}`);
    }
  }, [cnpj, history, jaExistente]);

  const validarCampo = (name: string, value: string) => {
    const mensagem = ValidarCampo(name, value);

    setValidacao({
      ...validacao,
      [name]: { mensagem, estaValido: !mensagem },
    });
  };

  const validarCamposEnderecoCepApi = (enderecoCep: EnderecoApiCep) => {
    const nomeBairro = 'bairro';
    const nomeCidade = 'cidade';
    const nomeEstado = 'estado';
    const nomeLogradouro = 'logradouro';

    const mensagemBairro = ValidarCampo(nomeCidade, enderecoCep.bairro);
    const mensagemCidade = ValidarCampo(nomeCidade, enderecoCep.cidade);
    const mensagemEstado = ValidarCampo(nomeEstado, enderecoCep.estado);
    const mensagemLogradouro = ValidarCampo(nomeLogradouro, enderecoCep.logradouro);

    setValidacao({
      ...validacao,
      [nomeBairro]: { mensagem: mensagemBairro, estaValido: !mensagemBairro },
      [nomeCidade]: { mensagem: mensagemCidade, estaValido: !mensagemCidade },
      [nomeEstado]: { mensagem: mensagemEstado, estaValido: !mensagemEstado },
      [nomeLogradouro]: { mensagem: mensagemLogradouro, estaValido: !mensagemLogradouro },
    });
  };

  const handleSucessoBuscaCep = (enderecoCep: EnderecoApiCep) => {
    dispatch(Actions.atribuirDadosEnderecoApiCep(enderecoCep));
    validarCamposEnderecoCepApi(enderecoCep);
  };

  const handleChange = (name, value) => {
    const valueComMascara = AplicarMascara(name, value);
    dispatch(Actions.atribuirCampoDadosEmpresa(name, valueComMascara));
    validarCampo(name, valueComMascara);
  };

  const handleValidacaoCnpj = () => {
    const mensagem = ValidarCampo('cnpj', cnpj);

    if (!mensagem) {
      dispatch(Actions.validarCnpjEhClienteValidandoIdentidade());
    }
  };

  const handleChangeDropDown = (name, value) => {
    handleChange(name, value);
  };

  const formularioNaoEstaValido = () => !formularioEstaValido(validacao);

  const avancarProximoPasso = () => {
    dispatch(Actions.avancarProximaEtapa());
    dispatch(Actions.enviarRascunho());
    history.push(EtapasRotas.get(Etapas.Senha)!);
  };

  return (
    <section id='cadastro-empresa-page' className='cadastro-page'>
      <section className='conteudo'>
        <CadastroCabecalho />
        <CadastroResumo />

        <section className='formulario'>
          <CadastroErroServidor />
          <div className='informacoes-gerais'>
            <Campo
              name='cnpj'
              titulo='CNPJ'
              value={cnpj}
              onChange={event => handleChange(event.target.name, event.target.value)}
              onBlur={handleValidacaoCnpj}
              mostrarLoading={consultandoCnpj}
              mensagemValidacao={validacao.cnpj.mensagem}
            />
            <Campo
              name='razaoSocial'
              titulo='Razão Social'
              value={razaoSocial}
              onChange={event => handleChange(event.target.name, event.target.value)}
              mensagemValidacao={validacao.razaoSocial.mensagem}
            />
            <Campo
              name='faturamentoMedioMensal'
              titulo='Faturamento'
              value={faturamentoMedioMensal}
              meiaColuna
              onChange={event => handleChange(event.target.name, event.target.value)}
              mensagemValidacao={validacao.faturamentoMedioMensal.mensagem}
            />
            <CampoData
              name='dataConstituicao'
              titulo='Data Constituição'
              value={dataConstituicao}
              meiaColuna
              mensagemValidacao={validacao.dataConstituicao.mensagem}
              onChange={e => handleChange(e.target.name, e.target.value ? new Date(e.target.value) : null)}
            />
            <Dropdown
              name='naturezaJuridica'
              titulo='Natureza Jurídica'
              itens={NaturezasJuridicas}
              onChange={handleChangeDropDown}
              textoItemSelecionado={naturezaJuridica}
            />
          </div>

          <div className='endereco'>
            <CampoCep
              name='cep'
              titulo='CEP'
              value={cep}
              onCepSearchSuccess={handleSucessoBuscaCep}
              onCepSearchError={() => null}
              onChange={handleChange}
              mensagemValidacao={validacao.cep.mensagem}
            />
            <Campo
              name='logradouro'
              titulo='Logradouro'
              value={logradouro}
              meiaColuna
              onChange={event => handleChange(event.target.name, event.target.value)}
              mensagemValidacao={validacao.logradouro.mensagem}
            />
            <Campo
              name='numero'
              titulo='Número'
              meiaColuna
              value={numero}
              onChange={event => handleChange(event.target.name, event.target.value)}
              mensagemValidacao={validacao.numero.mensagem}
            />
            <Campo
              name='cidade'
              titulo='Cidade'
              meiaColuna
              value={cidade}
              onChange={event => handleChange(event.target.name, event.target.value)}
              mensagemValidacao={validacao.cidade.mensagem}
            />
            <Dropdown
              name='estado'
              titulo='Estado'
              itens={Estados}
              meiaColuna
              onChange={handleChangeDropDown}
              textoItemSelecionado={estado}
              mensagemValidacao={validacao.estado.mensagem}
            />
            <Campo
              name='complemento'
              titulo='Complemento'
              meiaColuna
              value={complemento}
              onChange={event => handleChange(event.target.name, event.target.value)}
            />
            <Campo
              name='bairro'
              titulo='Bairro'
              meiaColuna
              value={bairro}
              onChange={event => handleChange(event.target.name, event.target.value)}
              mensagemValidacao={validacao.bairro.mensagem}
            />
          </div>
          <button
            type='button'
            className='botao-preenchido-azul'
            title='Próximo passo'
            disabled={formularioNaoEstaValido()}
            onClick={avancarProximoPasso}
          >
            Próximo passo
          </button>
          <CadastroFooter />
        </section>
      </section>
    </section>
  );
};
