import React from 'react';
import { Box, Grid } from '@material-ui/core';
import ResumoRecarga from '../ResumoRecarga/ResumoRecarga';
import Sucesso from '../../../assets/images/icones/sucesso-recarga-circulo.svg';
import ResultadoStyles from './ResultadoStyles';


interface Props {
  valorRecargaDigitado: number
}

const SucessoRecargaPage = ({
  valorRecargaDigitado,
}: Props) => {
  const styles = ResultadoStyles();

  return (
    <Box component='div' style={{ padding: '2rem', display: 'flex', alignContent: 'center' }}>
      <Grid container component='div' className={styles.gridContainer} direction='row' alignContent='center' spacing={3}>
        <Grid item lg={8} md={8} xs={12} sm={12} className={styles.gridItem}>
          <img src={Sucesso} alt='' />
          <p className={styles.tituloSucesso}>
            Recarga solicitada com sucesso.
          </p>
          <p className={styles.subtituloSucesso}>
            Obrigado por usar ConectCar. Espere alguns minutos até que seu saldo seja atualizado. Acesse seu em-mail cadastrado para mais informações
          </p>
          <a
            type='button'
            className={styles.botaoSucesso}
            href='/home'
            style={{ color: '#FFFFFF' }}
          >
            Voltar para a home
          </a>
        </Grid>
        <ResumoRecarga
          renderizarBotaoPagamento={false}
          valorRecargaDigitado={valorRecargaDigitado}
        />
      </Grid>
    </Box>
  );
};

export default SucessoRecargaPage;
