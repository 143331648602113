import './ExtratoPage.scss';

import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Actions from '../ExtratoActions';
import Aviso from '../../../components/Aviso/Aviso';
import { DropdownItem } from '../../../components/Inputs/Dropdown/DropdownInterface';
import { ExtratoState } from '../ExtratoInterfaces';
import Filtros from '../ExtratoFiltros';
import Paginacao from '../../../components/Paginacao';
import SaldoGlobal from '../../../components/SaldoGlobal/SaldoGlobal';
import { State } from '../../interfaces';
import Tabela from '../ExtratoTabela';
import ocultadorChatbot from '../../../infrastructure/ocultadorChatbot';

const ExtratoPage = () => {
  const {
    documento,
    saldo,
    filtros,
    filtrosSelecionados,
    tabela,
    paginaSelecionada,
    registrosPorPagina,
    totalPaginas,
    mensagemErro,
  } = useSelector<State, ExtratoState>(state => ({
    documento: state.extrato.documento,
    saldo: state.extrato.saldo,
    filtros: state.extrato.filtros,
    filtrosSelecionados: state.extrato.filtrosSelecionados,
    tabela: state.extrato.tabela,
    totalPaginas: state.extrato.totalPaginas,
    paginaSelecionada: state.extrato.paginaSelecionada,
    registrosPorPagina: state.extrato.registrosPorPagina,
    mensagemErro: state.extrato.mensagemErro,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = 'ConectCar Frotas - Extrato';
    ocultadorChatbot();
    dispatch(Actions.inicializarExtrato());
  }, [dispatch]);

  const handlePeriodoChange = (dataInicio: Date, dataFim: Date) => dispatch(Actions.filtrarPorPeriodo(dataInicio, dataFim));

  const handlePlacasChange = (placas: DropdownItem[]) => dispatch(Actions.filtrarPorPlacas(placas));

  const handleCentrosCustoChange = (centrosCusto: DropdownItem[]) => dispatch(Actions.filtrarPorCentroCustos(centrosCusto));

  const handleTiposTransacaoChange = (tiposTransacao: DropdownItem[]) => dispatch(Actions.filtrarPorTiposTransacao(tiposTransacao));

  const handleMudarPaginaChange = (
    pagina: number,
    quantidadeRegistros: number,
  ) => {
    dispatch(Actions.modificarPagina(pagina, quantidadeRegistros));
    dispatch(Actions.buscarExtrato());
  };

  const handleExportarClick = () => {
    dispatch(Actions.exportarExtrato());
  };

  const handleExportarPdfClick = () => {
    dispatch(Actions.exportarExtratoPdf());
  };

  const handlePesquisarClick = () => dispatch(Actions.buscarExtrato());

  const handleLimparFiltrosClick = () => dispatch(Actions.limparFiltros());

  const handleClose = () => dispatch(Actions.mensagemFiltro(''));

  return (
    <section id='extrato-page'>
      <Aviso
        open={mensagemErro !== ''}
        handleClose={handleClose}
        mensagem={mensagemErro}
        variacao='erro'
      />
      <SaldoGlobal saldo={saldo} documento={documento} />
      {documento?.length > 0 ? (
        <Filtros
          dataInicio={filtrosSelecionados.dataInicio}
          dataFim={filtrosSelecionados.dataFim}
          onPeriodoClose={handlePeriodoChange}
          placas={filtros.placas}
          placasSelecionadas={filtrosSelecionados.placas}
          onPlacasChange={handlePlacasChange}
          centrosCusto={filtros.centrosCusto}
          centrosCustoSelecionados={filtrosSelecionados.centrosCusto}
          onCentrosCustoChange={handleCentrosCustoChange}
          tiposTransacao={filtros.tiposTransacao}
          tiposTransacaoSelecionados={filtrosSelecionados.tiposTransacao}
          onTiposTransacaoChange={handleTiposTransacaoChange}
          onLimparFiltrosClick={handleLimparFiltrosClick}
          onExportarClick={handleExportarClick}
          onExportarPdfClick={handleExportarPdfClick}
          onPesquisarClick={handlePesquisarClick}
        />
      ) : (
        <div />
      )}
      <Tabela
        tabela={tabela}
        aviso='Não foram encontrados registros com os filtros selecionados'
      />
      <Paginacao
        totalPaginas={totalPaginas}
        paginaSelecionada={paginaSelecionada}
        registrosPorPagina={registrosPorPagina}
        onMudarPaginacaoChange={handleMudarPaginaChange}
      />
    </section>
  );
};

export default ExtratoPage;
