import * as React from 'react';
import { useSelector } from 'react-redux';
import { FaturaState } from '../../../features/fatura-pos-pago/FaturaInterfaces';
import { State } from '../../../features/interfaces';

import './SaldoLimiteDisponivel.scss';

const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

const SaldoLimiteDisponivel = () => {
  const {
    dadosLimitesSaldo,
  } = useSelector<State, FaturaState>(state => ({
    dadosFatura: null,
    erro: state.fatura.erro,
    dadosLimitesSaldo: state.fatura.dadosLimitesSaldo,
    historicoFatura: state.fatura.historicoFatura,
    carregando: false,
  }));

  return (
    <section className='container-limite-fatura'>
      <div id='saldo-limite-fatura' className='home-page-limite-fatura'>
        <div className='dvResumoFatura'>
          <div className='display'>
            <p><span className='spanFatura'>Fatura</span></p>
          </div>
          <div className='display'>
            <p>Visualize aqui as transações relacionadas a sua fatura.</p>
            <p>Todas as transações serão computadas somente no dia seguinte.</p>
          </div>
          <div className='displayDireita'>
            <p><span>Limite Disponível</span></p>
            <p className='valorLimite'>{dadosLimitesSaldo?.dados?.limiteDisponivel?.toLocaleString('pt-BR', formato)}</p>
          </div>
          <div className='displayDireita'>
            <span>Limite Crédito</span>
            <p className='valorLimite'>{dadosLimitesSaldo?.dados?.limiteCredito?.toLocaleString('pt-BR', formato)}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SaldoLimiteDisponivel;
