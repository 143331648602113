import { createStyles, makeStyles } from '@material-ui/core';

const avisoExclamacaoIcone = require('../../../../assets/images/icones/aviso-exclamacao.png');

const NovaRecargaStyles = makeStyles(() => createStyles({
  BoxNovaRecarga: {
    alignItems: 'center',
    borderRadius: '0.25rem',
    border: '1px solid #D5D5D5',
    display: 'flex',
    minHeight: '5rem',
    overflow: 'hidden',
  },
  ImagemNovaRecarga: {
    backgroundImage: `url(${avisoExclamacaoIcone})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'grid',
    justifyContent: 'center',
    height: '2.2rem',
    padding: '0.25em',
    width: '2.875rem',
  },
  TextoNovaRecarga: {
    color: '#707070',
    fontWeight: 400,
    fontSize: '14px',
  },
  GridBottomSpacing: {
    marginBottom: '1.5rem !important',
  },
}));

export default NovaRecargaStyles;
