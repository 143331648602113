import React, { memo } from 'react';
import { Button, Modal, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Logo from '../../assets/images/logo.svg';
import IconeErro from '../../assets/images/icones/icone-erro-circular.svg';

import './styles.scss';

import { colors } from '../../styles/theme';
import { useControleModalErro } from '../../hooks/useControleModalErro/useControleModalErro';

interface IModalErroProps {
    acaoAoSairDoModal?: () => void;
    acaoAoFecharModal?: () => void;
}

const ModalErro: React.FC<IModalErroProps> = ({
  acaoAoSairDoModal,
  acaoAoFecharModal,
}) => {
  const { visivel, mensagemCustomizada } = useControleModalErro();

  return (
    <div>
      <Modal
        open={visivel}
        onClose={acaoAoSairDoModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          className='modalBox'
          style={{ position: 'fixed', maxWidth: 430, width: 430 }}
        >

          <img src={Logo} alt='logo' />


          <img
            src={IconeErro}
            alt='icone erro'
            style={{
              marginTop: 40,
              width: 50,
              height: 50,
            }}
          />

          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: 20,
              textAlign: 'center',
              marginTop: 25,
              marginBottom: 40,
              color: colors.primary.PJ,
            }}
          >
            {mensagemCustomizada || 'Ocorreu um erro na sua solicitação! Tente novamente!'}
          </Typography>

          <Button
            onClick={acaoAoFecharModal}
            style={{
              width: '90%',
              background: '#FF6338',
              color: '#FFF',
              borderRadius: 100,
              height: 45,
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Tentar novamente
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default memo(ModalErro);
