import { Action } from '../interfaces';
import {
  Types,
  AtribuirDadosContaAction,
  AtribuirContatoAction,
  ToggleFormularioSenhaAction,
  AtribuirEnderecoAction,
  AtribuirMensagemErroAction,
} from './DadosContaActions';
import { DadosContaState } from './DadosContaInterfaces';

const INITIAL_STATE = {
  contato: { nome: '', email: '', telefone: '', celular: '' },
  endereco: {
    cep: '',
    logradouro: '',
    numero: '',
    nomeCidade: '',
    estado: '',
    complemento: '',
    bairro: '',
  },
  carregando: false,
  estaTrocandoSenha: false,
  mensagemErro: '',
} as DadosContaState;

const atribuirDadosConta = (state: DadosContaState, action: AtribuirDadosContaAction) => (
  { ...state, contato: action.contato, endereco: action.endereco });

const atribuirContato = (state: DadosContaState, action: AtribuirContatoAction) => (
  { ...state, contato: action.contato });

const atribuirEndereco = (state: DadosContaState, action: AtribuirEnderecoAction) => (
  { ...state, endereco: action.endereco });

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.AtribuirDadosConta:
      return atribuirDadosConta(state, action as AtribuirDadosContaAction);

    case Types.AtribuirContato:
      return atribuirContato(state, action as AtribuirContatoAction);

    case Types.AtribuirEndereco:
      return atribuirEndereco(state, action as AtribuirEnderecoAction);

    case Types.IniciarCarregamento:
      return { ...state, carregando: true };

    case Types.FinalizarCarregamento:
      return { ...state, carregando: false };

    case Types.ToggleFormularioSenha:
      return { ...state, estaTrocandoSenha: (action as ToggleFormularioSenhaAction).estaTrocandoSenha };

    case Types.AtribuirMensagemErro:
      return { ...state, mensagemErro: (action as AtribuirMensagemErroAction).mensagemErro };

    default:
      return state;
  }
};
