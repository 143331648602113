import { Box, Container, Grid } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React, { FC } from 'react';
import './Arquivos.scss';

import Filtro from '../../assets/images/icones/filtro.svg';
import Grafico from '../../assets/images/menina-graficos.svg';
import Cancelar from '../../assets/images/icones/cancelar.svg';
import ArquivoItem from './ArquivosItem';

const Arquivos: FC = () => {
  const [pdfselected, setPdfSelected] = React.useState(true);
  const [excelselected, setExcelSelected] = React.useState(true);

  return (
    <Container style={{ maxWidth: '1108px', padding: '0px', marginTop: '1em' }} className='arquivos-container'>
      <Box className='arquivos-header'>
        <img src={Grafico} alt='mulher com os graficos' style={{ width: '100%', height: 'auto', maxWidth: '331px', objectFit: 'contain' }} className='arquivos-header-img' />
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '0px', gap: '24px' }} className='arquivos-header-txt'>
          <p id='titulo-arquivos' style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '40px', lineHeight: '120%', letterSpacing: '-0.02em', color: '#3B5CB0' }}>Eficiência em movimento: Aprimore a gestão da sua frota com nossos materiais de apoio</p>
          <p id='subtitulo-arquivos' style={{ fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '20px', letterSpacing: '-0.02em', color: '#4D5761' }}>Para ajudá-los a controlar sua frota de maneira prática e eficiente, estamos disponibilizando algumas planilhas e ferramentas de gestão de frotas que irão se tornar indispensável no seu dia-a-dia.</p>
        </Box>
      </Box>
      <Box style={{ padding: '0px', marginTop: '2em', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <p className='subtitulo-planilhas'>Planilhas</p>
        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '1em', gap: '20px', alignItems: 'center' }}>
          <img src={Filtro} alt='' style={{ marginLeft: '2%' }} />
          <p id='titulo-filtro'>Filtro</p>
          <ToggleButton value='' selected={excelselected} onChange={() => { setExcelSelected(!excelselected); }} className='arquivos-button-filtro'>XLS
            <img src={Cancelar} alt='' hidden={!excelselected} />
          </ToggleButton>
          <ToggleButton value='' selected={pdfselected} onChange={() => { setPdfSelected(!pdfselected); }} className='arquivos-button-filtro'>PDF
            <img src={Cancelar} alt='' hidden={!pdfselected} />
          </ToggleButton>
        </Box>
        <Grid container spacing={2} className='grid-container-cards'>
          <ArquivoItem id='controle-multas' selected={excelselected} idArquivo={0} nomeArquivo='Controle de Multas.xlsx' descricao='Controle de Multas' />
          <ArquivoItem id='controle-abastecimento' selected={excelselected} idArquivo={1} nomeArquivo='Controle de Abastecimentos.xlsx' descricao='Controle de Abastecimentos' />
        </Grid>
      </Box>
    </Container>
  );
};

export default Arquivos;
