import React from 'react';

interface Props {
  action: string,
  dadosCriptografados: string
}

const ModalShopline = ({ action, dadosCriptografados }: Props) => (
  <form id='form-itau' action={action} method='post' name='form1' target='SHOPLINE'>
    <button type='submit' id='boleto-itau' name='DC' value={dadosCriptografados} />
  </form>
);

export default ModalShopline;
