import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './FaturaTabs.scss';
import { Box, Icon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CardFaturaStyle from './FaturaTabsStyle';
import FaturaAtualPage from '../FaturaAtualPage';
import FaturaAnteriorPage from '../FaturaAnteriorPage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FaturaTabs = () => {
  const classesTabs = CardFaturaStyle();
  const [value, setValue] = React.useState(1);
  const [iconeSeleted, setValueIconeSeleted] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setValueIconeSeleted(true);
  };

  return (
    <section>
      <Tabs classes={{ indicator: classesTabs.indicator }} className={classesTabs.tabs} value={value} onChange={handleChange} aria-label='tabs fatura'>
        <Tab
          className={classesTabs.tab1}
          classes={{ labelIcon: classesTabs.labelTabs }}
          icon={(
            <Icon className={clsx(classesTabs.imagemIcon, { [classesTabs.imagemIconSelected]: value === 0 && iconeSeleted })} />
          )}
          label='Fatura anterior'
        />

        <Tab
          className={classesTabs.tab2}
          classes={{ labelIcon: classesTabs.labelTabs }}
          icon={(
            <Icon className={clsx(classesTabs.imagemIcon, { [classesTabs.imagemIconSelected]: value === 1 && iconeSeleted })} />
          )}
          label='Fatura atual'
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <FaturaAnteriorPage />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <FaturaAtualPage />
      </TabPanel>
    </section>
  );
};

export default FaturaTabs;
