import { Button } from '@material-ui/core';
import React from 'react';
import './BoletoFlash.scss';

import BoletoFlashStyles from './BoletoFlashStyles';

const BoletoFlash = {
  BoletoFlashImagens: () => BoletoFlashImagens(),
  BoletoFlashDescricao: () => BoletoFlashDescricao(),
};

const BoletoFlashImagens = () => {
  const styles = BoletoFlashStyles();

  return (
    <div className={styles.boletoFlashDiv} id='icones-flash'>
      <div className={`${styles.boletoFlashStyleBase} ${styles.pixIcone}`} />
      <div className={`${styles.boletoFlashStyleBase} ${styles.bradescoIcone}`} />
      <div className={`${styles.boletoFlashStyleBase} ${styles.bancoBrasilIcone}`} />
      <div className={`${styles.boletoFlashStyleBase} ${styles.santanderIcone}`} />
      <div className={`${styles.boletoFlashStyleBase} ${styles.caixaIcone}`} />
      <div className={`${styles.boletoFlashStyleBase} ${styles.outrosBancosIcone}`}>
        <Button disabled className={styles.outrosBancosIconeButton}>+ outros bancos</Button>
      </div>
    </div>
  );
};

const BoletoFlashDescricao = () => {
  const styles = BoletoFlashStyles();

  return (
    <div className={styles.boletoFlashDescricao} id='descricao-flash'>
      <p className='informativoBoletoFlash'>Crédito em até 1 hora pagando o boleto no mesmo banco de emissão, das 9h às 18h em dias úteis.</p>
      <p className='informativoBoletoFlash'>Uma taxa de confirmação acelerada de R$4,99 será acrescentada ao valor final.</p>
    </div>
  );
};

export default BoletoFlash;
