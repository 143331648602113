import { createStyles, makeStyles } from '@material-ui/core';

const iconePix = require('../../../../assets/images/icones/logo-pix.png');
const iconeBoletoFlash = require('../../../../assets/images/icones/boleto-flash.png');
const iconeItau = require('../../../../assets/images/icones/itau.png');
const iconeBoleto = require('../../../../assets/images/icones/boleto.png');
const iconeCartaoCredito = require('../../../../assets/images/icones/cartaocredito.svg');

const MetodosPagamentoStyles = makeStyles(() => createStyles({
  boxPagamentos: {
    maxWidth: '1216px',
    width: '100%',
    float: 'right',
    display: 'contents',
  },
  botaoHistoricoRecarga: {
    backgroundColor: '#FF6A38',
    borderRadius: '40px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 500,
    height: '46px',
    textTransform: 'capitalize',
    width: '200px',
    '&:hover': {
      backgroundColor: '#FF6A38',
    },
  },
  metodosPagamentoTitulo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
  },
  logotiposBaseCss: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '80px',
    width: '80px',
    marginTop: '-20px',
    marginLeft: '-8px',
  },
  pixLogotipo: {
    backgroundImage: `url(${iconePix})`,
  },
  boletoFlashLogotipo: {
    backgroundImage: `url(${iconeBoletoFlash})`,
    marginTop: '0px',
  },
  itauLogotipo: {
    backgroundImage: `url(${iconeItau})`,
  },
  boletoLogotipo: {
    backgroundImage: `url(${iconeBoleto})`,
  },
  cartaoCreditotipo: {
    backgroundImage: `url(${iconeCartaoCredito})`,
  },
}));

export default MetodosPagamentoStyles;
