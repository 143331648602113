import React, { memo } from 'react';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { buttonStyles } from './styles';
import { useAvisoSaldo } from '../../../../../Context/AvisoSaldoContexto';

const ButtonSalvarEmail: React.FC<ButtonBaseProps> = (props) => {
  const styles = buttonStyles(props);
  const { flagReceberAvisoSaldo } = useAvisoSaldo();

  if (!flagReceberAvisoSaldo) return null;

  return (
    <ButtonBase
      {...props}
      classes={styles()}
    >
      <Check />
    </ButtonBase>
  );
};

export default memo(ButtonSalvarEmail);
