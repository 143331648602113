import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { GetApp, Visibility } from '@material-ui/icons';
import { State } from '../../interfaces';
import { downloadFile } from '../../../utils/downloadfile';
import ListaItemImportantePedidoBoleto from '../CadastroConcluidoPage/ListaItemImportantePedidoBoleto';

import './CadastroConcluidoPedidoPage.scss';

const textoBoletoGerado = 'Seu pedido foi recebido e será processado após o pagamento do boleto.';

export default () => {
  const hashBytesBoleto = useSelector<State, string>(state => state.cadastro.hashBytesBoleto);
  const urlBoleto = useSelector<State, string>(state => state.cadastro.urlBoleto);

  const abrirNovaGuiaVisualizarBoleto = () => window.open(urlBoleto, '_blank');

  const baixarBoletoPdf = () => downloadFile(hashBytesBoleto, 'BoletoPedidoConectCar.pdf');

  const redirecionarAmbienteCliente = () => {
    window.parent.location.href = `${(window as any).config.url_portal_cliente}/HomePj`;
  };

  return (
    <section id='cadastro-concluido-pedido'>
      <h3 className='proximos-passos-descricao'>{textoBoletoGerado}</h3>
      <div id='botoes-boleto-pedido' className='botoes-boleto-container'>
        {hashBytesBoleto
          && (
            <div className='botao-boleto-container'>
              <Button
                className='botao-baixar-boleto'
                endIcon={<GetApp />}
                onClick={baixarBoletoPdf}
                type='button'
                title='Faz o download do boleto em PDF'
                variant='contained'
              >
                baixar boleto
              </Button>
            </div>
          )
        }
        {urlBoleto
          && (
            <div className='botao-boleto-container'>
              <Button
                className='botao-ver-boleto'
                endIcon={<Visibility />}
                onClick={abrirNovaGuiaVisualizarBoleto}
                type='button'
                title='Abre uma nova guia com o boleto'
                variant='contained'
              >
                visualizar boleto
              </Button>
            </div>
          )
        }

        <div id='informacoes-importantes-boleto-pedido'>
          {urlBoleto || hashBytesBoleto
            ? (
              <React.Fragment>
                <h3 className='lista-importante-titulo'>Importante</h3>
                <ListaItemImportantePedidoBoleto />
              </React.Fragment>
            )
            : (
              <React.Fragment>
                <h3 className='acesso-portal-descricao'>Acesse o portal do cliente
                  <span
                    role='link'
                    className='anchor-ambiente-cliente'
                    onClick={redirecionarAmbienteCliente}
                    onKeyDown={redirecionarAmbienteCliente}
                    tabIndex={0}
                  >&nbsp;aqui&nbsp;
                  </span>
                  para ver o boleto do seu pedido.
                </h3>
              </React.Fragment>
            )
          }
        </div>
      </div>
    </section>
  );
};
