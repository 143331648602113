import {
  Box, Button, Fade, Modal,
  createStyles, makeStyles,
} from '@material-ui/core';
import React from 'react';
import { MensagemErroPadrao } from './ModalErroConstantes';

interface ModalErroProps {
  visivel: boolean;
  handleClose: () => void;
  mensagem: any;
}

const useStyles = makeStyles(() => createStyles({
  ModalInsucessoTitulo: {
    color: '#FF6338',
    fontSize: '20px !important',
    fontWeight: 'bold',
  },
  ModalInsucessoDescricao: {
    color: '#2A3037',
    fontSize: '14.4px !important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  ModalInsucessoBotao: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: 700,
    width: '100px',
  },
  InsucessoVoltar: {
    backgroundColor: 'white',
    color: '#FF6338',
    border: '2px solid #FF6338',
  },
}));

const ModalErro = ({ visivel, handleClose, mensagem }: ModalErroProps) => {
  const classes = useStyles();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    bgcolor: 'background.paper',
    border: '2px solid white',
    borderRadius: '10px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    paddingRight: '20px',
    paddingLeft: '20px',
  };

  const handlePrepararMensagem = () => {
    if (typeof mensagem === 'object') {
      return (Object.keys(mensagem).map(keyName => (
        <li key={keyName}>
          <span className={classes.ModalInsucessoDescricao}>{mensagem[keyName]}</span>
        </li>
      )));
    }
    if (typeof mensagem === 'string') return (<span className={classes.ModalInsucessoDescricao}>{mensagem}</span>);

    return <span>{MensagemErroPadrao}</span>;
  };

  return (
    <Modal
      open={visivel}
      onClose={handleClose}
    >
      <Fade in={visivel}>
        <Box sx={{ ...style, width: '300px' }}>
          <h2 className={classes.ModalInsucessoTitulo}>Atenção!</h2>
          <div style={{ padding: '15px 0px 25px 0px' }}>
            {handlePrepararMensagem()}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={`${classes.ModalInsucessoBotao} ${classes.InsucessoVoltar}`}
              variant='contained'
              onClick={handleClose}
            >Voltar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalErro;
