export const formatarValorMonetario = (valor: number, manterCasas: boolean) => {
  if (!manterCasas) {
    const valorFormatado: string = `R$ ${valor}`;

    return valorFormatado;
  }
  const valorFormatado: string = valor.toLocaleString(
    'pt-br',
    { style: 'currency', currency: 'BRL' },
  );

  return valorFormatado;
};

export const formatoBRL = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
