import { FilledInput, FilledInputProps } from '@material-ui/core';
import { AttachMoney } from '@material-ui/icons';
import React, { memo } from 'react';
import InputStyles from '../../Styles/InputStyles';
import { IconStyles } from './styles';

const ValorAvisoCustomInput: React.FC<FilledInputProps> = (props) => {
  const inputStyles = InputStyles();
  const iconStyles = IconStyles(props);

  return (
    <FilledInput
      {...props}
      classes={inputStyles}
      data-testid='aviso-saldo-input-valor-aviso'
      disableUnderline
      id='Recarga_Input_Valor_AlertaSaldo'
      startAdornment={<AttachMoney classes={iconStyles()} />}
    />
  );
};

export default memo(ValorAvisoCustomInput);
