import React, { memo } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { buttonStyles } from './styles';
import { useAvisoSaldo } from '../../../../../Context/AvisoSaldoContexto';

const ButtonAdicionarEmail: React.FC<ButtonProps> = (props) => {
  const styles = buttonStyles();

  const { desabilitarBotaoAdicionarEmail,
    flagReceberAvisoSaldo,
    handleAdicionarNovoContato } = useAvisoSaldo();

  return (
    <Button
      {...props}
      classes={styles}
      data-testid='aviso-saldo-btn-adicionar-contato'
      disabled={desabilitarBotaoAdicionarEmail || !flagReceberAvisoSaldo}
      onClick={handleAdicionarNovoContato}
      startIcon={<Add />}
      type='button'
    >
      Adicionar um e-mail
    </Button>
  );
};

export default memo(ButtonAdicionarEmail);
