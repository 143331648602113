import { Action } from '../interfaces';
import { Contato, Endereco } from './DadosContaInterfaces';

export enum Types {
  InicializarDadosConta = 'INICIALIZAR_DADOS_CONTA',
  AtribuirDadosConta = 'ATRIBUIR_DADOS_CONTA',
  AtualizarContato = 'ATUALIZAR_CONTATO',
  AtualizarEndereco = 'ATUALIZAR_ENDERECO',
  AtribuirContato = 'ATRIBUIR_CONTATO',
  AtribuirEndereco = 'ATRIBUIR_ENDERECO',
  IniciarCarregamento = 'INICIAR_CARREGAMENTO',
  FinalizarCarregamento = 'FINALIZAR_CARREGAMENTO',
  TrocarSenha = 'TROCAR_SENHA',
  ToggleFormularioSenha = 'TOGGLE_FORMULARIO_SENHA',
  AtribuirMensagemErro = 'ATRIBUIR_MENSAGEM_ERRO',
}

export interface InicializarDadosContaAction extends Action { }
export interface AtribuirDadosContaAction extends Action { contato: Contato, endereco: Endereco }
export interface AtualizarContatoAction extends Action { contato: Contato }
export interface AtualizarEnderecoAction extends Action { endereco: Endereco }
export interface AtribuirContatoAction extends Action { contato: Contato }
export interface AtribuirEnderecoAction extends Action { endereco: Endereco }
export interface IniciarCarregamentoAction extends Action { }
export interface FinalizarCarregamentoAction extends Action { }
export interface TrocarSenhaAction extends Action { senhaAtual: string, senhaNova: string }
export interface ToggleFormularioSenhaAction extends Action { estaTrocandoSenha: boolean }
export interface AtribuirMensagemErroAction extends Action { mensagemErro: string }

export default {
  inicializarDadosConta: (): InicializarDadosContaAction => ({ type: Types.InicializarDadosConta }),

  atribuirDadosConta: (contato: Contato, endereco: Endereco): AtribuirDadosContaAction => ({
    type: Types.AtribuirDadosConta,
    contato,
    endereco,
  }),

  atualizarContato: (contato: Contato): AtualizarContatoAction => ({
    type: Types.AtualizarContato,
    contato,
  }),

  atualizarEndereco: (endereco: Endereco): AtualizarEnderecoAction => ({
    type: Types.AtualizarEndereco,
    endereco,
  }),

  atribuirContato: (contato: Contato): AtribuirContatoAction => ({
    type: Types.AtribuirContato,
    contato,
  }),

  atribuirEndereco: (endereco: Endereco): AtribuirEnderecoAction => ({
    type: Types.AtribuirEndereco,
    endereco,
  }),

  iniciarCarregamento: (): IniciarCarregamentoAction => ({ type: Types.IniciarCarregamento }),

  finalizarCarregamento: (): FinalizarCarregamentoAction => ({ type: Types.FinalizarCarregamento }),

  trocarSenha: (senhaAtual: string, senhaNova: string): TrocarSenhaAction => ({
    type: Types.TrocarSenha, senhaAtual, senhaNova,
  }),

  toggleFormularioSenha: (estaTrocandoSenha: boolean): ToggleFormularioSenhaAction => ({ type: Types.ToggleFormularioSenha, estaTrocandoSenha }),

  atribuirMensagemErro: (mensagemErro: string): AtribuirMensagemErroAction => (
    { type: Types.AtribuirMensagemErro, mensagemErro }
  ),
};
