import * as React from 'react';
import './CadastroFooter.scss';

const logo = require('../../../assets/images/icones/cadeado.svg');

const CadastroFooter = () => {
  const data = new Date();

  return (
    <footer id='cadastro-footer'>
      <div className='logo'>
        <img src={logo} alt='Ambiente seguro' />
      </div>
      <span>
        Ambiente seguro  ·  ConectCar CNPJ: 16.577.631/0002-99 | © {data.getFullYear()} - Todos os direitos reservados
      </span>
    </footer>
  );
};

export default CadastroFooter;
