import { createStyles, makeStyles } from '@material-ui/core';

const bradescoIcone = require('../../../../assets/images/icones/bradesco-icone.png');
const bancoBrasilIcone = require('../../../../assets/images/icones/bancobrasil-icone.png');
const santanderIcone = require('../../../../assets/images/icones/santander-icone.png');
const caixaIcone = require('../../../../assets/images/icones/caixa-icone.png');
const pixIcone = require('../../../../assets/images/icones/pix-complemento.png');

const BoletoFlashStyles = makeStyles(() => createStyles({
  boletoFlashStyleBase: {
    width: '30px',
    marginRight: '8px',
    backgroundRepeat: 'no-repeat',
  },
  bradescoIcone: {
    backgroundImage: `url(${bradescoIcone})`,
  },
  bancoBrasilIcone: {
    backgroundImage: `url(${bancoBrasilIcone})`,
  },
  santanderIcone: {
    backgroundImage: `url(${santanderIcone})`,
    width: '32px',
  },
  caixaIcone: {
    backgroundImage: `url(${caixaIcone})`,
  },
  pixIcone: {
    backgroundImage: `url(${pixIcone})`,
    width: '32px',
    height: '32px',
  },
  outrosBancosIcone: {
    width: 'auto',
    height: '32px',
  },
  outrosBancosIconeButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    color: 'rgba(0, 0, 0, 0.87) !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  boletoFlashDiv: {
    display: 'flex',
    height: '31px',
    marginLeft: '10px',
    width: '400px',
  },
  boletoFlashDescricao: {
    marginBottom: '10px',
    '& .informativoBoletoFlash': {
      color: '#4D5761 !important',
      fontSize: '13.125px',
      fontWeight: 400,
    },
  },
}));

export default BoletoFlashStyles;
