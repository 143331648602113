import * as React from 'react';
import { TimeToLeave } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import '../Cadastro.scss';
import './CadastroConcluidoAtivacaoPage.scss';

export default () => {
  const ativarAdesivos = () => {
    window.parent.location.href = `${(window as any).config.url_portal_cliente}/AdesaoEmLote/AtivacaoManual`;
  };

  return (
    <section id='cadastro-concluido-ativacao-page'>
      <section>
        <div className='conteudo-proximos-passos'>
          <div className='proximos-passos-ativacao'>
            <h3 className='proximos-passos-descricao'>Seu cadastro foi realizado com sucesso</h3>
            <section>
              <p className='cadastro-ativacao-descricao'>Já tem os adesivos em mãos? Entre no nosso portal e cadastre seus veículos.</p>
              <Button
                className='cadastro-botao-ativacao'
                endIcon={<TimeToLeave />}
                type='button'
                onClick={ativarAdesivos}
                title='Cadastrar meus veículos'
                variant='contained'
              >
                Cadastrar meus veículos
              </Button>
            </section>
          </div>
        </div>
      </section>
    </section>
  );
};
