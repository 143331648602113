import * as React from 'react';

interface Props {
    texto: string;
    classe: string;
}

const BotaoTitulo = ({ texto, classe }: Props) => (
  <button type='button' className={classe}>
    <span style={{ padding: '4px' }}>{texto}</span>
  </button>
);

export default BotaoTitulo;
