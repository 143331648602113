import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './CadastroCabecalhoBreadcrumb.scss';

import Actions from '../CadastroActions';
import { State } from '../../interfaces';
import { CadastroState, Etapas } from '../CadastroInterfaces';

interface Props {
  descricao: string,
  etapaRepresentada: Etapas,
  icone: string,
  ehUltimo?: boolean,
}

export default ({ descricao, etapaRepresentada, icone, ehUltimo }: Props) => {
  const { etapaAtual } = useSelector<State, CadastroState>(state => state.cadastro);
  const dispatch = useDispatch();
  const history = useHistory();

  const deveMostrarDescricao = () => (etapaAtual === etapaRepresentada ? 'mostrar-descricao' : '');

  const definirClasseStatus = () => (etapaAtual >= etapaRepresentada ? 'ativo' : 'inativo');

  const definirClasseUltimoItem = () => (ehUltimo ? 'ultimo-item' : '');

  const obterUrlEtapa = (etapa: Etapas) => {
    switch (etapa) {
      case Etapas.Plano:
        return '/cadastro/plano';

      case Etapas.Representante:
        return '/cadastro/representante';

      case Etapas.Empresa:
        return '/cadastro/empresa';

      case Etapas.Senha:
        return '/cadastro/senha';

      default:
        return '/';
    }
  };

  const irParaEtapa = (etapa: Etapas) => {
    if (etapaAtual < etapa) return;

    dispatch(Actions.atribuirEtapaAtual(etapa));
    const url = obterUrlEtapa(etapa);
    history.push(url);
  };

  const montarClasseBreadcrumb = () => (`
    cadastro-cabecalho-breadcrumb
    ${deveMostrarDescricao()}
    ${definirClasseStatus()}
    ${definirClasseUltimoItem()}
   `);

  return (
    <button
      type='button'
      className={montarClasseBreadcrumb()}
      onClick={() => irParaEtapa(etapaRepresentada)}
    >
      <img src={icone} alt='etapa' />
      <span>{descricao}</span>
    </button>
  );
};
