import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const caminho = require('../../assets/images/Logo-colorful-vertical.png');

interface Props {
  pedidoId: number,
}

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logo_img: {
    height: '33px',
    width: '53px',
    margin: '0 5px',
  },
  texto_logo: {
    color: '#000000',
    fontSize: '5.5px',
    lineHeight: '1.7',
    marginTop: '4px',
  },
  texto_negrito: {
    color: '#000000',
    fontFamily: 'Helvetica-Bold',
    fontSize: '5.5px',
  },
  texto_titulo: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '8px',
    color: '#000000',
    textAlign: 'center',
  },
  numero_doc: {
    display: 'flex',
    alignSelf: 'flex-end',
    fontFamily: 'Helvetica-Bold',
    fontSize: '6px',
    color: '#000000',
    marginTop: '-10px',
  },
  linha: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1pt solid #000000',
    justifyContent: 'space-between',
  },
  linha_esquerda: {
    marginRight: '70px',
  },
  coluna: {
    display: 'flex',
    flexDirection: 'column',
  },
  pagina: {
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
  },
});

const Header = ({ pedidoId }: Props) => (
  <View>
    <View id='header' style={styles.header}>
      <View id='logo' style={styles.logo}>
        <View>
          <Image src={caminho} style={styles.logo_img} />
        </View>
        <View style={styles.texto_logo}>
          <Text>Central de Atendimento</Text>
          <Text>São Paulo e Grande SP: <Text style={styles.texto_negrito}>4020 2227</Text></Text>
          <Text>Demais localidades: <Text style={styles.texto_negrito}>0800 030 2227</Text></Text>
          <Text>Visite nosso site: <Text style={styles.texto_negrito}>www.conectcar.com</Text></Text>
        </View>
      </View>
    </View>
    <View id='titulo'>
      <Text style={styles.texto_titulo}>DEMONSTRATIVO DE RECARGA</Text>
    </View>
    <View id='numero-doc' style={styles.numero_doc}>
      <View style={styles.linha}>
        <Text />
        <Text>PAG. 1 de 1</Text>
      </View>
      <View style={styles.linha}>
        <Text style={styles.linha_esquerda}>Data Emissão:</Text>
        <Text>{new Date().toLocaleDateString()}</Text>
      </View>
      <View style={styles.linha}>
        <Text style={styles.linha_esquerda}>Código ID</Text>
        <Text>{pedidoId}</Text>
      </View>
    </View>
  </View>
);

export default Header;
