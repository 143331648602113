import axios from 'axios';
import { RespostaApi, IdApi } from '../ApiInterfaces';
import { RepresentanteLegal,
  Plano,
  DadosEmpresa } from '../../features/cadastro/CadastroInterfaces';
import { removerCaracteresNaoNumericos } from '../mascara';
import { mapearTelefone, parsearFaturamento } from '../prospecto-api/utils';
import { Pedido } from './Models/pedidoModel';

const urlbase = 'landingpage-bff/api/v1/Pedido';

interface IdApiComPedido extends IdApi<string> {
  hashBytesBoleto: string;
  pedidoPossuiBoleto: boolean;
  urlBoleto: string;
}

// export class Pedidos {
//   pedidos: Pedido[];
// }

const converterCadastroParaEnvio = (
  canalVendasHash: string,
  representanteLegal: RepresentanteLegal,
  plano: Plano,
  dadosEmpresa: DadosEmpresa,
  aceitaReceberOfertas: boolean,
  concordaTermosAdesao: boolean,
  compartilharDados: boolean,
  compartilharDadosItau: boolean,
  codigoPlano: string,
  senha: string,
) => {
  const telefone = mapearTelefone(representanteLegal.telefone);
  const celular = mapearTelefone(representanteLegal.celular);

  return {
    canalVendasId: canalVendasHash,
    cnpj: removerCaracteresNaoNumericos(dadosEmpresa.cnpj),
    razaoSocial: dadosEmpresa.razaoSocial,
    quantidadeAdesivos: plano.veiculosLeves + plano.veiculosPesados,
    codigoPlano,
    email: representanteLegal.email,
    enderecos: [
      {
        tipoEnderecoId: 1,
        cep: removerCaracteresNaoNumericos(dadosEmpresa.cep),
        logradouro: dadosEmpresa.logradouro,
        numero: dadosEmpresa.numero,
        bairro: dadosEmpresa.bairro,
        cidade: dadosEmpresa.cidade,
        uf: dadosEmpresa.estado,
        complemento: dadosEmpresa.complemento,
        codigoIbge: dadosEmpresa.codigoIbge,
      },
    ],
    contatos: [
      {
        principal: 1,
        nome: representanteLegal.nomeContatoComercial,
        dddTelefone: telefone.ddd,
        telefone: telefone.numero,
        dddCelular: celular.ddd,
        celular: celular.numero,
      },
    ],
    recargaAutomatica: null,
    naturezaJuridica: dadosEmpresa.naturezaJuridica,
    faturamentoMedioMensal: parsearFaturamento(dadosEmpresa.faturamentoMedioMensal),
    nomeRepresentante: representanteLegal.nomeRepresentanteLegal,
    cpf: removerCaracteresNaoNumericos(representanteLegal.cpf),
    dataRepresentante: representanteLegal.representanteDesde,
    dataConstituicao: dadosEmpresa.dataConstituicao,
    cargo: representanteLegal.cargo,
    expostaPublicamente: representanteLegal.pessoaPoliticamenteExposta,
    senha,
    dadosAdicionais: {
      quantidadeVeiculosLeves: plano.veiculosLeves,
      quantidadeVeiculosPesados: plano.veiculosPesados,
      possuiAdesivos: plano.possuiAdesivos,
    },
    receberOfertas: aceitaReceberOfertas,
    compartilhaDados: compartilharDados,
    termoAceite: concordaTermosAdesao,
    compartilhaDadosItau: compartilharDadosItau,
  };
};

export default {
  enviar: async (
    canalVendasHash: string,
    representanteLegal: RepresentanteLegal,
    plano: Plano,
    dadosEmpresa: DadosEmpresa,
    aceitaReceberOfertas: boolean,
    concordaTermosAdesao: boolean,
    compartilharDados: boolean,
    compartilharDadosItau: boolean,
    senha: string,
    codigoPlano: string,
  ): Promise<RespostaApi<IdApiComPedido>> => {
    const apiUrl = `${(window as any).config.url_api_management}${urlbase}/CadastroPedidoAdesivoPj`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const pedido = converterCadastroParaEnvio(
      canalVendasHash,
      representanteLegal,
      plano,
      dadosEmpresa,
      aceitaReceberOfertas,
      concordaTermosAdesao,
      compartilharDados,
      compartilharDadosItau,
      codigoPlano,
      senha,
    );

    return axios.post(apiUrl, pedido, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
    });
  },

  obterPedidosPorDocumentoOfertaStatus: async (
    cnpj: string,
    codigoCampanha: string,
    status: number,
    token: string,
  ): Promise<Pedido[]> => {
    try {
      const planoApiUrl = `${(window as any).config.url_api_management}`;
      const url = new URL(
        `${planoApiUrl}clientepedido/api/v1/Pedidos/buscaPorDocumentoOfertaNaoCancelado`,
      );
      const { searchParams } = url;
      searchParams.set('documento', cnpj);
      searchParams.set('codigoCampanha', codigoCampanha);
      searchParams.set('status', status.toString());
      url.search = searchParams.toString();
      const newUrl = url.toString();

      const subscriptionId = (window as any).config.subscription_api_management;

      const resposta = await axios.get<Pedido[]>(newUrl, {
        headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}`, Authorization: `Bearer ${token}` },
      });

      return resposta.data;
    } catch (error) {

    }
    return null;
  },

  obterUltimoPedidoPorDocumento: async (token: string): Promise<Pedido> => {
    try {
      const planoApiUrl = `${(window as any).config.url_api_management}`;
      const url = new URL(
        `${planoApiUrl}portalpj/v1/Pedido/buscarUltimoPedidoPorDocumento`,
      );
      const subscriptionId = (window as any).config.subscription_api_management;

      const resposta = await axios.get(url.toString(), {
        headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}`, Authorization: `Bearer ${token}` },
      });

      return resposta.data.dados;
    } catch (error) {

    }
    return null;
  },
};
