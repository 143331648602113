import * as React from 'react';
import './Paginacao.scss';

const avancarPaginaFinal = require('./images/avancar-final.png');
const avancarPagina = require('./images/avancar-item.png');
const voltarPagina = require('./images/voltar-item.png');
const voltarPrimeiraPagina = require('./images/voltar-inicio.png');

interface Props {
  totalPaginas: number,
  paginaSelecionada: number,
  registrosPorPagina: number,
  onMudarPaginacaoChange: any
}

const Paginacao = ({ totalPaginas,
  paginaSelecionada,
  registrosPorPagina,
  onMudarPaginacaoChange } : Props) => {
  const ObterPrimeiraPagina = (): number => {
    if (paginaSelecionada >= 6 && totalPaginas > 10) {
      return paginaSelecionada - 4;
    }

    return 1;
  };

  const GerarPaginas = () => {
    const lista = [];
    const primeiraPagina = ObterPrimeiraPagina();

    let pagina = lista.length + primeiraPagina;

    while (lista.length < 10 && pagina < totalPaginas) {
      pagina = lista.length + primeiraPagina;

      const paginaAtual = pagina;

      lista.push(
        <li
          key={paginaAtual}
          className={`item-pagina ${paginaSelecionada === (pagina) ? 'item-selecionado' : ''}`}
        >
          <button
            type='button'
            title={`Acessar página ${pagina}`}
            onClick={() => onMudarPaginacaoChange(paginaAtual, registrosPorPagina)}
            onKeyDown={() => onMudarPaginacaoChange(paginaAtual, registrosPorPagina)}
          >
            {pagina}
          </button>
        </li>,
      );
    }

    return lista;
  };

  return (
    <section id='paginacao'>
      <div className='filtro'>
        <label htmlFor='exibir-por-pagina'>
          Exibir:
          <select
            id='exibir-por-pagina'
            data-testid='exibir-por-pagina'
            title='Quantidade de Registros Exibidos'
            onChange={(event:
            React.ChangeEvent<HTMLSelectElement>) => {
              onMudarPaginacaoChange(1, parseInt(event.target.value, 10));
            }}
            value={registrosPorPagina}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </label>
        <label className='itens-por-pagina'>itens por página</label>
      </div>
      <ul className='botoes-paginacao'>

        { paginaSelecionada > 1 ? (
          <React.Fragment>
            <li className='navegador-primeira-pagina'>
              <button
                title='ir para primeira página'
                type='button'
                onClick={() => onMudarPaginacaoChange(1, registrosPorPagina)}
                onKeyDown={() => onMudarPaginacaoChange(1, registrosPorPagina)}
              >
                <img alt='ir para primeira página' src={voltarPrimeiraPagina} />
              </button>
            </li>
            <li className='navegador-pagina-anterior'>
              <button
                title='voltar página'
                type='button'
                onClick={() => onMudarPaginacaoChange(paginaSelecionada - 1, registrosPorPagina)}
                onKeyDown={() => onMudarPaginacaoChange(paginaSelecionada - 1, registrosPorPagina)}
              >
                <img alt='voltar página' src={voltarPagina} />
              </button>
            </li>
          </React.Fragment>
        ) : null }

        { GerarPaginas() }

        { paginaSelecionada !== totalPaginas && totalPaginas !== 0 ? (
          <React.Fragment>
            <li className='navegador-pagina-posterior'>
              <button
                title='avançar página'
                type='button'
                onClick={() => onMudarPaginacaoChange(paginaSelecionada + 1, registrosPorPagina)}
                onKeyDown={() => onMudarPaginacaoChange(paginaSelecionada + 1, registrosPorPagina)}
              >
                <img alt='avançar página' src={avancarPagina} />
              </button>
            </li>
            <li className='navegador-ultima-pagina'>
              <button
                title='ir para última página'
                type='button'
                onClick={() => onMudarPaginacaoChange(totalPaginas, registrosPorPagina)}
                onKeyDown={() => onMudarPaginacaoChange(totalPaginas, registrosPorPagina)}
              >
                <img alt='ir para última página' src={avancarPaginaFinal} />
              </button>
            </li>
          </React.Fragment>
        ) : null }
      </ul>
    </section>
  );
};

export default Paginacao;
