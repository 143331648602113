import { ParametrosPaginacaoMeusPedidos, PedidosCompras } from '../../../../features/solicitacao-adesivo/meus-pedidos/MeusPedidosInterfaces';
import { RespostaApi } from '../../../../infrastructure/ApiInterfaces';
import api from '../../../api';

const obterParametrosFormatadosMeusPedidos = (parametros: ParametrosPaginacaoMeusPedidos) => {
  let parametrosFormatados = `NumeroPagina=${parametros.paginaSelecionada}`;
  parametrosFormatados += `&TamanhoPagina=${parametros.registrosPorPagina}`;
  parametrosFormatados += `&RetornarQtdRegistros=${true}`;
  parametrosFormatados += `&OrdenarDecrescente=${true}`;
  return parametrosFormatados;
};

export const obterMeusPedidos = (paramentros: ParametrosPaginacaoMeusPedidos)
    : Promise<RespostaApi<PedidosCompras>> => {
  const parametrosFormatados = obterParametrosFormatadosMeusPedidos(paramentros);
  return api.get(
    `Pedido/buscarHistoricoPedidosPaginados?${parametrosFormatados}`,
  );
};
