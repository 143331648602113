import { CustomerPlanData } from '../../infrastructure/prospecto-api/cliente/Models/personModel';
import { Action } from '../interfaces';
import { DadosUltimaRecarga } from '../recarga/RecargaInterfaces';

export enum Types {
  InicializarHome = 'INICIALIZAR_HOME',
  AtribuirDocumento = 'ATRIBUIR_DOCUMENTO',
  ObterSaldo = 'OBTER_SALDO',
  ObterAdesoes = 'OBTER_ADESOES',
  CarregarDadosUltimaRecarga = 'CARREGAR_DADOS_ULTIMA_RECARGA',
  CarregarDadosPlanoCliente = 'CARREGAR_DADOS_PLANO_CLIENTE'
}

export interface InicializarHomeAction extends Action { }
export interface AtribuirDocumentoAction extends Action {
  documento: string,
  clienteId: string,
  token: string,
}
export interface ObterSaldoAction extends Action { saldo: number }
export interface ObterAdesoesAction extends Action { possuiAdesao: boolean }
export interface CarregarDadosUltimaRecargaAction extends Action { dados: DadosUltimaRecarga }
export interface CarregarDadosPlanoClienteAction extends Action { dados: CustomerPlanData }

export default {
  inicializarHome: (): InicializarHomeAction => ({ type: Types.InicializarHome }),

  atribuirDocumento: (documento: string, clienteId: string, token: string): AtribuirDocumentoAction => ({ type: Types.AtribuirDocumento, documento, clienteId, token }),

  obterSaldo: (saldo: number): ObterSaldoAction => ({ type: Types.ObterSaldo, saldo }),

  obterAdesoes: (possuiAdesao: boolean): ObterAdesoesAction => ({ type: Types.ObterAdesoes, possuiAdesao }),

  carregarDadosUltimaRecarga: (dados: DadosUltimaRecarga): CarregarDadosUltimaRecargaAction => ({ type: Types.CarregarDadosUltimaRecarga, dados }),

  carregarDadosPlanoCliente: (dados: CustomerPlanData): CarregarDadosPlanoClienteAction => ({ type: Types.CarregarDadosPlanoCliente, dados }),
};
