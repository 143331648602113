import React, { memo, useEffect, useState } from 'react';
import { FilledInput, Tooltip } from '@material-ui/core';
import { EmailOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useAvisoSaldo } from '../../../../../Context/AvisoSaldoContexto';
import InputStyles from '../../../Styles/InputStyles';
import { RecargaState } from '../../../../../RecargaInterfaces';
import { State } from '../../../../../../interfaces';
import { IconStyles, TooltipStyles } from './styles';

const ContatoPrincipalItem: React.FC = () => {
  const inputStyles = InputStyles();
  const tooltipStyles = TooltipStyles();

  const { contatosAdicionais, flagReceberAvisoSaldo, quantidadeLimiteContatosAdicionais } = useAvisoSaldo();

  const iconStyles = IconStyles(flagReceberAvisoSaldo);

  const [abrirTooltip, setAbrirTooltip] = useState<boolean>(false);

  const {
    dadosIndex,
  } = useSelector<State, RecargaState>(state => ({
    dadosIndex: state.recarga.dadosIndex,
  }));

  const email = dadosIndex?.dados?.valores?.alertaSaldo?.email || '';
  const quantidadeContatosAdicionaisSalvos = contatosAdicionais.filter(c => c.contactClientId).length;

  const handleObterQuantidadeContatosAdicionaisDisponiveis = () => {
    if (quantidadeLimiteContatosAdicionais - quantidadeContatosAdicionaisSalvos < 0) return 0;
    return quantidadeLimiteContatosAdicionais - quantidadeContatosAdicionaisSalvos;
  };

  const textoTooltip = `Seu alerta de saldo será enviado para seu e-mail principal cadastrado. 
    Adicione até ${handleObterQuantidadeContatosAdicionaisDisponiveis()} novo(s) e-mail(s) caso deseje receber em outros contatos.`;

  useEffect(() => {
    if (abrirTooltip) setTimeout(() => setAbrirTooltip(false), 5000);
  }, [abrirTooltip]);

  return (
    <Tooltip data-testid='aviso-saldo-contato-principal-tooltip' open={abrirTooltip} title={textoTooltip} classes={tooltipStyles} disableHoverListener placement='top' arrow>
      <div style={{ width: '100%' }}>
        <FilledInput
          classes={inputStyles}
          disabled={!flagReceberAvisoSaldo}
          disableUnderline
          data-testid='aviso-saldo-contato-principal-input'
          readOnly
          onClick={() => setAbrirTooltip(true)}
          startAdornment={<EmailOutlined classes={iconStyles()} />}
          value={email}
        />
      </div>
    </Tooltip>
  );
};

export default memo(ContatoPrincipalItem);
