import * as React from 'react';
import { IconButton, Box, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './RecargaRapida.scss';
import { useDispatch, useSelector } from 'react-redux';
import { EnvioRecargaRequest, FormaPagamentoOnline, RecargaState } from '../../RecargaInterfaces';
import { State } from '../../../interfaces';
import Actions from '../../RecargaActions';

const rotaIcone = require('../../../../assets/images/icones/tap.png');

const locale = 'pt-BR';
const moneyOptions = { style: 'currency', currency: 'BRL' };

const RecargaRapida = () => {
  const {
    dadosIndex, dadosUltimaRecarga, documento, servicos,
  } = useSelector<State, RecargaState>(state => ({
    dadosIndex: state.recarga.dadosIndex, dadosUltimaRecarga: state.recarga.dadosUltimaRecarga, documento: state.recarga.documento, servicos: state.recarga.servicos,
  }));

  const dispatch = useDispatch();

  const dadosUltima = dadosUltimaRecarga?.valores?.dados;

  const dadosRecarga = dadosIndex?.dados?.valores;

  const obterRecargaId = () => servicos?.find(x => x.valorCredito === dadosUltima?.valor)?.id;

  const eBoleto = () => {
    const Boletos = [FormaPagamentoOnline.ItauBoleto, FormaPagamentoOnline.PagBrasil];
    if (Boletos.includes(formaPagamentoEmEnum(dadosUltima?.descricao))) {
      return <span id='aviso-boleto'>Apenas dias úteis</span>;
    }
    return null;
  };

  const [show, setShow] = React.useState(true);

  const formaPagamentoEmEnum = (formaPagamento) => {
    switch (formaPagamento) {
      case 'Itaú Boleto':
        return FormaPagamentoOnline.ItauBoleto;
      case 'Itaú TEF':
        return FormaPagamentoOnline.ItauTEF;
      case 'Bradesco TEF':
        return FormaPagamentoOnline.BradescoTEF;
      case 'Boleto Rápido':
        return FormaPagamentoOnline.PagBrasil;
      case 'Pix':
        return FormaPagamentoOnline.Pix;
      default:
        return null;
    }
  };

  const realizarRecarga = () => {
    const formaPagamento = formaPagamentoEmEnum(dadosUltima.descricao);
    if (formaPagamento === null) return;
    const novaRecarga: EnvioRecargaRequest = {
      AdesaoId: dadosRecarga?.adesaoSelecionadaId,
      FormaPagamentoOnline: formaPagamento,
      Documento: documento,
      ServicoRecargaId: formaPagamento === FormaPagamentoOnline.Pix ? 0 : obterRecargaId(),
      ValorDeCredito: dadosUltima?.valor,
    };

    dispatch(Actions.carregarParametrosRecargaRequest(novaRecarga));
    dispatch(Actions.cadastrarRecarga());
  };

  return (
    show && (
    <Box id='Box-Recarga-Rapida' alignItems='center'>
      <p style={{ display: 'flex' }}>
        <img src={rotaIcone} alt='info' className='span-espacador' />
        <span className='span-espacador'>
          <b>Recarga Rápida</b>
        </span>
      </p>
      <Divider flexItem className='divider-recarga' orientation='vertical' style={{ margin: '1em' }} />
      <span className='span-espacador'>
        Refazer a última Recarga de
        {' '}
        <br className='quebra-mobile' />
        <span className='highlight-laranja'>
          {dadosUltima?.valor.toLocaleString(locale, moneyOptions)}
          {' '}
          via
          {' '}
          {dadosUltima?.descricao}
        </span>
      </span>
      {eBoleto()}
      <span className='span-espacador'>
        <button
          id='Recarga_Bt_RecargaRapida'
          type='button'
          className='botao-preenchido'
          onClick={realizarRecarga}
        >
          Recarregar agora
        </button>
      </span>
      <Divider flexItem className='divider-recarga' orientation='vertical' style={{ margin: '1em' }} />
      <IconButton onClick={() => setShow(false)}><CloseIcon /></IconButton>
    </Box>
    )
  );
};

export default RecargaRapida;
