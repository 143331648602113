import React, { memo } from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { PedidoActions, usePedido } from '../../../features/solicitacao-adesivo/solicitacaoAdesivo/context/SolicitacaoAdesivoContext';
import { useControleModalErro } from '../../../hooks/useControleModalErro/useControleModalErro';
import ResumoPedido from '../ResumoPedido/ResumoPedido';
import BoxEndereco from '../BoxEndereco/BoxEndereco';
import AvisoCliente from '../AvisoCliente/AvisoCliente';
import Endereco from '../Endereco/Endereco';
import ModalErro from '../../ModalErro';

import './InformacoesPedido.scss';

const InformacoesPedido = () => {
  const motivosPedido = ['Nova adesão', 'Substituição'];
  const metodosPagamento = ['Boleto bancário'];

  const { pedidoState, pedidoDispatch } = usePedido();
  const { fecharModal } = useControleModalErro();

  const handleMotivoPedidoChange = e => pedidoDispatch({ type: PedidoActions.setMotivoPedido, payload: e.target.value });
  const handleMetodoPagamentoChange = e => pedidoDispatch({ type: PedidoActions.setMetodoPagamento, payload: e.target.value });

  return (
    <section className='informacoes-novo-pedido-container-principal'>
      <ModalErro
        acaoAoFecharModal={fecharModal}
      />
      <Grid container spacing={0}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Grid container className='informacoes-novo-pedido-container-endereco' spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                className='informacoes-novo-pedido-titulo'
                variant='h5'
              >
                Selecione abaixo as primeiras informações do pedido
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography>
                Motivo do pedido
              </Typography>
              <Select
                className='informacoes-novo-pedido-select'
                IconComponent={props => (<KeyboardArrowDown fontSize='large' {...props} />)}
                id='select-motivos-novo-pedido'
                onChange={handleMotivoPedidoChange}
                value={pedidoState.motivoPedido}
                variant='outlined'
              >
                {motivosPedido.map((motivoPedido: string) => <MenuItem key={motivoPedido} value={motivoPedido}>{motivoPedido}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography>
                Método de pagamento
              </Typography>
              <Select
                className='informacoes-novo-pedido-select'
                disabled
                IconComponent={props => (<KeyboardArrowDown fontSize='large' {...props} />)}
                id='select-metodos-pagamento-novo-pedido'
                onChange={handleMetodoPagamentoChange}
                value={pedidoState.metodoPagamento}
                variant='outlined'
              >
                {metodosPagamento.map((metodoPagamento: string) => <MenuItem key={metodoPagamento} value={metodoPagamento}>{metodoPagamento}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div id='informacoes-novo-pedido-dados-endereco'>
                <BoxEndereco />
                <Endereco />
              </div>
              <div id='informacoes-novo-pedido-aviso-cliente'>
                <AvisoCliente />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Grid container className='informacoes-novo-pedido-container-resumo' justifyContent='center' spacing={0}>
            <ResumoPedido />
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
export default memo(InformacoesPedido);
