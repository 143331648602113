import {
  call, takeEvery, put, select, all, take,
} from 'redux-saga/effects';
import { State } from '../../interfaces';
import MeusPedidosApi from '../../../infrastructure/solicitacao-adesivo-api/meus-pedidos/meus-pedidos-api';
import GeneralActions from '../../actions';
import Actions, { Types } from './MeusPedidosActions';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../../autenticacao/AutenticacaoActions';
import { ParametrosPaginacaoMeusPedidos } from './MeusPedidosInterfaces';

const obterClienteId = (state: State): number => state.autenticacao.clienteId;
const obterBearerToken = (state: State): string => state.autenticacao.bearerToken;
const obterDocumento = (state: State): number => Number(state.autenticacao.documento);

const obterParametros = (state: State): ParametrosPaginacaoMeusPedidos => ({
  paginaSelecionada: state.historicoRecarga.paginaSelecionada,
  registrosPorPagina: state.historicoRecarga.registrosPorPagina,
});


function* atribuirDocumento() {
  yield put(GeneralActions.iniciarRequisicao());
  const documento = yield select(obterDocumento);
  const clienteId = yield select(obterClienteId);
  const token = yield select(obterBearerToken);

  yield put(Actions.atribuirDocumento(documento, clienteId, token));
  yield put(GeneralActions.terminarRequisicao());
}

function* buscarMeusPedidosPaginado() {
  yield put(GeneralActions.iniciarRequisicao());
  try {
    const parametros = yield select(obterParametros);
    const token = yield select(obterBearerToken);
    const { data } = yield call(MeusPedidosApi.obterMeusPedidos, parametros, token);

    yield put(Actions.buscarMeusPedidosSucesso(data));
    yield put(Actions.atualizarTotalPaginas(data?.dados?.quantidadeRegistros));
  } catch (e) {
    yield put(Actions.buscarMeusPedidosError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarMeusPedidos() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield all([atribuirDocumento(), buscarMeusPedidosPaginado()]);
}

export default function* () {
  yield takeEvery(Types.InicializarMeusPedidos, inicializarMeusPedidos);
  yield takeEvery(Types.BuscarMeusPedidosRequisicao, buscarMeusPedidosPaginado);
}
