// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icones/alerta.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "*{box-sizing:border-box}.campo{margin-left:2.5em;width:calc(1 * 100% - 2.5em);display:flex;flex-direction:column;height:5.6em;margin-top:1.5em;position:relative}.campo.meia-coluna{margin-left:2.5em;width:calc(0.5 * 100% - 2.5em)}.campo .titulo-campo{-moz-transition:.2s ease all;-webkit-transition:.2s ease all;color:#909090;font-size:1.5em;pointer-events:none;position:absolute;top:1.3em;transition:.2s ease all}.campo input{border:none;border-bottom:1px solid #e6e6e6;font-size:1.5em;padding:.5em 0;margin-top:.8em;outline:none}.campo input:focus~.titulo-campo,.campo .titulo-campo.acima{color:#333;top:.2em;font-size:.8em}.campo input:disabled{background-color:inherit;border-bottom:1px solid #fff;color:#333}.campo .icone-alerta{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right;background-position-y:.8em;color:#d1370d}.campo .mensagem-validacao{color:#d1370d;font-size:.8em;padding-top:.1em;text-align:right}.campo .barra-inferior{display:block;position:relative}.campo .barra-inferior:before,.campo .barra-inferior:after{-moz-transition:.2s ease all;-webkit-transition:.2s ease all;background:#ff6339;bottom:1px;content:\"\";height:1px;position:absolute;transition:.2s ease all;width:0}.campo .barra-inferior:before{left:50%}.campo .barra-inferior:after{right:50%}.campo input:focus~.barra-inferior:before,.campo input:focus~.barra-inferior:after{width:50%}", ""]);
// Exports
module.exports = exports;
