export interface RecargaState {
  adesaoId?: number,
  servicos?: any,
  documento?: string,
  token?: string,
  saldo?: number,

  dadosIndex?: DadosIndex,
  dadosRecargaPix?: DadosRecargaPix,
  dadosRecargaBoletoItau?: DadosRecargaBoletoItau,
  dadosRecargaBoletoFlash?: DadosRecargaBoletoFlash,
  dadosRecargaTef?: DadosRecargaTef,

  dadosModalErro?: DadosModalErro,
  dadosAviso?: DadosAviso,
  dadosUltimaRecarga?: ResultadoApiRecargaUltimaRecarga,

  dadosEnvioNovaRecarga?: DadosNovaRecarga,
  dadosEnvioAvisoSaldoMinimo?: DadosEnvioSaldoMinimo,

  boxPagamento?: MiddlewareAccordion;
}

export interface DadosAviso {
  texto: string,
  level: string,
  visivel: boolean
}

export interface DadosModalErro {
  action: any;
  visivel: boolean;
}

export interface MiddlewareAccordion {
  pix: boolean;
  tef: boolean;
  flash: boolean;
  itau: boolean;
}

export interface DadosRecargaBase<T> {
  dados?: T,
}

export interface DadosNovaRecarga extends DadosRecargaBase<EnvioRecargaRequest> { }

export interface DadosEnvioSaldoMinimo extends DadosRecargaBase<CadastroSaldoMinimo> { }

export interface DadosRecargaPix extends DadosRecargaBase<ResultadoApiPix> { }

export interface DadosRecargaBoletoFlash extends DadosRecargaBase<ResultadoApiRecarga> { }

export interface DadosRecargaBoletoItau extends DadosRecargaBase<ResultadoApiRecarga> { }

export interface DadosRecargaTef extends DadosRecargaBase<ResultadoApiRecarga> { }

export interface DadosIndex extends DadosRecargaBase<ResultadoApiRecargaIndex> { }

export interface ResultadoApiPix {
  qrCode: string;
  textoDoQrCode: string;
}

export interface ResultadoApiRecarga {
  valores: Valores;
  sucesso: boolean;
  mensagemErro: string;
}

export interface ResultadoApiRecargaIndex {
  valores: ValoresIndex;
  sucesso: boolean;
  mensagemErro: string;
}

export interface ResultadoApiRecargaPlanoCliente {
  valores: ValoresPlanoCliente;
  sucesso: boolean;
  mensagemErro: string;
}

export interface ValoresPlanoCliente {
  sucesso: boolean;
  notificacoes: string[];
  dados: DadosPlanoCliente;
}

export interface DadosPlanoCliente {
  id: number;
  nome: string;
  sigla: string;
  descricao: string;
}

export interface ResultadoApiRecargaUltimaRecarga {
  valores: ValoresUltimaRecarga;
  sucesso: boolean;
  mensagemErro: string;
}

export interface ValoresUltimaRecarga {
  sucesso: boolean;
  notificacoes: string[];
  dados: DadosUltimaRecarga;
}

export interface DadosUltimaRecarga {
  dataRecarga: Date;
  valor: number;
  descricao: string;
}

export interface ValoresIndex {
  recargaOnline: RecargaOnline;
  alertaSaldo: AlertaSaldo;
  formaPagamento: FormaPagamentoOnline;
  adesaoSelecionadaId: number;
  servicos: Servico[];
  podeVisualizarBoletoRapido: boolean;
  avisaSaldoMinimo: boolean;
}

export interface AlertaSaldo {
  email: string;
  celular: string;
  precoSms: number;
}

export interface Servico {
  id: number;
  dataDeCadastro?: string,
  descricaoMovimento?: string,
  descricaoServico?: string,
  nome?: string,
  nomeExibicao?: string,
  valorCredito: number,
  valorMovimento: number
}

export interface RecargaOnline {
  id: number;
  plano: Plano;
  saldo: Saldo;
  configuracaoPlano: ConfiguracaoPlano;
  formasRecebimento: FormaRecebimento[];
}

export interface Saldo {
  id: number;
  saldoCompartilhado: boolean;
  valor: number;
}

export interface FormaRecebimento {
  id?: number;
  nome: string;
}

export interface Plano {
  id: number;
  descricao: string;
  prePago: boolean;
  permiteConfigurarRecorrenciaRegarga: boolean;
  cobraMensalidade: boolean;
}

export interface ConfiguracaoPlano {
  id: number;
  limiteCredito: number;
  planoCobraMensalidade: boolean;
  diaVencimentoMensalidade?: number;
  formaPagamento: FormaPagamento;
  recorrencia: Recorrencia;
  planoPermiteConfigurarAvisoSaldoMinimo: boolean;
  avisoSaldo: AvisoSaldo;
}

export interface AvisoSaldo {
  formaRecebimento: FormaRecebimento;
  valor?: number;
}

export interface FormaPagamento {
  id: number;
  descricao: string;
}

export interface Recorrencia {
  valorMinimo: ValorMinimo;
  recarga: Recarga;
}

export interface ValorMinimo {
  id: number;
  valor: number;
}

export interface Recarga {
  id: number;
  valor: number;
}

export interface Valores {
  sucesso: boolean;
  notificacoes: string[];
  dadosRecarga: DadosRecarga;
}

export interface DadosRecarga {
  dadosCriptografados: string;
  url: string;
}

export enum FormaPagamentoOnline {
  ItauBoleto = 0,
  ItauTEF = 1,
  BradescoTEF = 2,
  CartaoCredito = 3,
  PagBrasil = 4,
  Pix = 5
}

export enum FormaRecebimentoEnum {
  Email = 2
}

export interface EnvioRecargaRequest {
  AdesaoId: number,
  Documento: string,
  FormaPagamentoOnline?: number,
  ServicoRecargaId: number,
  ValorDeCredito: number
}

export interface CadastroSaldoMinimo {
  valorAviso: number,
  saldoCompartilhado: boolean,
  avisoSaldoMinimo: boolean,
  configuracaoPlanoId: number,
  formaRecebimentoId: number
}
