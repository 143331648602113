import * as React from 'react';
import './Contador.scss';
import Campo, { PropsBase } from '../CampoBase';
import { removerCaracteresNaoNumericos } from '../../../infrastructure/mascara';

interface Props extends PropsBase {
  campoUnicoLinha?: boolean;
}

export default ({ name,
  titulo,
  value,
  onChange,
  onBlur,
  campoUnicoLinha }: Props) => {
  const handleChange = (changedValue: number) => {
    if (changedValue < 0) return;
    let valueAlterado = removerCaracteresNaoNumericos(changedValue.toString());
    if (valueAlterado.length >= 14) valueAlterado = valueAlterado.substring(0, 13);
    onChange(+valueAlterado);
  };

  return (
    <Campo
      name={name}
      titulo={titulo}
      value={value}
      type='text'
      classeExtra='campo-contador'
      onChange={e => handleChange(e.target.value)}
      disabled={false}
      onBlur={onBlur}
    >
      <div
        data-testid='contador-subtrair'
        className={campoUnicoLinha ? 'icone-menos unico' : 'icone-menos'}
        role='button'
        tabIndex={0}
        title='subtrair'
        onClick={() => handleChange(+value - 1)}
        onKeyPress={() => handleChange(+value - 1)}
      />
      <div
        data-testid='contador-somar'
        className={campoUnicoLinha ? 'icone-mais unico' : 'icone-mais'}
        role='button'
        tabIndex={0}
        title='somar'
        onClick={() => handleChange(+value + 1)}
        onKeyPress={() => handleChange(+value + 1)}
      />
    </Campo>
  );
};
