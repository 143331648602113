import { Action } from '../interfaces';
import {
  Types,
  AtribuirDocumentoAction,
  ObterSaldoAction,
  CarregarDadosUltimaRecargaAction,
  CarregarDadosPlanoClienteAction,
  ObterAdesoesAction,
} from './HomeActions';
import { HomeState } from './HomeInterfaces';

const INITIAL_STATE = {
  documento: '',
  clienteId: '',
  token: '',
  saldo: 0,
  dadosUltimaRecarga: undefined,
  dadosPlanoCliente: {},

} as HomeState;

const atribuirDocumento = (state: HomeState, action: AtribuirDocumentoAction) => (
  { ...state, documento: action.documento, clienteId: action.clienteId, token: action.token });

const obterSaldo = (state: HomeState, action: ObterSaldoAction) => (
  { ...state, saldo: action.saldo });

const obterAdesoes = (state: HomeState, action: ObterAdesoesAction) => (
  { ...state, possuiAdesao: action.possuiAdesao });

const carregarDadosUltimaRecarga = (state: HomeState, action: CarregarDadosUltimaRecargaAction) => (
  { ...state, dadosUltimaRecarga: action.dados }
);

const carregarDadosPlanoCliente = (state: HomeState, action: CarregarDadosPlanoClienteAction) => (
  { ...state, dadosPlanoCliente: action.dados }
);

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.AtribuirDocumento:
      return atribuirDocumento(state, action as AtribuirDocumentoAction);
    case Types.ObterSaldo:
      return obterSaldo(state, action as ObterSaldoAction);
    case Types.CarregarDadosUltimaRecarga:
      return carregarDadosUltimaRecarga(state, action as CarregarDadosUltimaRecargaAction);
    case Types.CarregarDadosPlanoCliente:
      return carregarDadosPlanoCliente(state, action as CarregarDadosPlanoClienteAction);
    case Types.ObterAdesoes:
      return obterAdesoes(state, action as ObterAdesoesAction);
    default:
      return state;
  }
};
