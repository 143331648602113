import * as React from 'react';
import { useSelector } from 'react-redux';
import { State, Item } from '../../interfaces';
import { formatarDinheiro } from '../../../infrastructure/formatador-string';

import {
  Plano, Etapas, RepresentanteLegal, DadosEmpresa, EtapasRotas,
} from '../CadastroInterfaces';

import Bloco from './CadastroResumoBloco';

import './CadastroResumo.scss';

const iconeCaminhao = require('../../../assets/images/icones/caminhao.svg');
const iconePessoa = require('../../../assets/images/icones/pessoa.svg');
const iconeEmpresa = require('../../../assets/images/icones/empresa.svg');
const iconeSenha = require('../../../assets/images/icones/senha.svg');


export default () => {
  const { veiculosLeves, veiculosPesados, possuiAdesivos } = useSelector<State, Plano>(state => state.cadastro.plano);

  const precoVeiculoLeve = useSelector<State, number>(state => state.cadastro.precoVeiculoLeve);
  const precoVeiculoPesado = useSelector<State, number>(state => state.cadastro.precoVeiculoPesado);
  const precoAdesao = useSelector<State, number>(state => state.cadastro.precoAdesao);
  const mesesIsencaoMensalidade = useSelector<State, number>(state => state.cadastro.mesesIsencaoMensalidade);

  const {
    nomeContatoComercial,
    email,
    telefone,
    celular,
    nomeRepresentanteLegal,
    cpf,
    representanteDesde,
    cargo,
  } = useSelector<State, RepresentanteLegal>(state => state.cadastro.representanteLegal);

  const {
    cnpj,
    razaoSocial,
    faturamentoMedioMensal,
    dataConstituicao,
    naturezaJuridica,
    cep,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    complemento,
  } = useSelector<State, DadosEmpresa>(state => state.cadastro.dadosEmpresa);

  const mapearItensPlano = (): Item[] => [

    { id: 1, nome: 'Cadastro sem pedido de adesivos', valor: '', class: 'pedido--sem-adesivo' },
    { id: 2, nome: 'Pedido:', valor: '', class: 'pedido--com-adesivo' },
    { id: 3, nome: 'Adesivos', valor: `${(veiculosLeves + veiculosPesados).toString()} x ${formatarDinheiro(precoAdesao).toString()}`, class: 'pedido--com-adesivo' },
    { id: 4, nome: 'Total pedido', valor: `${formatarDinheiro(precoAdesao * (veiculosLeves + veiculosPesados)).toString()}`, class: 'total adesao pedido--com-adesivo' },
    { id: 5, nome: 'Simulação mensalidades:', valor: '', class: 'margin-top pedido--com-adesivo' },
    { id: 6, nome: 'Veículos leves', valor: `${veiculosLeves.toString()} x ${formatarDinheiro(precoVeiculoLeve).toString()}`, class: 'pedido--com-adesivo' },
    { id: 7, nome: 'Veículos pesados', valor: `${veiculosPesados.toString()} x  ${formatarDinheiro(precoVeiculoPesado).toString()}`, class: 'pedido--com-adesivo' },
    { id: 8, nome: 'Total mensalidades', valor: `${formatarDinheiro(veiculosPesados * precoVeiculoPesado + veiculosLeves * precoVeiculoLeve).toString()}/mês`, class: `total ${mesesIsencaoMensalidade && mesesIsencaoMensalidade !== 0 && 'total---promocao'} pedido--com-adesivo` },
    { id: 9, nome: '', valor: formatarValorTotalMensalidades(), class: 'descricao-promocao pedido--com-adesivo' },
  ].filter(item => item.valor !== '0');

  const mapearItensRepresentante = (): Item[] => [
    { id: 1, nome: 'Contato comercial', valor: nomeContatoComercial, class: '' },
    { id: 2, nome: 'E-mail', valor: email, class: '' },
    { id: 3, nome: 'Telefone', valor: telefone, class: '' },
    { id: 4, nome: 'Celular', valor: celular, class: '' },
    { id: 5, nome: 'Representante legal', valor: nomeRepresentanteLegal, class: '' },
    { id: 6, nome: 'CPF', valor: cpf, class: '' },
    { id: 7, nome: 'Representante desde', valor: representanteDesde !== null ? representanteDesde.toLocaleDateString('pt-BR', { timeZone: 'UTC' }) : '', class: '' },
    { id: 8, nome: 'Cargo', valor: cargo, class: '' },
  ].filter(item => item.valor);

  const formatarValorTotalMensalidades = () => {
    if (mesesIsencaoMensalidade <= 0) return '0';

    const cardinalidadeIsencao = `${mesesIsencaoMensalidade > 1 ? 'meses' : 'mês'}`;

    return `${formatarDinheiro(veiculosPesados * precoVeiculoPesado + veiculosLeves * precoVeiculoLeve).toString()} após ${mesesIsencaoMensalidade} ${cardinalidadeIsencao}`;
  };

  const formatarEndereco = () => (logradouro && numero && bairro && cidade && estado && cep
    ? `${logradouro}, ${numero}${complemento ? ` - ${complemento}` : ''} - ${bairro} - ${cidade}, ${estado} - ${cep}` : '');

  const mapearItensEmpresa = (): Item[] => [
    { id: 1, nome: 'CNPJ', valor: cnpj, class: '' },
    { id: 2, nome: 'Razão social', valor: razaoSocial, class: '' },
    { id: 3, nome: 'Faturamento', valor: faturamentoMedioMensal, class: '' },
    { id: 4, nome: 'Constituição', valor: dataConstituicao !== null ? dataConstituicao.toLocaleDateString('pt-BR', { timeZone: 'UTC' }) : '', class: '' },
    { id: 5, nome: 'Natureza jurídica', valor: naturezaJuridica, class: '' },
    { id: 6, nome: 'Endereco', valor: formatarEndereco(), class: '' },
  ].filter(item => item.valor);

  return (
    <section id='cadastro-resumo' className={`${possuiAdesivos ? 'sem-pedido' : 'pedido-adesivo'}`}>
      <h1>Resumo</h1>

      <Bloco
        titulo='ConectCar Frotas'
        icone={iconeCaminhao}
        itens={mapearItensPlano()}
        url={EtapasRotas.get(Etapas.Plano)!}
        etapaRepresentada={Etapas.Plano}
      />
      <Bloco
        titulo='Contato e Representante'
        icone={iconePessoa}
        itens={mapearItensRepresentante()}
        url={EtapasRotas.get(Etapas.Representante)!}
        etapaRepresentada={Etapas.Representante}
      />
      <Bloco
        titulo='Dados da Empresa'
        icone={iconeEmpresa}
        itens={mapearItensEmpresa()}
        url={EtapasRotas.get(Etapas.Empresa)!}
        etapaRepresentada={Etapas.Empresa}
      />

      <Bloco
        titulo='Senha'
        icone={iconeSenha}
        itens={[]}
        url={EtapasRotas.get(Etapas.Senha)!}
        etapaRepresentada={Etapas.Senha}
        ehUltimo
      />
    </section>
  );
};
