import * as React from 'react';
import './FaturaAtualPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../FaturaActions';
import { FaturaState } from '../FaturaInterfaces';
import { State } from '../../interfaces';
import CardFaturaValidacao from '../../../components/Fatura/CardFaturaValidacao/CardFaturaValidacao';

const FaturaAtualPage = () => {
  const {
    dadosFatura,
    carregando,
    erro,
  } = useSelector<State, FaturaState>(state => ({
    dadosFatura: state.fatura.dadosFatura,
    carregando: state.fatura.dadosFatura.carregando,
    erro: state.fatura.dadosFatura.erro,
    dadosLimitesSaldo: state.fatura.dadosLimitesSaldo,
    historicoFatura: state.fatura.historicoFatura,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Actions.buscarFaturaAtualCard());
  }, [dispatch]);

  return (
    <CardFaturaValidacao dadosFatura={dadosFatura} carregando={carregando} erro={erro} />
  );
};

export default FaturaAtualPage;
