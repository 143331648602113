import { Action } from '../../interfaces';
import {
  AtualizarTotalPaginasAction, LimparTabelaAction, ObterMeusPedidosErrorAction, ObterMeusPedidosRequisicaoAction, ObterMeusPedidosSucessoAction, ResetarPaginacaoAction, Types,
} from './MeusPedidosActions';
import { MeusPedidosState } from './MeusPedidosInterfaces';

const INITIAL_STATE = {
  carregando: false,
  erro: false,
  paginaSelecionada: 1,
  registrosPorPagina: 10,
  totalPaginas: 0,
} as MeusPedidosState;

const buscarMeusPedidosRequest = (state: MeusPedidosState, action: ObterMeusPedidosRequisicaoAction) => (
  { ...state, action, meusPedidos: { carregando: true, erro: false } });

const buscarMeusPedidosSucesso = (state: MeusPedidosState, action: ObterMeusPedidosSucessoAction) => (
  { ...state, meusPedidos: { ...action.meusPedidos, erro: false, carregando: false } });

const buscarMeusPedidosError = (state: MeusPedidosState, action: ObterMeusPedidosErrorAction) => (
  { ...state, action, meusPedidos: { carregando: false, erro: true } });

const atualizarTotalPaginas = (state: MeusPedidosState, action: AtualizarTotalPaginasAction) => (
  { ...state, totalPaginas: action.totalPaginas });

const resetarPaginacao = (state: MeusPedidosState, action: ResetarPaginacaoAction) => ({
  ...state, action,
});

const limparTabela = (state: MeusPedidosState, action: LimparTabelaAction) => (
  { ...state, action, historicoRecargaFiltro: { dados: { recargasFiltradas: [] }, carregando: false, erro: false } });

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.BuscarMeusPedidosRequisicao:
      return buscarMeusPedidosRequest(state, action as ObterMeusPedidosRequisicaoAction);
    case Types.BuscarMeusPedidosSucesso:
      return buscarMeusPedidosSucesso(state, action as ObterMeusPedidosSucessoAction);
    case Types.BuscarMeusPedidosError:
      return buscarMeusPedidosError(state, action as ObterMeusPedidosErrorAction);
    case Types.AtualizarTotalPaginas:
      return atualizarTotalPaginas(state, action as AtualizarTotalPaginasAction);
    case Types.ResetarPaginacao:
      return resetarPaginacao(state, action as ResetarPaginacaoAction);
    case Types.LimparTabela:
      return limparTabela(state, action as LimparTabelaAction);
    default:
      return state;
  }
};
