import * as React from 'react';
import CadastroFooter from '../CadastroFooter';

import '../Cadastro.scss';
import './CadastroAvisoLayout.scss';

interface Props {
  titulo: string,
  children: React.ReactElement,
  botao: { texto: string, acao: Function }
}

const logo = require('../../../assets/images/logo.svg');

export default ({ titulo, children, botao } : Props) => (
  <section id='resultado-cadastro'>
    <section className='content-left'>
      <img src={logo} alt='logo' />
    </section>

    <section className='content-right'>
      <div className='resultado-content'>
        <h1>{titulo}</h1>
        <br />
        <div>{children}</div>
        <button type='button' className='botao-preenchido-azul' onClick={() => botao.acao()}>
          {botao.texto}
        </button>
      </div>

      <CadastroFooter />
    </section>
  </section>
);
