import axios from 'axios';
import { RespostaApi, IdApi } from '../../ApiInterfaces';
import { Plano, RepresentanteLegal, DadosEmpresa, Etapas } from '../../../features/cadastro/CadastroInterfaces';
import { DadosIntegracaoPlataforma, DadosParametrosUtm, RascunhoProspecto, Telefone } from './RascunhoInterfaces';
import { removerCaracteresNaoNumericos } from '../../mascara';
import { parsearFaturamento } from '../utils';

enum TiposTelefone {
  Fixo = 1,
  Movel = 2,
}

const rascunhoEndpoint = 'landingpage-bff/api/v1/Prospeccao/Rascunho';

const obterDddETelefone = (telefone: string) => {
  const telefoneSemMascara = removerCaracteresNaoNumericos(telefone);
  if (!telefoneSemMascara) return ['', ''];
  return [telefoneSemMascara.substring(0, 2), telefoneSemMascara.substring(2)];
};

const converterTelefonesParaEnvio = (telefone: string, celular: string) => {
  const [telefoneDdd, telefoneNumero] = obterDddETelefone(telefone);
  const [celularDdd, celularNumero] = obterDddETelefone(celular);

  let telefones = [];

  if (telefoneDdd && telefoneNumero) {
    telefones = [...telefones, {
      tipoTelefone: TiposTelefone.Fixo,
      ddd: telefoneDdd,
      numero: telefoneNumero,
    }];
  }

  if (celularDdd && celularNumero) {
    telefones = [...telefones, {
      tipoTelefone: TiposTelefone.Movel,
      ddd: celularDdd,
      numero: celularNumero,
    }];
  }

  return telefones;
};

const converterRascunhoParaEnvio = (
  idRascunho: string,
  planoId: string,
  plano: Plano,
  representante: RepresentanteLegal,
  dadosEmpresa: DadosEmpresa,
  dadosParametrosUtm: DadosParametrosUtm,
  dadosIntegracaoPlataforma: DadosIntegracaoPlataforma,
  etapaAtual: Etapas,
  parceiroId: number,
  parceiro: string,
  pedidoId?: string,
) => ({
  id: idRascunho,
  pedidoId,
  dadosPessoa: {
    nome: representante.nomeContatoComercial,
    email: representante.email,
    telefones: converterTelefonesParaEnvio(representante.telefone, representante.celular),
  },
  dadosEmpresa: {
    cnpj: removerCaracteresNaoNumericos(dadosEmpresa.cnpj),
    razaoSocial: dadosEmpresa.razaoSocial,
    dataConstituicao: dadosEmpresa.dataConstituicao,
    naturezaJuridica: dadosEmpresa.naturezaJuridica,
    nomeRepresentante: representante.nomeRepresentanteLegal,
    cpf: removerCaracteresNaoNumericos(representante.cpf),
    dataRepresentante: representante.representanteDesde,
    cargo: representante.cargo,
    expostaPublicamente: representante.pessoaPoliticamenteExposta,
    faturamentoMedioMensal: parsearFaturamento(dadosEmpresa.faturamentoMedioMensal),
    isento: null,
    cep: removerCaracteresNaoNumericos(dadosEmpresa.cep),
    endereco: dadosEmpresa.logradouro,
    numero: dadosEmpresa.numero,
    complemento: dadosEmpresa.complemento,
    estado: dadosEmpresa.estado,
    cidade: dadosEmpresa.cidade,
    bairro: dadosEmpresa.bairro,
    codigoIbge: dadosEmpresa.codigoIbge,
  },
  dadosAdicionais: {
    quantidadeVeiculosLeves: plano.veiculosLeves,
    quantidadeVeiculosPesados: plano.veiculosPesados,
    possuiAdesivos: plano.possuiAdesivos,
  },
  dadosIntegracaoPlataforma: {
    contatoPlataformaId: dadosIntegracaoPlataforma.contatoPlataformaId,
    plataformaDescricao: dadosIntegracaoPlataforma.plataformaDescricao,
    propostaPlataformaId: dadosIntegracaoPlataforma.propostaPlataformaId,
    landingPageURL: dadosIntegracaoPlataforma.landingPageURL,
    dataIntegracaoPropostaPlataforma: dadosIntegracaoPlataforma.dataIntegracaoPropostaPlataforma,
    ultimaDataIntegracaoPlataforma: dadosIntegracaoPlataforma.ultimaDataIntegracaoPlataforma,
  },
  dadosParametrosUtm: {
    inicial: dadosParametrosUtm.inicial,
    final: dadosParametrosUtm.final,
  },
  planoPjId: planoId,
  parceiro,
  parceiroId,
  etapaAtual,
});

const tratarNumeroParaInput = (numero: number) => {
  if (numero && numero.toString() !== '0') return numero.toString();
  return '';
};

export const converterTelefonesParaTela = (telefones: Telefone[]) => {
  const resultado = {
    telefoneDdd: '',
    telefoneNumero: '',
    celularDdd: '',
    celularNumero: '',
  };

  const telefone = telefones.find(x => x.tipoTelefone === TiposTelefone.Fixo);
  const celular = telefones.find(x => x.tipoTelefone === TiposTelefone.Movel);

  if (telefone) {
    resultado.telefoneDdd = tratarNumeroParaInput(telefone.ddd);
    resultado.telefoneNumero = tratarNumeroParaInput(telefone.numero);
  }

  if (celular) {
    resultado.celularDdd = tratarNumeroParaInput(celular.ddd);
    resultado.celularNumero = tratarNumeroParaInput(celular.numero);
  }

  return resultado;
};

export default {
  enviarRascunho: async (
    idRascunho: string,
    planoId: string,
    plano: Plano,
    representante: RepresentanteLegal,
    dadosEmpresa: DadosEmpresa,
    dadosParametrosUtm: DadosParametrosUtm,
    dadosIntegracaoPlataforma: DadosIntegracaoPlataforma,
    etapaAtual: Etapas,
    parceiroId: number,
    parceiro: string,
    pedidoId?: string,

  ): Promise<RespostaApi<IdApi<string>>> => {
    const apiUrl = `${(window as any).config.url_api_management}${rascunhoEndpoint}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const rascunhoEnvio = converterRascunhoParaEnvio(
      idRascunho,
      planoId,
      plano,
      representante,
      dadosEmpresa,
      dadosParametrosUtm,
      dadosIntegracaoPlataforma,
      etapaAtual,
      parceiroId,
      parceiro,
      pedidoId,
    );

    const resposta = await axios.put(apiUrl, rascunhoEnvio, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
    });

    return resposta;
  },

  obterRascunho: async (rascunhoId: string): Promise<RespostaApi<RascunhoProspecto>> => {
    const apiUrl = `${(window as any).config.url_api_management}${rascunhoEndpoint}/${rascunhoId}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const resposta = await axios.get(apiUrl, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
    });

    return resposta;
  },
};
