import * as React from 'react';
import './RadioButton.scss';
import { Item } from '../../../features/interfaces';

interface Props {
  itens: Item[],
  valorSelecionado: string,
  nome: string,
  onClick: Function
}

const RadioButton = ({ nome, itens, valorSelecionado, onClick }: Props) => {
  const onItemSelecionado = (event: React.MouseEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    onClick({ target: { name, value } });
  };

  const estaSelecionado = (valor: string) => valorSelecionado === valor;

  const lista = itens.map(item => (
    <label className='radio-label' key={item.id}>
      <input
        type='radio'
        name={nome}
        value={item.valor}
        checked={estaSelecionado(item.valor)}
        title={item.nome}
        onClick={e => onItemSelecionado(e)}
      />
      {item.nome}
      <span />
    </label>
  ));

  return (
    <div id='radio-button'>
      {lista}
    </div>
  );
};

export default RadioButton;
