import * as React from 'react';
import { DropdownItem } from './DropdownInterface';
import DropdownItemComponent from './DropdownItemComponent';

import './Dropdown.scss';

interface Props {
  titulo: string,
  name: string,
  textoItemSelecionado: string,
  itens: DropdownItem[],
  onChange: Function,
  meiaColuna?: boolean,
  mensagemValidacao?: string,
  disabled?: boolean,
}

const { useState, useRef, useEffect } = React;

const Dropdown = ({
  titulo,
  name,
  itens,
  textoItemSelecionado,
  onChange,
  meiaColuna,
  mensagemValidacao,
  disabled,
}: Props) => {
  const node = useRef<HTMLDivElement>(document.createElement('div'));
  const [itemVazio] = useState<DropdownItem>({ valor: 0, texto: 'Escolha' });
  const [menuAberto, setMenuAberto] = useState<boolean>(false);
  const [itemSelecionado, setItemSelecionado] = useState<DropdownItem>(itemVazio);

  const dropdownContentClass = `dropdown-content ${disabled ? 'desabilitado' : ''}`;
  const dropdownWrapperClass = `dropdown-wrapper ${meiaColuna ? 'meia-coluna' : ''}`;
  const dropdownHeaderOptionClass = `dropdown-header-option ${meiaColuna ? 'meia-coluna' : ''}`;
  const statusMenuClass = `dropdown-header-selected ${menuAberto ? 'aberto' : 'fechado'} ${itemSelecionado === itemVazio ? 'nenhum-item-selecionado' : ''}`;

  const toggleList = () => {
    if (!disabled) setMenuAberto(!menuAberto);
  };

  const onItemSelecionado = (item: DropdownItem) => {
    onChange(name, item.texto);
    setMenuAberto(false);
  };

  const handleCliqueFora = (e: any) => {
    if (!node.current.contains(e.target)) {
      setMenuAberto(false);
    }
  };

  useEffect(() => {
    const novoItem = !textoItemSelecionado
      ? itemVazio
      : itens.find(elemento => elemento.texto === textoItemSelecionado);

    setItemSelecionado(novoItem);
  }, [itemVazio, itens, textoItemSelecionado]);

  useEffect(() => {
    document.addEventListener('mousedown', handleCliqueFora);
    return () => {
      document.removeEventListener('mousedown', handleCliqueFora);
    };
  }, [handleCliqueFora]);

  return (
    <div className={dropdownWrapperClass} ref={node}>
      <div className={dropdownContentClass}>
        <div className='dropdown-header-title'>{titulo}</div>
        <div
          onClick={() => toggleList()}
          onKeyDown={() => toggleList()}
          className={dropdownHeaderOptionClass}
          role='button'
          tabIndex={0}
          data-testid='dropdown-button'
        >
          <span
            className={statusMenuClass}
            title={titulo}
            data-testid='dropdown'
          >
            {itemSelecionado.texto}
          </span>
        </div>
        {menuAberto && (
          <ul className='dropdown-list' data-testid='dropdown-list'>
            {itens.map(item => (
              <DropdownItemComponent
                key={item.valor}
                item={item}
                onClick={() => onItemSelecionado(item)}
                estaSelecionado={item === itemSelecionado}
              />
            ))}
          </ul>
        )}
      </div>
      <span className={mensagemValidacao ? 'mensagem-validacao' : ''}>{mensagemValidacao}</span>
    </div>
  );
};

export default Dropdown;
