import {
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Comprovante from '../../comprovante/Comprovante';
import { AppStatusRecarga } from '../../../common/AppStatusRecarga.enum';
import { RetornoHistoricoRecargaFiltro } from '../HistoricoRecargaInterfaces';
import './HistoricoRecargaTabela.scss';
import { DadosCliente } from '../../../services/requests/SolicitacaoAdesivo/comprovanteRecargaRequest/interface';
import { obterDadosCliente } from '../../../services/requests/SolicitacaoAdesivo/comprovanteRecargaRequest';
import { FalhaAoCarregarComprovantes } from '../../../components/ModalErro/ModalErroConstantes';
import ModalErro from '../../../components/ModalErro/ModalErro';

const IconBoleto = require('../../../assets/images/icones/icon_boleto.png');
const IconComprovante = require('../../../assets/images/icones/Pricing-doc.svg');

const StyledTableCell = withStyles(({
  root: {
    fontSize: '1em',
  },
  head: {
    color: '#909090',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '17px',
  },
}))(TableCell);

const ClassesStyles = makeStyles({
  root: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff6339',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'ff6339',
    },
    '& .MuiInputLabel-outlined': {
      color: 'black',
    },
  },
  spanMensagem: {
    color: '#293B8A',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
  divMensagem: {
    alignItems: 'center',
    textAlign: 'center',
    height: 25,
  },
  divBoletoRapido: {
    display: 'inline-block',
  },
  imageBoletoIcon: {
    backgroundImage: `url(${IconBoleto})`,
    backgroundRepeat: 'no-repeat',
  },
  imageComprovanteIcon: {
    backgroundImage: `url(${IconComprovante})`,
    backgroundRepeat: 'no-repeat',
    width: '24px',
    height: '24px',
  },
  fonteBoleto: {
    width: '15em',
    color: '#ff6339',
  },
  fonteTabela: {
    color: '#333333',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '17px',
  },
  fonteMsg: {
    color: '#909090',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '17px',
  },
});

interface Props {
  historicofiltro: RetornoHistoricoRecargaFiltro
}

const HistoricoRecargaTabela = ({ historicofiltro }: Props) => {
  const classes = ClassesStyles();

  historicofiltro?.dados?.recargasFiltradas.sort((a, b) => {
    const aa = a.data.split('/').reverse().join();
    const bb = b.data.split('/').reverse().join();
    return aa > bb ? -1 : (aa < bb ? 1 : 0);
  });

  const montarUrlBoleto = (url: string, hash: string): string => `${url}?DC=${hash} `;
  const monstarStatusRecarga = (status: string): string => (status === AppStatusRecarga.RECARGA_REALIZADA ? 'Recarga Creditada' : status);
  const visualizarBoleto = (descricaoBoleto: string, status: string): boolean => {
    if (descricaoBoleto.includes('Boleto') && (status === AppStatusRecarga.AGUARDANDO_PAGAMENTO)) {
      if (descricaoBoleto.includes('Boleto Rápido')) return false;
      return true;
    }
    return false;
  };
  const visualizarComprovante = (status: string): boolean => (status === AppStatusRecarga.RECARGA_REALIZADA);

  const dispatch = useDispatch();

  const [dadosCliente, setDadosCliente] = useState<DadosCliente>(null);
  const [mostrarModalErro, setMostrarModal] = useState<boolean>(false);
  const [mensagemModalErro, setMensagemModal] = useState<string>('');

  useEffect(() => {
    const obterDadosComprovante = async () => obterDadosCliente()
      .then(response => setDadosCliente(response?.data?.dados))
      .catch(() => { setMensagemModal(FalhaAoCarregarComprovantes); setMostrarModal(true); });

    obterDadosComprovante();
  }, [dispatch]);


  return (
    <section className='tabela-container'>
      <ModalErro
        visivel={mostrarModalErro}
        handleClose={() => setMostrarModal(false)}
        mensagem={mensagemModalErro}
      />
      <TableContainer component={Paper}>
        {historicofiltro.dados && historicofiltro.dados.recargasFiltradas.length > 0 && (
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Data</StyledTableCell>
                <StyledTableCell align='left'>Pedido</StyledTableCell>
                <StyledTableCell align='left'>Valor</StyledTableCell>
                <StyledTableCell align='left'>Forma de Pagamento</StyledTableCell>
                <StyledTableCell align='left'>Status</StyledTableCell>
                <StyledTableCell>Arquivo</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicofiltro.dados.recargasFiltradas.map(tabela => (
                <TableRow key={tabela?.data}>
                  <TableCell className={classes.fonteTabela} component='td' scope='row'>
                    {tabela.data}
                    {' '}
                  </TableCell>
                  <TableCell className={classes.fonteTabela} align='left'>{tabela.pedidoId}</TableCell>
                  <TableCell className={classes.fonteTabela} align='left'>
                    R$
                    {tabela.valor}
                  </TableCell>
                  <TableCell className={classes.fonteTabela} align='left'>{tabela.formaPagamento}</TableCell>
                  <TableCell className={classes.fonteTabela} align='left'>{monstarStatusRecarga(tabela.status)}</TableCell>
                  <TableCell component='td' className={classes.fonteBoleto} scope='row'>
                    <Grid container direction='column' alignContent='flex-start'>
                      {visualizarBoleto(tabela.formaPagamento, tabela.status) && (
                        <Grid item>
                          <IconButton id='HistoricoRecarga_Download_Boleto' onClick={() => window.open(montarUrlBoleto(tabela?.boleto?.url, tabela?.boleto?.informacoesBoleto), '_blank')} aria-label='boleto'>
                            <Icon className={classes.imageBoletoIcon} />
                          </IconButton>
                          {tabela.formaPagamento}
                        </Grid>
                      )}
                      {visualizarComprovante(tabela.status)
                        && dadosCliente && (
                          <Grid item>
                            <PDFDownloadLink document={<Comprovante dadosFiltro={tabela} dadosCliente={dadosCliente} />} fileName={`Comprovante_Recarga_${tabela.pedidoId}.pdf`}>
                              {({ error }) => (error ? 'Falha ao gerar comprovante' : <IconButton id='HistoricoRecarga_Download_Comprovante'><Icon className={classes.imageComprovanteIcon} /></IconButton>)}
                            </PDFDownloadLink>
                            Comprovante
                          </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        )}
        {historicofiltro.dados && historicofiltro.dados.recargasFiltradas.length === 0 && (
          <div className={classes.divMensagem}>
            <span className={classes.fonteMsg}>Nenhum registro encontrado</span>
          </div>
        )}
        {!historicofiltro.carregando && historicofiltro.erro && (
          <div className={classes.divMensagem}>
            <span className={classes.fonteMsg}>Ocorreu um erro ao carregar o histórico</span>
          </div>
        )}
      </TableContainer>
    </section>
  );
};

export default HistoricoRecargaTabela;
