import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../interfaces';
import './home.scss';
import Actions from './HomeActions';
import { HomeState } from './HomeInterfaces';
import ocultadorChatbot from '../../infrastructure/ocultadorChatbot';
import SaldoGlobal from '../../components/SaldoGlobal/SaldoGlobal';
import UltimoPedido from '../../components/UltimoPedido/UltimoPedido';
import FAQ from '../../components/FAQ/FAQ';
import UltimaRecarga from '../../components/UltimaRecarga/UltimaRecarga';
import NovidadesPortal from '../../components/NovidadesPortal/NovidadesPortal';


const HomePage = () => {
  const {
    documento,
    saldo,
    token,
    possuiAdesao,
    dadosPlanoCliente,
    dadosUltimaRecarga,
  } = useSelector<State, HomeState>(state => ({
    documento: state.home.documento,
    clienteId: state.home.clienteId,
    token: state.home.token,
    saldo: state.home.saldo,
    possuiAdesao: state.home.possuiAdesao,
    dadosPlanoCliente: state.home.dadosPlanoCliente,
    dadosUltimaRecarga: state.home.dadosUltimaRecarga,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = 'ConectCar Frotas - Home';
    ocultadorChatbot();
    dispatch(Actions.inicializarHome());
  }, [dispatch]);

  return (
    <section className='section-home iframe-resizable'>

      <SaldoGlobal saldo={saldo} documento={documento} />

      {dadosPlanoCliente?.paymentPlanId !== undefined && dadosPlanoCliente?.paymentPlanId !== 4 && dadosUltimaRecarga !== undefined && <UltimaRecarga />}

      <NovidadesPortal possuiAdesao={possuiAdesao} />

      {dadosPlanoCliente?.paymentPlanId !== undefined && documento?.length > 0
        ? <UltimoPedido documento={documento} token={token} tituloOferta={dadosPlanoCliente.description} />
        : <div />}

      <FAQ />

    </section>
  );
};

export default HomePage;
