import React from 'react';
import { useDispatch } from 'react-redux';
import ModalShopline from '../../Components/Shopline/ModalShopline';

interface ShoplineProps {
  action: any;
  dadosCriptografados: any;
  dispatchAction: any;
}

const CarregarRetornoShopline = ({ action, dadosCriptografados, dispatchAction }: ShoplineProps) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const botao = document.getElementById('boleto-itau');
    if (botao !== null) {
      window.open('', 'SHOPLINE', 'toolbar=yes,menubar=yes,resizable=yes,status=no,scrollbars=yes,width=675,height=485');
      botao.click();

      dispatch(dispatchAction());
    }
  });

  return (
    <ModalShopline
      action={action}
      dadosCriptografados={dadosCriptografados}
    />
  );
};

export default CarregarRetornoShopline;
