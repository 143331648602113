import React, { Fragment, memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import SubstituicaoAdesivoFormularioWrapper from './formulario';
import SubstituicaoAdesivoPesquisa from './pesquisa/SubstituicaoPesquisa';
import SubstituicaoAdesivoTitulo from './titulo/SubstituicaoTitulo';

import ModalErro from '../../../../components/ModalErro/ModalErro';
import { State } from '../../../interfaces';
import { SubstituicaoAdesivoState } from '../../SubstituicaoAdesivoInterfaces';
import Actions from '../../SubstituicaoAdesivoActions';
import ocultadorChatbot from '../../../../infrastructure/ocultadorChatbot';

import './SubstituicaoDados.scss';

const SubstituicaoAdesivo: React.FC = () => {
  const {
    mostrarModalErro,
    mensagemModalErro,
  } = useSelector<State, SubstituicaoAdesivoState>(state => ({
    mostrarModalErro: state.substituicaoAdesivo.mostrarModalErro,
    mensagemModalErro: state.substituicaoAdesivo.mensagemModalErro,
  }));

  const dispatch = useDispatch();

  const handleCloseModalErroSubstituicao = () => dispatch(Actions.fecharModalErro());

  useEffect(() => {
    ocultadorChatbot();
    dispatch(Actions.inicializarSubstituicaoAdesivo());
  }, [dispatch]);

  return (
    <Fragment>
      <ModalErro
        visivel={mostrarModalErro}
        handleClose={handleCloseModalErroSubstituicao}
        mensagem={mensagemModalErro}
      />
      <section id='substituicao-titulo-wrapper'>
        <SubstituicaoAdesivoTitulo />
      </section>
      <section id='substituicao-pesquisa-wrapper'>
        <SubstituicaoAdesivoPesquisa />
      </section>
      <section id='substituicao-formulario-wrapper'>
        <SubstituicaoAdesivoFormularioWrapper />
      </section>
    </Fragment>
  );
};

export default memo(SubstituicaoAdesivo);
