import React from 'react';

import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import Extrato from './extrato';
import {
  CadastroPlanoPage, CadastroRepresentantePage, CadastroEmpresaPage, CadastroSenhaPage, CadastroCnpjInvalidoPage,
} from './cadastro';
import Loading from '../components/Loading';
import './template.scss';
import CadastroConcluidoPage from './cadastro/CadastroConcluidoPage';
import Home from './home/index';
import FaturaPage from './fatura-pos-pago/FaturaPage';
import HistoricoRecargaPage from './historico-recarga/HistoricoRecargaPage';
import RecargaPage from './recarga';
import MeusPedidosPage from './solicitacao-adesivo/meus-pedidos/MeusPedidosPage';
import SolicitacaoAdesivo from './solicitacao-adesivo/solicitacaoAdesivo';
import SubstituicaoAdesivo from './substituicao-adesivo/pages/substituicao-dados/SubstituicaoAdesivo';
import SubstituicaoAdesivoSucesso from './substituicao-adesivo/pages/substituicao-sucesso';
import CadastroAvisoPage from './cadastro/CadastroAvisoPage/CadastroAvisoPage';
import RecargaCartaoCreditoPage from './recarga-cartaoCredito/RecargaCartaoCreditoPage';
import Arquivos from './arquivos/Arquivos';

export default () => {
  const token = sessionStorage.getItem('TOKEN_KEY');

  return (
    <BrowserRouter>
      <section id='content'>
        <Loading />
        <Switch>
          <Route path='/extrato' component={Extrato} />
          <Route path='/home' component={Home} />
          <Route path='/fatura/pos-pago' component={FaturaPage} />
          <Route path='/recarga/historico' component={HistoricoRecargaPage} />
          <Route path='/recarga/cartao' component={RecargaCartaoCreditoPage} />
          <Route path='/recarga' component={RecargaPage} />
          <Route path='/arquivos' component={Arquivos} />

          {token && <Route path='/solicitacao-adesivo' component={SolicitacaoAdesivo} />}
          {token && <Route path='/solicitacao-adesivo/meus-pedidos' component={MeusPedidosPage} />}
          {token && <Route path='/substituicao-adesivo' component={SubstituicaoAdesivo} />}
          {token && <Route path='/substituicao-adesivo-sucesso' component={SubstituicaoAdesivoSucesso} />}

          <Route path='/cadastro/rascunho/:rascunhoId?' component={CadastroPlanoPage} />
          <Route path='/cadastro/plano/:canalVendasId?' component={CadastroPlanoPage} />
          <Route path='/cadastro/representante' component={CadastroRepresentantePage} />
          <Route path='/cadastro/empresa' component={CadastroEmpresaPage} />
          <Route path='/cadastro/senha' component={CadastroSenhaPage} />
          <Route path='/cadastro/concluido' component={CadastroConcluidoPage} />
          <Route path='/cadastro/cnpjInvalido' component={CadastroCnpjInvalidoPage} />
          <Route path='/cadastro/:avisoTipo?' component={CadastroAvisoPage} />

          <Route
            path='/redirect'
            component={() => {
              window.parent.location.href = `${(window as any).config.url_portal_cliente}/HomePj`;
              return null;
            }}
          />
          <Redirect to='/redirect' />
        </Switch>
      </section>
    </BrowserRouter>
  );
};
