import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { RetornoFaturaCard } from '../../../features/fatura-pos-pago/FaturaInterfaces';
import CardFatura from '../CardFatura';

interface Props {
  dadosFatura: RetornoFaturaCard,
  carregando: boolean,
  erro: boolean
}

const styleFatura = makeStyles(() => ({
  root: {
    margin: '0 38px 15px 123px',
  },
  msgFaturaIndisponivel: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 22,
    color: '#FF6338',
  },
}));

const CardFaturaValidacao = ({ dadosFatura, carregando, erro }: Props) => {
  const style = styleFatura();
  return (
    <section>
      {!carregando && dadosFatura.dados && dadosFatura.dados.length > 0
        && (
          <CardFatura itemCardFatura={dadosFatura.dados} />
        )
      }
      {!carregando && dadosFatura.dados && dadosFatura.dados.length === 0
        && (
          <Box className={style.root} alignItems='center'>
            <Box className={style.msgFaturaIndisponivel}>Fatura indisponível</Box>
          </Box>
        )
      }
      {!carregando && erro
        && (
          <Box className={style.root} alignItems='center'>
            <Box className={style.msgFaturaIndisponivel}>Fatura indisponível</Box>
          </Box>
        )
      }
    </section>
  );
};

export default CardFaturaValidacao;
