import React, { memo } from 'react';
import { Box, Typography } from '@material-ui/core';

import { InfoOutlined } from '@material-ui/icons';

import './AvisoCliente.scss';

const AvisoCliente = () => {
  const mensagemAviso = `Prezado cliente, caso não efetue o pagamento dentro do vencimento,
        o mesmo será cancelado automaticamente, sendo necessário gerar um novo pedido.`;

  return (
    <Box className='box-aviso-cliente-pagamento-pedido'>
      <span>
        <InfoOutlined fontSize='large' />
      </span>
      <Typography>
        {mensagemAviso}
      </Typography>
    </Box>
  );
};
export default memo(AvisoCliente);
