import React from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  InputLabel,
  Divider,
} from '@material-ui/core';

import './BoxPagamentoControlado.scss';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import BoxPagamentoStyles from './BoxPagamentoStyles';

interface Props {
  titulo: string;
  inputValor?: any;
  labelValor?: string;

  id: string;
  btnID?: string;
  botaoValorTexto?: string;
  descricaoTitulo?: any;
  complementoTitulo?: any;
  complementoDescricaoTitulo?: any;
  logotipo?: string;
  onButtonClick: any;

  boxOpen?: boolean;
  setBoxOpen?: any;
}

const BoxPagamentoControlado: React.FC<Props> = ({
  titulo,
  complementoTitulo,
  complementoDescricaoTitulo,
  descricaoTitulo,
  id,
  btnID,
  inputValor,
  labelValor,
  botaoValorTexto,
  logotipo,
  onButtonClick,

  boxOpen,
  setBoxOpen,
}) => {
  const styles = BoxPagamentoStyles();

  return (
    <Accordion
      expanded={boxOpen || false}
      className={styles.BoxPagamentoAccordionStyle}
      style={{ borderColor: boxOpen ? '#298F7A' : '#D5D5D5' }}
    >

      <AccordionSummary
        id={id}
        expandIcon={boxOpen ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
        className={styles.panelSummary}
        onClick={() => setBoxOpen()}
      >
        <div style={{ height: '64px', width: '62px' }}>
          <div className={logotipo} />
        </div>
        <div>
          <div className={styles.BoxPagamentoTitulo} id={`${id}-titulo`}>
            {titulo}
            {complementoTitulo !== null && complementoTitulo}
          </div>
          <div className={styles.BoxPagamentoDescricao}>{descricaoTitulo}</div>
          {complementoDescricaoTitulo !== null && complementoDescricaoTitulo}
        </div>
      </AccordionSummary>
      <Divider className={styles.divider} />
      <AccordionDetails className='marginTopBoxPagamento'>
        <Grid container direction='column'>
          {labelValor !== undefined && inputValor !== undefined
            ? (
              <>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={styles.dotBoxPagamento} />
                  <InputLabel
                    variant='standard'
                    className={styles.BoxPagamentoLabel}
                  >
                    {labelValor}
                  </InputLabel>
                </Grid>
                <Grid item className='marginTopBoxPagamento marginLeftBoxPagamento' id={`input-valor-${id}`}>
                  {inputValor}
                </Grid>
              </>
            )
            : ''
          }
          <Grid item className='marginTopBoxPagamento marginLeftBoxPagamento'>
            <Button
              id={btnID}
              className={styles.BoxPagamentoButton}
              variant='contained'
              onClick={onButtonClick}
            >
              {botaoValorTexto}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default BoxPagamentoControlado;
