import { Action } from './interfaces';

export enum Types {
  IniciarRequisicao = 'INICIAR_REQUISICAO',
  TerminarRequisicao = 'TERMINAR_REQUISICAO',
}

export default {
  iniciarRequisicao: (): Action => ({ type: Types.IniciarRequisicao }),
  terminarRequisicao: (): Action => ({ type: Types.TerminarRequisicao }),
};
