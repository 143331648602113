import { HistoricoRecargaState } from './HistoricoRecargaInterfaces';
import {
  AtualizarTotalPaginasAction,
  BuscarHistoricoRecargaFiltroErrorAction, BuscarHistoricoRecargaFiltroRequisicaoAction, BuscarHistoricoRecargaFiltroSucessoAction, LimparTabelaAction, ModificarPaginaAction, ObterHistoricoUltimoPedidoErrorAction, ObterHistoricoUltimoPedidoRequisicaoAction, ObterHistoricoUltimoPedidoSucessoAction, ObterUltimasRecargaErrorAction, ObterUltimasRecargaRequisicaoAction, ObterUltimasRecargaSucessoAction, ResetarPaginacaoAction, Types,
} from './HistoricoRecargaActions';
import { Action } from '../interfaces';

const INITIAL_STATE = {
  historicoRecargaFiltro: {},
  filtrosHistoricoRecargaSelecionados: { status: '' },
  historicoUltimoPedido: {},
  carregando: false,
  erro: false,
  paginaSelecionada: 1,
  registrosPorPagina: 10,
  totalPaginas: 0,
} as HistoricoRecargaState;

const buscarHistoricoRecargaFiltroRequest = (state: HistoricoRecargaState, action: BuscarHistoricoRecargaFiltroRequisicaoAction) => (
  { ...state, action, historicoRecargaFiltro: { carregando: true, erro: false }, filtrosHistoricoRecargaSelecionados: { status: action.filtrosRecargaSelecionados.status, dataInicio: action.filtrosRecargaSelecionados.dataInicio, dataFim: action.filtrosRecargaSelecionados.dataFim } });

const buscarHistoricoRecargaFiltroSucesso = (state: HistoricoRecargaState, action: BuscarHistoricoRecargaFiltroSucessoAction) => (
  { ...state, historicoRecargaFiltro: { ...action.historicoRecargaFiltro, erro: false, carregando: false } });

const buscarHistoricoRecargaFiltroError = (state: HistoricoRecargaState, action: BuscarHistoricoRecargaFiltroErrorAction) => (
  { ...state, action, historicoRecargaFiltro: { carregando: false, erro: true } });


const obterUltimasRecargaRequest = (state: HistoricoRecargaState, action: ObterUltimasRecargaRequisicaoAction) => (
  { ...state, action, historicoRecargaFiltro: { carregando: true, erro: false } });

const obterUltimasRecargaSucesso = (state: HistoricoRecargaState, action: ObterUltimasRecargaSucessoAction) => (
  { ...state, historicoRecargaFiltro: { ...action.historicoRecargaFiltro, erro: false, carregando: false } });

const obterUltimasRecargaError = (state: HistoricoRecargaState, action: ObterUltimasRecargaErrorAction) => (
  { ...state, action, historicoRecargaFiltro: { carregando: false, erro: true } });

const modificarPagina = (state: HistoricoRecargaState, action: ModificarPaginaAction) => ({
  ...state, paginaSelecionada: action.paginaSelecionada, registrosPorPagina: action.registrosPorPagina,
});

const obterHistoricoUltimoPedidoRequest = (state: HistoricoRecargaState, action: ObterUltimasRecargaRequisicaoAction) => (
  { ...state, action, historicoRecargaFiltro: { carregando: true, erro: false } });

const obterHistoricoUltimoPedidoSucesso = (state: HistoricoRecargaState, action: ObterHistoricoUltimoPedidoSucessoAction) => (
  { ...state, historicoUltimoPedido: { ...action.historicoUltimoPedido, erro: false, carregando: false } });

const obterHistoricoUltimoPedidoError = (state: HistoricoRecargaState, action: ObterHistoricoUltimoPedidoErrorAction) => (
  { ...state, action, historicoUltimoPedido: { carregando: false, erro: true } });

const atualizarTotalPaginas = (state: HistoricoRecargaState, action: AtualizarTotalPaginasAction) => (
  { ...state, totalPaginas: action.totalPaginas });

const resetarPaginacao = (state: HistoricoRecargaState, action: ResetarPaginacaoAction) => ({
  ...state, action,
});

const limparTabela = (state: HistoricoRecargaState, action: LimparTabelaAction) => (
  { ...state, action, historicoRecargaFiltro: { dados: { recargasFiltradas: [] }, carregando: false, erro: false } });

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.BuscarHistoricoRecargaFiltroRequisicao:
      return buscarHistoricoRecargaFiltroRequest(state, action as BuscarHistoricoRecargaFiltroRequisicaoAction);
    case Types.BuscarHistoricoRecargaFiltroSucesso:
      return buscarHistoricoRecargaFiltroSucesso(state, action as BuscarHistoricoRecargaFiltroSucessoAction);
    case Types.BuscarHistoricoRecargaFiltroError:
      return buscarHistoricoRecargaFiltroError(state, action as BuscarHistoricoRecargaFiltroErrorAction);

    case Types.ObterUltimasRecargaRequisicao:
      return obterUltimasRecargaRequest(state, action as ObterUltimasRecargaRequisicaoAction);
    case Types.ObterUltimasRecargaSucesso:
      return obterUltimasRecargaSucesso(state, action as ObterUltimasRecargaSucessoAction);
    case Types.ObterUltimasRecargaError:
      return obterUltimasRecargaError(state, action as ObterUltimasRecargaErrorAction);
    case Types.ModificarPagina:
      return modificarPagina(state, action as ModificarPaginaAction);
    case Types.ObterHistoricoUltimoPedidoRequisicao:
      return obterHistoricoUltimoPedidoRequest(state, action as ObterHistoricoUltimoPedidoRequisicaoAction);
    case Types.ObterHistoricoUltimoPedidoSucesso:
      return obterHistoricoUltimoPedidoSucesso(state, action as ObterHistoricoUltimoPedidoSucessoAction);
    case Types.ObterHistoricoUltimoPedidoError:
      return obterHistoricoUltimoPedidoError(state, action as ObterHistoricoUltimoPedidoErrorAction);
    case Types.AtualizarTotalPaginas:
      return atualizarTotalPaginas(state, action as AtualizarTotalPaginasAction);
    case Types.ResetarPaginacao:
      return resetarPaginacao(state, action as ResetarPaginacaoAction);
    case Types.LimparTabela:
      return limparTabela(state, action as LimparTabelaAction);
    default:
      return state;
  }
};
