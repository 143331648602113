import { createStyles, makeStyles } from '@material-ui/core';

const modalRecargaPixStyle = makeStyles(() => createStyles({
  ModalRecargaPix: {
    backgroundColor: '#FFFFFF',
    height: '700px',
    width: '480px',
    padding: '16px',
    borderRadius: '12px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '24',
    overflow: 'hidden',
  },
  ModalRecargaPixTitulos:
  {
    '& .MuiTypography-h6': {
      color: '#676767',
      fontWeight: 700,
      lineHeight: '21.6px',
      padding: '12px',
    },
  },
  ModalPixDescricoes:
  {
    '& .MuiTypography-body1': {
      color: '#707070',
      lineHeight: '16px',
      padding: '0px 12px 0px 16px',
    },
  },
  ModalPixBoxQrCode: {
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 0px 12px 0px',
  },
  ModalPixBoxCodigo: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '22px',
    padding: '36px 12px',
    '& .MuiTypography-h6': {
      fontSize: '14px',
    },
  },
  ModalPixBoxBotao: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '30px',
    paddingTop: '30px',
  },
  BotaoCopiarCodigoPix: {
    color: '#FF6338',
    fontWeight: 700,
    fontSize: '14px',
    fontStyle: 'normal',
    textTransform: 'capitalize',
    height: '40px',
    width: '164px',
    border: '2px solid #FF6338',
    borderRadius: '200px',
  },
}));

export default modalRecargaPixStyle;
