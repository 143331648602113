import JwtDecode from 'jwt-decode';
import { call, take, takeEvery, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import Actions, { Types } from './AutenticacaoActions';

const TOKEN_KEY = 'TOKEN_KEY';

function eventoPertenceAoPortalCliente(evento: { origin: string; data: any; }, janela: any) {
  return evento.origin === janela.config.url_portal_cliente && evento.data.token !== undefined;
}

function canalConectSys() {
  return eventChannel((emit: any) => {
    const janela = (window as any);

    janela.addEventListener('message', (event: { origin: string; data: any; }) => {
      if (!eventoPertenceAoPortalCliente(event, janela)) {
        return;
      }
      emit(event.data.token);
      setToken(event.data.token);
    }, false);

    return () => janela.removeEventListener('message', () => { });
  });
}

function* atribuirClienteId() {
  const channel = yield call(canalConectSys);
  const janela = (window as any);

  janela.parent.postMessage('obterToken', janela.config.url_portal_cliente);

  const encodedToken = yield take(channel);
  const decodedToken: any = JwtDecode(encodedToken);
  sessionStorage.setItem('clienteId', decodedToken.conectsys_cliente_id);
  yield put(Actions.atribuirDadosToken(decodedToken.conectsys_cliente_id, decodedToken.sub, encodedToken));
}

function setToken(token) { sessionStorage.setItem(TOKEN_KEY, token); }

export default function* () {
  yield takeEvery(Types.RequisitarToken, atribuirClienteId);
}
