import { AppStatusEntregaMacroCode } from '../../../common/AppStatusEntregaMacroCode.enum';
import { AppStatusPedido } from '../../../common/AppStatusPedido.enum';

export const retornarStatusStep = (status: number): number => {
  switch (status) {
    case AppStatusPedido.AGUARDANDO_PAGAMENTO: {
      return 0;
    }
    case AppStatusPedido.PAGO: {
      return 1;
    }
    case AppStatusPedido.CANCELADO: {
      return 1;
    }
    case AppStatusPedido.EM_TRANSPORTE: {
      return 2;
    }
    case AppStatusPedido.PEDIDO_ENTREGUE: {
      return 3;
    }
    default: {
      return 0;
    }
  }
};

export const retornarStatusEntrega = (status: AppStatusEntregaMacroCode): string => {
  switch (status) {
    case AppStatusEntregaMacroCode.ENTREGUE: {
      return 'Pedido entregue';
    }
    case AppStatusEntregaMacroCode.FALHA_NA_ENTREGA: {
      return 'Falha na entrega';
    }
    case AppStatusEntregaMacroCode.SAIU_PARA_ENTREGA: {
      return 'Saiu para entrega';
    }
    case AppStatusEntregaMacroCode.EM_TRANSITO: {
      return 'Em trânsito';
    }
    case AppStatusEntregaMacroCode.DESPACHADO: {
      return 'Despachado';
    }
    case AppStatusEntregaMacroCode.CANCELADO: {
      return 'Cancelado';
    }
    default: {
      return '-';
    }
  }
};
