import { createStyles, makeStyles } from '@material-ui/core';

const ResumoRecargaStyles = makeStyles(() => createStyles({
  cardResumo: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px',
    gap: '24px',
    background: '#FFFFFF',
    border: '1px solid #EDEDED',
    borderRadius: '12px',
  },
  tituloResumo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '16px',
    background: '#FFFFFF',
    borderRadius: '12px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.015em',
    color: '#2A3037',
  },
  boxValorRecarga: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    padding: '24px 40px',
    gap: '36px',
    background: '#FFFFFF',
    width: '100%',
    borderTop: '1px solid #EDEDED',
  },
  valorRecarga: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#4D5761',
  },
  valorRecargaNumero: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '38px',
    textAlign: 'center',
    color: '#3B5CB0',
  },
  boxContainerDetalhamento: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 0px 0px',
    gap: '12px',
    borderTop: '1px solid #EDEDED',
  },
  boxDetalhamento: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 0px',
    gap: '8px',
    width: '100%',
  },
  boxDetalhamentoInterno: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: '0px',
    gap: '24px',
    justifyContent: 'space-between',
    width: '100%',
  },
  textoDetalhamento: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#4D5761',
  },
  numeroDetalhamento: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'right',
    color: '#2A3037',
  },
  numeroDetalhamentoNegativo: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'right',
    color: '#B52626',
  },
  numeroDetalhamentoPositivo: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'right',
    letterSpacing: '-0.015em',
    color: '#298F7A',
  },
  descricaoTaxaDetalhamento: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#4D5761',
  },
  botaoAtivo: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.01em',
    color: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    gap: '10px',
    borderRadius: '100px',
    border: '0',
    width: '95%',
  },
}));

export default ResumoRecargaStyles;
