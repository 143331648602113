import { all } from 'redux-saga/effects';
import AutenticacaoSagas from './autenticacao/AutenticacaoSagas';
import CadastroSagas from './cadastro/CadastroSagas';
import DadosContaSagas from './dados-conta/DadosContaSagas';
import ExtratoSagas from './extrato/ExtratoSagas';
import FaturaSagas from './fatura-pos-pago/FaturaSagas';
import HomeSagas from './home/HomeSagas';
import HistoricoRecargaSagas from './historico-recarga/HistoricoRecargaSagas';
import RecargaSagas from './recarga/RecargaSagas';
import MeusPedidosSagas from './solicitacao-adesivo/meus-pedidos/MeusPedidosSagas';
import SubstituicaoAdesivoSagas from './substituicao-adesivo/SubstituicaoAdesivoSagas';

export default function* () {
  yield all([
    AutenticacaoSagas(),
    CadastroSagas(),
    DadosContaSagas(),
    ExtratoSagas(),
    HomeSagas(),
    FaturaSagas(),
    HistoricoRecargaSagas(),
    RecargaSagas(),
    MeusPedidosSagas(),
    SubstituicaoAdesivoSagas(),
  ]);
}
