import axios from 'axios';


export default {

  obterDadosCliente: async (
    cnpj: string,
  ): Promise<any> => {
    try {
      const planoApiUrl = `${(window as any).config.url_api_management}`;
      const url = new URL(
        `${planoApiUrl}cadastro/cadastro-cliente-backend-v3/api/v3/PersonClient`,
      );
      const { searchParams } = url;
      searchParams.set('document', cnpj);
      url.search = searchParams.toString();

      const newUrl = url.toString();
      const subscriptionId = (window as any).config.pedido_api_key;

      return await axios.get<any>(newUrl, {
        headers: {
          'Ocp-Apim-Subscription-Key': `${subscriptionId}`,
        },
      });
    } catch (error) {
      return null;
    }
  },
};
