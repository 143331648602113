export const pegarDataHoraAtual = () => {
  const dataObj = new Date();
  const dia = dataObj.getDate();
  const mes = dataObj.getMonth() + 1;
  const ano = dataObj.getFullYear();

  const hora = dataObj.getHours();
  const minutos = dataObj.getMinutes();

  const dataHoraFormatada = `${dia}/${mes}/${ano} - ${hora}:${minutos}`;

  return dataHoraFormatada;
};

export const formatarData = (data: Date) => new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
}).format(new Date(data));
