import {
  Icon,
  makeStyles, Step, StepConnector, StepIconProps, StepLabel, Stepper, Theme, withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import './HistoricoUltimoPedido.scss';
import { useDispatch } from 'react-redux';
import { RetornoHistoricoUltimoPedido } from '../HistoricoRecargaInterfaces';
import { AppStatusRecarga } from '../../../common/AppStatusRecarga.enum';

const IconValor = require('../../../assets/images/icones/valorPedido.png');
const IconPedido = require('../../../assets/images/icones/icon_pedido.png');
const IconFormaPagamento = require('../../../assets/images/icones/icon_forma_pagamento.png');

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(255 99 57) 0%,rgb(255 99 57) 50%,rgb(255 99 57) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(255 99 57) 0%,rgb(255 99 57) 50%,rgb(255 99 57) 100%)',
    },
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 95deg,rgb(255 99 57) 0%,rgb(255 99 57) 50%,rgb(255 99 57) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 95deg,rgb(255 99 57) 0%,rgb(255 99 57) 50%,rgb(255 99 57) 100%)',
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <CheckCircleIcon fontSize='large' />,
    2: <AccountBalanceIcon fontSize='large' />,
    3: <LocalAtmIcon fontSize='large' />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}


const retornarStatusStep = (status: string): number => {
  switch (status) {
    case 'AguardandoPagamento': {
      return 1;
    }
    case 'Pago': {
      return 2;
    }
    case AppStatusRecarga.RECARGA_NAO_REALIZADA: {
      return 2;
    }
    default: {
      return 0;
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  step_label_root: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.1rem',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '17px',
      fontColor: 'F0F',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.9em',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '17px',
      fontColor: 'F0F',
    },
  },
  iconValor: {
    backgroundImage: `url(${IconValor})`,
    backgroundRepeat: 'no-repeat',
  },
  iconPedido: {
    backgroundImage: `url(${IconPedido})`,
    backgroundRepeat: 'no-repeat',
  },
  iconFormaPagamento: {
    backgroundImage: `url(${IconFormaPagamento})`,
    backgroundRepeat: 'no-repeat',
  },
}));

interface Props {
  historicoUltimoPedido: RetornoHistoricoUltimoPedido;
}
const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
const locale = 'pt-BR';
const HistoricoUltimoPedido = ({ historicoUltimoPedido }: Props) => {
  const dispatch = useDispatch();
  const steps = [
    `Pedido ${historicoUltimoPedido?.dados?.valores?.dados?.dataRecarga !== undefined ? new Date(historicoUltimoPedido?.dados?.valores?.dados?.dataRecarga).toLocaleDateString(locale) : ''}`,
    'Aguardando Pagamento', 'Creditado'];

  const [activeStep, setActiveStep] = React.useState(1);
  const classes = useStyles();

  const montarUrlBoleto = (url: string, hash: string): string => `${url}?DC=${hash} `;

  const visualizarBoleto = (descricaoBoleto: string): boolean => {
    if (descricaoBoleto.includes('Boleto')) {
      if (descricaoBoleto.includes('Boleto Rápido')) return false;
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (historicoUltimoPedido?.dados?.valores?.dados) {
      const statusStep = retornarStatusStep(historicoUltimoPedido?.dados?.valores?.dados?.statusPagamento);
      setActiveStep(statusStep);
    } else {
      setActiveStep(0);
    }
  }, [dispatch, historicoUltimoPedido?.dados?.valores?.dados]);

  return (
    <section className='historico-page-ultimo-pedido'>
      <section className='ultimo-pedido-container'>
        {!historicoUltimoPedido?.carregando && historicoUltimoPedido?.dados?.valores?.dados && (
          <div className='container-detalhes-ultimo-pedido'>
            <div className='inf-detalhe-ultimo-pedido'>
              <div className='icon-informacao'>
                <Icon className={classes.iconPedido} />
              </div>
              <div>
                <p className='label-infomacao-superior'>Nº Pedido</p>
                <p className='label-infomacao-inferior'>{historicoUltimoPedido?.dados?.valores?.dados?.pedidoId}</p>
              </div>
            </div>
            <div className='inf-detalhe-ultimo-pedido'>
              <div className='icon-informacao'>
                <Icon className={classes.iconValor} />
              </div>
              <div>
                <p className='label-infomacao-superior'>Valor</p>
                <p className='label-infomacao-inferior'>{historicoUltimoPedido?.dados?.valores?.dados?.valor.toLocaleString('pt-BR', formato)}</p>
              </div>
            </div>
            <div className='inf-detalhe-ultimo-pedido'>
              <div className='icon-informacao'>
                <Icon className={classes.iconFormaPagamento} />
              </div>
              <div>
                <p className='label-infomacao-superior'>Meio de Pagamento</p>
                <p className='label-infomacao-inferior'>{historicoUltimoPedido?.dados?.valores?.dados?.descricao}</p>
              </div>
            </div>
            {historicoUltimoPedido?.dados?.valores?.dados?.boleto && visualizarBoleto(historicoUltimoPedido?.dados?.valores?.dados?.descricao) && (
              <div className='inf-detalhe-ultimo-pedido-boleto'>
                <button
                  type='button'
                  onClick={() => window.open(montarUrlBoleto(historicoUltimoPedido?.dados?.valores?.dados?.boleto?.url, historicoUltimoPedido?.dados?.valores?.dados?.boleto?.informacoesBoleto), '_blank')}
                  className='botao-imprimir-boleto'
                >
                  {' '}
                  Imprimir Boleto
                </button>
              </div>
            )}
          </div>
        )}
        <div className='container-step-ultimo-pedido'>
          <Stepper alternativeLabel style={{ padding: '24px 0px 24px 0px' }} activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel classes={{ label: classes.step_label_root }} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </section>
    </section>
  );
};
export default HistoricoUltimoPedido;
