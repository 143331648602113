import React from 'react';
import SucessoRecargaPage from './SucessoPage';
import ErroRecargaPage from './ErroPage';

interface Props {
    recargaSucesso: boolean;
    valorRecargaDigitado?: number
}

const FinalizaRecargaPage = ({
  recargaSucesso,
  valorRecargaDigitado,
}: Props) => (
  <>
    {recargaSucesso ? (
      <SucessoRecargaPage
        valorRecargaDigitado={valorRecargaDigitado}
      />
    ) : (
      <ErroRecargaPage />
    )}
  </>
);


export default FinalizaRecargaPage;
