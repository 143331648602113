import * as React from 'react';
import DropdownMultiplaSelecao from '../../../components/Inputs/DropdownMultiplaSelecao';
import { DropdownItem } from '../../../components/Inputs/Dropdown/DropdownInterface';

import './ExtratoFiltros.scss';
import RangeDatePicker from '../../../components/RangeDatePicker';

interface Props {
  dataInicio: Date,
  dataFim: Date,
  onPeriodoClose: Function,
  placas: DropdownItem[],
  placasSelecionadas: DropdownItem[],
  onPlacasChange: Function,
  centrosCusto: DropdownItem[],
  centrosCustoSelecionados: DropdownItem[],
  onCentrosCustoChange: Function,
  tiposTransacao: DropdownItem[],
  tiposTransacaoSelecionados: DropdownItem[],
  onTiposTransacaoChange: Function,
  onLimparFiltrosClick: Function,
  onExportarClick: Function,
  onExportarPdfClick: Function,
  onPesquisarClick: Function,
}

const ExtratoFiltros = ({
  dataInicio,
  dataFim,
  onPeriodoClose,
  placas,
  placasSelecionadas,
  onPlacasChange,
  centrosCusto,
  centrosCustoSelecionados,
  onCentrosCustoChange,
  tiposTransacao,
  tiposTransacaoSelecionados,
  onTiposTransacaoChange,
  onLimparFiltrosClick,
  onExportarClick,
  onExportarPdfClick,
  onPesquisarClick,
}: Props) => (
  <section className='filtros-container extrato-page'>
    <section id='extrato-filtros' className='borda'>
      <DropdownMultiplaSelecao
        titulo='Placas'
        itens={placas}
        filtrados={placasSelecionadas}
        onFiltrarClick={onPlacasChange}
        identificador='Placas'
        btnID='Placas'
      />
      <RangeDatePicker
        dataInicio={dataInicio}
        dataFim={dataFim}
        onClose={onPeriodoClose}
      />
      <DropdownMultiplaSelecao
        titulo='Descrição'
        itens={tiposTransacao}
        filtrados={tiposTransacaoSelecionados}
        onFiltrarClick={onTiposTransacaoChange}
        identificador='Descricao'
        btnID='Descricao'
      />
      <DropdownMultiplaSelecao
        titulo='Centro de custo'
        itens={centrosCusto}
        filtrados={centrosCustoSelecionados}
        onFiltrarClick={onCentrosCustoChange}
        identificador='CentroDeCusto'
        btnID='CentroDeCusto'
      />
      <button
        id='btnLimparFiltrosExtrato'
        className='limpar-filtros botao-vazio'
        type='button'
        onClick={() => onLimparFiltrosClick()}
      >
        Limpar filtros
      </button>
      <button
        type='button'
        className='botao-exportar'
        onClick={() => onPesquisarClick()}
        id='btnPesquisarExtrato'
      >
        Pesquisar
      </button>
    </section>
    <section id='exportacao'>
      <button
        type='button'
        className='botao-exportar-xls'
        onClick={() => onExportarClick()}
        id='btnExportarExtratoXls'
      />
      <button
        type='button'
        className='botao-exportar-pdf'
        onClick={() => onExportarPdfClick()}
        id='btnExportarExtratoPdf'
      />
    </section>
  </section>


);

export default ExtratoFiltros;
