import { PlanosPjApi } from '../../infrastructure/prospecto-api';
import ClienteApi from '../../infrastructure/prospecto-api/cliente';

export default {

  obterPlanoPorCanalVendasId: async (canalDeVendasId: number | string) => {
    try {
      const data = await PlanosPjApi.obterPlanoPadrao(
        canalDeVendasId,
      );
      return data.data.dados;
    } catch (error) {

    }
    return null;
  },

  obterPlanoPorCanalVendasIdEPlanoId: async (canalDeVendasId: number | string, planoId: number | string) => {
    try {
      const data = await PlanosPjApi.obterParametrizacaoPorCanalVendasEPlano(
        canalDeVendasId,
        planoId,
      );

      return data.data.dados;
    } catch (error) {

    }
    return null;
  },

  obterDadosCliente: async (cnpj: string) => {
    try {
      const resp = await ClienteApi.obterDadosCliente(
        cnpj,
      );
      return resp;
    } catch (error) {

    }
    return null;
  },
  obterGratuidade: async (token: string) => {
    try {
      return await PlanosPjApi.obterGratuidade(token);
    } catch (error) {
    }
    return null;
  },
};
