import { createStyles, makeStyles } from '@material-ui/core';

const RecargaInsucessoStyles = makeStyles(() => createStyles({
  ModalInsucesso: {
    backgroundColor: '#FFFFFF',
    height: '216px',
    width: '343px',
    padding: '16px',
    borderRadius: '12px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #FFFFFF',
    boxShadow: '24',
    overflow: 'hidden',
  },
  ModalInsucessoTitulo: {
    alignSelf: 'stretch',
    color: '#FF6338',
    fontSize: '20px !important',
    fontWeight: 'bold',
    height: '48px',
    lineHeight: '24px',
  },
  ModalInsucessoDescricao: {
    color: '#2A3037',
    fontSize: '16px !important',
    fontStyle: 'normal',
    fontWeight: 400,
    height: '60px',
    lineHeight: '20px',
    marginTop: '8px',
  },
  ModalInsucessoBoxBotao: {
    display: 'flex',
    height: '44px',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '16px',
  },
  ModalInsucessoBotao: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: 700,
    width: '140px',
  },
  InsucessoVoltar: {
    backgroundColor: 'white',
    color: '#FF6338',
    border: '2px solid #FF6338',
  },
  InsucessoTentarNovamente: {
    backgroundColor: '#FF6338',
    color: 'white',
  },
}));

export default RecargaInsucessoStyles;
