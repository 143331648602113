import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { SplitFactory } from '@splitsoftware/splitio';
import { SplitFactory, SplitTreatments } from '@splitsoftware/splitio-react';
import CadastroCabecalho from '../CadastroCabecalho';
import CadastroResumo from '../CadastroResumo';
import Actions from '../CadastroActions';
import { Etapas, CadastroState, EtapasRotas, StatusCadastro } from '../CadastroInterfaces';
import CampoSenha from '../../../components/Inputs/CampoSenha';
import './CadastroSenhaPage.scss';
import { State } from '../../interfaces';
import Checkbox from '../../../components/Inputs/Checkbox';
import CadastroErroServidor from '../CadastroErroServidor';

export default () => {
  const { senha, concordaTermosAdesao, plano: { possuiAdesivos }, statusCadastro } = useSelector<State, CadastroState>(
    state => state.cadastro,
  );
  const etapaAtual = useSelector<State, Etapas>(
    state => state.cadastro.etapaAtual,
  );
  const [senhaValida, setSenhaValida] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (etapaAtual < Etapas.Senha) history.push(EtapasRotas.get(Etapas.Plano)!);
    document.title = 'Senha - Cadastro - ConectCar';
    return () => {
      dispatch(Actions.atribuirSenha(''));
      dispatch(Actions.atribuirConcordaTermosAdesao(false));
      dispatch(Actions.atribuirAceitaReceberOfertas(false));
      dispatch(Actions.atribuirCompartilharDados(false));
      dispatch(Actions.atribuirCompartilharDadosItau(false));
    };
  }, [dispatch, etapaAtual, history]);

  React.useEffect(() => {
    if (etapaAtual === Etapas.Concluido) history.push(EtapasRotas.get(Etapas.Concluido)!);
  }, [etapaAtual, history]);

  React.useEffect(() => {
    if (statusCadastro === StatusCadastro.Reprovado) history.push('/cadastro/cnpj-invalido');
  }, [history, statusCadastro]);

  const podePassarParaProximoPasso = () => senhaValida && concordaTermosAdesao;

  const handleSenhaValidaChange = valida => setSenhaValida(valida);

  const handleSenhaChange = senhaAtualizada => dispatch(Actions.atribuirSenha(senhaAtualizada));

  const handleTermosAdesaoCheckChange = atribuirConcordaTermos => dispatch(Actions.atribuirConcordaTermosAdesao(atribuirConcordaTermos));

  const handleReceberOfertasCheckChange = atribuirAceitaReceber => dispatch(Actions.atribuirAceitaReceberOfertas(atribuirAceitaReceber));

  const handleCompartilharDadosCheckChange = compartilharDados => dispatch(Actions.atribuirCompartilharDados(compartilharDados));

  const handleCompartilharDadosItauCheckChange = compartilharDadosItau => dispatch(Actions.atribuirCompartilharDadosItau(compartilharDadosItau));

  const textoAceiteItau = () => (window as any).config.txt_opt_in_aceite_itau;

  const configSplitIO: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: (window as any).config.split_io_authorization_key,
      key: 'key',
    },
    scheduler: {
      featuresRefreshRate: 15,
      segmentsRefreshRate: 15,
      metricsRefreshRate: 15,
      impressionsRefreshRate: 15,
    },
  };
  const handleButtonClick = () => {
    if (possuiAdesivos) dispatch(Actions.enviarProspecto());
    else dispatch(Actions.enviarPedido());
  };

  return (
    <section id='cadastro-senha-page' className='cadastro-page'>
      <section className='conteudo'>
        <CadastroCabecalho />
        <CadastroResumo />

        <section className='formulario'>
          <CadastroErroServidor />
          <h1>Crie uma senha.</h1>

          <CampoSenha
            name='senha'
            titulo='Digite uma senha'
            value={senha}
            onSenhaValidaChange={handleSenhaValidaChange}
            onChange={handleSenhaChange}
          />

          <Checkbox
            descricao='Compreendo e concordo com o {termoAdesao} e {politicaPrivacidade} da ConectCar (obrigatório)'
            links={[
              {
                chave: 'termoAdesao',
                texto: 'Termo de Adesão',
                url:
                  'https://23595722.fs1.hubspotusercontent-na1.net/hubfs/23595722/Termo%20de%20Adesão%20Único_junho2023.pdf',
              },
              {
                chave: 'politicaPrivacidade',
                texto: 'Política de Privacidade',
                url:
                  'https://conectcar.com/politica-privacidade/',
              },
            ]}
            onCheckChange={handleTermosAdesaoCheckChange}
          />

          <Checkbox
            descricao='Concordo em receber comunicações de ofertas da ConectCar (opcional).'
            onCheckChange={handleReceberOfertasCheckChange}
          />

          <Checkbox
            descricao='Concordo que a ConectCar compartilhe os dados da empresa com Parceiros para recebimento de materiais promocionais, informativos e marketing (opcional)'
            onCheckChange={handleCompartilharDadosCheckChange}
          />

          <SplitFactory config={configSplitIO}>
            <SplitTreatments names={['opt-in-termo-itau']}>
              {({ treatments, isReady }) => {
                if (!isReady) return <div>Loading SDK ...</div>;
                if (treatments['opt-in-termo-itau'].treatment === 'on') {
                  return (
                    <Checkbox
                      descricao={textoAceiteItau()}
                      onCheckChange={handleCompartilharDadosItauCheckChange}
                    />
                  );
                }
                return <div />;
              }}
            </SplitTreatments>
          </SplitFactory>

          <button
            data-testid='botao-cadastro-senha'
            type='button'
            className='botao-preenchido-azul'
            title='Próximo passo'
            disabled={!podePassarParaProximoPasso()}
            onClick={handleButtonClick}
          >
            Finalizar Cadastro
          </button>
        </section>
      </section>
    </section>
  );
};
