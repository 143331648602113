import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import SelectRecargaStyles from './SelectRecargaStyles';

interface Props {
  idSelect: string;
  valoresRecarga: any;
  setValorSelect: any;
  possuiLimitacaoValor: boolean;
  limitacaoValor?: number;
}

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const SelectRecarga = ({ idSelect, limitacaoValor, possuiLimitacaoValor, valoresRecarga, setValorSelect }: Props) => {
  const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

  const style = SelectRecargaStyles();

  const mapValoresSelect = (valores: any) => valores.map(item => (possuiLimitacaoValor ? (item.valorCredito <= limitacaoValor) : true)
    && (
      <MenuItem
        // data-testid='option-select-recarga'
        id={item.id}
        key={item.id}
        value={item.valorCredito}
      >
        {item.valorCredito.toLocaleString('pt-BR', formato)}
      </MenuItem>
    ));

  return (
    <div>
      <Select
        data-testid={idSelect}
        id={idSelect}
        className={style.SelectStyle}
        onChange={setValorSelect}
        defaultValue={0}
        variant='outlined'
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value={0}>
          Selecione um valor
        </MenuItem>
        {
          valoresRecarga && mapValoresSelect(valoresRecarga)
        }
      </Select>
    </div>
  );
};

export default SelectRecarga;
