import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  inputValorDefault: {
    border: '0.063rem solid',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    height: '1.25rem',
    width: '3.125rem',
  },
}));

export default useStyles;
