import axios from 'axios';
import { RespostaApi } from '../ApiInterfaces';
import { RecargasFiltradas, ParametrosFiltrosHistoricoRecarga, RetornoHistoricoUltimoPedido } from '../../features/historico-recarga/HistoricoRecargaInterfaces';

const obterParametrosFormatadosHistoricoRecargaFiltro = (parametros: ParametrosFiltrosHistoricoRecarga) => {
  let parametrosFormatados = `dataInicio=${parametros.filtrosSelecionados.dataInicio.toLocaleString()}`;
  parametrosFormatados += `&dataFim=${parametros.filtrosSelecionados.dataFim.toLocaleString()}`;
  parametrosFormatados += `&status=${parametros.filtrosSelecionados.status}`;
  parametrosFormatados += `&paginaSelecionada=${parametros.paginaSelecionada}`;
  parametrosFormatados += `&registrosPorPagina=${parametros.registrosPorPagina}`;

  return parametrosFormatados;
};

const obterParametrosFormatadosUltimasRecarga = (parametros: ParametrosFiltrosHistoricoRecarga) => {
  let parametrosFormatados = `paginaSelecionada=${parametros.paginaSelecionada}`;
  parametrosFormatados += `&registrosPorPagina=${parametros.registrosPorPagina}`;
  return parametrosFormatados;
};

export default {

  buscarHistoricoRecargaFiltro: (paramentros: ParametrosFiltrosHistoricoRecarga, token: string)
    : Promise<RespostaApi<RecargasFiltradas[]>> => {
    const parametrosFormatados = obterParametrosFormatadosHistoricoRecargaFiltro(paramentros);

    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/HistoricoRecarga/FiltroHistoricoRecarga?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  obterUltimasRecarga: (paramentros: ParametrosFiltrosHistoricoRecarga, token: string)
    : Promise<RespostaApi<RecargasFiltradas[]>> => {
    const parametrosFormatados = obterParametrosFormatadosUltimasRecarga(paramentros);
    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/HistoricoRecarga/ObterHistoricoRecarga?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  obterHistoricoUltimoPedido: (token: string)
    : Promise<RespostaApi<RetornoHistoricoUltimoPedido>> => axios.get(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/ObterUltimaRecarga`,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
    },
  ),
};
