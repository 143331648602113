import React, { memo, useEffect, useState } from 'react';
import { FormControl, FormLabel, Grid, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { IMarcaVeiculo } from '../../../../../../services/requests/SubstituicaoAdesivo/obterMarcasVeiculosPorTipoVeiculo/interface';
import { IModeloVeiculo } from '../../../../../../services/requests/SubstituicaoAdesivo/obterModelosPorMarca/interface';
import { ICategoriaVeiculo } from '../../../../../../services/requests/SubstituicaoAdesivo/obterCategoriasVeiculos/interface';

import { obterMarcasVeiculosPorTipoVeiculo } from '../../../../../../services/requests/SubstituicaoAdesivo/obterMarcasVeiculosPorTipoVeiculo';
import { obterModelosPorMarca } from '../../../../../../services/requests/SubstituicaoAdesivo/obterModelosPorMarca';
import { obterCategoriasVeiculos } from '../../../../../../services/requests/SubstituicaoAdesivo/obterCategoriasVeiculos';
import { SubstituicaoAutocomplete, SubstituicaoDivider, SubstituicaoMaskInput } from '../../../../shared/SubstituicaoComponentes';
import { SubstituicaoAdesivoState } from '../../../../SubstituicaoAdesivoInterfaces';
import { State } from '../../../../../interfaces';
import Actions from '../../../../SubstituicaoAdesivoActions';
import { MensagemErroPadrao } from '../../../../../../components/ModalErro/ModalErroConstantes';

import './SubstituicaoAdesivoFormularioTrocaVeiculo.scss';
import './SubstituicaoAdesivoFormularioTrocaVeiculoResponsive.scss';

const SubstituicaoAdesivoFormularioTrocaVeiculo: React.FC = () => {
  const {
    dadosSubstituicao,
  } = useSelector<State, SubstituicaoAdesivoState>(state => ({
    dadosSubstituicao: state.substituicaoAdesivo.dadosSubstituicao,
  }));

  const [categoriasVeiculos, setCategoriasVeiculos] = useState<ICategoriaVeiculo[]>([]);
  const [marcasVeiculos, setMarcasVeiculos] = useState<IMarcaVeiculo[]>([]);
  const [modelosVeiculos, setModelosVeiculos] = useState<IModeloVeiculo[]>([]);
  const [autoCompleteKey, setAutocompleteKey] = useState<string>('');

  const [tipoVeiculoId, setTipoVeiculoid] = useState<number>(null);

  const dispatch = useDispatch();

  const handleAlterarDadoSubstituicao = (prop: string, valor: any) => {
    const dados = dadosSubstituicao.veiculo[prop];
    dados.valor = valor;

    dispatch(Actions.atualizarDadosSubstituicaoVeiculo(dados, prop));
  };

  const handleLimparSelects = () => {
    setAutocompleteKey(Date.now().toLocaleString());
    setMarcasVeiculos([]);
    setModelosVeiculos([]);
  };

  useEffect(() => {
    const obterCategoriasVeiculosAsync = async () => {
      await obterCategoriasVeiculos()
        .then(response => setCategoriasVeiculos(response?.data?.dados?.categoriasVeiculos))
        .catch(error => dispatch(Actions.atribuirMensagemAbrirModalErro(error?.response?.data?.notificacoes || MensagemErroPadrao)));
    };

    obterCategoriasVeiculosAsync();
  }, [dispatch]);

  useEffect(() => {
    const state = dadosSubstituicao.veiculo.placa;

    if (state.valor !== '' && state.valor.length === 7) state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicaoVeiculo(state, 'placa'));
  }, [dadosSubstituicao.veiculo.placa, dadosSubstituicao.veiculo.placa.valor, dispatch]);

  useEffect(() => {
    const state = dadosSubstituicao.veiculo.ano;

    if (state.valor !== '' && state.valor.length === 4) state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicaoVeiculo(state, 'ano'));
  }, [dadosSubstituicao.veiculo.ano, dadosSubstituicao.veiculo.ano.valor, dispatch]);

  useEffect(() => {
    const state = dadosSubstituicao.veiculo.categoriaId;

    if (state.valor) state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicaoVeiculo(state, 'categoriaId'));
  }, [dadosSubstituicao.veiculo.categoriaId, dadosSubstituicao.veiculo.categoriaId.valor, dispatch]);

  useEffect(() => {
    const state = dadosSubstituicao.veiculo.marca;

    if (state.valor) state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicaoVeiculo(state, 'marca'));
  }, [dadosSubstituicao.veiculo.marca, dadosSubstituicao.veiculo.marca.valor, dispatch]);

  useEffect(() => {
    const state = dadosSubstituicao.veiculo.modelo;

    if (state.valor) state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicaoVeiculo(state, 'modelo'));
  }, [dadosSubstituicao.veiculo.modelo, dadosSubstituicao.veiculo.modelo.valor, dispatch]);

  useEffect(() => {
    const obterMarcasVeiculosAsync = async () => {
      await obterMarcasVeiculosPorTipoVeiculo(tipoVeiculoId)
        .then(response => setMarcasVeiculos(response?.data?.dados?.marcasVeiculos))
        .catch((error) => {
          dispatch(Actions.atribuirMensagemAbrirModalErro(error?.response?.data?.notificacoes || MensagemErroPadrao));
          handleLimparSelects();
        });
    };

    if (tipoVeiculoId !== null) obterMarcasVeiculosAsync();
  }, [dispatch, tipoVeiculoId]);

  useEffect(() => {
    const marcaId = dadosSubstituicao.veiculo.marca.valor;

    const obterModelosPorMarcaAsync = async () => {
      await obterModelosPorMarca(marcaId)
        .then(response => setModelosVeiculos(response?.data?.dados?.modelosVeiculos))
        .catch(error => dispatch(Actions.atribuirMensagemAbrirModalErro(error?.response?.data?.notificacoes || MensagemErroPadrao)));
    };

    if (marcaId !== null) obterModelosPorMarcaAsync();
  }, [dadosSubstituicao.veiculo.marca.valor, dispatch]);

  useEffect(() => {
    if (dadosSubstituicao.motivoSubstituicao.valido && dadosSubstituicao.numeroSerieTagNova.valido
      && dadosSubstituicao.numeroSerieTagNovaConfirmacao.valido && dadosSubstituicao.veiculo.ano.valido
      && dadosSubstituicao.veiculo.marca.valido && dadosSubstituicao.veiculo.categoriaId.valido
      && dadosSubstituicao.veiculo.modelo.valido && dadosSubstituicao.veiculo.placa.valido) {
      dispatch(Actions.habilitarBotaoConcluirTroca(true));
    } else {
      dispatch(Actions.habilitarBotaoConcluirTroca(false));
    }
  }, [dadosSubstituicao.motivoSubstituicao.valido, dadosSubstituicao.numeroSerieTagNova.valido,
    dadosSubstituicao.numeroSerieTagNovaConfirmacao.valido, dadosSubstituicao.veiculo.ano.valido,
    dadosSubstituicao.veiculo.categoriaId.valido, dadosSubstituicao.veiculo.marca.valido,
    dadosSubstituicao.veiculo.modelo.valido, dadosSubstituicao.veiculo.placa.valido, dispatch]);

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-padding substituicao-grid-justify-center' style={{ paddingBottom: '20px' }}>
        <SubstituicaoDivider id='substituicao-formulario-divider' />
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-dados-novo-veiculo' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-padding'>
        <Typography className='substituicao-troca-veiculo-dados-novo-veiculo'>Dados do novo veículo</Typography>
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-categoria' item xs={12} sm={6} md={6} lg={6} xl={6} className='substituicao-grid-flex substituicao-grid-padding'>
        <FormControl className='substituicao-grid-fill-width'>
          <FormLabel className='substituicao-formulario-label'>Categoria</FormLabel>
          <SubstituicaoAutocomplete
            parameters={{
              className: 'substituicao-formulario-autocomplete',
              id: 'substituicao-formulario-autocomplete-categoria',
              getOptionLabel: (option: ICategoriaVeiculo) => option.nome,
              onChange: (_e, n: ICategoriaVeiculo) => {
                handleAlterarDadoSubstituicao('categoriaId', n.categoriaVeiculoId);
                setAutocompleteKey(Date.now().toLocaleString());
                setTipoVeiculoid(n.tipoVeiculo);
              },
              options: categoriasVeiculos,
              noOptionsText: 'Não há categorias de veículos',
              renderOption: (option: ICategoriaVeiculo) => (
                <React.Fragment>
                  {`${option.codigo} - ${option.nome}`}
                </React.Fragment>
              )
              ,
            }}
            placeholder='Categoria do veículo'
          />
        </FormControl>
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-marca' item xs={12} sm={6} md={6} lg={6} xl={6} className='substituicao-grid-flex substituicao-grid-padding'>
        <FormControl className='substituicao-grid-fill-width'>
          <FormLabel className='substituicao-formulario-label'>Marca</FormLabel>
          <SubstituicaoAutocomplete
            parameters={{
              className: 'substituicao-formulario-autocomplete',
              key: autoCompleteKey,
              getOptionLabel: (option: IMarcaVeiculo) => option.descricao,
              id: 'substituicao-formulario-autocomplete-marca',
              onChange: (_e, n: IMarcaVeiculo) => handleAlterarDadoSubstituicao('marca', n.marcaVeiculoId),
              options: marcasVeiculos,
              noOptionsText: 'Não há marcas de veículos',
              renderOption: (option: IMarcaVeiculo) => (
                <React.Fragment>
                  {option.descricao}
                </React.Fragment>
              ),
            }}
            placeholder='Marca do veículo'
          />
        </FormControl>
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-modelo' item xs={12} sm={6} md={6} lg={6} xl={6} className='substituicao-grid-flex substituicao-grid-padding'>
        <FormControl className='substituicao-grid-fill-width'>
          <FormLabel className='substituicao-formulario-label'>Modelo</FormLabel>
          <SubstituicaoAutocomplete
            parameters={{
              className: 'substituicao-formulario-autocomplete',
              key: autoCompleteKey,
              getOptionLabel: (option: IModeloVeiculo) => option.descricao,
              id: 'substituicao-formulario-autocomplete-modelo',
              onChange: (_e, n: IModeloVeiculo) => handleAlterarDadoSubstituicao('modelo', n.modeloVeiculoId),
              options: modelosVeiculos,
              noOptionsText: 'Não há modelos de veículos',
              renderOption: (option: IModeloVeiculo) => (
                <React.Fragment>
                  {option.descricao}
                </React.Fragment>
              ),
            }}
            placeholder='Modelo do veículo'
          />
        </FormControl>
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-ano' item xs={12} sm={6} md={6} lg={6} xl={6} className='substituicao-grid-flex substituicao-grid-padding'>
        <FormControl className='substituicao-grid-fill-width'>
          <FormLabel className='substituicao-formulario-label'>Ano</FormLabel>
          <SubstituicaoMaskInput
            className='substituicao-formulario-input'
            id='substituicao-troca-veiculo-veiculo-ano'
            mask='9999'
            placeholder='Ano do veículo (4 dígitos)'
            autoComplete='off'
            onChange={(e: any) => handleAlterarDadoSubstituicao('ano', e.target.value)}
            value={dadosSubstituicao.veiculo.ano.valor}
          />
        </FormControl>
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-titulo-placa' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-justify-center substituicao-grid-padding'>
        <Typography className='substituicao-formulario-label substituicao-troca-veiculo-dados-novo-veiculo'>Insira a placa do veículo</Typography>
      </Grid>
      <Grid id='substituicao-troca-veiculo-grid-item-placa' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-justify-center substituicao-grid-padding'>
        <FormControl className='substituicao-grid-fill-width'>
          <SubstituicaoMaskInput
            className='substituicao-formulario-input substituicao-grid-justify-center'
            id='substituicao-troca-veiculo-placa'
            mask='aaa9*99'
            placeholder='Placa'
            autoComplete='off'
            onChange={(e: any) => handleAlterarDadoSubstituicao('placa', e.target.value?.toLocaleUpperCase())}
            value={dadosSubstituicao.veiculo.placa.valor}
          />
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};

export default memo(SubstituicaoAdesivoFormularioTrocaVeiculo);
