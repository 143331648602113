import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvioRecargaRequest, FormaPagamentoOnline, RecargaState } from '../../../RecargaInterfaces';
import MetodosPagamentoStyles from '../MetodosPagamentoStyles';
import Actions from '../../../RecargaActions';
import { State } from '../../../../interfaces';
import SelectRecarga from '../../../Components/SelectValorRecarga/SelectRecarga';
import BoxPagamentoControlado from '../../../../../components/BoxPagamentoControlado';
import CarregarRetornoShopline from '../../../Acoes/Shopline/CarregarRetornoShopline';

const BoxBoletoItau = () => {
  const {
    dadosRecargaBoletoItau,
    documento,
    adesaoId,
    servicos,
    boxPagamento,
  } = useSelector<State, RecargaState>(state => ({
    dadosRecargaBoletoItau: state.recarga.dadosRecargaBoletoItau,
    documento: state.recarga.documento,
    adesaoId: state.recarga.adesaoId,
    servicos: state.recarga.servicos,
    boxPagamento: state.recarga.boxPagamento,
  }));

  const dispatch = useDispatch();
  const metodosPagamentoStyles = MetodosPagamentoStyles();

  const [valorBoletoItau, setValorBoletoItau] = React.useState(0);
  const [servicoRecargaId, setServicoRecargaId] = React.useState(0);

  const setBoxItauOpen = () => {
    dispatch(Actions.atualizarStateAccordion('itau', !(boxPagamento.itau)));
  };

  const handlerValorBoletoItauChange = (event) => {
    setValorBoletoItau(event.target.value);
    setServicoRecargaId(event.currentTarget.id);
  };

  const configurarSelectBoletoItau = () => (
    <SelectRecarga
      idSelect='Recarga_Input_Valor_BoletoItau'
      possuiLimitacaoValor={false}
      setValorSelect={handlerValorBoletoItauChange}
      valoresRecarga={servicos}
    />
  );

  const handlePagamentoBoletoItau = () => {
    const invalido = valorBoletoItau === 0 || servicoRecargaId === 0;

    if (!invalido) {
      const novaRecarga: EnvioRecargaRequest = {
        AdesaoId: adesaoId,
        FormaPagamentoOnline: FormaPagamentoOnline.ItauBoleto,
        Documento: documento,
        ServicoRecargaId: servicoRecargaId,
        ValorDeCredito: valorBoletoItau,
      };

      dispatch(Actions.carregarParametrosRecargaRequest(novaRecarga));
      dispatch(Actions.cadastrarRecarga());
    } else {
      dispatch(Actions.atualizarAvisoRecarga('erro', 'Favor selecionar um valor válido para o Boleto Itaú.', true));
    }
  };

  return (
    <section id='section-boleto-itau'>
      <div id='boleto-itau-pagamento'>
        <BoxPagamentoControlado
          botaoValorTexto='Gerar boleto'
          descricaoTitulo='Crédito em até 3 dias úteis no seu saldo'
          id='Recarga_Rb_BoletoItau'
          btnID='Recarga_Bt_BoletoItau'
          inputValor={configurarSelectBoletoItau()}
          labelValor='Valor'
          logotipo={`${metodosPagamentoStyles.logotiposBaseCss} ${metodosPagamentoStyles.boletoLogotipo}`}
          onButtonClick={handlePagamentoBoletoItau}
          titulo='Boleto Bancário'
          boxOpen={boxPagamento?.itau}
          setBoxOpen={setBoxItauOpen}
        />

      </div>
      <div id='boleto-itau-pagamento-complementos'>
        {dadosRecargaBoletoItau?.dados != null
          && (
            <CarregarRetornoShopline
              action={dadosRecargaBoletoItau?.dados?.valores?.dadosRecarga?.url}
              dadosCriptografados={dadosRecargaBoletoItau?.dados?.valores?.dadosRecarga?.dadosCriptografados}
              dispatchAction={Actions.descarregarBoletoItau}
            />
          )
        }
      </div>
    </section>
  );
};

export default BoxBoletoItau;
