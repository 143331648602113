import { removerCaracteresNaoNumericos } from '../../mascara';

export const parsearFaturamento = (faturamento) => {
  const faturamentoFormatado = faturamento.replace('R$ ', '').replace(/\./g, '').replace(',', '.');
  return parseFloat(faturamentoFormatado);
};

export const mapearTelefone = (telefone: string): { ddd: string, numero: string } => {
  const telefoneSemMascara = removerCaracteresNaoNumericos(telefone);
  if (!telefoneSemMascara) return { ddd: '', numero: '' };
  return { ddd: telefoneSemMascara.substring(0, 2), numero: telefoneSemMascara.substring(2) };
};
