import axios from 'axios';
import { RespostaApi } from '../../ApiInterfaces';

const planoPadraoEndpoint = 'cadastro/prospeccaocliente/api/v1/PlanosPj/ObterPadrao';
const planoByIdEndpoint = 'landingpage-bff/api/v1/Prospeccao/ObterPlanoPorId';
const parametrizacaoPorCanalVendasEPlano = 'cadastro/prospeccaocliente/api/v1/PlanosPj/ObterPorCanalVendasPlano';
const obterGratuidade = 'portalpj/v1/Prospeccao/obterDataValidadePromocao';

export interface ParametrizacaoPlanoPj {
  id: string
  canalVendasId: number
  canalVendasNome: string
  codigoPlano: string
  planoId: number
  taxaMensalidadeId: number
  mesesIsencaoMensalidade: number
  percentualIsencaoMensalidade: number
  dataInicioVigencia: string
  urlTermoAdesao: any
  padrao: boolean
  valorAdesao: number
  mensalidadeVeiculoLeve: number
  mensalidadeVeiculoPesado: number
  titulo: string
  qtdLimitePedido: number
  imagem: any
}

export default {
  obterPlanoPadrao: async (id: number | string): Promise<RespostaApi<ParametrizacaoPlanoPj>> => {
    const canalVendasPadrao = id;
    const planoApiUrl = `${(window as any).config.url_api_management}${planoPadraoEndpoint}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const resposta = await axios.get(`${planoApiUrl}?canalVendasId=${canalVendasPadrao}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
    });

    return resposta;
  },
  obterPlanoPorCanalVendasId: async (canalVendasId: string): Promise<RespostaApi<ParametrizacaoPlanoPj>> => {
    const planoApiUrl = `${(window as any).config.url_api_management}${planoByIdEndpoint}/${canalVendasId}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const resposta = await axios.get(planoApiUrl, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
    });
    return resposta;
  },
  obterParametrizacaoPorCanalVendasEPlano: async (canalVendasId: number | string, planoId: number | string): Promise<RespostaApi<ParametrizacaoPlanoPj>> => {
    const planoApiUrl = `${(window as any).config.url_api_management}${parametrizacaoPorCanalVendasEPlano}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    const resposta = await axios.get(`${planoApiUrl}?canalVendasId=${canalVendasId}&planoId=${planoId}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}` },
    });
    return resposta;
  },
  obterGratuidade: async (token: string): Promise<RespostaApi<string>> => {
    const gratuidadeUrl = `${(window as any).config.url_api_management}${obterGratuidade}`;
    const subscriptionId = (window as any).config.subscription_api_management;

    return axios.get(`${gratuidadeUrl}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${subscriptionId}`, Authorization: `Bearer ${token}` },
    });
  },
};
