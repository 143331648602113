import * as React from 'react';

import './SaldoResumoFatura.scss';

interface Props {
  dataVencimentoAtual: string,
  dataVencimentoProxima: string,
  faturaAtual: number,
  proximaFatura: number,
}

const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
const SaldoResumoFatura = ({ faturaAtual, proximaFatura, dataVencimentoAtual, dataVencimentoProxima }: Props) => (
  <section id='saldo-global-fatura' className='home-page-saldo-global-fatura'>
    <div className='clsFaturaAtual'>
      <span id='spnSaldoResumo'>$ Fatura Atual</span>
      <p className='pValorResumo' id='pValorResumo'>{faturaAtual?.toLocaleString('pt-BR', formato)}</p>
      {dataVencimentoAtual && (
      <p className='pVencimentoResumo'>
        Vencimento em :
        {dataVencimentoAtual}
      </p>
      )}
    </div>
    <div className='clsFaturaProxima'>
      <span id='spnSaldoResumo'>$ Próxima Fatura</span>
      <p className='pValorResumo' id='pValorResumo'>{proximaFatura?.toLocaleString('pt-BR', formato)}</p>
      {dataVencimentoProxima && (
      <p className='pVencimentoResumo'>
        Vencimento em :
        {dataVencimentoProxima}
      </p>
      )}
    </div>
  </section>
);
export default SaldoResumoFatura;
