export const colors = {
  primary: {
    PF: '#ff6338',
    PJ: '#293B8A',
    light: '#fff',
    dark: '#2a3037',
  },

  secondary: {
    PF: {
      700: '#F03B29',
      300: '#FF9166',
      50: '#F7F2ED',
    },

    PJ: {
      700: '#0D2993',
      300: '#3B5CB0',
    },
  },

  terciary: {
    gray: {
      default: '#BEC4CC',
      700: '#4D5761',
      300: '#EDEDED',
    },

    alert: '#FFB200',
    success: '#298F7A',
    error: '#B52626',

    PF: {
      disabled: {
        700: '#DEBDB3',
        300: '#FFD0BF',
      },
    },
  },
};
