import Actions, { Types } from "./ExtratoActions";
import AutenticacaoActions, {
  Types as AutenticacaoTypes,
} from "../autenticacao/AutenticacaoActions";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";

import ExtratoApi from "../../infrastructure/extrato-api";
import GeneralActions from "../actions";
import { Parametros } from "./ExtratoInterfaces";
import { State } from "../interfaces";

const MENSAGEM_LIMITE_TRANSACOES =
  "Relatório excedeu o limite máximo de transações. Reduza o período do filtro ou solicite o relatório via Central de Atendimento, no email: atendimento.empresarial@conectcar.com";

const obterClienteId = (state: State): number => state.autenticacao.clienteId;
const obterBearerToken = (state: State): string =>
  state.autenticacao.bearerToken;
const obterDocumento = (state: State): string => state.autenticacao.documento;

function* atribuirSaldo() {
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const bearerToken = yield select(obterBearerToken);

    const { data } = yield call(ExtratoApi.buscarSaldo, bearerToken);
    yield put(Actions.atribuirSaldo(data.dados[0].valor));
    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    window.alert(e);
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* atribuirDocumento() {
  yield put(GeneralActions.iniciarRequisicao());

  const documento = yield select(obterDocumento);
  const clienteId = yield select(obterClienteId);
  const token = yield select(obterBearerToken);

  yield put(Actions.atribuirDocumento(documento, clienteId, token));
  yield put(GeneralActions.terminarRequisicao());
}

function* atribuirFiltros() {
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const token = yield select(obterBearerToken);

    const { data } = yield call(ExtratoApi.buscarFiltros, token);
    yield put(
      Actions.atribuirFiltros(
        data.dados.placas || [],
        data.dados.centrosDeCusto || []
      )
    );
    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  }
}

const obterParametros = (state: State): Parametros => ({
  filtrosSelecionados: state.extrato.filtrosSelecionados,
  paginaSelecionada: state.extrato.paginaSelecionada,
  registrosPorPagina: state.extrato.registrosPorPagina,
});

function* exportarExtrato() {
  const parametros = yield select(obterParametros);
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const bearerToken = yield select(obterBearerToken);

    yield call(ExtratoApi.exportarExtrato, bearerToken, parametros);
  } catch (e) {
    yield put(Actions.mensagemFiltro(MENSAGEM_LIMITE_TRANSACOES));
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* exportarExtratoPdf() {
  const parametros = yield select(obterParametros);
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const bearerToken = yield select(obterBearerToken);

    yield call(ExtratoApi.exportarExtratoPdf, bearerToken, parametros);
  } catch (e) {
    yield put(Actions.mensagemFiltro(MENSAGEM_LIMITE_TRANSACOES));
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* atribuirTabela() {
  const parametros = yield select(obterParametros);
  yield put(GeneralActions.iniciarRequisicao());
  try {
    const token = yield select(obterBearerToken);
    const { data } = yield call(ExtratoApi.buscarExtrato, parametros, token);

    if (!data) {
      yield put(Actions.atribuirTabela([], 0));
    } else {
      yield put(
        Actions.atribuirTabela(data.dados.resultado, data.dados.totalPaginas)
      );
    }
  } catch (e) {
    yield put(Actions.atribuirTabela([], 0));
    yield put(Actions.mensagemFiltro(MENSAGEM_LIMITE_TRANSACOES));
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarExtrato() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield all([
    atribuirSaldo(),
    atribuirDocumento(),
    atribuirFiltros(),
    atribuirTabela(),
  ]);
}

export default function* () {
  yield takeEvery(Types.InicializarExtrato, inicializarExtrato);
  yield takeEvery(Types.ExportarExtrato, exportarExtrato);
  yield takeEvery(Types.ExportarExtratoPdf, exportarExtratoPdf);
  yield takeEvery(Types.BuscarExtrato, atribuirTabela);
}
