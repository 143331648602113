import {
  Backdrop,
  Box, Button, Fade, Modal, Typography,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import RecargaInsucessoStyles from './RecargaInsucessoStyles';
import Actions from '../../RecargaActions';

interface Props {
  onTentarNovamenteClick?: any;
  visivel: boolean;
}

const ModalRecargaInsucesso = ({ onTentarNovamenteClick, visivel } : Props) => {
  const modal = RecargaInsucessoStyles();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(Actions.atualizarErro(null, false));
  };

  const handleTentarNovamente = () => {
    dispatch(onTentarNovamenteClick());
    handleClose();
  };

  return (
    <div>
      <Modal
        open={visivel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visivel}>
          <Box className={modal.ModalInsucesso}>
            <Typography
              className={modal.ModalInsucessoTitulo}
              variant='h5'
            >
              Tivemos um problema ao realizar sua solicitação
            </Typography>

            <Typography
              className={modal.ModalInsucessoDescricao}
              variant='h6'
            >
              Aparentemente, houve uma falha de comunicação. Verifique sua conexão de Internet e tente novamente.
            </Typography>

            <div className={modal.ModalInsucessoBoxBotao}>
              <Button
                className={`${modal.ModalInsucessoBotao} ${modal.InsucessoVoltar}`}
                variant='contained'
                onClick={handleClose}
              >
                Voltar
              </Button>

              <Button
                className={`${modal.ModalInsucessoBotao} ${modal.InsucessoTentarNovamente}`}
                variant='contained'
                onClick={onTentarNovamenteClick !== null ? handleTentarNovamente : handleClose}
              >
                Tentar Novamente
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalRecargaInsucesso;
