export const MotivosSubstituicaoAdesivo = {
  Defeito: {
    id: 1,
    descricao: 'Defeito',
  },
  InstalacaoIncorreta: {
    id: 2,
    descricao: 'Instalação Incorreta',
  },
  Retencao: {
    id: 3,
    descricao: 'Retenção',
  },
  Inexistente: {
    id: 4,
    descricao: 'Inexistente',
  },
  TrocaParaBrisa: {
    id: 5,
    descricao: 'Troca Para-Brisa',
  },
  TrocaDeVeiculo: {
    id: 6,
    descricao: 'Troca de Veículo',
  },
  TrocaPorAdesivo: {
    id: 7,
    descricao: 'Troca por Adesivo',
  },
};
