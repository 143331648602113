import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import { ItemTabela, TiposTransacao } from '../ExtratoInterfaces';
import './ExtratoTabela.scss';

const iconeOutros = require('../../../assets/images/icones/outros.svg');
const iconeInform = require('../../../assets/images/icones/info.svg');

const locale = 'pt-BR';
const moneyOptions = { style: 'currency', currency: 'BRL' };
const dateOptions = { hour: 'numeric', minute: 'numeric' };

interface Props { tabela: ItemTabela[], aviso: string }


const ExtratoTabela = ({ tabela, aviso }: Props) => {
  React.useEffect(() => {
    const criarTabelaRedimensionavel = function (table) {
      const cols = table.querySelectorAll('th');
      [].forEach.call(cols, (col) => {
        const resizer = document.createElement('div');

        resizer.classList.add('resizer');
        resizer.style.height = '50px';

        col.appendChild(resizer);
        criarColunaRedimensionavel(col, resizer);
      });
    };

    const criarColunaRedimensionavel = (col, resizer) => {
      let x = 0;
      let w = 0;

      const mouseDownHandler = (e) => {
        x = e.clientX;

        const styles = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);

        window.addEventListener('mousemove', mouseMoveHandler);
        window.addEventListener('mouseup', mouseUpHandler);

        resizer.classList.add('resizing');
      };

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - x;
        col.style.width = `${w + dx}px`;
      };

      const mouseUpHandler = () => {
        resizer.classList.remove('resizing');
        window.removeEventListener('mousemove', mouseMoveHandler);
        window.removeEventListener('mouseup', mouseUpHandler);
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    };

    criarTabelaRedimensionavel(document.getElementById('extrato-tabela'));
  }, []);

  const formatarDataPassagem = (dataPassagem: Date) => (
    dataPassagem
      ? new Date(dataPassagem).toLocaleDateString(locale, dateOptions).replace(' ', ' - ')
      : ''
  );

  const formatarCategoria = (codigoCategoria: number) => (
    codigoCategoria !== 0 ? `Cat.${codigoCategoria}` : ''
  );

  const recuperarIconeTipoTransacao = (id: number) => {
    const tipoTransacao = TiposTransacao.find(icone => icone.valor === id);
    return tipoTransacao ? tipoTransacao.icone : iconeOutros;
  };

  const retornaClassCredito = (credito: boolean, valor: number) => {
    if (valor === 0) return 'zerado';
    return credito ? 'credito' : 'debito';
  };

  const formatarTipoTransacao = (descricao: string) => (descricao ? descricao.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : '');

  const formatarTransacaoRecargaVPO = (numeroViagem: number, cnpjEmbarcador: string, razaoSocialEmbarcador: string) => {
    let parametrosFormatados = `Número da viagem: ${numeroViagem.toString()} / `;
    parametrosFormatados += `CNPJ do embarcador: ${cnpjEmbarcador} / `;
    parametrosFormatados += `Razão social do embarcador: ${razaoSocialEmbarcador}`;

    return parametrosFormatados;
  };

  const renderizarLinhas = () => tabela?.map((item: ItemTabela) => (
    <tr key={`${item.id}${Math.random()}`}>
      <td>{new Date(item.dataCobranca).toLocaleDateString(locale)}</td>
      <td>{item.placa}</td>
      <td>{formatarDataPassagem(item.dataPassagem)}</td>
      <td title={item.categoria}>
        {`${formatarCategoria(item.codigoCategoria)} `}
        <img className='icone-categoria' src={iconeInform} alt={item.categoria} />
      </td>
      <td className='com-icone'>
        <img src={recuperarIconeTipoTransacao(item.tipoTransacao.id)} title={item.tipoTransacao.descricao} alt='icone-transacao' />
        <span className='limitador'>{formatarTipoTransacao(item.tipoTransacao.descricao)}</span>
        {item.numeroViagem > 0 ? (
          <Tooltip
            title={formatarTransacaoRecargaVPO(item.numeroViagem, item.cnpjEmbarcador, item.razaoSocialEmbarcador)}
            placement='right'
          >
            <img className='icone-categoria' src={iconeInform} alt={item.categoria} />
          </Tooltip>
        ) : ''}
      </td>
      <td>{item.centroCusto}</td>
      <td className={retornaClassCredito(item.operacaoCredito, item.valor)} align='right'>
        {item.valor.toLocaleString(locale, moneyOptions)}
      </td>
      <td align='right'>
        {(item.saldo + item.valor).toLocaleString(locale, moneyOptions)}
      </td>
    </tr>
  ));


  return (
    <React.Fragment>
      <span className={`aviso-tabela ${tabela?.length === 0 ? '' : 'esconder'}`}>{aviso}</span>
      <span className={`scroll-table-info ${tabela?.length === 0 ? 'esconder' : ''}`}> ARRASTE PARA O LADO PARA VER MAIS &rarr;</span>
      <div className={`table-container ${tabela?.length === 0 ? 'esconder' : ''}`}>
        <table id='extrato-tabela'>
          <thead>
            <tr>
              <th className='data-cobranca'>Data cobrança</th>
              <th className='placa'>Placa</th>
              <th className='data-passagem'>Data passagem</th>
              <th className='categoria'>Categoria</th>
              <th className='descricao'>Descrição</th>
              <th className='centro-custo'>Centro de custo</th>
              <th className='valor' align='right'>Valor</th>
              <th className='saldo' align='right'>Saldo do dia (R$)</th>
            </tr>
          </thead>
          <tbody>
            {renderizarLinhas()}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default ExtratoTabela;
