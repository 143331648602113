import { createStyles, makeStyles, Theme } from '@material-ui/core';

const BoxPagamentoStyles = makeStyles((theme: Theme) => createStyles({
  panelSummary: {
    flexDirection: 'row-reverse',
    paddingLeft: '0px',
  },
  dotBoxPagamento: {
    marginRight: '16px',
    float: 'left',
    backgroundColor: '#FFA789',
    width: '12px',
    height: '12px',
    borderRadius: '45px',
  },
  BoxPagamentoTitulo: {
    color: '#2A3037',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginLeft: '10px',
  },
  BoxPagamentoDescricao: {
    fontSize: theme.typography.pxToRem(15),
    color: '#4D5761',
    fontWeight: 400,
    marginLeft: '10px',
  },
  BoxPagamentoButton: {
    backgroundColor: '#293B8A',
    borderRadius: '50px',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 700,
    height: '44px',
    textTransform: 'capitalize',
    width: '200px',
    '&:hover': {
      backgroundColor: '#293B8A',
    },
    '&> span': {
      pointerEvents: 'none',
    },
  },
  BoxPagamentoLabel: {
    color: '#676767 !important',
    fontSize: '15px !important',
    fontWeight: 700,
  },
  BoxPagamentoAccordionStyle: {
    border: '1px solid #D5D5D5',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '20px 20px 5px 20px',
    marginBottom: '24px !important',
    '& .MuiIconButton-label .MuiSvgIcon-root': {
      height: '0.9em !important',
      width: '0.9em !important',
      margin: '-24px 12px 0px 0px',
    },
    '& .Mui-expanded': {
      color: '#298F7A',
      transform: 'none !important',
    },
  },

  innerMenuItem: {
    paddingLeft: '32px',
  },
  divider: {
    backgroundColor: '#BEC4CC',
  },
}));

export default BoxPagamentoStyles;
