import { Action } from '../interfaces';

export enum Types {
  RequisitarToken = 'REQUISITAR_TOKEN',
  AtribuirDadosToken = 'ATRIBUIR_DADOS_TOKEN',
}

export interface RequisitarTokenAction extends Action { }
export interface AtribuirDadosTokenAction extends Action { clienteId: number, documento: string, bearerToken: string }

export default {
  requisitarToken: (): RequisitarTokenAction => ({ type: Types.RequisitarToken }),
  atribuirDadosToken: (clienteId: number, documento: string, bearerToken: string): AtribuirDadosTokenAction => (
    { type: Types.AtribuirDadosToken, clienteId, documento, bearerToken }),
};
