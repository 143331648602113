import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';
import { DadosCliente } from '../../services/requests/SolicitacaoAdesivo/comprovanteRecargaRequest/interface';

interface Props{
  dados : DadosCliente
}

const styles = StyleSheet.create({
  corpo: {
    marginTop: '3px',
  },
  legenda: {
    fontSize: '5px',
    alignSelf: 'baseline',
  },
  linha: {
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '1.6',
    width: '100%',
    border: '1pt solid #000000',
  },
  linha_debaixo: {
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '1.6',
    width: '100%',
    borderBottom: '1pt solid #000000',
    borderLeft: '1pt solid #000000',
    borderRight: '1pt solid #000000',
  },
  quadrado: {
    width: '100%',
  },
  quadrado_60: {
    width: '60%',
  },
  quadrado_direita: {
    width: '100%',
    borderLeft: '1pt solid #000000',
  },
  quadrado_direita_60: {
    width: '60%',
    borderLeft: '1pt solid #000000',
  },
  conteudo: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '6px',
    alignSelf: 'center',
  },
  conteudo_email: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '6px',
    alignSelf: 'flex-start',
    marginLeft: '50px',
  },
  informacoes: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '6px',
    alignSelf: 'flex-start',
    lineHeight: '0',
  },
  espacador: {
    height: '3px',
    borderBottom: '1pt solid #000000',
    borderLeft: '1pt solid #000000',
    borderRight: '1pt solid #000000',
    width: '100%',
  },
});

const Tomador = ({ dados }:Props) => {
  const enderecoPrincipal = dados?.enderecos.find(x => x.tipoEndereco === 'Principal');

  return (dados
    && (
    <View id='corpo' style={styles.corpo}>
      <View id='identificacao' style={styles.linha}>
        <View style={styles.quadrado}>
          <Text style={styles.legenda}>
            Nome Tomador de Serviço:
          </Text>
          <Text style={styles.conteudo}>
            {dados.nome}
          </Text>
        </View>
        <View style={styles.quadrado_direita_60}>
          <Text style={styles.legenda}>
            CPF/CNPJ:
          </Text>
          <Text style={styles.conteudo}>
            {dados.documento.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
          </Text>
        </View>
      </View>
      <View id='endereco' style={styles.linha_debaixo}>
        <View style={styles.quadrado}>
          <Text style={styles.legenda}>
            Endereço:
          </Text>
          <Text style={styles.conteudo}>
            {`${enderecoPrincipal.logradouro}, ${enderecoPrincipal.numero}`}
          </Text>
        </View>
        <View style={styles.quadrado_direita}>
          <Text style={styles.legenda}>
            Complemento:
          </Text>
          <Text style={styles.conteudo}>
            {enderecoPrincipal.complemento}
          </Text>
        </View>
      </View>
      <View id='cep' style={styles.linha_debaixo}>
        <View style={styles.quadrado}>
          <Text style={styles.legenda}>
            CEP:
          </Text>
          <Text style={styles.conteudo}>
            {`${enderecoPrincipal.cep.substring(0, 5)}-${enderecoPrincipal.cep.substring(5, 8)}`}
          </Text>
        </View>
        <View style={styles.quadrado_direita}>
          <Text style={styles.legenda}>
            Bairro:
          </Text>
          <Text style={styles.conteudo}>
            {enderecoPrincipal.bairro}
          </Text>
        </View>
        <View style={styles.quadrado_direita}>
          <Text style={styles.legenda}>
            Cidade:
          </Text>
          <Text style={styles.conteudo}>
            {enderecoPrincipal.cidade}
          </Text>
        </View>
        <View style={styles.quadrado_direita}>
          <Text style={styles.legenda}>
            UF:
          </Text>
          <Text style={styles.conteudo}>
            {enderecoPrincipal.estado}
          </Text>
        </View>
      </View>
      <View id='email' style={styles.linha_debaixo}>
        <View style={styles.quadrado}>
          <Text style={styles.legenda}>
            E-mail:
          </Text>
          <Text style={styles.conteudo_email}>
            {dados.email}
          </Text>
        </View>
      </View>
      <View id='plano' style={styles.linha_debaixo}>
        <View style={styles.quadrado_60}>
          <Text style={styles.legenda}>
            Plano:
          </Text>
          <Text style={styles.conteudo}>
            Pré PJ EMPRESARIAL
          </Text>
        </View>
        <View style={styles.quadrado_direita} />
      </View>
      <View style={styles.espacador} />
    </View>
    )
  );
};

export default Tomador;
