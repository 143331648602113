import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.URL_API_MANAGEMENT}portalpj/v1`,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Ocp-Apim-Subscription-Key': process.env.SUBSCRIPTION_API_MANAGEMENT,
    Authorization: `Bearer ${sessionStorage.getItem('TOKEN_KEY')}`,
  },
});

export default api;
