import * as React from 'react';
import './FaturaPage.scss';
import { useDispatch } from 'react-redux';
import SaldoGlobalFatura from '../../../components/Fatura/SaldoGlobalFatura/SaldoGlobalFatura';
import FaturaTabs from '../FaturaTabs/FaturaTabs';
import SaldoLimiteDisponivel from '../../../components/Fatura/SaldoLimiteDisponivel/SaldoLimiteDisponivel';
import FaturaTabelaHistorico from '../FaturaTabelaHistorico';
import Actions from '../FaturaActions';
import ocultadorChatbot from '../../../infrastructure/ocultadorChatbot';

const FaturaPage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    ocultadorChatbot();
    dispatch(Actions.inicializarFatura());
  }, [dispatch]);

  return (
    <section id='fatura-page'>
      <SaldoGlobalFatura />
      <section className='page iframe-resizable'>

        <section className='fatura-container fatura-page-body'>
          <SaldoLimiteDisponivel />
        </section>

        <section className='fatura-container fatura-page-navigation'>
          <FaturaTabs />
        </section>

        <section className='fatura-container fatura-page-navigation'>
          <FaturaTabelaHistorico />
        </section>

      </section>
    </section>
  );
};

export default FaturaPage;
