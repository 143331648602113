import * as React from 'react';
import './ModalSlide.scss';

interface Props {
  estaAberto: boolean,
  onCloseClick: Function,
  children?: React.ReactElement,
  botao?: boolean,
  botaoTexto?: string
}

const iconeFechar = require('../../assets/images/icones/fechar.svg');

const ModalSlide = ({ estaAberto, onCloseClick, children, botao, botaoTexto }: Props) => {
  const [fechando, setFechando] = React.useState<boolean>(false);

  const estadoModal = () => {
    if (fechando) return 'fechando';
    if (estaAberto) return 'aberto';
    return 'fechado';
  };

  const handleCloseClick = () => {
    setFechando(true);

    setTimeout(() => {
      setFechando(false);
      onCloseClick();
    }, 1000);
  };

  return (
    <section id='modal-slide' title='modal-slide' className={estadoModal()}>
      <div className='fundo' />
      <div className='conteudo'>
        <button
          className='fechar'
          type='button'
          onClick={() => handleCloseClick()}
        >
          <img src={iconeFechar} alt='fechar' title='fechar' />
        </button>
        {estaAberto ? children : ''}
        {botao ? <button type='button' className='botao-preenchido-azul' id='botao-fechar-modal-slide' title='botao-fechar-modal-slide' onClick={() => handleCloseClick()}>{botaoTexto}</button> : ''}
      </div>
    </section>
  );
};

export default ModalSlide;
