import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { EtapaEntrega, HistoricoPedido } from '../../../features/solicitacao-adesivo/meus-pedidos/MeusPedidosInterfaces';
import { AppStatusPedido } from '../../../common/AppStatusPedido.enum';
import { TrackingPedidoConnector, TrackingPedidoStepIcon } from './TrackingPedidoStyles';
import { formatarData } from '../../../utils/dataHoraAtual';
import { AppStatusEntregaMacroCode } from '../../../common/AppStatusEntregaMacroCode.enum';
import { retornarStatusEntrega, retornarStatusStep } from './TrackingPedidoStepsService';

import './TrackingPedido.scss';

interface TrackingPedidoProps {
  historicoPedido: HistoricoPedido[];
  etapaEntrega?: EtapaEntrega;
}

const steps = ['Pedido', 'Pagamento', 'Envio', 'Entrega'];

const TrackingPedido = ({ historicoPedido, etapaEntrega }: TrackingPedidoProps) => {
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (historicoPedido.length > 0) {
      setActiveStep(retornarStatusStep(historicoPedido[historicoPedido.length - 1].status));
    }
  }, [historicoPedido]);

  const determinarEstado = (label: string, index: number) => {
    const itemHistorico = historicoPedido.find(item => retornarStatusStep(item.status) === index);

    const pago = historicoPedido.find(h => h.status === AppStatusPedido.PAGO);
    const cancelado = historicoPedido.find(h => h.status === AppStatusPedido.CANCELADO);
    const entregue = historicoPedido.find(h => h.status === AppStatusPedido.PEDIDO_ENTREGUE);
    const emTransporte = historicoPedido.find(h => h.status === AppStatusPedido.EM_TRANSPORTE);

    let descricaoComplementar: React.ReactElement = <span><p>Aguardando</p></span>;

    switch (label) {
      case 'Pedido':
        descricaoComplementar = criarDescricaoComplementar('Realizado', formatarData(itemHistorico?.dataStatus!));
        break;

      case 'Pagamento':
        if (cancelado) {
          descricaoComplementar = criarDescricaoComplementar('Cancelado', formatarData(cancelado.dataStatus));
        } else if (pago) {
          descricaoComplementar = criarDescricaoComplementar('Pago', formatarData(itemHistorico?.dataStatus!));
        }
        break;

      case 'Envio':
        if (emTransporte) {
          descricaoComplementar = criarDescricaoEnvio(entregue !== undefined, emTransporte);
        } else if (cancelado) {
          descricaoComplementar = criarDescricaoComplementar('Cancelado', formatarData(cancelado.dataStatus));
        }
        break;

      case 'Entrega':
        if (etapaEntrega?.macroCode === AppStatusEntregaMacroCode.FALHA_NA_ENTREGA) {
          descricaoComplementar = criarDescricaoComplementar('Falha na entrega', formatarData(etapaEntrega.dataAtualizacao));
        } else if (entregue) {
          descricaoComplementar = criarDescricaoComplementar('Pedido entregue', formatarData(etapaEntrega?.dataAtualizacao!));
        } else if (cancelado) {
          descricaoComplementar = criarDescricaoComplementar('Cancelado', formatarData(cancelado.dataStatus));
        }
        break;

      default:
        break;
    }

    const exibirIconeVermelho = deveExibirIconeVermelho(cancelado !== undefined, label, index);
    const stepFinalizado = !!itemHistorico;

    return { descricaoComplementar, stepFinalizado, exibirIconeVermelho };
  };

  const criarDescricaoComplementar = (descricao: string, dataStatus: string | null) => (
    <span>
      <p>{descricao}</p>
      <p>{dataStatus}</p>
    </span>
  );

  const criarDescricaoEnvio = (entregue: boolean, emTransporte: HistoricoPedido) => (
    <span>
      <p><b>Cód. Rastreio: </b>{etapaEntrega?.codigoRastreio}</p>
      <p><b>Transportadora: </b>{etapaEntrega?.transportadora}</p>
      {etapaEntrega?.macroCode !== AppStatusEntregaMacroCode.INVALIDO
        && !entregue
        && <p><b>Status: </b>{retornarStatusEntrega(etapaEntrega?.macroCode!)}</p>
      }
      <p>
        {etapaEntrega?.macroCode !== AppStatusEntregaMacroCode.INVALIDO
          ? formatarData(etapaEntrega?.dataAtualizacao!)
          : formatarData(emTransporte.dataStatus)
        }
      </p>
    </span>
  );

  const deveExibirIconeVermelho = (cancelado: boolean, label: string, index: number) => {
    const stepCanceladoPagamentoOuPosterior = cancelado && index >= 1;
    const falhaNaEntrega = etapaEntrega?.macroCode === AppStatusEntregaMacroCode.FALHA_NA_ENTREGA && label === 'Entrega';

    return stepCanceladoPagamentoOuPosterior || falhaNaEntrega;
  };

  return (
    <Stepper className='stepper-tracking-pedido' activeStep={activeStep} alternativeLabel connector={<TrackingPedidoConnector />}>
      {steps.map((label, index) => {
        const { descricaoComplementar, stepFinalizado, exibirIconeVermelho } = determinarEstado(label, index);

        return (
          <Step key={label}>
            <StepLabel StepIconComponent={stepProps => (
              <TrackingPedidoStepIcon
                {...stepProps}
                cancelado={exibirIconeVermelho}
                completed={stepFinalizado}
              />
            )}
            >
              <p
                key={`titulo-step-${label}`}
                id={`titulo-step-${label}`}
                data-testid={`titulo-step-${label}`}
              >
                <b>{label}</b>
              </p>
              <p
                key={`descricao-step-${label}`}
                id={`descricao-step-${label}`}
                data-testid={`descricao-step-${label}`}
              >
                {descricaoComplementar}
              </p>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default TrackingPedido;
