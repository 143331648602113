import * as React from 'react';

import '../Cadastro.scss';
import './AguardandoAprovacaoPage.scss';

export default () => (
  <section id='aguardando-aprovacao'>
    <section className='conteudo'>

      <section className='conteudo-aguardando'>
        <div className='conteudo-analise'>
          <div className='conferir-saldo-icon' />

          <div className='analise'>
            <h2 className='analise-descricao'>Análise dos dados</h2>

            <div className='analise-texto'>
              <span>
                Analisaremos seus dados e, em breve, você receberá nosso contato.
                <br />
                <br />
                Caso algum dado esteja divergente com o cadastro na Receita Federal, retornaremos para dar continuidade ao seu cadastro!
              </span>
            </div>
          </div>
        </div>

      </section>
    </section>
  </section>
);
