import {
  Button,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TrackingPedido from '../../../../components/SolicitacaoAdesivo/TrackingPedido/TrackingPedido';
import { PedidosComprasDados } from '../MeusPedidosInterfaces';
import './MeusPedidosTabela.scss';

const IconBoleto = require('../../../../assets/images/icones/icon_boleto.png');

const StyledTableCell = withStyles(({
  root: {
    fontSize: '1em',
  },
  head: {
    color: '#4D5761',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
}))(TableCell);

const ClassesStyles = makeStyles({
  root: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff6339',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'ff6339',
    },
    '& .MuiInputLabel-outlined': {
      color: 'black',
    },
  },
  spanMensagem: {
    color: '#293B8A',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
  divMensagem: {
    alignItems: 'center',
    textAlign: 'center',
    height: 25,
  },
  divBoletoRapido: {
    display: 'inline-block',
  },
  imageBoletoIcon: {
    backgroundImage: `url(${IconBoleto})`,
    backgroundRepeat: 'no-repeat',
  },
  fonteBoleto: {
    width: '15em',
    color: '#ff6339',
  },
  fonteValor: {
    color: '#FF6338',
    fontSize: '14px',
  },
  fonteTabela: {
    color: '#2A3037',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '18px',
  },
  fonteTabelaRow: {
    width: '100%',
  },
  fonteMsg: {
    color: '#4D5761',
    fontSize: '17px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  btnDownLoadFatura: {
    backgroundColor: '#fff',
    width: '145px',
    border: '1px solid #585d62',
    borderRadius: '16px',
    cursor: 'pointer',
    height: '33px',
    textTransform: 'none',
  },
});

interface Props {
  meusPedidos: PedidosComprasDados[]
}

const MeusPedidosTabela = ({ meusPedidos }: Props) => {
  const classes = ClassesStyles();

  const baixarFatura = (arquivo: string) => {
    window.open(arquivo, '_blank');
  };

  const formatarEndereco = (endereco: string): JSX.Element => {
    const partes = endereco.split(', ');
    return (
      <span>
        {partes.map(parte => (
          <React.Fragment key={parte}>
            {parte}
            <br />
          </React.Fragment>
        ))}
      </span>
    );
  };

  return (
    <>
      <section className='tabela-container'>
        <div style={{ display: 'block', width: '100%' }}>
          <div>
            <TableContainer component={Paper} style={{ width: '100%', maxWidth: 1366 }}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Número do Pedido</StyledTableCell>
                    <StyledTableCell align='left'>Qtd</StyledTableCell>
                    <StyledTableCell align='left'>Endereço de entrega</StyledTableCell>
                    <StyledTableCell align='left'>Forma de Pagamento</StyledTableCell>
                    <StyledTableCell align='left'>Valor</StyledTableCell>
                    <StyledTableCell>Arquivo</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meusPedidos?.map(tabela => (
                    <Fragment>
                      <TableRow style={{ backgroundColor: '#FBFAFA', color: 'white' }} key={tabela?.numeroPedido}>
                        <TableCell className={classes.fonteTabela} component='td' scope='row'>
                          {tabela?.numeroPedido}
                        </TableCell>
                        <TableCell className={classes.fonteTabela} align='left'>{tabela?.quantidade}</TableCell>
                        <TableCell className={classes.fonteTabela} align='left'>{formatarEndereco(tabela?.endereco)}</TableCell>
                        <TableCell className={classes.fonteTabela} align='left'>{tabela?.formaPagamento?.nome}</TableCell>
                        <TableCell className={classes.fonteValor} align='left'>
                          R$
                          {tabela?.valor}
                        </TableCell>
                        <TableCell className={classes.fonteTabela} align='left'>
                          {tabela?.urlBoleto && tabela?.urlBoleto !== null
                            ? <Button variant='text' className={classes.btnDownLoadFatura} type='button' onClick={() => baixarFatura(tabela?.urlBoleto)} startIcon={<CloudDownloadIcon />}>Boleto</Button>
                            : ''
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow key={tabela?.valor} className={classes.fonteTabelaRow}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <TrackingPedido historicoPedido={tabela?.historicoPedido} etapaEntrega={tabela?.etapaEntrega} />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </section>
    </>
  );
};

export default MeusPedidosTabela;
