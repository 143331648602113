import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../FaturaActions';
import { State } from '../../interfaces';
import { FaturaState } from '../FaturaInterfaces';
import FaturaHistoricoStyle from './FaturaTabelaHistoricoStyle';
import Utils from '../../../common/Utils';


const StyledTableCell = withStyles(({
  root: {
    width: '10px',
  },
  head: {
    color: '#293B8A',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 5,
  },
}))(TableCell);

const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
const locale = 'pt-BR';
const FaturaTabelaHistorico = () => {
  const classes = FaturaHistoricoStyle();

  const {
    historicoFatura,
    carregando,
    erro,
  } = useSelector<State, FaturaState>(state => ({
    dadosFatura: state.fatura.dadosFatura,
    loading: state.fatura.carregando,
    erro: state.fatura.historicoFatura.erro,
    dadosLimitesSaldo: state.fatura.dadosLimitesSaldo,
    historicoFatura: state.fatura.historicoFatura,
    carregando: state.fatura.historicoFatura.carregando,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Actions.buscarHistoricoFaturaRequest());
  }, [dispatch]);

  const baixarFatura = (arquivo: string) => {
    const urlArquivo = `data:application/pdf;base64,${encodeURI(arquivo)}`;
    const iframe = `<iframe width='100%' height='100%' src='${urlArquivo}'></iframe>`;
    const x = window.open();
    x.document.write(iframe);
    x.document.close();
  };

  const renderizarStatus = (status: string, vencimento) => {
    const dataAtual = new Date();
    const dataVencimento = new Date(vencimento);
    let statusFatura = status;

    if (dataAtual > dataVencimento && status === 'EMABERTO') {
      statusFatura = 'ATRASADO';
    }

    return (
      <Box className={clsx(classes.statusAguardando, { [classes.statusAguardando]: statusFatura === 'EMABERTO', [classes.statusPago]: statusFatura === 'PAGO', [classes.statusAtrasado]: statusFatura === 'ATRASADO' })}>
        <span className={classes.labelStatus}>{Utils.formatarStatusFatura(statusFatura)}</span>
      </Box>
    );
  };

  const renderizarData = data => (
    <div>
      <p className={classes.pMes}>{new Date(data).toLocaleDateString(locale, { month: 'long' })}</p>
      <p>{new Date(data).toLocaleDateString(locale)}</p>
    </div>
  );

  return (
    <TableContainer component={Paper}>
      {!carregando && historicoFatura.dados && historicoFatura.dados.length > 0 && (
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Data</StyledTableCell>
              <StyledTableCell align='left'>Status</StyledTableCell>
              <StyledTableCell align='right'>Valor</StyledTableCell>
              <StyledTableCell align='right'>Vencimento</StyledTableCell>
              <StyledTableCell align='center'>Arquivo</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historicoFatura.dados.map(tabela => (
              <TableRow key={tabela?.dataEmissao}>
                <TableCell component='td' scope='row'>{renderizarData(tabela.dataEmissao)}</TableCell>
                <TableCell align='left'>{renderizarStatus(tabela?.status, tabela.dataVencimento)}</TableCell>
                <TableCell align='right'>{tabela?.valorOriginal.toLocaleString('pt-BR', formato)}</TableCell>
                <TableCell align='right'>{new Date(tabela?.dataVencimento).toLocaleDateString(locale)}</TableCell>
                <TableCell align='center'>
                  {tabela.arquivo && tabela.arquivo !== null
                    ? <Button variant='text' className={classes.btnDownLoadFatura} type='button' onClick={() => baixarFatura(tabela.arquivo)} startIcon={<CloudDownloadIcon />}>Baixar Fatura</Button>
                    : ''
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!carregando && historicoFatura.dados && historicoFatura.dados.length === 0 && (
        <div className={classes.divMensagem}>
          <span className={classes.spanMensagem}>Você não possui faturas anteriores</span>
        </div>
      )}
      {!carregando && erro && (
        <div className={classes.divMensagem}>
          <span className={classes.spanMensagem}>Faturas indisponível no momento</span>
        </div>
      )}
    </TableContainer>
  );
};

export default FaturaTabelaHistorico;
