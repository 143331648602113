import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { State } from '../../features/interfaces';
import { HomeState } from '../../features/home/HomeInterfaces';

import './UltimaRecarga.scss';

const iconeCifraoUp = require('../../assets/images/icones/cifrao-up.png');

const UltimaRecarga = () => {
  const {
    dadosUltimaRecarga,
  } = useSelector<State, HomeState>(state => ({
    dadosUltimaRecarga: state.home.dadosUltimaRecarga,
  }));

  const handlerRealizarRecarga = () => {
    window.parent.location.href = `${(window as any).config.url_portal_cliente}/RecargaPJ`;
  };

  const obterDataMensalidadeFormatada = (dataUltimaRecarga: Date) => {
    const data = new Date(dataUltimaRecarga);
    return data.toLocaleDateString();
  };

  const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

  return (
    <Box className='box-ultima-recarga'>
      <Grid className='grid-ultima-recarga' container spacing={3}>
        {dadosUltimaRecarga != null ? (
          <>
            <Grid item className='grid-img-cifrao' xs={1} sm={1} md={1} lg={1} xl={1}>
              <img alt='imagem' className='imagemCifrao center-valign' src={iconeCifraoUp} />
            </Grid>
            <Grid className='grid-titulo center-valign' item xs={10} sm={2} md={2} lg={2} xl={2}>
              <span className='spanBase spnRecarga'>
                Última Recarga
              </span>
            </Grid>
            <Grid className='center-valign' item xs={4} sm={2} md={2} lg={2} xl={2}>
              <span className='spanBase spnTitulo'>
                Descrição
              </span>
              <Typography className='spnValor'>{dadosUltimaRecarga.descricao}</Typography>
            </Grid>
            <Grid className='center-valign' item xs={4} sm={2} md={2} lg={2} xl={2}>
              <span className='spanBase pnTitulo'>
                Data
              </span>
              <Typography className='spnValor'>{obterDataMensalidadeFormatada(dadosUltimaRecarga.dataRecarga)}</Typography>
            </Grid>
            <Grid className='center-valign' item xs={4} sm={2} md={2} lg={2} xl={2}>
              <span className='spanBase spnTitulo'>
                Valor
              </span>
              <Typography className='spnValor'>{dadosUltimaRecarga.valor?.toLocaleString('pt-BR', formato)}</Typography>
            </Grid>
            <Grid className='item center-valign end-align' item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Button variant='contained' id='btnUltimaRecarga' className='btnRecarregar' onClick={handlerRealizarRecarga}>
                <span className='btnRecarregarSpn'>Nova Recarga</span>
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid className='center-valign grid-mensagem-alerta-recarga' item xs={8} sm={8} md={8} lg={8} xl={8}>
              <span className='spanBase spnMensagemAlertaRecarga'>Sua empresa não possui saldo. Que tal fazer uma recarga?</span>
            </Grid>
            <Grid className='item center-valign end-align' item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Button variant='contained' id='btnUltimaRecarga' className='btnRecarregar' onClick={handlerRealizarRecarga}>
                <span className='btnRecarregarSpn'>Nova Recarga</span>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default UltimaRecarga;
