import * as React from 'react';
import NumberFormat from 'react-number-format';
import useStyles from './InputValorStyle';

interface InputValorProps {
  inputstyle?: any;
  onChange?: any;
  valorpadrao?: number;
  identificador: string;

  separadorDecimal?: string;
  separadorMilhares?: string;
  prefixo?: string;
}

const InputValor = React.forwardRef<NumberFormat, InputValorProps>(
  (props, ref) => {
    const
      {
        identificador,
        inputstyle,
        onChange,
        prefixo,
        separadorDecimal,
        separadorMilhares,
        valorpadrao,
      } = props;

    const classes = useStyles();

    return (
      <NumberFormat
        id={identificador}
        ref={ref}
        className={inputstyle || classes.inputValorDefault}
        onValueChange={onChange}
        defaultValue={valorpadrao}
        onChange={e => e.stopPropagation()}
        allowEmptyFormatting
        decimalSeparator={separadorDecimal || ','}
        thousandSeparator={separadorMilhares || '.'}
        isNumericString
        prefix={prefixo}
      />
    );
  },
);

export default InputValor;
