import axios from 'axios';
import {
  ResultadoApiRecargaUltimaRecarga,
  CadastroSaldoMinimo,
  EnvioRecargaRequest, ResultadoApiPix, ResultadoApiRecarga, ResultadoApiRecargaIndex, ResultadoApiRecargaPlanoCliente,
} from '../../features/recarga/RecargaInterfaces';

import { RespostaApi } from '../ApiInterfaces';

export default {
  obterDadosRecargaPorDocumento: (bearerToken: string)
    : Promise<RespostaApi<ResultadoApiRecargaIndex>> => axios.get(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/ObterDadosRecargaPorDocumento`,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
    },
  ),

  cadastrarRecarga: (bearerToken: string, request: EnvioRecargaRequest)
    : Promise<RespostaApi<ResultadoApiRecarga>> => axios.post(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/CadastrarRecarga`, request,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
    },
  ),

  cadastrarRecargaPix: (bearerToken: string, request: EnvioRecargaRequest)

    : Promise<RespostaApi<ResultadoApiPix>> => axios.post(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/CadastrarRecargaPix`, request,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
    },
  ),

  cadastrarAvisoSaldoMinimoRecarga: (bearerToken: string, avisoSaldoMinimo: CadastroSaldoMinimo)
    : Promise<RespostaApi<ResultadoApiRecarga>> => axios.post(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/CadastrarAvisoRecarga`, avisoSaldoMinimo,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
    },
  ),

  obterDadosUltimaRecarga: (bearerToken: string)
    : Promise<RespostaApi<ResultadoApiRecargaUltimaRecarga>> => axios.get(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/ObterUltimaRecarga`,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
    },
  ),

  obterPlanoCliente: (bearerToken: string)
    : Promise<RespostaApi<ResultadoApiRecargaPlanoCliente>> => axios.get(
    `${(window as any).config.url_api_management}portalpj/v1/Recarga/ObterPlanoCliente`,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
    },
  ),
};
