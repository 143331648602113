import * as React from 'react';
import Campo, { PropsBase } from '../CampoBase';
import Spinner from '../../Spinner';
import './CampoTexto.scss';

interface Props extends PropsBase {
  password?: boolean,
  mostrarLoading?: boolean,
}

export default ({
  name,
  titulo,
  value,
  disabled = false,
  password,
  meiaColuna,
  mensagemValidacao,
  onChange,
  onBlur,
  mostrarLoading,
}: Props) => (
  <Campo
    name={name}
    titulo={titulo}
    value={value}
    disabled={disabled}
    type={`${password ? 'password' : 'text'}`}
    meiaColuna={meiaColuna}
    mensagemValidacao={mensagemValidacao}
    classeExtra='campo-texto'
    onChange={onChange}
    onBlur={onBlur}
  >
    {mostrarLoading ? (<Spinner />) : (null)}
  </Campo>
);
