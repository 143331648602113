import { createStyles, makeStyles } from '@material-ui/core';

const SelectRecargaStyles = makeStyles(() => createStyles({
  SelectStyle: {
    borderRadius: '4px',
    width: '200px',
  },
}));

export default SelectRecargaStyles;
