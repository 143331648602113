import { makeStyles } from '@material-ui/core';

const InputStyles = makeStyles({
  input: {
    padding: '16px',
  },
  root: {
    backgroundColor: '#fff !important',
    border: '1px solid',
    borderColor: '#BEC4CC',
    borderRadius: '10px',
    color: '#2A3037',
    width: '100%',
  },
  disabled: {
    backgroundColor: '#BEC4CC !important',
    borderRadius: '10px',
    color: '#fff',
  },
  error: {
    borderColor: '#B52626',
  },
});

export default InputStyles;
