import api from '../../services/api';

interface DadosRecarga {
  valor: number,
  dadosDoCartao?: string,
  dadosDoDispositivo?: string,
  nomeDoTitularDoCartao?: string
}

export const obterCartaoFavorito = async () : Promise<string> => {
  const resposta = await api.get('MeiosPagamentos/CartaoDeCredito/Favorito');

  if (resposta.status !== 200 || resposta.data.sucesso === false) {
    return '';
  }

  if (resposta.data.dados === null) {
    return '';
  }

  return resposta.data.dados.quatroUltimosDigitos;
};

export const realizarRecarga = async (dados : DadosRecarga) : Promise<boolean> => {
  const resposta = await api.post('Recarga/CadastrarRecargaCartaoCredito', dados);

  if (resposta.status !== 200 || resposta.data.sucesso === false) {
    return false;
  }

  return true;
};


export const tokenBraintree = async () : Promise<string> => {
  const resposta = await api.get('MeiosPagamentos/Token');

  if (resposta.status !== 200 || resposta.data.sucesso === false) {
    return '';
  }

  if (resposta.data.dados === null) {
    return '';
  }

  return resposta.data.dados.token;
};
