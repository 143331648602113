import React from 'react';
import '../../Paginacao/Paginacao.scss';

const avancarPagina = require('../../Paginacao/images/avancar-item.png');
const voltarPagina = require('../../Paginacao/images/voltar-item.png');

interface Props {
  totalPaginas: number,
  paginaSelecionada: number,
  registrosPorPagina: number,
  onMudarPaginacaoChange: any,
  onVoltarPagina: any,
  alterarPaginaSelecionadaManual: any;
}

const Paginacao = ({ totalPaginas,
  paginaSelecionada,
  registrosPorPagina,
  onMudarPaginacaoChange,
  onVoltarPagina,
  alterarPaginaSelecionadaManual } : Props) => {
  const ObterPrimeiraPagina = (): number => {
    if (paginaSelecionada >= 6 && totalPaginas > 10) {
      return paginaSelecionada - 4;
    }

    return 1;
  };

  const GerarPaginas = () => {
    const lista = [];
    const primeiraPagina = ObterPrimeiraPagina();

    let pagina = lista.length + primeiraPagina;

    while (lista.length < 10 && pagina < totalPaginas) {
      pagina = lista.length + primeiraPagina;

      const paginaAtual = pagina;

      lista.push(
        <li
          key={paginaAtual}
          className={`item-pagina ${paginaSelecionada === (pagina) ? 'item-selecionado' : ''}`}
        >
          <button
            type='button'
            title={`Acessar página ${pagina}`}
            data-testid={`selecionar-pagina-${pagina}`}
            onClick={() => alterarPaginaSelecionadaManual(paginaAtual)}
          >
            {pagina}
          </button>
        </li>,
      );
    }

    return lista;
  };

  return (
    <section id='paginacao' style={{ border: 0 }}>
      <ul className='botoes-paginacao'>

        { paginaSelecionada > 1 ? (
          <React.Fragment>
            <li className='navegador-pagina-anterior'>
              <button
                title='voltar página'
                type='button'
                onClick={() => onVoltarPagina(paginaSelecionada - 1, registrosPorPagina)}
                onKeyDown={() => onVoltarPagina(paginaSelecionada - 1, registrosPorPagina)}
              >
                <img alt='voltar página' src={voltarPagina} />
              </button>
            </li>
          </React.Fragment>
        ) : null }

        { GerarPaginas() }

        { paginaSelecionada !== totalPaginas && totalPaginas !== 0 ? (
          <React.Fragment>
            <li className='navegador-pagina-posterior'>
              <button
                title='avançar página'
                type='button'
                onClick={() => onMudarPaginacaoChange(paginaSelecionada + 1, registrosPorPagina)}
                onKeyDown={() => onMudarPaginacaoChange(paginaSelecionada + 1, registrosPorPagina)}
              >
                <img alt='avançar página' src={avancarPagina} />
              </button>
            </li>
          </React.Fragment>
        ) : null }
      </ul>
    </section>
  );
};

export default Paginacao;
