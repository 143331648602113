import { Action } from '../interfaces';
import {
  Types,
  AtribuirPlacaSelecionadaAction,
  ToggleBotaoConcluirTrocaAction,
  AtribuirMensagemAbrirModalErroAction,
  AtualizarDadosSubstituicaoAction,
  AtualizarDadosSubstituicaoVeiculoAction,
} from './SubstituicaoAdesivoActions';
import { SubstituicaoAdesivoState } from './SubstituicaoAdesivoInterfaces';

const INITIAL_STATE = {
  placaSelecionada: '',
  formularioInicialAberto: false,
  habilitarBotoesSolicitacaoSubstituicao: false,
  habilitarBotaoConcluirTroca: false,

  mostrarModalErro: false,
  mensagemModalErro: '',

  dadosSubstituicao: {
    adesaoAtualId: {
      valido: false,
      valor: null,
    },
    motivoSubstituicao: {
      valido: false,
      valor: null,
    },
    numeroSerieTagAtual: {
      valido: false,
      valor: '',
    },
    numeroSerieTagNova: {
      valido: false,
      valor: '',
    },
    numeroSerieTagNovaConfirmacao: {
      valido: false,
      valor: '',
    },
    veiculo: {
      ano: {
        valido: false,
        valor: '',
      },
      categoriaId: {
        valido: false,
        valor: null,
      },
      marca: {
        valido: false,
        valor: null,
      },
      modelo: {
        valido: false,
        valor: null,
      },
      placa: {
        valido: false,
        valor: '',
      },
    },
  },

} as SubstituicaoAdesivoState;

const atribuirPlacaSelecionada = (state: SubstituicaoAdesivoState, action: AtribuirPlacaSelecionadaAction) => (
  { ...state, placaSelecionada: action.placaSelecionada });

const abrirFormularioInicial = (state: SubstituicaoAdesivoState) => (
  { ...state, formularioInicialAberto: true });

const habilitarBotoesSolicitacaoSubstituicao = (state: SubstituicaoAdesivoState) => (
  { ...state, habilitarBotoesSolicitacaoSubstituicao: true });

const toggleBotaoConcluirTroca = (state: SubstituicaoAdesivoState, action: ToggleBotaoConcluirTrocaAction) => (
  { ...state, habilitarBotaoConcluirTroca: action.habilitar });

const fecharModalErro = (state: SubstituicaoAdesivoState) => (
  { ...state, mensagemModalErro: '', mostrarModalErro: false });

const atribuirMensagemAbrirModalErro = (state: SubstituicaoAdesivoState, action: AtribuirMensagemAbrirModalErroAction) => (
  { ...state, mensagemModalErro: action.mensagem, mostrarModalErro: true });

const atualizarDadosSubstituicao = (state: SubstituicaoAdesivoState, action: AtualizarDadosSubstituicaoAction) => ({
  ...state,
  dadosSubstituicao: {
    ...state.dadosSubstituicao,
    [action.nomeProp]: action.valorProp,
  },
});

const atualizarDadosSubstituicaoVeiculo = (state: SubstituicaoAdesivoState, action: AtualizarDadosSubstituicaoVeiculoAction) => ({
  ...state,
  dadosSubstituicao: {
    ...state.dadosSubstituicao,
    veiculo: {
      ...state.dadosSubstituicao.veiculo,
      [action.nomeProp]: action.valorProp,
    },
  },
});

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.AtribuirPlacaSelecionadaAction:
      return atribuirPlacaSelecionada(state, action as AtribuirPlacaSelecionadaAction);
    case Types.AbrirFormularioInicialAction:
      return abrirFormularioInicial(state);
    case Types.HabilitarBotoesSolicitacaoSubstituicaoAction:
      return habilitarBotoesSolicitacaoSubstituicao(state);
    case Types.ToggleBotaoConcluirTrocaAction:
      return toggleBotaoConcluirTroca(state, action as ToggleBotaoConcluirTrocaAction);


    case Types.FecharModalErroAction:
      return fecharModalErro(state);
    case Types.AtribuirMensagemAbrirModalErroAction:
      return atribuirMensagemAbrirModalErro(state, action as AtribuirMensagemAbrirModalErroAction);


    case Types.AtualizarDadosSubstituicaoAction:
      return atualizarDadosSubstituicao(state, action as AtualizarDadosSubstituicaoAction);
    case Types.AtualizarDadosSubstituicaoVeiculoAction:
      return atualizarDadosSubstituicaoVeiculo(state, action as AtualizarDadosSubstituicaoVeiculoAction);

    default:
      return state;
  }
};
