import * as React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './RangeDatePicker.scss';
import Botao from '../Botao';

interface Props {
  dataInicio: Date,
  dataFim: Date,
  onClose: Function,
  limiteRange?: number,
}

const meses = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
];

const diasDaSemanaAbreviados = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

const diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const primeiroDiaDaSemana = 1;

const labels = { nextMonth: 'Próximo mês', previousMonth: 'Mês anterior' };

const RangeDatePicker = ({ dataInicio, dataFim, onClose, limiteRange = 60 }: Props) => {
  const node = React.useRef<HTMLDivElement>(document.createElement('div'));

  const [ateMes, setAteMes] = React.useState<Date>(dataFim);
  const [dataInicial, setDataInicial] = React.useState<Date>(dataInicio);
  const [dataFinal, setDataFinal] = React.useState<Date>(dataFim);
  const [entrouNaData, setEntrouNaData] = React.useState<Date>(dataFim);
  const [estaVisivel, setEstaVisivel] = React.useState<boolean>(false);
  const [mes, setMes] = React.useState<Date>(dataFim);

  const resetar = (voltarParaUltimoRangeValido = false) => {
    setAteMes(voltarParaUltimoRangeValido ? (dataFim || new Date()) : new Date());
    setDataFinal(voltarParaUltimoRangeValido ? dataFim : null);
    setDataInicial(voltarParaUltimoRangeValido ? dataInicio : null);
    setEntrouNaData(voltarParaUltimoRangeValido ? dataFim : null);
    setMes(voltarParaUltimoRangeValido ? dataFim : null);
  };

  const estaSelecionandoDataInicial = (dia: Date): boolean => {
    const estaAntesDoPrimeiroDia = dataInicial && DateUtils.isDayBefore(dia, dataInicial);
    const selecionouRange = Boolean(dataInicial && dataFinal);

    return !dataInicial || estaAntesDoPrimeiroDia || selecionouRange;
  };

  const obterRangeLimite = (): Date => {
    if (!dataInicial) return undefined;

    if (dataFinal) return dataFinal;

    const dataRangeTrintaDias = new Date(dataInicial);
    dataRangeTrintaDias.setDate(dataRangeTrintaDias.getDate() + (limiteRange - 1));

    return dataRangeTrintaDias;
  };

  const estaNoMesmoDia = (diaUm: Date, diaDois: Date): boolean => ((!diaUm || !diaDois) ? false
    : diaUm.getDate() === diaDois.getDate()
    && diaUm.getMonth() === diaDois.getMonth()
    && diaUm.getFullYear() === diaDois.getFullYear());

  const exibindoUltimosDias = (dias: number): boolean => {
    if (!dataInicial || !dataFinal) return false;

    const hoje = new Date();

    if (!estaNoMesmoDia(dataFinal, hoje)) return false;

    const diasAtras = new Date(hoje);
    diasAtras.setDate(diasAtras.getDate() - (dias - 1));

    return estaNoMesmoDia(dataInicial, diasAtras);
  };

  const toggleVisivel = (visivel: boolean, novaDataFinal: Date = null) => {
    setEstaVisivel(visivel);

    const dataIniciaIgual = estaNoMesmoDia(dataInicial, dataInicio);
    const dataFinalIgual = estaNoMesmoDia(dataFinal, novaDataFinal);
    const rangeIgual = dataIniciaIgual && dataFinalIgual;

    if (novaDataFinal && !rangeIgual) {
      onClose(dataInicial, novaDataFinal);
    } else if (!dataInicial || !dataFinal) {
      resetar(true);
    }
  };

  const selecionarDataInicial = (data: Date) => {
    setDataInicial(data);
    setDataFinal(null);
    setEntrouNaData(null);
  };

  const selecionarDataFinal = (data: Date) => {
    setDataFinal(data);
    setEntrouNaData(data);
    setAteMes(data);
    toggleVisivel(false, data);
  };

  const tratarCliqueNoDia = (dia: Date) => {
    if (dia > new Date() || dia > obterRangeLimite()) return;

    if (dataInicial && dataFinal && dia >= dataInicial && dia <= dataFinal) {
      resetar();
      return;
    }

    if (estaSelecionandoDataInicial(dia)) selecionarDataInicial(dia);
    else selecionarDataFinal(dia);
  };

  const tratarMouseNoDia = (dia: Date) => {
    if (!estaSelecionandoDataInicial(dia)) setEntrouNaData(dia);
  };

  const obterDescricaoPeriodoSelecionado = (): string => {
    if (!dataInicial && !dataFinal) return 'Não selecionado';

    if (dataInicial && !dataFinal) return `${dataInicial.toLocaleDateString('pt-BR')} - `;

    if (exibindoUltimosDias(30)) return 'Últimos 30 dias';

    if (exibindoUltimosDias(15)) return 'Últimos 15 dias';

    if (exibindoUltimosDias(7)) return 'Últimos 7 dias';

    return `${dataInicial.toLocaleDateString('pt-BR')} - 
      ${dataFinal.toLocaleDateString('pt-BR')}`;
  };

  const obterUltimosDias = (ultimosDias: number): any => {
    const ate = new Date();
    const de = new Date(ate);
    de.setDate(de.getDate() - (ultimosDias - 1));

    return { dataInicio: de, dataFim: ate };
  };

  const autoSelecaoUltimosDias = (ultimosDias: number) => {
    if (exibindoUltimosDias(ultimosDias)) {
      resetar();
      return;
    }

    const periodo = obterUltimosDias(ultimosDias);
    onClose(periodo.dataInicio, periodo.dataFim);
    setEstaVisivel(false);
    setMes(periodo.dataFim);
    setEntrouNaData(periodo.dataFim);
  };

  const handleCliqueFora = (e: any) => {
    if (!node.current.contains(e.target) && (!dataInicial || !dataFinal)) {
      setEstaVisivel(false);
      setDataInicial(dataInicio);
      setDataFinal(dataFim);
      setEntrouNaData(dataFim);
      return;
    }

    if (!node.current.contains(e.target)) setEstaVisivel(false);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleCliqueFora);
    return () => document.removeEventListener('mousedown', handleCliqueFora);
  }, [handleCliqueFora]);

  React.useEffect(() => {
    setDataInicial(dataInicio);
    setDataFinal(dataFim);
  }, [dataInicio, dataFim]);

  const modificadores = { start: dataInicial, end: entrouNaData };

  const diasDesabilitados = [
    { after: obterRangeLimite() },
    { after: new Date() },
  ];

  const diasSelecionados = [dataInicial, { from: dataInicial, to: entrouNaData }];

  return (
    <div className='date-picker-container' ref={node}>
      <div className='date-picker-header'>
        <div className={`date-picker-title ${estaVisivel ? 'laranja' : ''}`}>Período</div>
        <div
          onClick={() => toggleVisivel(!estaVisivel)}
          onKeyDown={() => toggleVisivel(!estaVisivel)}
          className='date-picker-info'
          role='button'
          tabIndex={0}
        >
          <span
            className={
              `date-picker-selected 
              ${estaVisivel ? 'aberto' : 'fechado'} 
              ${dataInicial && dataFinal ? 'laranja' : ''}
            `}
            title={estaVisivel ? 'Esconder Seleção' : 'Exibir Seleção'}
            id='ExtratoFiltroPeriodo'
          >
            {obterDescricaoPeriodoSelecionado()}
          </span>
        </div>
      </div>

      <div className={`date-picker ${estaVisivel ? '' : 'hidden'}`} data-testid='rangeDatePicker'>
        <DayPicker
          locale='pt'
          months={meses}
          weekdaysLong={diasDaSemana}
          weekdaysShort={diasDaSemanaAbreviados}
          firstDayOfWeek={primeiroDiaDaSemana}
          labels={labels}
          className='Range'
          numberOfMonths={2}
          toMonth={ateMes}
          month={mes}
          selectedDays={diasSelecionados}
          disabledDays={diasDesabilitados}
          modifiers={modificadores}
          onDayClick={tratarCliqueNoDia}
          onDayMouseEnter={tratarMouseNoDia}
        />

        <div className={`date-picker-footer ${estaVisivel ? '' : 'hidden'}`}>
          <div>Atalhos:</div>
          <Botao
            identificador='AplicarFiltroDatePicker'
            texto='Últimos 7 dias'
            cheio={exibindoUltimosDias(7)}
            onClick={() => autoSelecaoUltimosDias(7)}
            largura='12em'
          />
          <Botao
            identificador='AplicarFiltroDatePicker'
            texto='Últimos 15 dias'
            cheio={exibindoUltimosDias(15)}
            onClick={() => autoSelecaoUltimosDias(15)}
            largura='12em'
          />
          <Botao
            identificador='AplicarFiltroDatePicker'
            texto='Últimos 30 dias'
            cheio={exibindoUltimosDias(30)}
            onClick={() => autoSelecaoUltimosDias(30)}
            largura='12em'
          />
        </div>
      </div>
    </div>
  );
};

export default RangeDatePicker;
