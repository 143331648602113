import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Actions from '../CadastroActions';
import Contador from '../../../components/Inputs/Contador';
import { Plano, CadastroState, Etapas, EtapasRotas } from '../CadastroInterfaces';
import { State } from '../../interfaces';

import CadastroErroServidor from '../CadastroErroServidor';
import CadastroCabecalho from '../CadastroCabecalho';
import CadastroResumo from '../CadastroResumo';
import CadastroFooter from '../CadastroFooter';
import SimuladorInvestimento from './PlanoPageSimulador';
import RadioButton from '../../../components/Inputs/RadioButton';
import { DadosIntegracaoPlataforma } from '../../../infrastructure/prospecto-api/rascunho/RascunhoInterfaces';

import '../Cadastro.scss';
import './CadastroPlanoPage.scss';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { canalVendasId, rascunhoId } = useParams();

  const {
    veiculosLeves,
    veiculosPesados,
    possuiAdesivos,

  } = useSelector<State, Plano>(state => state.cadastro.plano);

  const {
    etapaAtual,
    canalVendasHash,
  } = useSelector<State, CadastroState>(state => state.cadastro);

  const {
    plataformaDescricao,
  } = useSelector<State, DadosIntegracaoPlataforma>(state => state.cadastro.dadosIntegracaoPlataforma);

  const erroServidor = useSelector<State, string>(state => state.cadastro.erroServidor);

  React.useEffect(() => {
    document.title = 'ConectCar Frotas - Cadastro - ConectCar';

    if (rascunhoId) dispatch(Actions.obterRascunho(rascunhoId));
    if (canalVendasId) dispatch(Actions.obterPlano(canalVendasId));

    if (etapaAtual !== Etapas.Plano) {
      history.push(EtapasRotas.get(etapaAtual)!);
    }
  }, [dispatch, canalVendasId, rascunhoId, etapaAtual, history]);

  const handleContadorVeiculosLeves = (quantidadeVeiculosLeves) => {
    dispatch(Actions.atribuirVeiculosLeves(quantidadeVeiculosLeves));
  };

  const handleContadorVeiculosPesados = (quantidadeVeiculosPesados) => {
    dispatch(Actions.atribuirVeiculosPesados(quantidadeVeiculosPesados));
  };

  const camposEstaoValidos = () => {
    if (!canalVendasHash) return false;

    return (veiculosLeves > 0 || veiculosPesados > 0 || possuiAdesivos) && !erroServidor;
  };

  const handlePossuiAdesivosClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(Actions.atribuirPossuiAdesivos(event.target.value === 'true'));
  };

  const validarSeOrigemLandingPageHubspot = () => plataformaDescricao?.toLowerCase() === 'hubspot';

  const avancarProximoPasso = () => {
    dispatch(Actions.avancarProximaEtapa());
    dispatch(Actions.enviarRascunho());
    history.push(EtapasRotas.get(Etapas.Representante)!);
  };

  return (
    <section data-testid='cadastro-plano-page' id='cadastro-plano-page' className='cadastro-page'>
      <section className='conteudo'>
        <CadastroCabecalho />
        <CadastroResumo />

        <section className='cadastro-plano'>
          <CadastroErroServidor />

          <h1 className='titulo-pagina'>Boas vindas à ConectCar</h1>
          <section>
            <RadioButton
              onClick={handlePossuiAdesivosClick}
              nome='possuiAdesivos'
              valorSelecionado={possuiAdesivos?.toString()}
              itens={[
                { valor: 'true', nome: 'Já tenho adesivos', id: 1, class: '' },
                { valor: 'false', nome: 'Ainda não tenho adesivos', id: 2, class: '' },
              ]}
            />
          </section>
          <section className={possuiAdesivos ? 'contadores esconde' : 'contadores'}>
            <React.Fragment>
              <Contador
                name='contador-veiculos-leves'
                titulo={validarSeOrigemLandingPageHubspot() ? 'Selecione a quantidade de tags' : 'Veículos Leves'}
                value={veiculosLeves?.toString()}
                onChange={handleContadorVeiculosLeves}
                campoUnicoLinha={validarSeOrigemLandingPageHubspot()}
              />
              {!validarSeOrigemLandingPageHubspot()
                && (
                  <Contador
                    name='contador-veiculos-pesados'
                    titulo='Veículos Pesados'
                    value={veiculosPesados?.toString()}
                    onChange={handleContadorVeiculosPesados}
                  />
                )
              }
            </React.Fragment>
          </section>
          <section>
            <div className='information'>
              Sua frota possui 30 veículos ou mais? Envie um e-mail com seus principais dados e necessidades para<a href='mailto:empresas@conectcar.com'> empresas@conectcar.com </a>para que a gente possa te atender melhor.
            </div>
          </section>
          {!possuiAdesivos && <SimuladorInvestimento />}
          <button
            data-testid='cadastro-plano-proximo-passo'
            type='button'
            className={camposEstaoValidos() ? 'botao-preenchido-azul' : 'botao-preenchido-cinza-clarissimo'}
            onClick={avancarProximoPasso}
            title='Quero contratar'
            disabled={!camposEstaoValidos()}
          >
            Quero contratar
          </button>

          <CadastroFooter />
        </section>
      </section>
    </section>
  );
};
