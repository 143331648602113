/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import DropIn from 'braintree-web-drop-in-react';
import { useHistory } from 'react-router';
import CartaoValor from '../../components/CartaoValor/CartaoValor';
import ResumoRecarga from './ResumoRecarga/ResumoRecarga';
import FinalizaRecargaPage from './Resultados/FinalizaRecargaPage';
import SimboloRecarga from '../../assets/images/icones/simbolo-preco.svg';
import CartaoDeCredito from '../../assets/images/icones/cartaodecredito.svg';
import Canetinha from '../../assets/images/icones/canetinha.svg';
import RecargaCartaoCreditoPageStyles from './RecargaCartaoCreditoPageStyles';
import { obterCartaoFavorito, realizarRecarga, tokenBraintree } from './cartaoService';
import { possuiAdesao } from '../../utils/possuiAdesao';
import './braintreeStyles.scss';

const RecargaCartaoCreditoPage = () => {
  const styles = RecargaCartaoCreditoPageStyles();
  const history = useHistory();

  const valoresPreDefinidos = [200, 300, 500, 1000, 2000, 3000];
  const [valorDigitado, setValorDigitado] = useState<number>(0);
  const [processadoPagamento, setProcessadoPagamento] = useState(false);
  const [buttonEstado, setButtonEstado] = useState(false);
  const [pagamentoRequisitavel, setPagamentoRequisitavel] = useState(false);
  const [pagamentoSucesso, setPagamentoSucesso] = useState<boolean>(null);
  const [instance, setInstance] = useState(null);
  const [cartaoFavorito, setCartaoFavorito] = useState<string>('');
  const [nonce, setNonce] = useState<any>(null);
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    setButtonEstado(valorDigitado > 100 && pagamentoRequisitavel && instance != null);
  }, [pagamentoRequisitavel, valorDigitado, instance]);

  useEffect(() => {
    if (nonce !== null) {
      efetuarRecarga();
    }
  }, [nonce]);

  useEffect(() => {
    possuiAdesao().then((possui) => {
      if (!possui) {
        history.push('/home');
      }
    }).catch(() => { history.push('/home'); });

    obterCartaoFavorito().then((final) => {
      if (final !== '') {
        setCartaoFavorito(final);
        setPagamentoRequisitavel(true);
      }
    });

    tokenBraintree().then((auth) => {
      setToken(auth);
    });
  }, []);


  const handleCartaoClick = (valorCartao: number) => {
    handleValorChange(valorCartao);
  };

  const handleValorChange = (e: number) => {
    if (e === undefined) {
      setValorDigitado(0);
      return;
    }
    setValorDigitado(e);
  };

  const novoCartaoHandler = () => {
    setPagamentoRequisitavel(false);
    setCartaoFavorito('');
  };

  const handleBotaoPagamento = () => {
    setPagamentoRequisitavel(false);
    if (cartaoFavorito === '') {
      gerarNonce();
    } else {
      efetuarRecarga();
    }
  };

  const efetuarRecarga = () => {
    realizarRecarga({ valor: valorDigitado, dadosDoDispositivo: instance._dataCollector._instance.deviceData, dadosDoCartao: nonce?.nonce, nomeDoTitularDoCartao: nonce?.details?.cardholderName }).then((sucesso) => {
      setPagamentoSucesso(sucesso);
      setProcessadoPagamento(true);
    }).catch(() => {
      setPagamentoSucesso(false);
      setProcessadoPagamento(true);
    });
  };

  const gerarNonce = async () => {
    const dados = await instance.requestPaymentMethod();
    setNonce(dados);
  };

  return (
    processadoPagamento && pagamentoSucesso !== null ? (
      <Box>
        <FinalizaRecargaPage
          recargaSucesso={pagamentoSucesso}
          valorRecargaDigitado={valorDigitado}
        />
      </Box>
    ) : (
      <Box className={styles.boxCartao} style={{ marginTop: '1rem' }}>
        <Box component='div' className={styles.tituloCartao} style={{ padding: '16px' }}>
          <img src={SimboloRecarga} alt='' style={{ width: '27.88px', height: '27.88px' }} />
          <p className={styles.textoTituloCartao}>Recarga Cartão de Crédito</p>
        </Box>
        <Grid container className={styles.containerFormCartao} spacing={4}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box component='div' className={styles.cardValorRecarga}>
              <Typography
                component='p'
                className={styles.tituloFormValor}
              >
                Digite o valor da recarga desejado ou escolha um valor abaixo
              </Typography>
              <NumberFormat
                prefix='R$ '
                decimalScale={2}
                value={valorDigitado === 0 ? '' : valorDigitado}
                decimalSeparator=','
                thousandSeparator='.'
                allowNegative={false}
                allowLeadingZeros={false}
                className={styles.fieldFormValor}
                onValueChange={value => handleValorChange(value.floatValue)}
                id='input-valor-recarga-cartao-credito'
                placeholder='Digite o valor da recarga'
                maxLength={15}
              />
              <Grid container component='div' spacing={1} className={styles.cardsValoresSelecionaveis}>
                {valoresPreDefinidos.map(valorCartao => (
                  <CartaoValor
                    key={valorCartao}
                    id={`valor-cartao-${valorCartao}`}
                    className={styles.cardItemSelecionavel}
                    valor={valorCartao}
                    valorSelecionado={valorDigitado}
                    onClick={async () => handleCartaoClick(valorCartao)}
                  />
                ))}
              </Grid>
            </Box>
            { token !== null
            && (
            <Box component='div' id='form-braintree' style={cartaoFavorito !== '' ? { display: 'none' } : {}}>
              <DropIn
                options={
                      {
                        authorization: token,
                        locale: 'pt_BR',
                        card:
                        {
                          clearFieldsAfterTokenization: true,
                          cardholderName: true,
                          overrides:
                          {
                            fields:
                              { postalCode: null },
                          },
                        },
                        dataCollector: { kount: true },
                      }
                    }
                onInstance={i => setInstance(i)}
                onPaymentMethodRequestable={() => setPagamentoRequisitavel(true)}
                onNoPaymentMethodRequestable={() => setPagamentoRequisitavel(false)}
              />
            </Box>
            )
            }
            {cartaoFavorito !== ''
              && (
                <Box className={styles.cardFavorito}>
                  <Box className={styles.cardFavoritoContent}>
                    <img src={CartaoDeCredito} alt='' style={{ width: '26px', height: '21px' }} />
                    <Box className={styles.cardFavoritoContentTexto}>
                      <p className={styles.cardFavoritoTextoTitulo}>Cartão de Crédito</p>
                      <p className={styles.cardFavoritoTextoSubTitulo}>*** **** **** {cartaoFavorito}</p>
                    </Box>
                  </Box>
                  <Box onClick={() => novoCartaoHandler()} style={{ cursor: 'pointer' }}>
                    <img src={Canetinha} alt='' style={{ width: '13px', height: '13px' }} />
                  </Box>
                </Box>
              )
            }
          </Grid>
          <ResumoRecarga
            renderizarBotaoPagamento
            buttonEstado={buttonEstado}
            handleBotaoPagamento={() => handleBotaoPagamento()}
            valorRecargaDigitado={valorDigitado}
          />
        </Grid>
      </Box>
    )
  );
};

export default RecargaCartaoCreditoPage;
