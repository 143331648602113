
import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './FAQ.scss';

const useStyles = makeStyles((theme: Theme) => createStyles({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function Faq() {
  const classes = useStyles();

  return (
    <div className='rootPrincipaisDuvidas'>
      <p className='pPrincipaisDuvidas'>Principais Dúvidas</p>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fill: '#FF6A38' }} />}
          aria-controls='panel1a-content'
          id='accordionComoAtivarAdesivo'
        >
          <Typography className={classes.heading}>Como ativar um Adesivo?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Basta acessar a tela de VEÍCULOS &gt; Cadastrar Veículos no menu superior, e realizar os seguintes passos:
            {' '}
            <br />
            {' '}
            <br />
            1 - Preencher o campo “Placa” com os dados da placa do veículo que deseja instalar o adesivo;
            {' '}
            <br />
            2 - Preencher o campo “Adesivo” com o código que vem no adesivo/embalagem;
            {' '}
            <br />
            3 - Escolher a categoria na lista “Categoria” de acordo com o tipo de veículo que deseja instalar a tag e após preencher todos os dados, basta clicar em “Ativar Todos”.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fill: '#FF6A38' }} />}
          aria-controls='panel2a-content'
          id='accordionComoFazerRecarga'
        >
          <Typography className={classes.heading}>Como fazer uma Recarga?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Basta acessar a tela de RECARGA no menu superior, escolher a faixa de valor, escolher a forma de pagamento (cada uma tem sua característica) e por fim clicar no botão “Pagar”.
            Você será redirecionado para o respectivo ambiente dependendo da escolha de forma de pagamento.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fill: '#FF6A38' }} />}
          aria-controls='panel3a-content'
          id='accordionComoConsultarExtrato'
        >
          <Typography className={classes.heading}>Como realizar a consulta de Extrato?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Basta acessar a tela de EXTRATO no menu superior, preencher os filtros que deseja visualizar e clicar em “Pesquisar”, o sistema já exibira as transações relacionadas ao filtro selecionado.
            Você também tem a opção de extrair um relatório clicando nos ícones “XLS” e “PDF”.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <div className='ainda-possui-duvidas'>
        Ainda possui dúvidas?
        <a
          id='linkAcesseFaqCompleta'
          href='https://atendimentoconectcar.zendesk.com/hc/pt-br/categories/360003765051-Auto-Atendimento'
          target='_blank'
          rel='noreferrer'
        >
          Acesse a FAQ completa.
        </a>
      </div>
    </div>
  );
}
