import { ButtonBaseProps, makeStyles } from '@material-ui/core';

export const buttonStyles = (props: ButtonBaseProps) => makeStyles(() => ({
  root: {
    backgroundColor: props.disabled ? '#BEC4CC' : '#293B8A',
    border: '1px solid',
    borderColor: props.disabled ? '#BEC4CC' : '#293B8A',
    borderRadius: '30px',
    color: '#fff',
    padding: '8px',
  },
}));
