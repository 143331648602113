import * as React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../features/interfaces';

import './Loading.scss';

const gifLoading = require('../../assets/images/loading.svg');

interface TLoadingProps {
  carregando?: boolean;
}

const Loading: React.FC<TLoadingProps> = ({ carregando }) => {
  const requisicoes = useSelector<State, number>(state => state.requisicoes);

  return (
    <section id='loading' className={`${requisicoes > 0 || carregando ? 'visivel' : ''}`}>
      <img src={gifLoading} alt='loading' />
    </section>
  );
};

export default Loading;
