import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, FormControl, Grid, Typography } from '@material-ui/core';

import { obterPlacasAdesivos } from '../../../../../services/requests/SubstituicaoAdesivo/obterPlacasAdesivos';
import { IPlacaAdesivo } from '../../../../../services/requests/SubstituicaoAdesivo/obterPlacasAdesivos/interface';

import { SubstituicaoAdesivoState } from '../../../SubstituicaoAdesivoInterfaces';
import Actions from '../../../SubstituicaoAdesivoActions';
import { State } from '../../../../interfaces';
import { SubstituicaoAutocomplete } from '../../../shared/SubstituicaoComponentes';
import { MensagemErroPadrao } from '../../../../../components/ModalErro/ModalErroConstantes';
import { obterClassNameBotaoPrecisoNovoAdesivo, obterClassNameBotaoJaPossuoAdesivo, obterClassNameTituloBotoesEscolha } from '../SubstituicaoDadosEstilosCondicionais';

import './SubstituicaoPesquisa.scss';
import './SubstituicaoPesquisaResponsive.scss';

const SubstituicaoAdesivoPesquisa = () => {
  const dispatch = useDispatch();

  const {
    dadosSubstituicao,
    habilitarBotoesSolicitacaoSubstituicao,
    formularioInicialAberto,
  } = useSelector<State, SubstituicaoAdesivoState>(state => ({
    dadosSubstituicao: state.substituicaoAdesivo.dadosSubstituicao,
    habilitarBotoesSolicitacaoSubstituicao: state.substituicaoAdesivo.habilitarBotoesSolicitacaoSubstituicao,
    formularioInicialAberto: state.substituicaoAdesivo.formularioInicialAberto,
  }));

  const [placasAdesivos, setPlacasAdesivos] = useState<IPlacaAdesivo[]>([]);

  const handleAlterarDadoSubstituicao = (prop: string, valor: any) => {
    const dados = dadosSubstituicao[prop];
    dados.valor = valor;

    dispatch(Actions.atualizarDadosSubstituicao(dados, prop));
  };

  const handleSelectPlacasAdesivosChange = (p: IPlacaAdesivo) => {
    handleAlterarDadoSubstituicao('adesaoAtualId', p.adesaoId);
    handleAlterarDadoSubstituicao('numeroSerieTagAtual', p.numeroSerie);

    dispatch(Actions.atribuirPlacaSelecionada(p.placa));
    dispatch(Actions.habilitarBotoesSolicitacaoSubstituicao());
  };

  const handleDirecionarTelaSolicitacaoPedidos = () => {
    window.parent.location.href = `${(window as any).config.url_portal_cliente}/SolicitacaoAdesivosPj`;
  };

  useEffect(() => {
    const obterPlacasAdesivosAsync = async () => {
      await obterPlacasAdesivos()
        .then(response => setPlacasAdesivos(response?.data?.dados?.placasAdesivos))
        .catch(error => dispatch(Actions.atribuirMensagemAbrirModalErro(error?.response?.data?.notificacoes || MensagemErroPadrao)));
    };
    obterPlacasAdesivosAsync();
  }, [dispatch]);

  return (
    <Grid container spacing={0} justifyContent='center'>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='substituicao-pesquisa-autocomplete-grid-item substituicao-grid-flex substituicao-grid-justify-center'>
        <FormControl variant='outlined'>
          <SubstituicaoAutocomplete
            parameters={{
              className: 'substituicao-pesquisa-autocomplete',
              getOptionLabel: (option: IPlacaAdesivo) => `${option?.placa} / ${option?.numeroSerie}`,
              id: 'substituicao-formulario-autocomplete-placas-adesivos',
              onChange: (_e, n: IPlacaAdesivo) => handleSelectPlacasAdesivosChange(n),
              options: placasAdesivos,
              noOptionsText: 'Não há placas/adesivos',
              renderOption: (option: IPlacaAdesivo) => (
                <React.Fragment>
                  {`${option?.placa} / ${option?.numeroSerie}`}
                </React.Fragment>
              ),
            }}
            placeholder='Digite a placa/número do adesivo'
          />
        </FormControl>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='substituicao-pesquisa-titulo-escolha-grid-item substituicao-grid-flex substituicao-grid-justify-center'>
        <Typography
          variant='h5'
          className={obterClassNameTituloBotoesEscolha(habilitarBotoesSolicitacaoSubstituicao)}
        >
          Já possui um novo adesivo para realizar a troca?
        </Typography>
      </Grid>
      <Grid item id='substituicao-pesquisa-btn-novo-adesivo-grid' xl={6} lg={6} md={6} sm={6} xs={12} className='substituicao-grid-flex'>
        <Button
          className={obterClassNameBotaoPrecisoNovoAdesivo(habilitarBotoesSolicitacaoSubstituicao)}
          disabled={!habilitarBotoesSolicitacaoSubstituicao}
          id='substituicao-pesquisa-btn-novo-adesivo'
          onClick={handleDirecionarTelaSolicitacaoPedidos}
          type='button'
          variant='contained'
        >
          Preciso de um novo adesivo
        </Button>
      </Grid>
      <Grid item id='substituicao-pesquisa-btn-possui-adesivo-grid' xl={6} lg={6} md={6} sm={6} xs={12} className='substituicao-grid-flex'>
        <Button
          className={obterClassNameBotaoJaPossuoAdesivo(habilitarBotoesSolicitacaoSubstituicao, formularioInicialAberto)}
          disabled={!habilitarBotoesSolicitacaoSubstituicao}
          id='substituicao-pesquisa-btn-possui-adesivo'
          onClick={() => dispatch(Actions.abrirFormularioInicial())}
          type='button'
          variant='contained'
        >
          Já tenho um novo adesivo
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(SubstituicaoAdesivoPesquisa);
