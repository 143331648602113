import * as React from 'react';
import { FiberManualRecord } from '@material-ui/icons';

const mensagensImportantes = [
  'Se o boleto não for pago até a data do vencimento o pedido será automaticamente cancelado.',
  'O prazo de entrega dos pedidos pagos com boleto bancário começa a contar três dias após o pagamento do boleto, '
    + 'tempo necessário para que a instituição bancária confirme o pagamento.',
];

const ListaItemImportantePedidoBoleto = () => (
  <>
    {mensagensImportantes.map((item: string) => (
      <div className='item-lista-importante-container'>
        <span className='item-lista-importante-circulo-container'>
          <FiberManualRecord fontSize='small' className='circulo' />
        </span>
        <p>{item}</p>
      </div>
    ))}
  </>
);

export default ListaItemImportantePedidoBoleto;
