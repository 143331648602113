import React from 'react';
import './style.scss';

const Spinner = () => (
  <div id='spinner-loading'>
    <div className='lds-spinner' title='spinner'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Spinner;
