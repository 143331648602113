import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../interfaces';
import { RecargaState } from '../../RecargaInterfaces';
import Actions from '../../RecargaActions';

const CarregarRetornoBoletoFlash = () => {
  const {
    dadosRecargaBoletoFlash,
  } = useSelector<State, RecargaState>(state => ({
    dadosRecargaBoletoFlash: state.recarga.dadosRecargaBoletoFlash,
  }));

  const [dadosBoletoFlash] = React.useState(dadosRecargaBoletoFlash);

  const dispatch = useDispatch();

  React.useEffect(() => {
    window.open(dadosRecargaBoletoFlash?.dados?.valores?.dadosRecarga?.url, '_blank');
    dispatch(Actions.descarregarBoletoFlash());
  }, [dadosRecargaBoletoFlash.dados.valores.dadosRecarga.url, dispatch, dadosBoletoFlash]);

  return null;
};

export default CarregarRetornoBoletoFlash;
