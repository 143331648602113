import { Action } from '../interfaces';

export enum Types {
  InicializarSubstituicaoAdesivoAction = 'INICIALIZAR_SUBSTITUICAO_ADESIVO',

  AtribuirPlacaSelecionadaAction = 'ATRIBUIR_PLACA_SELECIONADA',

  AbrirFormularioInicialAction = 'ABRIR_FORMULARIO_INICIAL',
  HabilitarBotoesSolicitacaoSubstituicaoAction = 'HABILITAR_BOTOES_SOLICITACAO_SUBSTITUICAO',
  ToggleBotaoConcluirTrocaAction = 'TOGGLE_BOTAO_CONCLUIR_TROCA',

  FecharModalErroAction = 'FECHAR_MODAL_ERRO',
  AtribuirMensagemAbrirModalErroAction = 'ATRIBUIR_MENSAGEM_ABRIR_MODAL_ERRO',

  AtualizarDadosSubstituicaoAction = 'ATUALIZAR_DADOS_SUBSTITUICAO',
  AtualizarDadosSubstituicaoVeiculoAction = 'ATUALIZAR_DADOS_SUBSTITUICAO_VEICULO',
}

export interface InicializarSubstituicaoAdesivoAction extends Action { }

export interface AtribuirPlacaSelecionadaAction extends Action { placaSelecionada: string }

export interface AbrirFormularioInicialAction extends Action { }
export interface HabilitarBotoesSolicitacaoSubstituicaoAction extends Action { }
export interface ToggleBotaoConcluirTrocaAction extends Action { habilitar: boolean }

export interface FecharModalErroAction extends Action { }
export interface AtribuirMensagemAbrirModalErroAction extends Action { mensagem: string }

export interface AtualizarDadosSubstituicaoAction extends Action { valorProp: any, nomeProp: string }
export interface AtualizarDadosSubstituicaoVeiculoAction extends Action { valorProp: any, nomeProp: string }

export default {
  inicializarSubstituicaoAdesivo: (): InicializarSubstituicaoAdesivoAction => ({ type: Types.InicializarSubstituicaoAdesivoAction }),

  atribuirPlacaSelecionada: (placaSelecionada: string): AtribuirPlacaSelecionadaAction => ({ type: Types.AtribuirPlacaSelecionadaAction, placaSelecionada }),

  abrirFormularioInicial: (): AbrirFormularioInicialAction => ({ type: Types.AbrirFormularioInicialAction }),
  habilitarBotoesSolicitacaoSubstituicao: (): HabilitarBotoesSolicitacaoSubstituicaoAction => ({ type: Types.HabilitarBotoesSolicitacaoSubstituicaoAction }),
  habilitarBotaoConcluirTroca: (habilitar: boolean): ToggleBotaoConcluirTrocaAction => ({ type: Types.ToggleBotaoConcluirTrocaAction, habilitar }),

  fecharModalErro: (): FecharModalErroAction => ({ type: Types.FecharModalErroAction }),
  atribuirMensagemAbrirModalErro: (mensagem: string): AtribuirMensagemAbrirModalErroAction => ({ type: Types.AtribuirMensagemAbrirModalErroAction, mensagem }),

  atualizarDadosSubstituicao: (valorProp: any, nomeProp: string): AtualizarDadosSubstituicaoAction => ({ type: Types.AtualizarDadosSubstituicaoAction, valorProp, nomeProp }),
  atualizarDadosSubstituicaoVeiculo: (valorProp: any, nomeProp: string): AtualizarDadosSubstituicaoVeiculoAction => ({ type: Types.AtualizarDadosSubstituicaoVeiculoAction, valorProp, nomeProp }),
};
