import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';
import React, { memo } from 'react';
import Header from './Header';
import Informacoes from './Informacoes';
import Prestador from './Prestador';
import Tomador from './Tomador';
import { DadosCliente } from '../../services/requests/SolicitacaoAdesivo/comprovanteRecargaRequest/interface';
import { DadosFiltro } from '../historico-recarga/HistoricoRecargaInterfaces';

interface Props {
  dadosFiltro : DadosFiltro
  dadosCliente : DadosCliente
}

const styles = StyleSheet.create({
  documento: {
    margin: '70px 40px',
    display: 'flex',
  },
});

const Comprovante = ({ dadosFiltro, dadosCliente }:Props) => (dadosCliente
  && (
  <Document>
    <Page size='A4'>
      <View style={styles.documento}>
        <Header pedidoId={dadosFiltro.pedidoId} />
        <Prestador />
        <Tomador dados={dadosCliente} />
        <Informacoes data={dadosFiltro.data} formaPagamento={dadosFiltro.formaPagamento} valor={dadosFiltro.valor} />
      </View>
    </Page>
  </Document>
  )
);

export default memo(Comprovante);
