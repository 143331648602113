export default () => {
  const interval2 = setInterval(() => {
    const zen = document.querySelector('#web-messenger-container');
    if (zen) {
      zen.remove();
      clearInterval(interval2);
    }
  }, 500);


  const interval = setInterval(() => {
    const elementoChatbot = document.querySelector('.wrap-p-smk-o');
    const elementoLgpdCookie = document.querySelector('.lgpd-bottom-cotainer');

    if (elementoChatbot) {
      elementoChatbot.remove();
      clearInterval(interval);
    }

    if (elementoLgpdCookie) {
      elementoLgpdCookie.remove();
      clearInterval(interval);
    }
  }, 500);
};
