import axios from 'axios';
import FileSaver from 'file-saver';
import { RespostaApi } from '../ApiInterfaces';
import { ItemTabela, Parametros } from '../../features/extrato/ExtratoInterfaces';
import { DropdownItem } from '../../components/Inputs/Dropdown/DropdownInterface';

const urlBase = 'portalpj/';

export interface Saldo {
  id: number,
  saldoCompartilhado: boolean,
  valor: number,
}

export interface Filtros {
  centrosDeCusto: number[],
  placas: string[],
}

export interface Extrato {
  resultado: ItemTabela[],
  totalPaginas: number,
  paginaSelecionada: number,
}

const obterParametrosFormatados = (parametros, placas: DropdownItem[], centrosCusto: DropdownItem[], tiposTransacao: DropdownItem[]) => {
  let parametrosFormatados = `dataInicioTransacao=${parametros.filtrosSelecionados.dataInicio.toJSON()}`;
  parametrosFormatados += `&dataFimTransacao=${parametros.filtrosSelecionados.dataFim.toJSON()}`;
  parametrosFormatados += `&pagina=${parametros.paginaSelecionada}`;
  parametrosFormatados += `&quantidade=${parametros.registrosPorPagina}`;

  if (placas.length > 0) {
    const parametroPlacas = placas.map(placa => `&Placa=${placa.texto}`).join('');
    parametrosFormatados += parametroPlacas;
  }

  if (centrosCusto.length > 0) {
    const parametroCentrosCusto = centrosCusto.map(centroCusto => `&CentroCusto=${centroCusto.texto}`).join('');

    parametrosFormatados += parametroCentrosCusto;
  }

  if (tiposTransacao.length > 0) {
    tiposTransacao.forEach((tipoTransacao) => {
      parametrosFormatados += `&TipoOperacao=${tipoTransacao.valor}`;

      if (tipoTransacao.origem != null) {
        parametrosFormatados += '&TipoOperacao=1';
      }
    });
  }

  if (tiposTransacao.length > 0) {
    tiposTransacao.forEach((tipoTransacao) => {
      if (tipoTransacao.origem != null) {
        parametrosFormatados += `&recargaOrigemIds=${tipoTransacao.origem}`;
      }
    });
  }
  return parametrosFormatados;
};


export default {
  buscarSaldo: (bearerToken: string): Promise<RespostaApi<Saldo[]>> => axios.get(
    `${(window as any).config.url_api_management}${urlBase}v1/Filtro/obterSaldo`,
    {
      headers:
      {
        'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
        Authorization: `Bearer ${bearerToken}`,
      },
    },
  ),

  buscarFiltros: (token: string): Promise<RespostaApi<Filtros>> => axios.get(
    `${(window as any).config.url_api_management}${urlBase}v1/Filtro/obterFiltroExtrato`,
    {
      headers:
      {
        'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
        Authorization: `Bearer ${token}`,
      },
    },
  ),

  buscarExtrato: (parametros: Parametros, token: string)
    : Promise<RespostaApi<Extrato>> => {
    const { filtrosSelecionados: { placas, centrosCusto, tiposTransacao } } = parametros;

    const parametrosFormatados = obterParametrosFormatados(parametros, placas, centrosCusto, tiposTransacao);

    return axios.get(
      `${(window as any).config.url_api_management}${urlBase}v1/Filtro/obterTransacoes?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  exportarExtrato: async (bearerToken: string, parametros: Parametros) => {
    const { filtrosSelecionados: { placas, centrosCusto, tiposTransacao } } = parametros;

    const parametrosFormatados = obterParametrosFormatados(parametros, placas, centrosCusto, tiposTransacao);

    const resposta = await axios.get(
      `${(window as any).config.url_api_management}${urlBase}v1/Filtro/exportarExcel?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: 'blob',
      },
    );

    if (resposta.status === 204) {
      alert('Não foram encontrados registros em seu extrato com os filtros informados.');
      return;
    }

    const formatTwoDigits = digit => (`0${digit}`).slice(-2);
    const tempDate = new Date();
    const year = tempDate.getFullYear();
    const month = formatTwoDigits(tempDate.getMonth() + 1);
    const date = formatTwoDigits(tempDate.getDate());
    const hours = formatTwoDigits(tempDate.getHours());
    const minutes = formatTwoDigits(tempDate.getMinutes());
    const seconds = formatTwoDigits(tempDate.getSeconds());
    const formatDate = `${year}${month}${date}${hours}${minutes}${seconds}`;

    const blob = new Blob([resposta.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    FileSaver.saveAs(blob, `Extrato-${formatDate}.xlsx`);
  },

  exportarExtratoPdf: async (bearerToken: string, parametros: Parametros) => {
    const { filtrosSelecionados: { placas, centrosCusto, tiposTransacao } } = parametros;

    const parametrosFormatados = obterParametrosFormatados(parametros, placas, centrosCusto, tiposTransacao);

    const resposta = await axios.get(
      `${(window as any).config.url_api_management}${urlBase}v1/Filtro/exportarPdf?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: 'blob',
      },
    );

    if (resposta.status === 204) {
      alert('Não foram encontrados registros em seu extrato com os filtros informados.');
      return;
    }

    const formatTwoDigits = (digit: number) => (`0${digit}`).slice(-2);
    const tempDate = new Date();
    const year = tempDate.getFullYear();
    const month = formatTwoDigits(tempDate.getMonth() + 1);
    const date = formatTwoDigits(tempDate.getDate());
    const hours = formatTwoDigits(tempDate.getHours());
    const minutes = formatTwoDigits(tempDate.getMinutes());
    const formatDate = `${year}${month}${date}${hours}${minutes}`;
    const blob = new Blob([resposta.data], { type: 'application/pdf;' });
    FileSaver.saveAs(blob, `Extrato-${formatDate}.pdf`);
  },

};
