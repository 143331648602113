import { makeStyles } from '@material-ui/core';

export const IconStyles = (props: boolean) => makeStyles(() => ({
  root: {
    color: !props ? '#fff' : '#979797',
  },
}));

export const TypographyStyles = makeStyles(() => ({
  root: {
    color: '#B52626',
    fontSize: '14px',
  },
}));
