export const obterClassNameFundoFormularioPrincipal = (formularioInicialAberto: boolean, mostrarFormularioTrocaVeiculo: boolean) => {
  if (formularioInicialAberto && !mostrarFormularioTrocaVeiculo) return 'substituicao-formulario-fundo';
  if (formularioInicialAberto && mostrarFormularioTrocaVeiculo) return 'substituicao-formulario-fundo-extendido';
  if (!formularioInicialAberto && !mostrarFormularioTrocaVeiculo) return 'substituicao-formulario-fundo-fechado';

  return '';
};

export const obterClassNameBotaoConcluirTroca = (habilitarBotaoConcluirTroca: boolean) => (habilitarBotaoConcluirTroca ? 'substituicao-formulario-btn-concluir-troca' : 'substituicao-formulario-btn-concluir-troca-disabled');

export const obterClassNameBotaoJaPossuoAdesivo = (habilitarBotoesSolicitacaoSubstituicao: boolean, formularioInicialAberto: boolean) => {
  if (habilitarBotoesSolicitacaoSubstituicao && formularioInicialAberto) return 'substituicao-pesquisa-escolha-btn focus-btn-substituicao';
  if (habilitarBotoesSolicitacaoSubstituicao && !formularioInicialAberto) return 'substituicao-pesquisa-escolha-btn';
  return 'substituicao-pesquisa-escolha-btn-disabled';
};

export const obterClassNameBotaoPrecisoNovoAdesivo = (habilitarBotoesSolicitacaoSubstituicao: boolean) => (habilitarBotoesSolicitacaoSubstituicao
  ? 'substituicao-pesquisa-escolha-btn'
  : 'substituicao-pesquisa-escolha-btn-disabled');

export const obterClassNameTituloBotoesEscolha = (habilitarBotoesSolicitacaoSubstituicao: boolean) => (habilitarBotoesSolicitacaoSubstituicao
  ? 'substituicao-pesquisa-titulo-escolha'
  : 'substituicao-pesquisa-titulo-escolha-disabled');
