import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../features/fatura-pos-pago/FaturaActions';

import './SaldoGlobalFatura.scss';
import { FaturaState } from '../../../features/fatura-pos-pago/FaturaInterfaces';
import { State } from '../../../features/interfaces';
import SaldoResumoFatura from '../SaldoResumoFatura/SaldoResumoFatura';

const locale = 'pt-BR';
const SaldoGlobal = () => {
  const [venciProximaFatura, setVenciProximaFatura] = React.useState('');
  const [venciFaturaAtual, setVenciFaturaAtual] = React.useState('');

  const {
    dadosFatura,
    dadosLimitesSaldo,
  } = useSelector<State, FaturaState>(state => ({
    dadosFatura: state.fatura.dadosFatura,
    erro: state.fatura.erro,
    dadosLimitesSaldo: state.fatura.dadosLimitesSaldo,
    historicoFatura: state.fatura.historicoFatura,
    carregando: false,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Actions.buscarLimiteSaldoRequest());
  }, [dispatch]);

  React.useEffect(() => {
    if (dadosFatura?.dados && dadosFatura?.dados.length > 0) {
      const dataVencimentoProximaFatura = new Date(dadosFatura?.dados[0].dataVencimento);

      dataVencimentoProximaFatura.setMonth(dataVencimentoProximaFatura.getMonth() + 1);

      setVenciProximaFatura(dataVencimentoProximaFatura.toLocaleDateString(locale, { month: 'numeric', day: 'numeric' }));
      setVenciFaturaAtual(new Date(dadosFatura?.dados[0].dataVencimento).toLocaleDateString(locale, { month: 'numeric', day: 'numeric' }));
    }
  }, [dadosFatura.dados]);

  return (
    <section className='container-saldo-fatura'>
      <SaldoResumoFatura
        dataVencimentoAtual={venciFaturaAtual}
        dataVencimentoProxima={venciProximaFatura}
        faturaAtual={dadosLimitesSaldo?.dados?.faturaAtual}
        proximaFatura={dadosLimitesSaldo?.dados?.proximaFatura}
      />
    </section>
  );
};

export default SaldoGlobal;
