import { makeStyles } from '@material-ui/core';

const FaturaHistoricoStyle = makeStyles({
  table: {
    minWidth: 940,
  },
  statusAguardando: {
    background: '#FFB200',
    textAlign: 'center',
    borderRadius: '12px',
  },
  statusPago: {
    background: '#298F7A',
    textAlign: 'center',
    borderRadius: '12px',
    width: '50px',
  },
  statusAtrasado: {
    background: '#B52626',
    textAlign: 'center',
    borderRadius: '12px',
    width: '100px',
  },
  labelStatus: {
    width: 176.5,
    height: 32,
    top: 8,
    left: 207.5,
    fontFamily: 'Lato',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#fff',
  },
  pMes: {
    fontSize: '16px',
    color: '#2A3037',
  },
  btnDownLoadFatura: {
    backgroundColor: '#fff',
    width: '145px',
    border: '1px solid #585d62',
    borderRadius: '16px',
    cursor: 'pointer',
    height: '33px',
    textTransform: 'none',
  },
  spanMensagem: {
    color: '#293B8A',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
  divMensagem: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    padding: 20,
  },
});

export default FaturaHistoricoStyle;
