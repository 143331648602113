import React, { createContext,
  useContext,
  useCallback,
  useState } from 'react';

export interface ILoadingControleContexto {
    carregando: boolean;
    alterarEstadoCarregando: (estado: boolean) => void;
}

export const LoadingControleContexto = createContext<ILoadingControleContexto>({} as ILoadingControleContexto);

const LoadingControleProvider: React.FC = ({ children }) => {
  const [carregando, setCarregando] = useState<boolean>(false);

  const alterarEstadoCarregando = useCallback((estado: boolean) => {
    setCarregando(estado);
  }, []);

  return (
    <LoadingControleContexto.Provider
      value={{
        carregando,
        alterarEstadoCarregando,
      }}
    >
      {children}
    </LoadingControleContexto.Provider>
  );
};

const useLoadingControle = (): ILoadingControleContexto => {
  const contexto = useContext(LoadingControleContexto);

  return contexto;
};

export { LoadingControleProvider, useLoadingControle };
