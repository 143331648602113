import React, { memo, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Button, Grid, makeStyles, Typography,
} from '@material-ui/core';

import { Add, Edit, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import { IEnderecoCliente } from '../../../services/requests/SolicitacaoAdesivo/obterEnderecoClienteRequest/interface';
import { usePedido, PedidoActions } from '../../../features/solicitacao-adesivo/solicitacaoAdesivo/context/SolicitacaoAdesivoContext';
import { EnderecoActions, useEndereco } from '../../../features/solicitacao-adesivo/solicitacaoAdesivo/context/SolicitacaoAdesivoEnderecoContext';
import { useLoadingControle } from '../../../hooks/useLoadingControle/useLoadingControle';
import { obterEnderecoClienteRequest } from '../../../services/requests/SolicitacaoAdesivo/obterEnderecoClienteRequest';

import './BoxEndereco.scss';

const BoxEndereco = () => {
  const accordionContent = makeStyles(() => ({
    root: {
      flexDirection: 'row-reverse',
    },
    content: {
      justifyContent: 'space-between',
    },
  }))(AccordionSummary);

  const [enderecos, setEnderecos] = useState<IEnderecoCliente[]>([]);
  const { pedidoState, pedidoDispatch } = usePedido();
  const { enderecoDispatch } = useEndereco();
  const { alterarEstadoCarregando } = useLoadingControle();

  const handleSetEnderecoEmEdicao = (endereco?: IEnderecoCliente) => {
    if (endereco) { enderecoDispatch({ type: EnderecoActions.setEnderecoCompleto, payload: endereco }); }
    pedidoDispatch({ type: PedidoActions.setModalEnderecoEmEdicaoAberta, payload: true });
  };

  const handleAlterarEnderecoSelecionado = (tipoEndereco: string) => {
    const endereco = enderecos.find(x => x.tipoEndereco === tipoEndereco);
    pedidoDispatch({ type: PedidoActions.setEnderecoSelecionado, payload: endereco });
  };

  useEffect(() => {
    const obterEnderecosCliente = async () => {
      alterarEstadoCarregando(true);
      await obterEnderecoClienteRequest()
        .then(response => setEnderecos(response.data.dados))
        .finally(() => alterarEstadoCarregando(false));
    };

    obterEnderecosCliente();
  }, [alterarEstadoCarregando]);

  useEffect(() => {
    const definirEnderecoPrincipalSelecionado = () => pedidoDispatch({ type: PedidoActions.setEnderecoSelecionado, payload: enderecos[0] });
    if (enderecos.length > 0) definirEnderecoPrincipalSelecionado();
  }, [enderecos, pedidoDispatch]);

  const handleRenderizarBotaoAcaoEndereco = (enderecoCliente: IEnderecoCliente) => {
    if (enderecoCliente.tipoEndereco === 'Principal' && enderecos.length === 1) {
      return (
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className='box-endereco-accordion-grid-item'>
          <Button
            className='box-endereco-accordion-btn'
            startIcon={<Add />}
            onClick={() => handleSetEnderecoEmEdicao()}
          >
            Novo endereço
          </Button>
        </Grid>
      );
    }
    if (enderecoCliente.tipoEndereco === 'Entrega') {
      return (
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className='box-endereco-accordion-grid-item'>
          <Button
            className='box-endereco-accordion-btn'
            startIcon={<Edit />}
            onClick={() => handleSetEnderecoEmEdicao(enderecoCliente)}
          >
            Editar endereço
          </Button>
        </Grid>
      );
    }
    return <></>;
  };

  return (
    <section className='box-endereco-box-accordion'>
      {enderecos && enderecos.map(endereco => (
        <Accordion key={endereco.tipoEndereco} className='box-endereco-accordion' expanded={false}>
          <AccordionSummary
            classes={accordionContent}
            onClick={() => handleAlterarEnderecoSelecionado(endereco.tipoEndereco)}
            IconButtonProps={{ edge: 'start' }}
            expandIcon={pedidoState.enderecoSelecionado.tipoEndereco === endereco.tipoEndereco
              ? <RadioButtonChecked style={{ color: '#FF6338', fontSize: '1.8rem' }} />
              : <RadioButtonUnchecked style={{ color: '#BEC4CC', fontSize: '1.8rem' }} />
            }
          >
            <Grid container>
              <Grid item xl={9} lg={9} md={9} sm={12} xs={12} className='box-endereco-accordion-grid-item'>
                <Typography className='box-endereco-accordion-tipo-endereco'>
                  {endereco.tipoEndereco === 'Principal' ? 'Endereço de cadastro na Receita Federal' : 'Novo endereço'}
                </Typography>
                <Typography className='box-endereco-accordion-endereco'>
                  {endereco.logradouro}, {endereco.numero} - {endereco.cidade} - {endereco.bairro}/{endereco.estado}
                </Typography>
              </Grid>
              {handleRenderizarBotaoAcaoEndereco(endereco)}
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))}
    </section>
  );
};
export default memo(BoxEndereco);
