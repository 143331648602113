import { makeStyles } from '@material-ui/core';

export const buttonStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #293B8A',
    borderRadius: '30px',
    color: '#293B8A',
    padding: '8px',
  },
}));
