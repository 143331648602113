import { Action } from '../interfaces';
import { AutenticacaoState } from './AutenticacaoInterfaces';
import { Types, AtribuirDadosTokenAction } from './AutenticacaoActions';

const INITIAL_STATE = { clienteId: null, bearerToken: null } as AutenticacaoState;

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.AtribuirDadosToken:
      return {
        ...state,
        clienteId: (action as AtribuirDadosTokenAction).clienteId,
        documento: (action as AtribuirDadosTokenAction).documento?.padStart(14, '0'),
        bearerToken: (action as AtribuirDadosTokenAction).bearerToken,
      };

    default:
      return state;
  }
};
