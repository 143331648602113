import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../interfaces';
import './recarga.scss';
import Actions from './RecargaActions';
import { RecargaState } from './RecargaInterfaces';
import ocultadorChatbot from '../../infrastructure/ocultadorChatbot';
import NovaRecarga from './Page/NovaRecarga';
import MetodosPagamento from './Page/MetodosPagamento';
import ModalRecargaInsucesso from './Components/RecargaInsucesso/ModalRecargaInsucesso';
import RecargaRapida from './Page/RecargaRapida/RecargaRapida';

import AvisoSaldo from './Page/AvisoSaldo/AvisoSaldo';
import { AvisoSaldoProvider } from './Context/AvisoSaldoContexto';
import Loading from '../../components/Loading';
import { useLoadingControle } from '../../hooks/useLoadingControle/useLoadingControle';

const RecargaPage = () => {
  const {
    dadosIndex,
    dadosUltimaRecarga,
    dadosModalErro,
  } = useSelector<State, RecargaState>(state => ({
    dadosIndex: state.recarga.dadosIndex,
    dadosUltimaRecarga: state.recarga.dadosUltimaRecarga,
    dadosModalErro: state.recarga.dadosModalErro,
  }));

  const dispatch = useDispatch();

  React.useEffect(() => {
    ocultadorChatbot();
    dispatch(Actions.inicializarRecarga());
  }, [dispatch]);

  const { carregando } = useLoadingControle();

  return (
    <>
      <Loading carregando={carregando} />
      <section className='iframe-resizable'>
        <section className='recarga-page'>
          <section className='recarga-container'>

            {(dadosUltimaRecarga?.sucesso && dadosUltimaRecarga?.valores?.sucesso && dadosUltimaRecarga?.valores?.dados != null) && <RecargaRapida />}

            <NovaRecarga />

            <MetodosPagamento />

            {dadosModalErro?.visivel
              && (
                <ModalRecargaInsucesso
                  onTentarNovamenteClick={dadosModalErro?.action}
                  visivel={dadosModalErro?.visivel}
                />
              )
            }

            {dadosIndex?.dados?.valores?.recargaOnline?.configuracaoPlano?.planoPermiteConfigurarAvisoSaldoMinimo

              && (
              <AvisoSaldoProvider>
                <AvisoSaldo />
              </AvisoSaldoProvider>
              )
            }
          </section>
        </section>
      </section>
    </>
  );
};

export default RecargaPage;
