import React, { memo } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useAvisoSaldo } from '../../../../../Context/AvisoSaldoContexto';
import { FormaRecebimentoEnum, RecargaState } from '../../../../../RecargaInterfaces';
import { State } from '../../../../../../interfaces';

const ButtonSalvarValorAviso: React.FC = () => {
  const buttonStyles = makeStyles(() => ({
    root: {
      backgroundColor: '#293B8A',
      border: '1px solid #293B8A',
      borderRadius: '30px',
      color: '#fff',
      padding: '8px',
    },
    disabled: {
      backgroundColor: '#BEC4CC',
      borderColor: '#BEC4CC',
      color: '#fff',
    },
  }))(ButtonBase);

  const { flagReceberAvisoSaldo, valorAviso, valorAvisoNoLoadTela, handleAlterarValorSaldo } = useAvisoSaldo();

  const {
    dadosIndex,
  } = useSelector<State, RecargaState>(state => ({
    dadosIndex: state.recarga.dadosIndex,
  }));

  if (!flagReceberAvisoSaldo) return null;

  const handleButtonClick = () => {
    handleAlterarValorSaldo({
      desligarAlerta: false,
      configuracaoPlanoId: dadosIndex?.dados?.valores?.recargaOnline?.configuracaoPlano?.id as number,
      formaRecebimentoId: FormaRecebimentoEnum.Email as number,
      saldoCompartilhado: dadosIndex?.dados?.valores?.recargaOnline?.saldo?.saldoCompartilhado as boolean,
      valorAviso: valorAviso as number,
    });
  };

  return (
    <ButtonBase
      classes={buttonStyles}
      disabled={valorAvisoNoLoadTela === valorAviso}
      onClick={handleButtonClick}
    >
      <Check />
    </ButtonBase>
  );
};

export default memo(ButtonSalvarValorAviso);
