import { Action } from '../interfaces';
import {
  Types,
  AtribuirDocumentoAction,
  CarregarParametrosRecargaRequest,
  CarregarParametrosAvisoSaldoMinimo,
  CarregarParametrosModalPixAction,
  CarregarTefAction,
  CarregarBoletoItauAction,
  CarregarBoletoFlashAction,
  CarregarParametrosIndexAction,
  AtualizarErroAction,
  AtualizarStateAccordionAction,
  AtualizarAvisoRecargaAction,
  CarregarDadosUltimaRecargaAction,
  ObterSaldoAction,
} from './RecargaActions';
import { RecargaState } from './RecargaInterfaces';

const INITIAL_STATE = {
  adesaoId: 0,
  documento: '',
  token: '',
  servicos: [],
  dadosIndex: null,
  dadosRecargaPix: null,
  dadosRecargaBoletoFlash: null,
  dadosRecargaBoletoItau: null,
  dadosRecargaTef: null,
  dadosEnvioNovaRecarga: null,
  dadosEnvioAvisoSaldoMinimo: null,
  dadosModalErro: {
    action: null,
    visivel: false,
  },
  dadosAviso: {
    level: '',
    texto: '',
    visivel: false,
  },
  boxPagamento: {
    flash: false,
    itau: false,
    pix: false,
    tef: false,
  },
  dadosUltimaRecarga: null,
  saldo: 0,
} as RecargaState;

const atribuirDocumento = (state: RecargaState, action: AtribuirDocumentoAction) => (
  { ...state, documento: action.documento, clienteId: action.clienteId, token: action.token });

const obterSaldo = (state: RecargaState, action: ObterSaldoAction) => (
  { ...state, saldo: action.saldo });

const carregarParametrosIndex = (state: RecargaState, action: CarregarParametrosIndexAction) => (
  { ...state, adesaoId: action.dadosIndex.valores.adesaoSelecionadaId, servicos: action.dadosIndex.valores.servicos, dadosIndex: { ...state.dadosIndex, dados: action.dadosIndex } });

const carregarParametrosModalPix = (state: RecargaState, action: CarregarParametrosModalPixAction) => (
  { ...state, dadosRecargaPix: { ...state.dadosRecargaPix, dados: action.dadosPix } });

const carregarParametrosRecargaRequest = (state: RecargaState, action: CarregarParametrosRecargaRequest) => (
  { ...state, dadosEnvioNovaRecarga: { ...state.dadosEnvioNovaRecarga, dados: action.recarga } });

const carregarParametrosAvisoSaldoMinimo = (state: RecargaState, action: CarregarParametrosAvisoSaldoMinimo) => (
  { ...state, dadosEnvioAvisoSaldoMinimo: { ...state.dadosEnvioAvisoSaldoMinimo, dados: action.aviso } }
);

const descarregarParametrosModalPix = (state: RecargaState) => (
  { ...state, dadosRecargaPix: null }
);

const carregarBoletoFlash = (state: RecargaState, action: CarregarBoletoFlashAction) => (
  { ...state, dadosRecargaBoletoFlash: { ...state.dadosRecargaBoletoFlash, dados: action.dadosRecarga } }
);

const descarregarBoletoFlash = (state: RecargaState) => (
  { ...state, dadosRecargaBoletoFlash: { ...state.dadosRecargaBoletoFlash, dados: null } }
);

const carregarBoletoItau = (state: RecargaState, action: CarregarBoletoItauAction) => (
  { ...state, dadosRecargaBoletoItau: { ...state.dadosRecargaBoletoItau, dados: action.dadosRecarga } }
);

const descarregarBoletoItau = (state: RecargaState) => (
  { ...state, dadosRecargaBoletoItau: null }
);

const carregarTef = (state: RecargaState, action: CarregarTefAction) => (
  { ...state, dadosRecargaTef: { ...state.dadosRecargaTef, dados: action.dadosRecarga } }
);

const descarregarTef = (state: RecargaState) => (
  { ...state, dadosRecargaTef: null }
);

const carregarUltimaRecarga = (state: RecargaState, action: CarregarDadosUltimaRecargaAction) => (
  { ...state, dadosUltimaRecarga: action.dadosUltimaRecarga }
);

const cadastrarAvisoSaldoMinimo = (state: RecargaState) => ({ ...state });

const atualizarStateAccordion = (state: RecargaState, action: AtualizarStateAccordionAction) => ({
  ...state,
  boxPagamento: {
    [action.accordionId]: action.accordionState,
  },
});

const atualizarErro = (state: RecargaState, action: AtualizarErroAction) => ({
  ...state,
  dadosModalErro: {
    action: action.action,
    visivel: action.visivel,
  },
});

const atualizarAvisoRecarga = (state: RecargaState, action: AtualizarAvisoRecargaAction) => ({
  ...state,
  dadosAviso: {
    level: action.level,
    texto: action.texto,
    visivel: action.visivel,
  },
});

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.DescarregarBoletoFlashAction:
      return descarregarBoletoFlash(state);
    case Types.CarregarBoletoFlashAction:
      return carregarBoletoFlash(state, action as CarregarBoletoFlashAction);

    case Types.DescarregarBoletoItauAction:
      return descarregarBoletoItau(state);
    case Types.CarregarBoletoItauAction:
      return carregarBoletoItau(state, action as CarregarBoletoItauAction);

    case Types.DescarregarTefAction:
      return descarregarTef(state);
    case Types.CarregarTefAction:
      return carregarTef(state, action as CarregarTefAction);

    case Types.DescarregarParametrosModalPixAction:
      return descarregarParametrosModalPix(state);
    case Types.CarregarParametrosModalPixAction:
      return carregarParametrosModalPix(state, action as CarregarParametrosModalPixAction);

    case Types.CadastrarAvisoSaldoMinimoAction:
      return cadastrarAvisoSaldoMinimo(state);
    case Types.CarregarParametrosAvisoSaldoMinimoAction:
      return carregarParametrosAvisoSaldoMinimo(state, action as CarregarParametrosAvisoSaldoMinimo);

    case Types.CarregarDadosUltimaRecargaAction:
      return carregarUltimaRecarga(state, action as CarregarDadosUltimaRecargaAction);

    case Types.CarregarParametrosIndexAction:
      return carregarParametrosIndex(state, action as CarregarParametrosIndexAction);

    case Types.AtualizarErroAction:
      return atualizarErro(state, action as AtualizarErroAction);

    case Types.CarregarParametrosRecargaRequestAction:
      return carregarParametrosRecargaRequest(state, action as CarregarParametrosRecargaRequest);
    case Types.AtribuirDocumento:
      return atribuirDocumento(state, action as AtribuirDocumentoAction);

    case Types.AtualizarStateAccordionAction:
      return atualizarStateAccordion(state, action as AtualizarStateAccordionAction);

    case Types.AtualizarAvisoRecargaAction:
      return atualizarAvisoRecarga(state, action as AtualizarAvisoRecargaAction);

    case Types.ObterSaldo:
      return obterSaldo(state, action as ObterSaldoAction);
    default:
      return state;
  }
};
