import React, { memo, useCallback, useState } from 'react';
import { Button, Modal, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import Logo from '../../assets/images/logo.svg';
import IconeFechar from '../../assets/images/icones/fechar-preto.svg';
import IconeSucesso from '../../assets/images/icones/iconeSucesso.svg';

import { useControleModalConfirmarPedido } from '../../hooks/useControleModalConfirmarPedido/useControleModalConfirmarPedido';

import './styles.scss';
import { solicitarAdesivo } from '../../services/requests/SolicitacaoAdesivo/solicitarAdesivoRequest';
import { ICriarEnderecoClienteParametros } from '../../models/ICriarEnderecoCLienteParametros';
import { colors } from '../../styles/theme';
import ModalErro from '../ModalErro';
import { useControleModalErro } from '../../hooks/useControleModalErro/useControleModalErro';
import { pegarDataHoraAtual } from '../../utils/dataHoraAtual';

interface TModalCentralProps {
  motivoPedido: string;
  quantidade: number;
  formaPagamento: string;
  frete: string;
  total: string;
  taxa: string;
  endereco: ICriarEnderecoClienteParametros;
}

const ModalCentral: React.FC<TModalCentralProps> = ({
  motivoPedido,
  quantidade,
  formaPagamento,
  frete,
  taxa,
  total,
  endereco,
}) => {
  const [tipoModal, setTipoModal] = useState<'pendente' | 'sucesso'>('pendente');
  const [loading, setLoading] = useState<boolean>(false);
  const [urlBoleto, setUrlBoleto] = useState<string>('');

  const { visivel, alterarVisualizacaoModal } = useControleModalConfirmarPedido();
  const { abrirModal, fecharModal } = useControleModalErro();

  const history = useHistory();

  const solicitarAdesivoRequest = useCallback(async () => {
    setLoading(true);

    await solicitarAdesivo(
      '',
      '',
      motivoPedido === 'Substituição',
      quantidade,
      endereco,
    ).then((response: AxiosResponse) => {
      setUrlBoleto(response.data.dados.dados.urlBoleto);
      setTipoModal('sucesso');
    }).catch(() => abrirModal())
      .finally(() => setLoading(false));
  }, [abrirModal, endereco, motivoPedido, quantidade]);

  const abrirUrlBoleto = useCallback(() => {
    window.open(urlBoleto, '_blank');
  }, [urlBoleto]);

  const acaoAoFecharModal = useCallback(() => {
    fecharModal();
    solicitarAdesivoRequest();
  }, [fecharModal, solicitarAdesivoRequest]);

  const acaoAoSairDoModal = useCallback(() => {
    fecharModal();
  }, [fecharModal]);

  const esconderModalCentral = useCallback(() => {
    alterarVisualizacaoModal(false);
    setTipoModal('pendente');
  }, [alterarVisualizacaoModal]);

  const encerrarFluxo = useCallback(() => {
    alterarVisualizacaoModal(false);
    setTipoModal('pendente');

    history.push('/home');

    window.parent.location.href = `${(window as any).config.url_portal_cliente}/HomePj`;
  }, [alterarVisualizacaoModal, history]);


  return (
    <div>
      <ModalErro
        acaoAoFecharModal={acaoAoFecharModal}
        acaoAoSairDoModal={acaoAoSairDoModal}
      />
      <Modal
        open={visivel}
        onClose={esconderModalCentral}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          className='modalBox'
          style={{ position: 'fixed', maxWidth: 430, width: 430 }}
        >
          <Box className='modalIconeFechar'>
            <img
              src={IconeFechar}
              alt='iconeFechar'
              onClick={esconderModalCentral}
              role='presentation'
            />
          </Box>

          <img src={Logo} alt='logo' />

          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: 20,
              textAlign: 'center',
              marginTop: 25,
              color: colors.primary.PJ,
            }}
          >
            {tipoModal === 'pendente'
              ? 'Verfique se o seu pedido está correto e confirme a compra'
              : 'Pedido realizado com sucesso'
            }
          </Typography>

          {tipoModal === 'sucesso' && (
            <Typography style={{ marginTop: 10 }}>
              {pegarDataHoraAtual()}
            </Typography>
          )}

          {tipoModal === 'sucesso' && (
            <img
              src={IconeSucesso}
              alt='icone sucesso'
              style={{ marginTop: 20 }}
            />
          )}

          <Box className='boxColunas'>
            <Box className='colunaDireita'>
              {tipoModal === 'pendente' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Quantidade</Typography>
              )}
              <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Forma de pagamento</Typography>
              {tipoModal === 'sucesso' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Status</Typography>
              )}
              {tipoModal === 'pendente' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Frete</Typography>
              )}
              {tipoModal === 'pendente' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Taxa de adesão</Typography>
              )}
              <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Total</Typography>
            </Box>
            <Box className='colunaEsquerda'>
              {tipoModal === 'pendente' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>{quantidade}</Typography>
              )}
              <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>{formaPagamento}</Typography>
              {tipoModal === 'sucesso' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>Aguardando pagamento</Typography>
              )}
              {tipoModal === 'pendente' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>{frete}</Typography>
              )}
              {tipoModal === 'pendente' && (
                <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>{taxa}</Typography>
              )}
              <Typography style={{ marginBottom: 12, fontWeight: 'bold' }}>{total}</Typography>
            </Box>
          </Box>

          {tipoModal === 'sucesso' && (
            <Button
              style={{
                width: '90%',
                background: '#FFF',
                borderRadius: 100,
                height: 45,
                fontWeight: 'bold',
                fontSize: 12,
                marginBottom: 12,
                border: `1px solid ${colors.primary.PF}`,
                color: colors.primary.PF,
              }}
              onClick={abrirUrlBoleto}
            >
              Baixar boleto
            </Button>
          )}

          <Button
            style={{
              width: '90%',
              background: '#FF6338',
              color: '#FFF',
              borderRadius: 100,
              height: 45,
              fontWeight: 'bold',
              fontSize: 12,
            }}
            onClick={() => (tipoModal === 'pendente'
              ? solicitarAdesivoRequest()
              : encerrarFluxo())
            }
          >
            {loading
              ? 'Carregando...'
              : tipoModal === 'pendente'
                ? 'Confirmar pedido'
                : 'Fechar e voltar para página inicial'
            }
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default memo(ModalCentral);
