import {
  call, takeEvery, put, select, all, take,
} from 'redux-saga/effects';
import { State } from '../interfaces';
import RecargaApi from '../../infrastructure/historico-recarga-api';
import GeneralActions from '../actions';
import Actions, { Types } from './HistoricoRecargaActions';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../autenticacao/AutenticacaoActions';
import { ParametrosFiltrosHistoricoRecarga } from './HistoricoRecargaInterfaces';

const obterClienteId = (state: State): number => state.autenticacao.clienteId;
const obterBearerToken = (state: State): string => state.autenticacao.bearerToken;
const obterDocumento = (state: State): number => Number(state.autenticacao.documento);

const obterParametros = (state: State): ParametrosFiltrosHistoricoRecarga => ({
  filtrosSelecionados: state.historicoRecarga.filtrosHistoricoRecargaSelecionados,
  paginaSelecionada: state.historicoRecarga.paginaSelecionada,
  registrosPorPagina: state.historicoRecarga.registrosPorPagina,
});

function* atribuirDocumento() {
  yield put(GeneralActions.iniciarRequisicao());
  const documento = yield select(obterDocumento);
  const clienteId = yield select(obterClienteId);
  const token = yield select(obterBearerToken);

  yield put(Actions.atribuirDocumento(documento, clienteId, token));
  yield put(GeneralActions.terminarRequisicao());
}

function* buscarHistoricoRecargaFiltro() {
  yield put(GeneralActions.iniciarRequisicao());
  try {
    const parametros = yield select(obterParametros);
    const token = yield select(obterBearerToken);
    const { data } = yield call(RecargaApi.buscarHistoricoRecargaFiltro, parametros, token);

    yield put(Actions.buscarhistoricoRecargaFiltroSucesso(data));
    yield put(Actions.atualizarTotalPaginas(data?.dados?.qntRegistros));
  } catch (e) {
    yield put(Actions.buscarhistoricoRecargaFiltroError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* obterUltimasRecarga() {
  yield put(GeneralActions.iniciarRequisicao());
  try {
    const parametros = yield select(obterParametros);
    const token = yield select(obterBearerToken);
    const { data } = yield call(RecargaApi.obterUltimasRecarga, parametros, token);
    yield put(Actions.obterUltimasRecargaSucesso(data));
    yield put(Actions.atualizarTotalPaginas(data));
  } catch (e) {
    yield put(Actions.obterUltimasRecargaError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* obterHistoricoUltimoPedido() {
  yield put(GeneralActions.iniciarRequisicao());
  try {
    const token = yield select(obterBearerToken);
    const { data } = yield call(RecargaApi.obterHistoricoUltimoPedido, token);
    yield put(Actions.obterHistoricoUltimoPedidoSucesso(data));
  } catch (e) {
    yield put(Actions.obterHistoricoUltimoPedidoError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarHistoricoRecarga() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield all([atribuirDocumento(), obterUltimasRecarga(), obterHistoricoUltimoPedido()]);
}

export default function* () {
  yield takeEvery(Types.InicializarHistoricoRecarga, inicializarHistoricoRecarga);
  yield takeEvery(Types.ObterUltimasRecargaRequisicao, obterUltimasRecarga);
  yield takeEvery(Types.BuscarHistoricoRecargaFiltroRequisicao, buscarHistoricoRecargaFiltro);
  yield takeEvery(Types.ObterHistoricoUltimoPedidoRequisicao, obterHistoricoUltimoPedido);
}
