import * as React from 'react';
import { PropsBase } from '../CampoBase';
import Campo from '../CampoTexto/CampoTexto';
import './CampoSenha.scss';
import { validarSenha as ValidarSenha } from '../../../infrastructure/validador';
import { ValidacoesSenha } from '../../../infrastructure/validador/validador.interfaces';

interface Props extends PropsBase {
  onSenhaValidaChange: Function,
}

const iconeCheck = require('../../../assets/images/icones/check.svg');
const iconeErro = require('../../../assets/images/icones/erro.svg');

export default ({
  name,
  titulo,
  value,
  disabled = false,
  onChange,
  onBlur,
  onSenhaValidaChange,
}: Props) => {
  const validacaoInicial = {
    maiuscula: false,
    minuscula: false,
    digito: false,
    quantidade: false,
  };
  const [validacoes, setValidacoes] = React.useState<ValidacoesSenha>(validacaoInicial);
  const [senhaRepetida, setSenhaRepetida] = React.useState<string>('');

  const recuperarIcone = (estaValido: boolean) => (estaValido ? iconeCheck : iconeErro);

  const validarSenha = (valor: string) => {
    const validacao = ValidarSenha(valor);
    setValidacoes(validacao);
  };

  const handleSenhaNovaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = e.target.value;
    validarSenha(valor);
    onChange(valor);
  };


  React.useEffect(() => onSenhaValidaChange(validacoes.maiuscula && validacoes.minuscula && validacoes.digito && validacoes.quantidade && (senhaRepetida.length > 0 && senhaRepetida === value)),
    [validacoes.maiuscula, validacoes.minuscula, validacoes.digito, validacoes.quantidade, onSenhaValidaChange, senhaRepetida, value]);

  return (
    <div className='campo-senha'>
      <Campo
        name={name}
        titulo={titulo}
        value={value}
        disabled={disabled}
        password
        onChange={handleSenhaNovaChange}
        onBlur={onBlur}
      />

      <Campo
        name='repetir-senha'
        titulo='Repita a senha'
        value={senhaRepetida}
        disabled={disabled}
        password
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSenhaRepetida(e.target.value)}
        onBlur={onBlur}
      />

      <ul className='validacoes'>
        <li>
          <img alt='validacao' src={recuperarIcone(validacoes.maiuscula)} />
          {' '}
          <span>1 maiúscula</span>
        </li>
        <li>
          <img alt='validacao' src={recuperarIcone(validacoes.minuscula)} />
          {' '}
          <span>1 minúscula</span>
        </li>
        <li>
          <img alt='validacao' src={recuperarIcone(validacoes.digito)} />
          {' '}
          <span>1 número</span>
        </li>
        <li>
          <img alt='validacao' src={recuperarIcone(validacoes.quantidade)} />
          {' '}
          <span>Mínimo de 8 caracteres</span>
        </li>
        <li>
          <img alt='validacao' src={recuperarIcone(senhaRepetida.length > 0 && senhaRepetida === value)} />
          {' '}
          <span>Repetir a senha</span>
        </li>
      </ul>
    </div>
  );
};
