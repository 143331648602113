import {
  all, call, take, takeEvery, put, select, takeLatest,
} from 'redux-saga/effects';

import { State } from '../interfaces';
import GeneralActions from '../actions';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../autenticacao/AutenticacaoActions';
import Actions, { Types } from './RecargaActions';
import RecargaApi from '../../infrastructure/recarga-api';
import { CadastroSaldoMinimo, EnvioRecargaRequest, FormaPagamentoOnline } from './RecargaInterfaces';
import ExtratoApi from '../../infrastructure/extrato-api';

const obterClienteId = (state: State): number => state.autenticacao.clienteId;
const obterBearerToken = (state: State): string => state.autenticacao.bearerToken;
const obterDocumento = (state: State): string => state.autenticacao.documento;
const obterRecargaRequest = (state: State): EnvioRecargaRequest => state.recarga.dadosEnvioNovaRecarga.dados;
const obterSaldoMinimoRequest = (state: State): CadastroSaldoMinimo => state.recarga.dadosEnvioAvisoSaldoMinimo.dados;

const mappingTipoPagamentoRecarga = [
  {
    handlerApiRecarga: RecargaApi.cadastrarRecarga,
    actionPosRecarga: (dados: any) => Actions.carregarBoletoItau(dados),
    formaPagamento: FormaPagamentoOnline.ItauBoleto,
  },
  {
    handlerApiRecarga: RecargaApi.cadastrarRecarga,
    actionPosRecarga: (dados: any) => Actions.carregarTef(dados),
    formaPagamento: FormaPagamentoOnline.ItauTEF,
  },
  {
    handlerApiRecarga: RecargaApi.cadastrarRecarga,
    actionPosRecarga: (dados: any) => Actions.carregarBoletoFlash(dados),
    formaPagamento: FormaPagamentoOnline.PagBrasil,
  },
  {
    handlerApiRecarga: RecargaApi.cadastrarRecargaPix,
    actionPosRecarga: (dados: any) => Actions.carregarParametrosModalPix(dados),
    formaPagamento: FormaPagamentoOnline.Pix,
  },
];

function* atribuirDocumento() {
  yield put(GeneralActions.iniciarRequisicao());

  const documento = yield select(obterDocumento);
  const clienteId = yield select(obterClienteId);
  const token = yield select(obterBearerToken);

  yield put(Actions.atribuirDocumento(documento, clienteId, token));
  yield put(GeneralActions.terminarRequisicao());
}

function* obterDadosRecarga() {
  try {
    yield put(GeneralActions.iniciarRequisicao());
    const token = yield select(obterBearerToken);
    const { data } = yield call(RecargaApi.obterDadosRecargaPorDocumento, token);

    yield put(Actions.carregarParametrosIndex(data.dados));

    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* obterDadosUltimaRecarga() {
  try {
    yield put(GeneralActions.iniciarRequisicao());
    const token = yield select(obterBearerToken);
    const { data } = yield call(RecargaApi.obterDadosUltimaRecarga, token);

    yield put(Actions.carregarUltimaRecarga(data.dados));

    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(Actions.atualizarErroUltimaRecarga(true));
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* cadastrarAvisoSaldoMinimo() {
  try {
    yield put(GeneralActions.iniciarRequisicao());

    const token = yield select(obterBearerToken);
    const avisoSaldoMinimo = yield select(obterSaldoMinimoRequest);

    yield call(RecargaApi.cadastrarAvisoSaldoMinimoRecarga, token, avisoSaldoMinimo);

    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(Actions.atualizarErro(Actions.cadastrarAvisoSaldoMinimo, true));
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* cadastrarRecarga() {
  const recarga = yield select(obterRecargaRequest);

  const contextoRecarga = mappingTipoPagamentoRecarga
    .find(x => x.formaPagamento === recarga.FormaPagamentoOnline);

  try {
    yield put(GeneralActions.iniciarRequisicao());

    const token = yield select(obterBearerToken);
    const { data } = yield call(contextoRecarga.handlerApiRecarga, token, recarga);

    if (!data?.sucesso) {
      yield put(Actions.atualizarErro(Actions.cadastrarRecarga, true));
    } else {
      yield put(contextoRecarga.actionPosRecarga(data.dados));
    }
  } catch (e) {
    yield put(Actions.atualizarErro(Actions.cadastrarRecarga, true));
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* obterSaldo() {
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const bearerToken = yield select(obterBearerToken);

    const { data } = yield call(ExtratoApi.buscarSaldo, bearerToken);

    yield put(Actions.obterSaldo(data.dados[0].valor));
    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarRecarga() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield all([atribuirDocumento(), obterDadosRecarga(), obterDadosUltimaRecarga(), obterSaldo()]);
}

export default function* () {
  yield takeEvery(Types.InicializarRecargaAction, inicializarRecarga);
  yield takeEvery(Types.CadastrarRecargaAction, cadastrarRecarga);
  yield takeLatest(Types.CadastrarAvisoSaldoMinimoAction, cadastrarAvisoSaldoMinimo);
}
