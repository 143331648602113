import { makeStyles } from '@material-ui/core';

export const checkboxStyles = makeStyles(() => ({
  root: {
    color: '#4D5761 !important',
    marginRight: '16px',
    padding: '0px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));
