import { RespostaApi } from '../../infrastructure/ApiInterfaces';
import { Action } from '../interfaces';
import { ItemCardFatura, ItemLimitesSaldoFatura } from './FaturaInterfaces';

export enum Types {
  InicializarFatura = 'INICIALIZAR_FATURA',

  BuscarFaturaAtualCard = 'BUSCAR_FATURA_ATUAL_CARD',
  BuscarFaturaAtualCardSucesso = 'BUSCAR_FATURA_ATUAL_CARD_SUCESSO',
  BuscarFaturaAtualCardError = 'BUSCAR_FATURA_ATUAL_CARD_ERROR',

  BuscarFaturaAnteriorCard = 'BUSCAR_FATURA_ANTERIOR_CARD',
  BuscarFaturaAnteriorSucesso = 'BUSCAR_FATURA_ANTERIOR_CARD_SUCESSO',
  BuscarFaturaAnteriorCardError = 'BUSCAR_FATURA_ANTERIOR_CARD_ERROR',

  BuscarLimiteSaldoRequest= 'BUSCAR_LIMITES_SALDO_FATURA_REQUEST',
  BuscarLimiteSaldoSucesso = 'BUSCAR_LIMITES_SALDO_FATURA_SUCESSO',
  BuscarLimiteSaldoError = 'BUSCAR_LIMITES_SALDO_FATURA_ERROR',

  BuscarHistoricoFaturaRequest= 'BUSCAR_HISTORICO_FATURA_REQUEST',
  BuscarHistoricoFaturaSucesso = 'BUSCAR_HISTORICO_FATURA_SUCESSO',
  BuscarHistoricoFaturaError = 'BUSCAR_HISTORICO_FATURA_ERROR',
}

export interface InicializarFatura extends Action { }
export interface BuscarFaturaAtualCardAction extends Action { }
export interface BuscarFaturaAtualCardSucessoAction extends Action { dadosFatura: RespostaApi<ItemCardFatura[]> }
export interface BuscarFaturaAtualCardErrorAction extends Action { }

export interface BuscarFaturaAnteriorCardAction extends Action { }
export interface BuscarFaturaAnteriorCardSucessoAction extends Action { dadosFatura: RespostaApi<ItemCardFatura[]> }
export interface BuscarFaturaAnteriorCardErrorAction extends Action { }

export interface BuscarLimiteSaldoRequestAction extends Action { }
export interface BuscarLimiteSaldoSucessoAction extends Action { dadosLimitesSaldo: RespostaApi<ItemLimitesSaldoFatura> }
export interface BuscarLimiteSaldoErrorAction extends Action { }

export interface BuscarHistoricoFaturaRequestAction extends Action { }
export interface BuscarHistoricoFaturaSucessoAction extends Action { historicoFatura: RespostaApi<ItemCardFatura[]> }
export interface BuscarHistoricoFaturaErrorAction extends Action { }

export default {
  inicializarFatura: (): InicializarFatura => ({ type: Types.InicializarFatura }),

  buscarFaturaAtualCard: (): BuscarFaturaAtualCardAction => ({ type: Types.BuscarFaturaAtualCard }),
  buscarFaturaAtualCardSucesso: (dadosFatura: RespostaApi<ItemCardFatura[]>): BuscarFaturaAtualCardSucessoAction => ({ type: Types.BuscarFaturaAtualCardSucesso, dadosFatura }),
  buscarFaturaAtualCardError: (): BuscarFaturaAtualCardErrorAction => ({ type: Types.BuscarFaturaAtualCardError }),

  buscarFaturaAnteriorCard: (): BuscarFaturaAnteriorCardAction => ({ type: Types.BuscarFaturaAnteriorCard }),
  buscarFaturaAnteriorCardSucesso: (dadosFatura: RespostaApi<ItemCardFatura[]>): BuscarFaturaAnteriorCardSucessoAction => ({ type: Types.BuscarFaturaAnteriorSucesso, dadosFatura }),
  buscarFaturaAnteriorCardError: (): BuscarFaturaAnteriorCardErrorAction => ({ type: Types.BuscarFaturaAnteriorCardError }),

  buscarLimiteSaldoRequest: (): BuscarLimiteSaldoRequestAction => ({ type: Types.BuscarLimiteSaldoRequest }),
  buscarLimiteSaldoSucesso: (dadosLimitesSaldo: RespostaApi<ItemLimitesSaldoFatura>): BuscarLimiteSaldoSucessoAction => ({ type: Types.BuscarLimiteSaldoSucesso, dadosLimitesSaldo }),
  buscarLimiteSaldoError: (): BuscarLimiteSaldoErrorAction => ({ type: Types.BuscarLimiteSaldoError }),

  buscarHistoricoFaturaRequest: (): BuscarHistoricoFaturaRequestAction => ({ type: Types.BuscarHistoricoFaturaRequest }),
  buscarHistoricoFaturaSucesso: (historicoFatura: RespostaApi<ItemCardFatura[]>): BuscarHistoricoFaturaSucessoAction => ({ type: Types.BuscarHistoricoFaturaSucesso, historicoFatura }),
  buscarHistoricoFaturaError: (): BuscarHistoricoFaturaErrorAction => ({ type: Types.BuscarHistoricoFaturaError }),
};
