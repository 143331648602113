import { createStyles, makeStyles } from '@material-ui/core';

const InputRecargaStyles = makeStyles(() => createStyles({
  inputValorMetodoPagamento: {
    border: '1px solid #D5D5D5',
    borderRadius: '0.25rem',
    color: '#707070',
    fontSize: '15px',
    height: '3.2rem',
    paddingLeft: '0.5rem',
    width: '200px',
  },
}));

export default InputRecargaStyles;
