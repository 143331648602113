import { DadosIntegracaoPlataforma, DadosParametrosUtm } from '../../infrastructure/prospecto-api/rascunho/RascunhoInterfaces';

export enum Etapas {
  Plano = 1,
  Representante = 2,
  Empresa = 3,
  Senha = 4,
  Concluido = 5,
}

export enum StatusCadastro {
  Aprovado = 1,
  Reprovado = 2,
  Processando = 3,
}

export const EtapasRotas = new Map([
  [Etapas.Plano, '/cadastro/plano'],
  [Etapas.Representante, '/cadastro/representante'],
  [Etapas.Empresa, '/cadastro/empresa'],
  [Etapas.Senha, '/cadastro/senha'],
  [Etapas.Concluido, '/cadastro/concluido'],
]);

export interface Plano {
  veiculosLeves: number,
  veiculosPesados: number,
  possuiAdesivos?: boolean,
}

export interface RepresentanteLegal {
  nomeContatoComercial: string,
  email: string,
  telefone: string,
  celular: string,
  nomeRepresentanteLegal: string,
  cpf: string,
  representanteDesde: Date,
  cargo: string,
  pessoaPoliticamenteExposta: boolean
}

export interface DadosEmpresa {
  cnpj: string,
  razaoSocial: string,
  faturamentoMedioMensal: string,
  dataConstituicao: Date,
  naturezaJuridica: string,
  cep: string,
  logradouro: string,
  numero: string,
  cidade: string,
  estado: string,
  bairro: string,
  complemento: string,
  codigoIbge: string,
  consultandoCnpj: boolean,
}

export interface CadastroState {
  idRascunho: string,
  pedidoId?: string,
  canalVendasId: number,
  canalVendasHash: string,
  jaExistente: boolean,
  senha: string,
  aceitaReceberOfertas: boolean,
  concordaTermosAdesao: boolean,
  compartilharDados: boolean,
  compartilharDadosItau: boolean,
  mesesIsencaoMensalidade: number,
  percentualIsencaoMensalidade: number,
  precoVeiculoLeve: number,
  precoVeiculoPesado: number,
  precoAdesao: number,
  codigoPlano: string,
  statusCadastro?: StatusCadastro,
  etapaAtual: Etapas,
  erroServidor: string,
  plano: Plano,
  representanteLegal: RepresentanteLegal,
  dadosEmpresa: DadosEmpresa,
  dadosParametrosUtm: DadosParametrosUtm,
  dadosIntegracaoPlataforma: DadosIntegracaoPlataforma,
  possuiBoleto: boolean,
  urlBoleto: string,
  hashBytesBoleto: string
}
