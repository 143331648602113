import CadastroPlanoPage from './CadastroPlanoPage';
import CadastroRepresentantePage from './CadastroRepresentantePage';
import CadastroEmpresaPage from './CadastroEmpresaPage';
import CadastroSenhaPage from './CadastroSenhaPage';
import CadastroCnpjInvalidoPage from './CadastroCnpjInvalidoPage';

export {
  CadastroPlanoPage, CadastroRepresentantePage,
  CadastroEmpresaPage, CadastroSenhaPage,
  CadastroCnpjInvalidoPage,
};
