import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { State } from '../../interfaces';
import Actions from '../CadastroActions';
import { Etapas } from '../CadastroInterfaces';
import './CadastroResumoBloco.scss';

export interface Item {
  id: number,
  nome: string,
  valor: string,
  class: string,
}

enum StatusResumo {
  inativo,
  ativo,
  completo,
}

interface Props {
  titulo: string,
  icone: string,
  itens: Item[],
  url: string,
  etapaRepresentada: Etapas,
  ehUltimo?: boolean,
}

export default ({ titulo, icone, itens, url, etapaRepresentada, ehUltimo }: Props) => {
  const [status, setStatus] = React.useState<StatusResumo>(StatusResumo.inativo);
  const etapaAtual = useSelector<State, Etapas>(state => state.cadastro.etapaAtual);
  const history = useHistory();
  const dispatch = useDispatch();

  const definirStatus = () => {
    if (etapaAtual === etapaRepresentada) return StatusResumo.ativo;
    if (etapaAtual > etapaRepresentada) return StatusResumo.completo;
    return StatusResumo.inativo;
  };

  React.useEffect(() => {
    setStatus(definirStatus());
  }, [definirStatus, itens]);

  const definirClasseStatus = () => {
    switch (status) {
      case StatusResumo.inativo:
        return 'inativo';

      case StatusResumo.ativo:
        return 'ativo';

      case StatusResumo.completo:
        return 'completo';

      default:
        return '';
    }
  };

  const definirClasseUltimoItem = () => (ehUltimo ? 'ultimo-item' : '');

  const handleEditarClick = () => {
    dispatch(Actions.atribuirEtapaAtual(etapaRepresentada));
    dispatch(Actions.enviarRascunho());
    history.push(url);
  };

  const exibirBotaoEditar = () => (status === StatusResumo.completo && etapaAtual !== Etapas.Concluido
    ? <button type='button' onClick={() => handleEditarClick()}>Editar</button> : '');

  return (
    <section className={`cadastro-resumo-bloco ${definirClasseStatus()} ${definirClasseUltimoItem()}`}>
      <div className='titulo'>
        <img src={icone} alt='icone caminhao' />
        <h2 data-testid={definirClasseStatus()}>{titulo}</h2>
        {exibirBotaoEditar()}
      </div>
      <ul className='propriedades'>
        {
          itens.map(item => (
            <li key={item.id} className={`${item.class}`}>
              <span className='nome'>{item.nome}</span>
              <span className='valor'>{item.valor}</span>
            </li>
          ))
        }
      </ul>
    </section>
  );
};
