
import { removerCaracteresNaoNumericos } from '../mascara';
import { ValidacoesSenha } from './validador.interfaces';

export interface Validacao {
  mensagem: string,
  estaValido: boolean,
}

const validarCampoObrigatorio = (value: string) => {
  if (value.trim().length === 0) return 'Este campo é obrigatório';
  return '';
};

const validarEmail = (value: string) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!regexEmail.test(value)) return 'E-mail deve estar em formato correto';
  return '';
};

const validarTelefone = (value: string) => {
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (valueLimpo.length < 10) return 'Telefone precisa ter 10 dígitos (com DDD)';
  return '';
};

const validarCelular = (value: string) => {
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (valueLimpo.length > 0 && valueLimpo.length < 11) return 'Celular precisa ter 11 dígitos (com DDD)';
  return '';
};

const validarCelularObrigatorio = (value: string) => {
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (valueLimpo.length < 11) return 'Celular precisa ter 11 dígitos (com DDD)';
  return '';
};

const validarApenasNumeros = (value: string) => {
  const regex = new RegExp(/[^0-9]/g);

  if (value.length === 0) return 'Este campo é obrigatório';
  if (!regex.test(value)) return '';

  return 'Campo deve conter apenas números';
};

const validarCep = (value: string) => {
  const regexCep = /^\d{8}$/i;
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (!regexCep.test(valueLimpo)) return 'CEP deve possuir 8 caracteres numéricos';
  return '';
};

const cpfValido = (value: string) => {
  const cpf = value;
  if (cpf === '') return false;

  if (cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999') return false;

  let add = 0;

  for (let i = 0; i < 9; i) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
    i += 1;
  }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  if (rev !== parseInt(cpf.charAt(9), 10)) { return false; }

  add = 0;
  for (let i = 0; i < 10; i) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
    i += 1;
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  if (rev !== parseInt(cpf.charAt(10), 10)) return false;

  return true;
};

const validarCpf = (value: string) => {
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (cpfValido(valueLimpo) === false) return 'CPF inválido';
  return '';
};

const cnpjValido = (value) => {
  let cnpj = value;
  const valida = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let dig1 = 0;
  let dig2 = 0;

  if (cnpj.length !== 14) return false;
  if (cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999') return false;

  const exp = /\.|-|\//g;
  cnpj = cnpj.toString().replace(exp, '');
  const radix = 10;
  const digitoVerificador = parseInt(cnpj.charAt(12) + cnpj.charAt(13), radix);

  for (let i = 0; i < valida.length; i += 1) {
    dig1 += (i > 0 ? (cnpj.charAt(i - 1) * valida[i]) : 0);
    dig2 += cnpj.charAt(i) * valida[i];
  }
  dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
  dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));

  if (((dig1 * 10) + dig2) !== digitoVerificador) return false;

  return true;
};

const validarCnpj = (value: string) => {
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (cnpjValido(valueLimpo) === false) return 'CNPJ inválido';
  return '';
};

const novaDataMaiorQueDataAtual = (novaData: Date) => {
  const dataAtual = new Date();

  const novaAnoIgualAoAtual = novaData.getFullYear() === dataAtual.getFullYear();
  const novoAnoMaiorQueAtual = novaData.getUTCFullYear() > dataAtual.getUTCFullYear();

  const novoMesIgualAoAtual = novaData.getUTCMonth() === dataAtual.getUTCMonth();
  const novoMesMaiorQueAtual = novaData.getUTCMonth() > dataAtual.getUTCMonth();

  const novoDiaMaiorQueAtual = novaData.getUTCDate() > dataAtual.getUTCDate();

  const novaDataNoMesmoAnoComMesMaiorQueAtual = novaAnoIgualAoAtual && novoMesMaiorQueAtual;
  const novaDataNoMesmoAnoComDiaMaiorQueAtual = novaAnoIgualAoAtual && novoMesIgualAoAtual && novoDiaMaiorQueAtual;

  return novoAnoMaiorQueAtual
    || (novaDataNoMesmoAnoComMesMaiorQueAtual || novaDataNoMesmoAnoComDiaMaiorQueAtual);
};

const validarData = (value) => {
  if (value === null) { return 'Esta data é obrigatória'; }
  return validarDataMaiorQueAtual(value);
};

const validarDataMaiorQueAtual = (value) => {
  if (value !== '' && novaDataMaiorQueDataAtual(value)) { return 'A data não pode ser maior que a data atual'; }
  return '';
};

const validarFaturamentoMedioMensal = (value: string) => {
  const valueLimpo = removerCaracteresNaoNumericos(value);
  if (valueLimpo.length < 3) return 'Faturamento mínimo precisa ter 3 dígitos';
  return '';
};

export function dadosValidacaoEstaVazio<T extends { [key: string]: any }>(validacao: T): boolean {
  const keys = Object.keys(validacao);

  for (let i = 0; i < keys.length; i += 1) {
    const mensagem = validacao[keys[i]];
    if (mensagem !== '') {
      return false;
    }
  }

  return true;
}

export const formularioEstaValido = (validacao) => {
  const campos = Object.keys(validacao);
  let todosCampoesEstaoValidos = true;
  campos.forEach((campo) => {
    todosCampoesEstaoValidos = todosCampoesEstaoValidos && validacao[campo].estaValido;
  });
  return todosCampoesEstaoValidos;
};

export const validarSenha = (value: string): ValidacoesSenha => {
  const regexMinuscula = /^(?=.*[a-z])/;
  const regexMaiuscula = /^(?=.*[A-Z])/;
  const regexDigito = /^(?=.*[0-9])/;

  return {
    maiuscula: regexMaiuscula.test(value),
    minuscula: regexMinuscula.test(value),
    digito: regexDigito.test(value),
    quantidade: value.length >= 8,
  };
};

export default (name: string, value: any) => {
  switch (name) {
    case 'logradouro':
    case 'estado':
    case 'nomeCidade':
    case 'cidade':
    case 'bairro':
    case 'nome':
    case 'nomeRepresentanteLegal':
    case 'nomeContatoComercial':
    case 'razaoSocial':
      return validarCampoObrigatorio(value);

    case 'email':
      return validarEmail(value);

    case 'telefone':
      return validarTelefone(value);

    case 'celular':
      return validarCelular(value);

    case 'celularObrigatorio':
      return validarCelularObrigatorio(value);

    case 'numero':
      return validarApenasNumeros(value);

    case 'cep':
      return validarCep(value);

    case 'cpf':
      return validarCpf(value);

    case 'faturamentoMedioMensal':
      return validarFaturamentoMedioMensal(value);

    case 'cnpj':
      return validarCnpj(value);

    case 'representanteDesde':
      return validarDataMaiorQueAtual(value);

    case 'dataConstituicao':
      return validarData(value);

    default:
      return '';
  }
};
