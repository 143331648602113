import {
  AtribuirEtapaAtualAction,
  AtribuirPlanoAction,
  Types,
  AtribuirIdRascunhoAction,
  AtribuirRascunhoAction,
  AtribuirPossuiAdesivosAction,
  AtribuirVeiculosLevesAction,
  AtribuirVeiculosPesadosAction,
  AtribuirDadosQueryStringAction,
  AtribuirCadastroExistenteAction,
  AtribuirCampoRepresentanteLegalAction,
  AtribuirCampoDadosEmpresaAction,
  AtribuirDadosEnderecoApiCepAction,
  AtribuirConsultandoCnpjAction,
  AtribuirSenhaAction,
  AtribuirAceitaReceberOfertasAction,
  AtribuirConcordaTermosAdesaoAction,
  AtribuirCompartilharDadosAction,
  AtribuirCompartilharDadosItauAction,
  AtribuirStatusCadastroAction,
  AtribuirPessoaPoliticamenteExpostaAction,
  AtribuirErroServidorAction,
  AtribuirPedidoResponseBoletoAction,
  AtribuirPedidoIdAction,
} from './CadastroActions';
import { Action } from '../interfaces';
import { CadastroState, Etapas } from './CadastroInterfaces';
import AplicarMascara from '../../infrastructure/mascara';
import { converterTelefonesParaTela } from '../../infrastructure/prospecto-api/rascunho';
import { formatarFaturamentoApi } from '../../infrastructure/mascara/mascara';

const INITIAL_STATE = {
  idRascunho: '',
  pedidoId: null,
  jaExistente: null,
  senha: '',
  canalVendasHash: '',
  canalVendasId: 0,
  aceitaReceberOfertas: false,
  concordaTermosAdesao: false,
  compartilharDados: false,
  compartilharDadosItau: false,
  mesesIsencaoMensalidade: 0,
  percentualIsencaoMensalidade: 0,
  precoVeiculoLeve: 0,
  precoVeiculoPesado: 0,
  precoAdesao: 0,
  etapaAtual: Etapas.Plano,
  statusCadastro: undefined,
  erroServidor: '',
  codigoPlano: '',
  plano: {
    veiculosLeves: 0,
    veiculosPesados: 0,
    possuiAdesivos: false,
  },
  representanteLegal: {
    nomeContatoComercial: '',
    email: '',
    telefone: '',
    celular: '',
    nomeRepresentanteLegal: '',
    cpf: '',
    representanteDesde: null,
    cargo: '',
    pessoaPoliticamenteExposta: false,
  },
  dadosIntegracaoPlataforma: {
    contatoPlataformaId: null,
    dataIntegracaoPropostaPlataforma: null,
    landingPageURL: null,
    plataformaDescricao: null,
    propostaPlataformaId: null,
    ultimaDataIntegracaoPlataforma: null,
  },
  dadosParametrosUtm: {
    final: [],
    inicial: [],
  },
  dadosEmpresa: {
    cnpj: '',
    razaoSocial: '',
    faturamentoMedioMensal: '',
    dataConstituicao: null,
    naturezaJuridica: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    consultandoCnpj: false,
  },
  possuiBoleto: false,
  hashBytesBoleto: '',
  urlBoleto: '',
} as CadastroState;

const atribuirPlano = (state: CadastroState, action: AtribuirPlanoAction) => {
  const { plano } = action as AtribuirPlanoAction;
  return {
    ...state,
    codigoPlano: plano.codigoPlano,
    precoVeiculoLeve: plano.mensalidadeVeiculoLeve,
    precoVeiculoPesado: plano.mensalidadeVeiculoPesado,
    precoAdesao: plano.valorAdesao,
    planoId: plano.id,
    canalVendasId: plano.canalVendasId,
    canalVendasHash: plano.id,
    canalVendasNome: plano.canalVendasNome,
    mesesIsencaoMensalidade: plano.mesesIsencaoMensalidade,
    percentualIsencaoMensalidade: plano.percentualIsencaoMensalidade,
  };
};

const atribuirPedidoId = (state: CadastroState, action: AtribuirPedidoIdAction) => ({
  ...state,
  pedidoId: action.pedidoId,
});

const atribuirIdRascunho = (state: CadastroState, action: AtribuirIdRascunhoAction) => {
  const { idRascunho } = action as AtribuirIdRascunhoAction;
  return { ...state, idRascunho };
};

const atribuirVeiculosLeves = (state: CadastroState, action: AtribuirVeiculosLevesAction) => {
  const { veiculosLeves } = action;
  return { ...state, plano: { ...state.plano, veiculosLeves } };
};

const atribuirVeiculosPesados = (state: CadastroState, action: AtribuirVeiculosPesadosAction) => {
  const { veiculosPesados } = action;
  return { ...state, plano: { ...state.plano, veiculosPesados } };
};

const atribuirEtapaAtual = (state: CadastroState, novaEtapa: Etapas) => ({ ...state, etapaAtual: novaEtapa });

const avancarProximaEtapa = (state: CadastroState) => {
  const { etapaAtual } = state;
  return atribuirEtapaAtual(state, etapaAtual + 1);
};

const atribuirRascunho = (state: CadastroState, action: AtribuirRascunhoAction) => {
  const { rascunho } = action as AtribuirRascunhoAction;
  const { telefoneDdd,
    telefoneNumero,
    celularDdd,
    celularNumero } = converterTelefonesParaTela(rascunho.dadosPessoa.telefones);
  const { dataRepresentante } = rascunho.dadosEmpresa;

  return {
    ...state,
    idRascunho: rascunho.id,
    pedidoId: rascunho.pedidoId,
    planoId: rascunho.planoPjId,
    plano: {
      ...state.plano,
      veiculosLeves: rascunho.dadosAdicionais.quantidadeVeiculosLeves,
      veiculosPesados: rascunho.dadosAdicionais.quantidadeVeiculosPesados,
      possuiAdesivos: rascunho.dadosAdicionais.possuiAdesivos,
    },
    representanteLegal: {
      nomeContatoComercial: rascunho.dadosPessoa.nome,
      email: rascunho.dadosPessoa.email,
      telefone: AplicarMascara('telefone', telefoneDdd + telefoneNumero),
      celular: AplicarMascara('celular', celularDdd + celularNumero),
      nomeRepresentanteLegal: rascunho.dadosEmpresa.nomeRepresentante,
      cpf: AplicarMascara('cpf', rascunho.dadosEmpresa.cpf),
      representanteDesde: dataRepresentante ? new Date(dataRepresentante) : null,
      cargo: rascunho.dadosEmpresa.cargo,
      pessoaPoliticamenteExposta: rascunho.dadosEmpresa.expostaPublicamente,
    },
    dadosEmpresa: {
      cnpj: AplicarMascara('cnpj', rascunho.dadosEmpresa.cnpj),
      razaoSocial: rascunho.dadosEmpresa.razaoSocial,
      faturamentoMedioMensal: formatarFaturamentoApi(rascunho.dadosEmpresa.faturamentoMedioMensal),
      dataConstituicao: rascunho.dadosEmpresa.dataConstituicao ? new Date(rascunho.dadosEmpresa.dataConstituicao) : null,
      naturezaJuridica: rascunho.dadosEmpresa.naturezaJuridica,
      cep: AplicarMascara('cep', rascunho.dadosEmpresa.cep),
      logradouro: rascunho.dadosEmpresa.endereco,
      numero: rascunho.dadosEmpresa.numero,
      cidade: rascunho.dadosEmpresa.cidade,
      estado: rascunho.dadosEmpresa.estado,
      complemento: rascunho.dadosEmpresa.complemento,
      bairro: rascunho.dadosEmpresa.bairro,
      codigoIbge: rascunho.dadosEmpresa.codigoIbge,
    },
    dadosIntegracaoPlataforma: {
      contatoPlataformaId: rascunho.dadosIntegracaoPlataforma.contatoPlataformaId,
      plataformaDescricao: rascunho.dadosIntegracaoPlataforma.plataformaDescricao,
      propostaPlataformaId: rascunho.dadosIntegracaoPlataforma.propostaPlataformaId,
      landingPageURL: rascunho.dadosIntegracaoPlataforma.landingPageURL,
      dataIntegracaoPropostaPlataforma: rascunho.dadosIntegracaoPlataforma.dataIntegracaoPropostaPlataforma,
      ultimaDataIntegracaoPlataforma: rascunho.dadosIntegracaoPlataforma.ultimaDataIntegracaoPlataforma,
    },
    dadosParametrosUtm: {
      inicial: rascunho.dadosParametrosUtm.inicial,
      final: rascunho.dadosParametrosUtm.final,
    },
    etapaAtual: rascunho.etapaAtual,
  };
};

const atribuirSenha = (state: CadastroState, action: AtribuirSenhaAction) => {
  const { senha } = action;
  return { ...state, senha };
};

const atribuirConcordaTermosAdesao = (state: CadastroState, action: AtribuirConcordaTermosAdesaoAction) => {
  const { concordaTermosAdesao } = action;
  return { ...state, concordaTermosAdesao };
};

const atribuirAceitaReceberOfertas = (state: CadastroState, action: AtribuirAceitaReceberOfertasAction) => {
  const { aceitaReceberOfertas } = action;
  return { ...state, aceitaReceberOfertas };
};

const atribuirCompartilharDados = (state: CadastroState, action: AtribuirCompartilharDadosAction) => {
  const { compartilharDados } = action;
  return { ...state, compartilharDados };
};

const atribuirCompartilharDadosItau = (state: CadastroState, action: AtribuirCompartilharDadosItauAction) => {
  const { compartilharDadosItau } = action;
  return { ...state, compartilharDadosItau };
};

const atribuirPossuiAdesivos = (state: CadastroState, action: AtribuirPossuiAdesivosAction) => {
  const { possuiAdesivos } = action;
  return { ...state, plano: { ...state.plano, possuiAdesivos } };
};

const atribuirDadosQueryString = (state: CadastroState, action: AtribuirDadosQueryStringAction) => {
  const { veiculosLeves, veiculosPesados, cnpj, email, nome, telefone } = action;
  return {
    ...state,
    dadosEmpresa: {
      ...state.dadosEmpresa,
      cnpj,
    },
    representanteLegal: {
      ...state.representanteLegal,
      email,
      nomeRepresentanteLegal: nome,
      telefone: AplicarMascara('telefone', telefone),
    },
    plano: { veiculosLeves, veiculosPesados },
  };
};

const atribuirCadastroExistente = (state: CadastroState, action: AtribuirCadastroExistenteAction) => {
  const { jaExistente } = action;
  return { ...state, jaExistente };
};

const atribuirCampoRepresentanteLegal = (state: CadastroState, action: AtribuirCampoRepresentanteLegalAction) => {
  const novoValorComMascara = AplicarMascara(action.nomeCampo, action.novoValor);

  return {
    ...state,
    representanteLegal: {
      ...state.representanteLegal,
      [action.nomeCampo]: novoValorComMascara,
    },
  };
};

const atribuirCampoDadosEmpresa = (state: CadastroState, action: AtribuirCampoDadosEmpresaAction) => {
  const novoValorComMascara = AplicarMascara(action.nomeCampo, action.novoValor);

  return {
    ...state,
    dadosEmpresa: {
      ...state.dadosEmpresa,
      [action.nomeCampo]: novoValorComMascara,
    },
  };
};

const atribuirDadosEnderecoApiCep = (state: CadastroState, action: AtribuirDadosEnderecoApiCepAction) => ({
  ...state,
  dadosEmpresa: {
    ...state.dadosEmpresa,
    bairro: action.enderecoApiCep.bairro,
    cidade: action.enderecoApiCep.cidade,
    codigoIbge: action.enderecoApiCep.ibge,
    estado: action.enderecoApiCep.estado,
    logradouro: action.enderecoApiCep.logradouro,
  },
});

const atribuirConsultandoCnpj = (state: CadastroState, action: AtribuirConsultandoCnpjAction) => ({
  ...state,
  dadosEmpresa: {
    ...state.dadosEmpresa,
    consultandoCnpj: action.consultandoCnpj,
  },
});

const atribuirPessoaPoliticamenteExposta = (state: CadastroState, action: AtribuirPessoaPoliticamenteExpostaAction) => ({
  ...state,
  representanteLegal: {
    ...state.representanteLegal,
    pessoaPoliticamenteExposta: action.pessoaPoliticamenteExposta,
  },
});

const atribuirBoletoResponseBoleto = (state: CadastroState, action: AtribuirPedidoResponseBoletoAction) => {
  const { possuiBoleto, hashBytesBoleto, urlBoleto } = action;
  return { ...state, possuiBoleto, hashBytesBoleto, urlBoleto };
};

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.AtribuirPlano:
      return atribuirPlano(state, action as AtribuirPlanoAction);

    case Types.AtribuirIdRascunho:
      return atribuirIdRascunho(state, action as AtribuirIdRascunhoAction);

    case Types.AvancarProximaEtapa:
      return avancarProximaEtapa(state);

    case Types.AtribuirEtapaAtual:
      return atribuirEtapaAtual(state, (action as AtribuirEtapaAtualAction).novaEtapa);

    case Types.AtribuirVeiculosLeves:
      return atribuirVeiculosLeves(state, action as AtribuirVeiculosLevesAction);

    case Types.AtribuirVeiculosPesados:
      return atribuirVeiculosPesados(state, action as AtribuirVeiculosPesadosAction);

    case Types.AtribuirRascunho:
      return atribuirRascunho(state as CadastroState, action as AtribuirRascunhoAction);

    case Types.AtribuirPossuiAdesivos:
      return atribuirPossuiAdesivos(state as CadastroState, action as AtribuirPossuiAdesivosAction);

    case Types.AtribuirDadosQueryString:
      return atribuirDadosQueryString(state, action as AtribuirDadosQueryStringAction);

    case Types.AtribuirCadastroExistente:
      return atribuirCadastroExistente(state, action as AtribuirCadastroExistenteAction);

    case Types.AtribuirCampoRepresentanteLegal:
      return atribuirCampoRepresentanteLegal(state, action as AtribuirCampoRepresentanteLegalAction);

    case Types.AtribuirCampoDadosEmpresa:
      return atribuirCampoDadosEmpresa(state, action as AtribuirCampoDadosEmpresaAction);

    case Types.AtribuirDadosEnderecoApiCep:
      return atribuirDadosEnderecoApiCep(state, action as AtribuirDadosEnderecoApiCepAction);

    case Types.AtribuirConsultandoCnpj:
      return atribuirConsultandoCnpj(state, action as AtribuirConsultandoCnpjAction);
    case Types.AtribuirSenha:
      return atribuirSenha(state, action as AtribuirSenhaAction);

    case Types.AtribuirConcordaTermosAdesao:
      return atribuirConcordaTermosAdesao(state, action as AtribuirConcordaTermosAdesaoAction);

    case Types.AtribuirAceitaReceberOfertas:
      return atribuirAceitaReceberOfertas(state, action as AtribuirAceitaReceberOfertasAction);

    case Types.AtribuirCompartilharDados:
      return atribuirCompartilharDados(state, action as AtribuirCompartilharDadosAction);

    case Types.AtribuirCompartilharDadosItau:
      return atribuirCompartilharDadosItau(state, action as AtribuirCompartilharDadosItauAction);

    case Types.AtribuirStatusCadastro:
      return { ...state, statusCadastro: (action as AtribuirStatusCadastroAction).statusCadastro };

    case Types.AtribuirPessoaPoliticamenteExposta:
      return atribuirPessoaPoliticamenteExposta(state, action as AtribuirPessoaPoliticamenteExpostaAction);

    case Types.AtribuirErroServidor:
      return { ...state, erroServidor: (action as AtribuirErroServidorAction).erroServidor };

    case Types.AtribuirPedidoResponseBoleto:
      return atribuirBoletoResponseBoleto(state, action as AtribuirPedidoResponseBoletoAction);

    case Types.AtribuirPedidoId:
      return atribuirPedidoId(state, action as AtribuirPedidoIdAction);

    default:
      return state;
  }
};
