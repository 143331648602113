import axios from 'axios';
import { RespostaApi } from '../ApiInterfaces';
import { ItemCardFatura, ItemLimitesSaldoFatura } from '../../features/fatura-pos-pago/FaturaInterfaces';

const locale = 'en-US';
const obterParametrosFormatadosMesAtual = (parceiroId) => {
  const formatTwoDigits = digit => (`0${digit}`).slice(-2);
  const tempDate = new Date();
  const mesAnterior = formatTwoDigits(tempDate.getMonth() + 1);
  const anoAtual = tempDate.getFullYear();

  let parametrosFormatados = `parceiroId=${parceiroId}`;
  parametrosFormatados += `&competenciaMes=${mesAnterior}`;
  parametrosFormatados += `&competenciaAno=${anoAtual}`;
  parametrosFormatados += '&IncluirPdfBase64=true';
  parametrosFormatados += '&FaturaValorZerado=true';

  return parametrosFormatados;
};

const obterParametrosFormatadosMesAnterior = (parceiroId) => {
  const formatTwoDigits = digit => (`0${digit}`).slice(-2);
  const tempDate = new Date();
  const mesAnterior = formatTwoDigits(tempDate.getMonth());
  const anoAtual = tempDate.getFullYear();

  let parametrosFormatados = `parceiroId=${parceiroId}`;
  parametrosFormatados += `&competenciaMes=${mesAnterior}`;
  parametrosFormatados += `&competenciaAno=${anoAtual}`;
  parametrosFormatados += '&IncluirPdfBase64=true';
  parametrosFormatados += '&FaturaValorZerado=true';

  return parametrosFormatados;
};

const obterParametrosFormatadosLimitesSaldo = parceiroId => `parceiroId=${parceiroId}`;

const obterParametrosFormatadosHistorico = (parceiroId) => {
  const tempDate = new Date();

  const primeiroDiaMes = new Date(tempDate.getFullYear(), tempDate.getMonth(), 1);
  const primeiroDiaMesCincoAnterior = new Date(primeiroDiaMes.setMonth(primeiroDiaMes.getMonth() - 5));

  const ultimoDiaMes = new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0);

  let parametrosFormatados = `parceiroId=${parceiroId}`;
  parametrosFormatados += `&dataInicial=${primeiroDiaMesCincoAnterior.toLocaleDateString(locale)}`;
  parametrosFormatados += `&dataFinal=${ultimoDiaMes.toLocaleDateString(locale)}`;
  parametrosFormatados += '&IncluirPdfBase64=true';
  parametrosFormatados += '&FaturaValorZerado=true';
  return parametrosFormatados;
};

export default {

  buscarDadosFaturaAtualCard: (parceiroId: number, token: string)
    : Promise<RespostaApi<ItemCardFatura[]>> => {
    const parametrosFormatados = obterParametrosFormatadosMesAtual(parceiroId);
    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/Fatura/ListarFatura?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  buscarDadosFaturaAnteriorCard: (parceiroId: number, token: string)
    : Promise<RespostaApi<ItemCardFatura[]>> => {
    const parametrosFormatados = obterParametrosFormatadosMesAnterior(parceiroId);

    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/Fatura/ListarFatura?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  buscarLimitesSaldoFatura: (parceiroId: number, token: string)
    : Promise<RespostaApi<ItemLimitesSaldoFatura>> => {
    const parametrosFormatados = obterParametrosFormatadosLimitesSaldo(parceiroId);

    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/Fatura/ConsultarValorReal?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  buscarHistoricoFatura: (parceiroId: number, token: string)
    : Promise<RespostaApi<ItemCardFatura[]>> => {
    const parametrosFormatados = obterParametrosFormatadosHistorico(parceiroId);

    return axios.get(
      `${(window as any).config.url_api_management}portalpj/v1/Fatura/ListarFatura?${parametrosFormatados}`,
      {
        headers:
        {
          'Ocp-Apim-Subscription-Key': (window as any).config.subscription_api_management,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
};
