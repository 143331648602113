import { FaturaState } from './FaturaInterfaces';
import {
  Types,
  BuscarFaturaAtualCardAction,
  BuscarFaturaAtualCardSucessoAction,
  BuscarFaturaAtualCardErrorAction,
  BuscarFaturaAnteriorCardAction,
  BuscarFaturaAnteriorCardSucessoAction,
  BuscarFaturaAnteriorCardErrorAction,
  BuscarLimiteSaldoRequestAction,
  BuscarLimiteSaldoSucessoAction,
  BuscarLimiteSaldoErrorAction,
  BuscarHistoricoFaturaRequestAction,
  BuscarHistoricoFaturaSucessoAction,
  BuscarHistoricoFaturaErrorAction,
} from './FaturaActions';
import { Action } from '../interfaces';

const INITIAL_STATE = {
  dadosFatura: {},
  historicoFatura: {},
  dadosLimitesSaldo: { dados: { faturaAtual: 0, limiteCredito: 0, limiteDisponivel: 0, proximaFatura: 0 } },
  carregando: false,
  erro: false,
} as FaturaState;


const buscarFaturaAtualCard = (state: FaturaState, action: BuscarFaturaAtualCardAction) => (
  { ...state, action, dadosFatura: { carregando: true, erro: false } });

const buscarFaturaAtualCardSucesso = (state: FaturaState, action: BuscarFaturaAtualCardSucessoAction) => (
  { ...state, dadosFatura: { carregando: false, ...action.dadosFatura, erro: false } });

const buscarFaturaAtualCardError = (state: FaturaState, action: BuscarFaturaAtualCardErrorAction) => (
  { ...state, action, dadosFatura: { carregando: false, erro: true } });

const buscarFaturaAnteriorCard = (state: FaturaState, action: BuscarFaturaAtualCardAction) => (
  { ...state, action, dadosFatura: { carregando: true, erro: false } });

const buscarFaturaAnteriorCardSucesso = (state: FaturaState, action: BuscarFaturaAnteriorCardSucessoAction) => (
  { ...state, dadosFatura: { carregando: false, ...action.dadosFatura, erro: false } });

const buscarFaturaAnteriorCardError = (state: FaturaState, action: BuscarFaturaAnteriorCardErrorAction) => (
  { ...state, action, dadosFatura: { carregando: false, erro: true } });

const buscarLimiteSaldoRequest = (state: FaturaState, action: BuscarLimiteSaldoRequestAction) => (
  { ...state, action, carregando: true, erro: false });

const buscarLimiteSaldoSucesso = (state: FaturaState, action: BuscarLimiteSaldoSucessoAction) => (
  { ...state, dadosLimitesSaldo: action.dadosLimitesSaldo, carregando: false, erro: false });

const buscarLimiteSaldoError = (state: FaturaState, action: BuscarLimiteSaldoErrorAction) => (
  { ...state, action, carregando: false, erro: true });

const buscarHistoricoFaturaRequest = (state: FaturaState, action: BuscarHistoricoFaturaRequestAction) => (
  { ...state, action, historicoFatura: { carregando: true, erro: false } });

const buscarHistoricoFaturaSucesso = (state: FaturaState, action: BuscarHistoricoFaturaSucessoAction) => (
  { ...state, historicoFatura: { carregando: false, ...action.historicoFatura, erro: false } });

const buscarHistoricoFaturaError = (state: FaturaState, action: BuscarHistoricoFaturaErrorAction) => (
  { ...state, action, historicoFatura: { carregando: false, erro: true } });

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.BuscarFaturaAtualCard:
      return buscarFaturaAtualCard(state, action as BuscarFaturaAtualCardAction);
    case Types.BuscarFaturaAtualCardSucesso:
      return buscarFaturaAtualCardSucesso(state, action as BuscarFaturaAtualCardSucessoAction);
    case Types.BuscarFaturaAtualCardError:
      return buscarFaturaAtualCardError(state, action as BuscarFaturaAtualCardErrorAction);

    case Types.BuscarFaturaAnteriorCard:
      return buscarFaturaAnteriorCard(state, action as BuscarFaturaAnteriorCardAction);
    case Types.BuscarFaturaAnteriorSucesso:
      return buscarFaturaAnteriorCardSucesso(state, action as BuscarFaturaAnteriorCardSucessoAction);
    case Types.BuscarFaturaAnteriorCardError:
      return buscarFaturaAnteriorCardError(state, action as BuscarFaturaAnteriorCardErrorAction);

    case Types.BuscarLimiteSaldoRequest:
      return buscarLimiteSaldoRequest(state, action as BuscarLimiteSaldoRequestAction);
    case Types.BuscarLimiteSaldoSucesso:
      return buscarLimiteSaldoSucesso(state, action as BuscarLimiteSaldoSucessoAction);
    case Types.BuscarLimiteSaldoError:
      return buscarLimiteSaldoError(state, action as BuscarLimiteSaldoErrorAction);

    case Types.BuscarHistoricoFaturaRequest:
      return buscarHistoricoFaturaRequest(state, action as BuscarHistoricoFaturaRequestAction);
    case Types.BuscarHistoricoFaturaSucesso:
      return buscarHistoricoFaturaSucesso(state, action as BuscarHistoricoFaturaSucessoAction);
    case Types.BuscarHistoricoFaturaError:
      return buscarHistoricoFaturaError(state, action as BuscarHistoricoFaturaErrorAction);
    default:
      return state;
  }
};
