import axios from 'axios';

export const possuiAdesao = async () : Promise<boolean> => {
  const ativo = sessionStorage.getItem('ativo');

  if (ativo === null) {
    return setAdesao(sessionStorage.getItem('TOKEN_KEY'));
  }

  return (+ativo > 0);
};


export const setAdesao = async (token : string) : Promise<boolean> => {
  const urlbase = process.env.URL_API_MANAGEMENT;
  const subscription = process.env.SUBSCRIPTION_API_MANAGEMENT;

  const resposta = await axios.get(
    `${urlbase}portalpj/v1/Adesao/Ativa`,
    {
      headers:
      {
        'Ocp-Apim-Subscription-Key': subscription,
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (resposta?.data?.dados?.adesoes[0]?.planoId === 3) {
    sessionStorage.setItem('ativo', '1');
    return true;
  }

  sessionStorage.setItem('ativo', '0');
  return false;
};
