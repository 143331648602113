import React, { memo } from 'react';
import {
  Button, Card, CardActions, CardContent, CardHeader, Divider, makeStyles, Typography,
} from '@material-ui/core';

import { Add, Remove } from '@material-ui/icons';
import ModalCentral from '../../ModalCentral';

import { useControleModalConfirmarPedido } from '../../../hooks/useControleModalConfirmarPedido/useControleModalConfirmarPedido';
import { PedidoActions, usePedido } from '../../../features/solicitacao-adesivo/solicitacaoAdesivo/context/SolicitacaoAdesivoContext';
import { formatoBRL } from '../../../utils/formatarValorMonetario';

import './ResumoPedido.scss';

const ResumoPedido = () => {
  const cardHeader = makeStyles(() => ({
    title: {
      fontSize: '16px !important',
      fontWeight: 700,
    },
    subheader: {
      color: '#4D5761',
      fontSize: '14px !important',
    },
  }))(CardHeader);

  const { alterarVisualizacaoModal } = useControleModalConfirmarPedido();
  const { pedidoState, pedidoDispatch } = usePedido();

  const handleAumentarQuantidadePedido = () => pedidoDispatch({ type: PedidoActions.setQuantidadeItensPedido, payload: pedidoState.quantidadeItensPedido + 1 });
  const handleDiminuirQuantidadePedido = () => pedidoState.quantidadeItensPedido > 1
            && pedidoDispatch({ type: PedidoActions.setQuantidadeItensPedido, payload: pedidoState.quantidadeItensPedido - 1 });

  return (
    <>
      <ModalCentral
        motivoPedido={pedidoState.motivoPedido}
        quantidade={pedidoState.quantidadeItensPedido}
        formaPagamento={pedidoState.metodoPagamento}
        frete='Grátis'
        taxa={pedidoState.taxa?.toLocaleString('pt-BR', formatoBRL)}
        total={(pedidoState.taxa * pedidoState.quantidadeItensPedido)?.toLocaleString('pt-BR', formatoBRL)}
        endereco={{
          bairro: pedidoState.enderecoSelecionado.bairro,
          cep: pedidoState.enderecoSelecionado.cep,
          cidade: pedidoState.enderecoSelecionado.cidade,
          codigoIbge: pedidoState.enderecoSelecionado.codigoIbge?.toString(),
          complemento: pedidoState.enderecoSelecionado.complemento,
          logradouro: pedidoState.enderecoSelecionado.logradouro,
          numero: pedidoState.enderecoSelecionado.numero,
          uf: pedidoState.enderecoSelecionado.estado,
        }}
      />

      <Card className='resumo-pedido-box' variant='outlined'>
        <CardHeader
          classes={cardHeader}
          className='resumo-pedido-box-header'
          title='Resumo do pedido'
          subheader='Confira as informações do seu pedido'
        />
        <CardContent className='resumo-pedido-box-content'>
          <Divider className='resumo-pedido-divider' />
          <span className='resumo-pedido-box-quantidade-btn'>
            <Button className='resumo-pedido-quantidade-btn' id='resumo-pedido-btn-diminuir-quantidade' onClick={handleDiminuirQuantidadePedido}><Remove fontSize='large' /></Button>
            <p id='resumo-pedido-quantidade-itens'>{pedidoState.quantidadeItensPedido}</p>
            <Button className='resumo-pedido-quantidade-btn' id='resumo-pedido-btn-aumentar-quantidade' onClick={handleAumentarQuantidadePedido}><Add fontSize='large' /></Button>
          </span>
          <span className='resumo-pedido-box-descricao'>
            <Typography>{pedidoState.quantidadeItensPedido > 1 ? 'Adesões' : 'Adesão'}</Typography>
          </span>
          <Divider className='resumo-pedido-divider' />
          <span className='resumo-pedido-box-forma-pagamento'>
            <Typography>Forma de pagamento</Typography>
            <Typography>{pedidoState.metodoPagamento || '-'}</Typography>
          </span>
          <span className='resumo-pedido-box-frete'>
            <Typography>Frete</Typography>
            <Typography>Grátis</Typography>
          </span>
          <span className='resumo-pedido-box-taxa-adesao'>
            <Typography>Taxa de adesão</Typography>
            <Typography>{(pedidoState.taxa || 0).toLocaleString('pt-BR', formatoBRL)}</Typography>
          </span>
          <span className='resumo-pedido-box-total'>
            <Typography>Total</Typography>
            <Typography className='resumo-pedido-box-label-valor-total'>{((pedidoState.taxa || 0) * pedidoState.quantidadeItensPedido).toLocaleString('pt-BR', formatoBRL)}</Typography>
          </span>
        </CardContent>
        <CardActions>
          <span className='resumo-pedido-box-btn-realizar-pedido'>
            <Button
              className={pedidoState.modalEnderecoEmEdicaoAberta || pedidoState.taxa === 0 ? 'resumo-pedido-btn-realizar-pedido-disabled' : 'resumo-pedido-btn-realizar-pedido'}
              disabled={pedidoState.modalEnderecoEmEdicaoAberta || pedidoState.taxa === 0}
              onClick={() => alterarVisualizacaoModal(true)}
              size='medium'
              id='btnRealizarPedido'
            >Realizar pedido
            </Button>
          </span>
        </CardActions>
      </Card>
    </>
  );
};
export default memo(ResumoPedido);
