import { makeStyles } from '@material-ui/core';

export const buttonStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #293B8A',
    borderRadius: '30px',
    color: '#293B8A',
    fontSize: '14px',
    fontWeight: 700,
    padding: '10px 48px',
    textTransform: 'inherit',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  disabled: {
    borderColor: '#BEC4CC',
    color: '#BEC4CC',
  },
}));
