import { createStyles, makeStyles } from '@material-ui/core';

const RecargaCartaoCreditoPageStyles = makeStyles(() => createStyles({
  boxCartao: {
    flexDirection: 'row',
    maxWidth: '1100px',
    justifyContent: 'center',
    alignContent: 'center',
    margin: 'auto',
  },
  containerFormCartao: {
    flexDirection: 'row',
    maxWidth: '1100px',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '1rem',
  },
  tituloCartao: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '12px',
  },
  textoTituloCartao: {
    textTransform: 'capitalize',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
  },
  cardValorRecarga: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '24px 22px 32px 24px',
    gap: '24px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 5px 7px rgba(0, 0, 0, 0.05)',
    border: '1px solid #EDEDED',
    borderRadius: '12px',
  },
  tituloFormValor: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2A3037',
  },
  fieldFormValor: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '19px 16px',
    gap: '16px',
    background: '#FFFFFF',
    border: '1px solid #BEC4CC',
    borderRadius: '10px',
    width: '99%',
  },
  cardsValoresSelecionaveis: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    width: '100%',
  },
  cardItemSelecionavel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '16px',
  },
  cardFavorito: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px',
    gap: '16px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 5px 7px rgba(0, 0, 0, 0.05)',
    border: '1px solid #EDEDED',
    borderRadius: '12px',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  cardFavoritoContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '12px',
  },
  cardFavoritoContentTexto: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '4px',
  },
  cardFavoritoTextoTitulo: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3B5CB0',
  },
  cardFavoritoTextoSubTitulo: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#4D5761',
  },
}));

export default RecargaCartaoCreditoPageStyles;
