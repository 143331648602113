import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import './UltimoPedido.scss';
import { Avatar, Grid } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withRouter } from 'react-router';
import Icon from '@material-ui/core/Icon';
import UltimoPedidoService from './UltimoPedidoService';
import { EnderecoEntrega, Pedido } from '../../infrastructure/pedido-api/Models/pedidoModel';
import ocultadorChatbot from '../../infrastructure/ocultadorChatbot';
import BotaoImprimirBoleto from './BotaoImprimirBoleto';
import { ParametrizacaoPlanoPj } from '../../infrastructure/prospecto-api/planospj/planospj';
import PainelOfertasService from '../PainelOfertas/PainelOfertasService';


interface IState {
  pedido: Pedido,
  ofertaPrateleira: ParametrizacaoPlanoPj,
}

class UltimoPedido extends PureComponent<{ history, documento, token, tituloOferta }, IState> {
  constructor(props) {
    super(props);
    this.state = {
      pedido: {
        id: '',
        codigoPedido: 0,
        dadosCliente: {
          id: 0,
          codigoErp: '',
          nome: '',
          documento: '',
          pessoaFisica: false,
          inscricaoEstadual: '',
          email: '',
          telefones: [],
        },
        dadosEntrega: {
          integracaoPedidoId: '',
          enderecoEntrega: {
            cep: '',
            logradouro: '',
            numero: '',
            bairro: '',
            cidade: '',
            uf: '',
            codigoIbge: '',
            complemento: '',
          },
          prazoEntregaDias: 0,
          codigoRastreio: '',
          dadosNotaFiscal: null,
          statusEntrega: [],
          itensEntregues: [],
          ultimoStatusEntrega: null,
          nfEmitida: false,
          dataEntrega: null,
        },
        dadosPagamento: null,
        dadosPromocionais: null,
        dataPedido: null,
        dataUltimaAtualizacao: null,
        itens: [],
        status: 0,
        tipoPedido: null,
        valorFrete: 0,
        valorTotal: 0,
      },
      ofertaPrateleira: {
        id: '',
        canalVendasId: 0,
        canalVendasNome: '',
        codigoPlano: '',
        planoId: 0,
        taxaMensalidadeId: 0,
        mesesIsencaoMensalidade: 0,
        percentualIsencaoMensalidade: 0,
        dataInicioVigencia: '',
        urlTermoAdesao: null,
        padrao: true,
        valorAdesao: 0,
        mensalidadeVeiculoLeve: 0,
        mensalidadeVeiculoPesado: 0,
        titulo: '',
        qtdLimitePedido: 0,
        imagem: '',
      },
    };
  }

  async componentDidMount() {
    ocultadorChatbot();
    await this.inicializarDados();
  }


  /**
   * Inicializa os dados (API) e organiza o que precisa
   */
  async inicializarDados() {
    await this.obterUltimoPedidoPorCliente();
    const { pedido } = this.state;
    if (pedido) {
      const data = await PainelOfertasService.obterPlanoPorCanalVendasId(pedido.dadosPromocionais.canalVendasId);
      this.setState(previousState => ({
        ...previousState,
        data,
      }));
    }
  }

  /**
   * Obtem os pedidos referente a oferta prateleira
   */
  async obterUltimoPedidoPorCliente() {
    const data = await UltimoPedidoService.obterUltimoPedido(this.props.token);

    this.setState({
      pedido: data,
    });
  }

  obterCurrencyFormat(valor: number = 0): string {
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  obterDataPedido(data: string): string {
    if (data === undefined || data === null || data.length < 1 || data === 'null') return '';
    const dataConvertida = new Date(data);
    const dataFormatada = `${dataConvertida.getDate()}/${dataConvertida.getMonth() + 1}`;
    return dataFormatada;
  }

  ArrumarEnderecoParaExibir(enderecoEntrega: EnderecoEntrega): string {
    const logradouro = `${enderecoEntrega?.logradouro ? `${enderecoEntrega.logradouro}` : ''}`;
    const numero = `, ${enderecoEntrega?.numero ? `${enderecoEntrega.numero}. - ` : ''}`;
    const bairro = ` ${enderecoEntrega?.bairro ? `${enderecoEntrega.bairro} - ` : ''}`;
    const cidade = ` ${enderecoEntrega?.cidade ? enderecoEntrega.cidade : ''}`;
    const uf = `/ ${enderecoEntrega?.uf ? enderecoEntrega.uf : ''}`;
    const cep = ` - ${enderecoEntrega?.cep ? enderecoEntrega.cep : ''}`;

    const endereco = `${logradouro}${numero}${bairro}${cidade}${uf} ${cep}`;
    return endereco;
  }

  ValidarPedidoOfertaPrateleira(): boolean {
    return this.state.pedido == null || this.state.ofertaPrateleira == null;
  }

  render() {
    const { pedido } = this.state;

    const { tituloOferta } = this.props;

    if (this.ValidarPedidoOfertaPrateleira()) { return null; }

    const endereco: string = this.ArrumarEnderecoParaExibir(pedido.dadosEntrega?.enderecoEntrega);
    const devoExibir: boolean = pedido.codigoPedido > 0 && tituloOferta?.length > 0 && endereco?.length > 5;

    return (
      <div className='rootUltimoPedido' style={devoExibir ? {} : { display: 'none' }}>

        <p className='pPromocoes'>Último Pedido</p>
        <Card className='card'>
          <CssBaseline />
          <Container>
            <CardContent>
              <div>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                  <Grid item xs={10} sm={2}>
                    <p className='pTitulo'>Promoção:</p>
                    <span className='SpanConteudo'>{tituloOferta}</span>
                  </Grid>
                  <Grid item xs={10} sm={2}>
                    <p className='pTitulo'>Nº Pedido</p>
                    <span className='SpanConteudo'>{pedido.codigoPedido}</span>
                  </Grid>
                  <Grid item xs={10} sm={2}>
                    <p className='pTitulo'>Valor:</p>
                    <span className='SpanConteudo'>{this.obterCurrencyFormat(pedido.valorTotal)}</span>
                  </Grid>
                  <Grid item xs={10} sm={2}>
                    <p className='pTitulo'>Qtd</p>
                    <span className='SpanConteudo'>{pedido.itens[0]?.quantidade}</span>
                  </Grid>
                  <Grid item xs={11} sm={4}>
                    <p className='pTitulo'>Endereço de entrega</p>
                    <span className='SpanConteudo'>
                      {endereco}
                      {' '}
                    </span>
                  </Grid>
                  <Grid container item xs={11} sm={8} alignItems='center' justify='flex-start' direction='row'>
                    <Grid className='gridStatusPedido' container item xs={1} sm={1} alignItems='center' justify='center' direction='column'>
                      <span>PEDIDO</span>
                      <Avatar style={{ backgroundColor: '#FFA789', color: '#fff', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                        <ShoppingCartIcon />
                      </Avatar>
                      <span>{this.obterDataPedido(pedido.dataPedido?.toString())}</span>
                    </Grid>
                    <Grid container style={{ marginLeft: -20, marginRight: -16 }} item xs={3} sm={3} alignItems='center' justify='flex-start' direction='row'>
                      {pedido.status === 3 || pedido.status === 4 || pedido.status === 5
                        ? <div className='divDashedOrange100' />
                        : (
                          <>
                            <div className='divDashedOrange60' />
                            <div className='divDashedGray40' />
                          </>
                        )
                      }
                    </Grid>
                    <Grid className='gridStatusPagamento' item container xs={1} sm={1} alignItems='center' justify='center' direction='column'>
                      <span>PAGAMENTO</span>
                      {pedido.status === 3 || pedido.status === 4 || pedido.status === 5
                        ? (
                          <Avatar style={{ border: '1px solid #FFA789', color: '#fff', backgroundColor: '#FFA789', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                            <AccountBalanceIcon />
                          </Avatar>
                        )
                        : pedido.status === 2
                          ? (
                            <Avatar style={{ border: '1px solid #999', color: '#fff', backgroundColor: '#999', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                              <Icon>clear</Icon>
                            </Avatar>
                          )
                          : (
                            <Avatar style={{ border: '1px solid #FFA789', backgroundColor: '#FFF', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                              <Icon />
                            </Avatar>
                          )
                      }
                      {pedido.status === 3 || pedido.status === 4 || pedido.status === 5
                        ? <span>{this.obterDataPedido(pedido.dadosPagamento?.dataPagamento?.toString())}</span>
                        : (pedido.status === 2)
                          ? <span>CANCELADO</span>
                          : <span>AGUARDANDO</span>}
                    </Grid>
                    <Grid container style={{ marginLeft: -3, marginRight: -35 }} item xs={3} sm={3} alignItems='center' justify='flex-start' direction='row'>
                      {pedido.status === 3 || pedido.status === 5
                        ? <div className='divDashedOrange100' />
                        : (pedido.status === 0 || pedido.status === 2)
                          ? <div className='divDashedGray100' />
                          : (
                            <>
                              <div className='divDashedOrange60' />
                              <div className='divDashedGray40' />
                            </>
                          )
                      }
                    </Grid>
                    <Grid className='gridStatusEnvio' item container xs={2} sm={2} alignItems='center' justify='center' direction='column'>
                      <span>ENVIO</span>
                      {pedido.status === 3 || pedido.status === 5
                        ? (
                          <Avatar style={{ border: '1px solid #FFA789', color: '#fff', backgroundColor: '#FFA789', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                            <LocalShippingIcon />
                          </Avatar>
                        )
                        : (
                          <Avatar style={{ border: '1px solid #FFA789', backgroundColor: '#FFF', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                            <Icon />
                          </Avatar>
                        )
                      }
                      <span className='spanHidden'>.</span>
                    </Grid>
                    <Grid container style={{ marginLeft: -45, marginRight: -34 }} item xs={3} sm={3} alignItems='center' justify='flex-start' direction='row'>
                      {pedido.status === 5
                        ? <div className='divDashedOrange100' />
                        : (pedido.status === 0 || pedido.status === 4 || pedido.status === 2)
                          ? <div className='divDashedGray100' />
                          : (
                            <>
                              <div className='divDashedOrange60' />
                              <div className='divDashedGray40' />
                            </>
                          )
                      }
                    </Grid>
                    <Grid className='gridStatusEntregue' item container xs={2} sm={2} alignItems='center' justify='center' direction='column'>
                      <span>ENTREGUE</span>
                      {pedido.status === 5
                        ? (
                          <Avatar style={{ border: '1px solid #FFA789', color: '#fff', backgroundColor: '#FFA789', height: '30px', width: '30px', marginTop: 5, marginBottom: 17 }}>
                            <CheckCircleIcon />
                          </Avatar>
                        )
                        : (
                          <Avatar style={{ border: '1px solid #FFA789', backgroundColor: '#FFF', height: '30px', width: '30px', marginTop: 5, marginBottom: 5 }}>
                            <Icon />
                          </Avatar>
                        )
                      }
                      {pedido.status === 5
                        ? <span>{this.obterDataPedido(pedido.dadosEntrega?.dataEntrega?.toString())}</span>
                        : <span className='spanHidden'>.</span>}

                    </Grid>

                  </Grid>
                  <Grid item xs={10} sm={4}>
                    <BotaoImprimirBoleto deveExibir={false} urlBoleto={pedido.dadosPagamento?.urlBoleto} />
                  </Grid>
                </Grid>
              </div>

            </CardContent>

          </Container>
        </Card>
      </div>
    );
  }
}

export default withRouter(UltimoPedido);
