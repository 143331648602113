import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Accordion, AccordionSummary, Divider } from '@material-ui/core';
import { RadioButtonUnchecked } from '@material-ui/icons';
import BotaoComplementoTituloStyles from '../../../Components/BotaoComplementoTitulo/BotaoTituloStyles';
import BotaoComplementoTitulo from '../../../Components/BotaoComplementoTitulo/BotaoTitulo';
import MetodosPagamentoStyles from '../MetodosPagamentoStyles';
import BoxPagamentoStyles from '../../../../../components/BoxPagamentoControlado/BoxPagamentoStyles';
import { possuiAdesao } from '../../../../../utils/possuiAdesao';

const BoxCartaoCredito = () => {
  const textoComplemento = BotaoComplementoTituloStyles();
  const metodosPagamentoStyles = MetodosPagamentoStyles();
  const styles = BoxPagamentoStyles();
  const history = useHistory();
  const [ativo, setAtivo] = useState<boolean>(false);

  useEffect(() => {
    possuiAdesao().then((res) => { setAtivo(res); }).catch(() => setAtivo(false));
  }, []);

  const handlerPagamentoCartaoCredito = () => {
    history.push('/recarga/cartao');
  };

  return ativo && (
    <section id='section-cartaoCredito'>
      <div>
        <Accordion
          expanded={false}
          className={styles.BoxPagamentoAccordionStyle}
          style={{ borderColor: '#D5D5D5' }}
        >

          <AccordionSummary
            id='Recarga_Rb_CartaoCredito'
            expandIcon={<RadioButtonUnchecked />}
            className={styles.panelSummary}
            onClick={() => handlerPagamentoCartaoCredito()}
          >
            <div style={{ height: '64px', width: '62px' }}>
              <div className={`${metodosPagamentoStyles.logotiposBaseCss} ${metodosPagamentoStyles.cartaoCreditotipo}`} />
            </div>
            <div>
              <div className={styles.BoxPagamentoTitulo} id='Recarga_Rb_CartaoCredito-titulo'>
                Cartão de Crédito
                <BotaoComplementoTitulo texto='NOVO' classe={textoComplemento.botaoNovoTipoPagamento} />
              </div>
              <div className={styles.BoxPagamentoDescricao}>Aprovação imediata todos os dias.</div>
            </div>
          </AccordionSummary>
          <Divider className={styles.divider} />
        </Accordion>
      </div>
    </section>
  );
};

export default BoxCartaoCredito;
