import { call, takeEvery, put, select } from 'redux-saga/effects';
import Actions, { ObterPlanoAction, ObterRascunhoAction, Types } from './CadastroActions';
import { PlanosPjApi, RascunhoApi } from '../../infrastructure/prospecto-api';
import { State } from '../interfaces';
import ActionsBase from '../actions';
import { Plano, RepresentanteLegal, DadosEmpresa, Etapas } from './CadastroInterfaces';
import QueryString from '../../infrastructure/query-string';
import ProspectoApi from '../../infrastructure/prospecto-api/prospecto';
import { representaErroServidor } from '../../infrastructure/prospecto-api/prospecto/prospecto';
import PedidoApi from '../../infrastructure/pedido-api';
import { removerCaracteresNaoNumericos } from '../../infrastructure/mascara';
import { DadosIntegracaoPlataforma, DadosParametrosUtm } from '../../infrastructure/prospecto-api/rascunho/RascunhoInterfaces';

const obterPlanoState = (state: State): Plano => state.cadastro.plano;
const obterRepresentanteState = (state: State): RepresentanteLegal => state.cadastro.representanteLegal;
const obterDadosEmpresa = (state: State): DadosEmpresa => state.cadastro.dadosEmpresa;
const obterDadosParametrosUtm = (state: State): DadosParametrosUtm => state.cadastro.dadosParametrosUtm;
const obterDadosIntegracaoPlataforma = (state: State): DadosIntegracaoPlataforma => state.cadastro.dadosIntegracaoPlataforma;
const obterSenha = (state: State): string => state.cadastro.senha;
const obterAceitaReceberOfertas = (state: State): boolean => state.cadastro.aceitaReceberOfertas;
const obterConcordaTermosAdesao = (state: State): boolean => state.cadastro.concordaTermosAdesao;
const obterCompartilharDados = (state: State): boolean => state.cadastro.compartilharDados;
const obterCompartilharDadosItau = (state: State): boolean => state.cadastro.compartilharDadosItau;
const obterCodigoPlano = (state: State): string => state.cadastro.codigoPlano;
const obterCnpj = (state: State): string => state.cadastro.dadosEmpresa.cnpj;
const obterCanalVendasId = (state: State): number => state.cadastro.canalVendasId;
const obterCanalVendasHash = (state: State): string => state.cadastro.canalVendasHash;
const obterRascunhoId = (state: State): string => state.cadastro.idRascunho;
const obterEtapaAtual = (state: State): Etapas => state.cadastro.etapaAtual;
const obterPedidoState = (state: State): string => state.cadastro.pedidoId;


const formatarMensagemErro = (erro) => {
  if (erro.response && erro.response.data.notificacoes) {
    if (erro.response.data.notificacoes.length === 0) return 'Ocorreu um erro inesperado';
    return erro.response.data.notificacoes[0];
  }

  return erro.message;
};

function* obterPlano(action: ObterPlanoAction) {
  yield put(ActionsBase.iniciarRequisicao());

  try {
    const { data } = yield call(PlanosPjApi.obterPlanoPorCanalVendasId, action.canalVendasId);
    yield put(Actions.atribuirPlano(data.dados));
  } catch (erro) {
    yield put(Actions.atribuirErroServidor(formatarMensagemErro(erro)));
  } finally {
    yield put(ActionsBase.terminarRequisicao());
  }
}

function* enviarRascunho() {
  const plano = yield select(obterPlanoState);
  const pedidoId = yield select(obterPedidoState);
  const representante = yield select(obterRepresentanteState);
  const dadosEmpresa = yield select(obterDadosEmpresa);
  const dadosParametrosUtm = yield select(obterDadosParametrosUtm);
  const dadosIntegracaoPlataforma = yield select(obterDadosIntegracaoPlataforma);
  const planoId = yield select(obterCanalVendasHash);
  const rascunhoId = yield select(obterRascunhoId);
  const etapaAtual = yield select(obterEtapaAtual);
  const parceiroId = 1;
  const parceiro = 'Conectcar';

  try {
    const { data } = yield call(
      RascunhoApi.enviarRascunho,
      rascunhoId,
      planoId,
      plano,
      representante,
      dadosEmpresa,
      dadosParametrosUtm,
      dadosIntegracaoPlataforma,
      etapaAtual,
      parceiroId,
      parceiro,
      pedidoId,
    );
    yield put(Actions.atribuirIdRascunho(data.dados.id));
  } catch (erro) {
    yield put(Actions.atribuirErroServidor(formatarMensagemErro(erro)));
  }
}

function* obterRascunho(action: ObterRascunhoAction) {
  yield put(ActionsBase.iniciarRequisicao());
  try {
    const { data } = yield call(RascunhoApi.obterRascunho, action.idRascunho);
    yield put(Actions.atribuirRascunho(data.dados));
    yield put(Actions.obterPlano(data.dados.planoPjId));
  } catch (erro) {
    yield put(Actions.atribuirErroServidor(formatarMensagemErro(erro)));
  } finally {
    yield put(ActionsBase.terminarRequisicao());
  }
}

function* enviarProspecto() {
  yield put(ActionsBase.iniciarRequisicao());

  const canalVendasId = yield select(obterCanalVendasId);
  const plano = yield select(obterPlanoState);
  const representante = yield select(obterRepresentanteState);
  const dadosEmpresa = yield select(obterDadosEmpresa);
  const aceitaReceberOfertas = yield select(obterAceitaReceberOfertas);
  const concordaTermosAdesao = yield select(obterConcordaTermosAdesao);
  const compartilharDados = yield select(obterCompartilharDados);
  const compartilharDadosItau = yield select(obterCompartilharDadosItau);
  const codigoPlano = yield select(obterCodigoPlano);
  const senha = yield select(obterSenha);

  try {
    const { data, status } = yield call(
      ProspectoApi.enviar,
      canalVendasId,
      representante,
      plano,
      dadosEmpresa,
      aceitaReceberOfertas,
      concordaTermosAdesao,
      compartilharDados,
      compartilharDadosItau,
      senha,
      codigoPlano,
    );

    if (status === 200) {
      yield put(Actions.atribuirStatusCadastro(data.dados.status));
    }

    if (status === 202) {
      yield put(Actions.atribuirErroServidor('Usuário já cadastrado'));
    }

    yield put(Actions.avancarProximaEtapa());
    yield put(Actions.enviarRascunho());
  } catch (erro) {
    yield put(Actions.atribuirErroServidor(formatarMensagemErro(erro)));
  } finally {
    yield put(ActionsBase.terminarRequisicao());
  }
}

function* enviarPedido() {
  yield put(ActionsBase.iniciarRequisicao());

  const canalVendasHash = yield select(obterCanalVendasHash);
  const plano = yield select(obterPlanoState);
  const representante = yield select(obterRepresentanteState);
  const dadosEmpresa = yield select(obterDadosEmpresa);
  const senha = yield select(obterSenha);
  const aceitaReceberOfertas = yield select(obterAceitaReceberOfertas);
  const concordaTermosAdesao = yield select(obterConcordaTermosAdesao);
  const compartilharDados = yield select(obterCompartilharDados);
  const compartilharDadosItau = yield select(obterCompartilharDadosItau);
  const codigoPlano = yield select(obterCodigoPlano);

  try {
    const { data } = yield call(
      PedidoApi.enviar,
      canalVendasHash,
      representante,
      plano,
      dadosEmpresa,
      aceitaReceberOfertas,
      concordaTermosAdesao,
      compartilharDados,
      compartilharDadosItau,
      senha,
      codigoPlano,
    );

    if (!data.dados.hashBytesBoleto && !data.dados.urlBoleto) {
      yield put(Actions.atribuirErroServidor('Houve algum problema na geração do seu boleto.'));
      return;
    }

    yield put(Actions.atribuirPedidoResponseBoleto(data.dados.pedidoPossuiBoleto, data.dados.hashBytesBoleto, data.dados.urlBoleto));
    yield put(Actions.atribuirStatusCadastro(1));

    yield put(Actions.atribuirPedidoId(data.dados.id));
    yield put(Actions.avancarProximaEtapa());
    yield put(Actions.enviarRascunho());
  } catch (erro) {
    yield put(Actions.atribuirErroServidor(formatarMensagemErro(erro)));
  } finally {
    yield put(ActionsBase.terminarRequisicao());
  }
}

function* atribuirDadosQueryString() {
  const veiculosLevesQueryString = +QueryString.obterParametro('qtdeLeves');
  const veiculosPesadosQueryString = +QueryString.obterParametro('qtdePesados');
  const nome = !QueryString.obterParametro('nome')
    ? ''
    : QueryString.obterParametro('nome');
  const email = !QueryString.obterParametro('email')
    ? ''
    : QueryString.obterParametro('email');
  const telefone = !QueryString.obterParametro('telefone')
    ? ''
    : QueryString.obterParametro('telefone');
  const cnpj = !QueryString.obterParametro('cnpj')
    ? ''
    : QueryString.obterParametro('cnpj');

  yield put(
    Actions.atribuirDadosQueryString(
      !veiculosLevesQueryString ? 0 : veiculosLevesQueryString,
      !veiculosPesadosQueryString ? 0 : veiculosPesadosQueryString,
      nome,
      email,
      telefone,
      cnpj,
    ),
  );

  QueryString.removerTodosParametros();
}

function* validarCnpjEhClienteValidandoIdentidade() {
  yield put(Actions.atribuirConsultandoCnpj(true));
  let clienteExiste: boolean = false;

  try {
    const documento = yield select(obterCnpj);
    yield call(ProspectoApi.cadastroJaExistente, removerCaracteresNaoNumericos(documento));

    clienteExiste = true;
  } catch (error) {
    if (representaErroServidor(error.status)) {
      yield put(Actions.atribuirErroServidor(formatarMensagemErro(error)));
      return;
    }
  } finally {
    yield put(Actions.atribuirCadastroExistente(clienteExiste));
    yield put(Actions.atribuirConsultandoCnpj(false));
  }
}

function* inicializarPaginaClienteJaCadastrado() {
  yield call(atribuirDadosQueryString);

  const cnpj = yield select(obterCnpj);

  if (!cnpj) {
    yield put(Actions.atribuirCadastroExistente(true));
    return;
  }

  yield call(validarCnpjEhClienteValidandoIdentidade);
}

export default function* () {
  yield takeEvery(Types.EnviarRascunho, enviarRascunho);
  yield takeEvery(Types.ObterRascunho, obterRascunho);
  yield takeEvery(Types.ObterPlano, obterPlano);
  yield takeEvery(Types.EnviarProspecto, enviarProspecto);
  yield takeEvery(Types.EnviarPedido, enviarPedido);
  yield takeEvery(
    Types.InicializarPaginaClienteJaCadastrado,
    inicializarPaginaClienteJaCadastrado,
  );
  yield takeEvery(
    Types.ValidarCnpjEhClienteValidandoIdentidade,
    validarCnpjEhClienteValidandoIdentidade,
  );
}
