import React, { createContext, ReactNode, useContext, useReducer } from 'react';

export interface EnderecoState {
    cep: string,
    logradouro: string,
    complemento: string,
    numero: string,
    bairro: string,
    estado: string,
    codigoIbge: number,
    enderecoId: number,
    ibge: number,
    tipoEndereco: string,
    cidade: string,
    cidadeId: number,
}

export interface EnderecoAction {
    type: EnderecoActions;
    payload: any;
}

export interface EnderecoContextType {
    enderecoState: EnderecoState;
    enderecoDispatch: (action: EnderecoAction) => void;
}

interface EnderecoProviderProps {
    children: ReactNode
}

const initialData: EnderecoState = {
  cep: '',
  logradouro: '',
  complemento: '',
  numero: '',
  bairro: '',
  estado: '',
  codigoIbge: 0,
  enderecoId: 0,
  ibge: 0,
  tipoEndereco: '',
  cidade: '',
  cidadeId: 0,
};

export const EnderecoContext = createContext<EnderecoContextType | undefined>(undefined);

export enum EnderecoActions {
    setCep,
    setLogradouro,
    setComplemento,
    setNumero,
    setBairro,
    setEstado,
    setCodigoIbge,
    setIbge,
    setEnderecoId,
    setTipoEndereco,
    setCidade,
    setCidadeId,
    setEnderecoCompleto
}

const enderecoReducer = (state: EnderecoState, action: EnderecoAction) => {
  switch (action.type) {
    case EnderecoActions.setCep:
      return { ...state, cep: action.payload };
    case EnderecoActions.setLogradouro:
      return { ...state, logradouro: action.payload };
    case EnderecoActions.setComplemento:
      return { ...state, complemento: action.payload };
    case EnderecoActions.setNumero:
      return { ...state, numero: action.payload };
    case EnderecoActions.setBairro:
      return { ...state, bairro: action.payload };
    case EnderecoActions.setEstado:
      return { ...state, estado: action.payload };
    case EnderecoActions.setCodigoIbge:
      return { ...state, codigoIbge: action.payload };
    case EnderecoActions.setIbge:
      return { ...state, ibge: action.payload };
    case EnderecoActions.setEnderecoId:
      return { ...state, enderecoId: action.payload };
    case EnderecoActions.setTipoEndereco:
      return { ...state, tipoEndereco: action.payload };
    case EnderecoActions.setCidade:
      return { ...state, cidade: action.payload };
    case EnderecoActions.setCidadeId:
      return { ...state, cidadeId: action.payload };
    case EnderecoActions.setEnderecoCompleto:
      return {
        cep: action.payload.cep,
        logradouro: action.payload.logradouro,
        complemento: action.payload.complemento,
        numero: action.payload.numero,
        bairro: action.payload.bairro,
        estado: action.payload.estado,
        codigoIbge: action.payload.codigoIbge,
        enderecoId: action.payload.enderecoId,
        ibge: action.payload.ibge,
        tipoEndereco: action.payload.tipoEndereco,
        cidade: action.payload.cidade,
        cidadeId: action.payload.cidadeId,
      };
    default:
      return state;
  }
};

export const EnderecoProvider = ({ children }: EnderecoProviderProps) => {
  const [enderecoState, enderecoDispatch] = useReducer(enderecoReducer, initialData);
  const value = { enderecoState, enderecoDispatch };

  return (
    <EnderecoContext.Provider value={value}>
      {children}
    </EnderecoContext.Provider>
  );
};

export const useEndereco = () => {
  const context = useContext(EnderecoContext);
  return context;
};
