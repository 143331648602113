import React, {
  createContext, ReactNode, useContext, useEffect, useReducer,
} from 'react';
import { IEnderecoCliente } from '../../../../services/requests/SolicitacaoAdesivo/obterEnderecoClienteRequest/interface';
import { obterParametrizacaoPorPlanoCanalVendas } from '../../../../services/requests/SolicitacaoAdesivo/obterParametrizacaoPorPlanoCanalVendas';

export interface IPedidoState {
    motivoPedido: string;
    metodoPagamento: string;
    enderecoSelecionado: IEnderecoCliente;
    modalEnderecoEmEdicaoAberta: boolean;
    quantidadeItensPedido: number;
    frete: string;
    taxa: number;
}

export interface PedidoAction {
    type: PedidoActions;
    payload: any;
}

export interface IPedidoContext {
    pedidoState: IPedidoState;
    pedidoDispatch: (action: PedidoAction) => void;
}

interface PedidoProviderProps {
    children: ReactNode
}

const initialData: IPedidoState = {
  motivoPedido: 'Nova adesão',
  metodoPagamento: 'Boleto bancário',
  enderecoSelecionado: {
    bairro: '',
    cep: '',
    cidade: '',
    cidadeId: 0,
    codigoIbge: 0,
    complemento: '',
    enderecoId: 0,
    estado: '',
    ibge: 0,
    logradouro: '',
    numero: '',
    tipoEndereco: '',
  },
  modalEnderecoEmEdicaoAberta: false,
  frete: 'Grátis',
  quantidadeItensPedido: 1,
  taxa: 0,
};

export const PedidoContext = createContext<IPedidoContext | undefined>(undefined);

export enum PedidoActions {
    setMotivoPedido,
    setMetodoPagamento,
    setEnderecoSelecionado,
    setModalEnderecoEmEdicaoAberta,
    setQuantidadeItensPedido,
    setTaxa,
}

export const pedidoReducer = (state: IPedidoState, action: PedidoAction) => {
  switch (action.type) {
    case PedidoActions.setMotivoPedido:
      return { ...state, motivoPedido: action.payload };
    case PedidoActions.setMetodoPagamento:
      return { ...state, metodoPagamento: action.payload };
    case PedidoActions.setEnderecoSelecionado:
      return { ...state, enderecoSelecionado: action.payload };
    case PedidoActions.setModalEnderecoEmEdicaoAberta:
      return { ...state, modalEnderecoEmEdicaoAberta: action.payload };
    case PedidoActions.setQuantidadeItensPedido:
      return { ...state, quantidadeItensPedido: action.payload };
    case PedidoActions.setTaxa:
      return { ...state, taxa: action.payload };
    default:
      return state;
  }
};

export const PedidoProvider = ({ children }: PedidoProviderProps) => {
  const [pedidoState, pedidoDispatch] = useReducer(pedidoReducer, initialData);
  const value = { pedidoState, pedidoDispatch };

  useEffect(() => {
    const obterParametrizacaoPedido = async () => {
      const planoId = sessionStorage.getItem('PlanoId');
      const canalVendasId = sessionStorage.getItem('CanalVendas');

      await obterParametrizacaoPorPlanoCanalVendas(canalVendasId, planoId)
        .then((response) => {
          const { dados } = response.data;

          pedidoDispatch({
            type: PedidoActions.setTaxa,
            payload: dados.valorAdesao,
          });
        });
    };

    obterParametrizacaoPedido();
  }, []);

  return (
    <PedidoContext.Provider value={value}>
      {children}
    </PedidoContext.Provider>
  );
};

export const usePedido = () => {
  const context = useContext(PedidoContext);
  return context;
};
