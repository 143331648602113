import React from 'react';
import { StepConnector, StepIconProps, makeStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';

const IconInProgress = require('../../../assets/images/icones/success-circle-cinza.svg');
const IconCompleted = require('../../../assets/images/icones/success-circle-verde.svg');
const IconCancelled = require('../../../assets/images/icones/success-circle-vermelho.svg');

export const TrackingPedidoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#298f7a',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#298f7a',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export const useTrackingPedidoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#298f7a',
  },
});

export function TrackingPedidoStepIcon(props: StepIconProps & { cancelado?: boolean }) {
  const classes = useTrackingPedidoStepIconStyles();
  const { active, completed, cancelado } = props;

  let iconSrc = IconInProgress;

  if (cancelado) {
    iconSrc = IconCancelled;
  } else if (completed) {
    iconSrc = IconCompleted;
  }

  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      <img src={iconSrc} alt='icone stepper' />
    </div>
  );
}
