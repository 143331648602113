import React, { createContext,
  useContext,
  useCallback,
  useState } from 'react';

 interface IDadosEnderecoContexto {
     visualizarDadosEndereco: boolean;
     alterarVisualizacaoDadosEndereco: (identificador: number) => void;
     identificador: number;
 }

const DadosEnderecoContexto = createContext<IDadosEnderecoContexto>({} as IDadosEnderecoContexto);

const DadosEnderecoProvider: React.FC = ({ children }) => {
  const [visualizarDadosEndereco, setVisualizarDadosEndereco] = useState<boolean>(false);
  const [identificador, setIdentificador] = useState<number>(0);

  const alterarVisualizacaoDadosEndereco = useCallback((identificadorParam: number) => {
    setIdentificador(identificadorParam);
    setVisualizarDadosEndereco(!visualizarDadosEndereco);
  }, [visualizarDadosEndereco]);

  return (
    <DadosEnderecoContexto.Provider
      value={{
        visualizarDadosEndereco,
        identificador,
        alterarVisualizacaoDadosEndereco,
      }}
    >
      {children}
    </DadosEnderecoContexto.Provider>
  );
};

const useControleBoxDadosEndereco = (): IDadosEnderecoContexto => {
  const contexto = useContext(DadosEnderecoContexto);

  return contexto;
};

export { DadosEnderecoProvider, useControleBoxDadosEndereco };
