import { RespostaApi } from '../../../../infrastructure/ApiInterfaces';
import api from '../../../api';

export const solicitarAdesivo = (
  customerId: string,
  cnpj: string,
  ehSubstituicao: boolean,
  quantidade: number,
  endereco: {
      cep: string,
      logradouro: string,
      numero: string,
      complemento: string,
      bairro: string,
      cidade: string,
      uf: string,
      codigoIbge: string,
  },

): Promise<RespostaApi<unknown>> => api.post(
  '/Pedido/cadastroPedido',
  {
    customerId,
    cnpj,
    ehSubstituicao,
    quantidade,
    endereco,
  },
);
