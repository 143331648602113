import { take, takeEvery, put } from 'redux-saga/effects';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../autenticacao/AutenticacaoActions';
import { Types } from './SubstituicaoAdesivoActions';

function* inicializarSubstituicaoAdesivo() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
}

export default function* () {
  yield takeEvery(Types.InicializarSubstituicaoAdesivoAction, inicializarSubstituicaoAdesivo);
}
