import { Box, Grid, Typography } from '@material-ui/core';
import React, { memo } from 'react';

import './SubstituicaoSucesso.scss';

const iconeCole = require('../../../../assets/images/icones/adesivo-cole.svg');
const iconeConcluido = require('../../../../assets/images/icones/adesivo-concluido.svg');
const iconeDestaque = require('../../../../assets/images/icones/adesivo-destaque.svg');
const iconePrepare = require('../../../../assets/images/icones/adesivo-prepare.svg');

const SubstituicaoSucesso = () => {
  const handleOnClickLink = () => {
    window.open('https://conectcar.com/comocolar/', '_blank');
  };

  return (
    <Box id='substituicao-sucesso-box'>
      <Grid className='substituicao-sucesso-titulo' alignContent='center' alignItems='center'>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant='h3' className='substituicao-sucesso-titulo'>
            Sua troca foi realizada com sucesso!
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant='subtitle1' className='substituicao-sucesso-subtitulo'>
            Abaixo algumas dicas para que você possa colar o adesivo de maneira correta em seu veículo
          </Typography>
        </Grid>
      </Grid>
      <Box alignItems='baseline' id='substituicao-sucesso-painel'>
        <Box className='substituicao-sucesso-grid-item' id='substituicao-sucesso-adesivo-em-maos'>
          <img src={iconeDestaque} id='substituicao-sucesso-destaque-img' alt='' />
          <p className='substituicao-sucesso-titulo-card'>Adesivo em mãos</p>
          <p className='substituicao-sucesso-mensagem-card'>Agora que você já está com o adesivo em mãos e ativado chegou a hora de aplica-lo em seu veículo.</p>
        </Box>
        <Box className='substituicao-sucesso-grid-item' id='substituicao-sucesso-limpe-o-vidro'>
          <img src={iconePrepare} id='substituicao-sucesso-destaque-img' alt='' />
          <p className='substituicao-sucesso-titulo-card'>Limpe o vidro</p>
          <p className='substituicao-sucesso-mensagem-card'>Remova toda a cola de adesivos ou tags utilizados anteriormente e limpe a parte de dentro do para-brisa com álcool.</p>
        </Box>
        <Box className='substituicao-sucesso-grid-item' id='substituicao-sucesso-cole-o-adesivo'>
          <img src={iconeCole} id='substituicao-sucesso-destaque-img' alt='' />
          <p className='substituicao-sucesso-titulo-card'>Cole o adesivo</p>
          <p className='substituicao-sucesso-mensagem-card'>Cole seu ConectCar no para-brisa na parte interna do carro, próximo ao retrovisor.</p>
        </Box>
        <Box className='substituicao-sucesso-grid-item' id='substituicao-sucesso-pronto'>
          <img src={iconeConcluido} id='substituicao-sucesso-destaque-img' alt='' />
          <p className='substituicao-sucesso-titulo-card'>Pronto! Vamos lá</p>
          <p className='substituicao-sucesso-mensagem-card'>Você já pode passar em qualquer estacionamento conveniado e nas rodovias estaduais de SP. Para as demais rodovias, aguarde 6 horas.</p>
        </Box>
      </Box>
      <Typography variant='subtitle1' className='substituicao-sucesso-subtitulo' align='center' id='substituicao-sucesso-subtitulo-footer'>
        Ainda tem dúvidas de como colar? <button type='button' className='substituicao-sucesso-titulo-footer' onClick={handleOnClickLink}>Acesse aqui</button>
      </Typography>
    </Box>
  );
};
export default memo(SubstituicaoSucesso);
