import {
  call, takeEvery, put, select, take, all, takeLatest,
} from 'redux-saga/effects';

import FaturaApi from '../../infrastructure/fatura-pos-pago-api';
import GeneralActions from '../actions';
import Actions, { Types } from './FaturaActions';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../autenticacao/AutenticacaoActions';
import { State } from '../interfaces';

const obterParceiroId = (): number => (window as any).config.parceiroId;
const obterBearerToken = (state: State): string => state.autenticacao.bearerToken;

function* buscarFaturaAtualCard() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const parceiroId = yield select(obterParceiroId);
    const token = yield select(obterBearerToken);

    const { data } = yield call(FaturaApi.buscarDadosFaturaAtualCard, parceiroId, token);
    yield put(Actions.buscarFaturaAtualCardSucesso(data));
  } catch (e) {
    yield put(Actions.buscarFaturaAtualCardError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* buscarFaturaAnteriorCard() {
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const parceiroId = yield select(obterParceiroId);
    const token = yield select(obterBearerToken);

    const { data } = yield call(FaturaApi.buscarDadosFaturaAnteriorCard, parceiroId, token);
    yield put(Actions.buscarFaturaAnteriorCardSucesso(data));
  } catch (e) {
    yield put(Actions.buscarFaturaAnteriorCardError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* buscarLimitesSaldoFatura() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const parceiroId = yield select(obterParceiroId);
    const token = yield select(obterBearerToken);

    const { data } = yield call(FaturaApi.buscarLimitesSaldoFatura, parceiroId, token);
    yield put(Actions.buscarLimiteSaldoSucesso(data));
  } catch (e) {
    yield put(Actions.buscarLimiteSaldoError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* buscarHistoricoFatura() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const parceiroId = yield select(obterParceiroId);
    const token = yield select(obterBearerToken);

    const { data } = yield call(FaturaApi.buscarHistoricoFatura, parceiroId, token);
    yield put(Actions.buscarHistoricoFaturaSucesso(data));
  } catch (e) {
    yield put(Actions.buscarHistoricoFaturaError());
  } finally {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarFatura() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield all([buscarFaturaAtualCard(), buscarLimitesSaldoFatura(), buscarHistoricoFatura()]);
}

export default function* () {
  yield takeEvery(Types.InicializarFatura, inicializarFatura);
  yield takeEvery(Types.BuscarFaturaAtualCard, buscarFaturaAtualCard);
  yield takeEvery(Types.BuscarFaturaAnteriorCard, buscarFaturaAnteriorCard);
  yield takeEvery(Types.BuscarLimiteSaldoRequest, buscarLimitesSaldoFatura);
  yield takeLatest(Types.BuscarHistoricoFaturaRequest, buscarHistoricoFatura);
}
