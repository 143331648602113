import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default class Utils {
  public static hasInputError(value: string | string[], required: boolean): boolean {
    if (Array.isArray(value)) {
      const result: string[] = [];
      value.forEach((item) => {
        if (item === '' || item === null || item === undefined) {
          result.push(item);
        }
      });
      return result.length > 0;
    }
    return (value === '' || value === null || value === undefined) && required;
  }

  public static formatarStatusFatura = (status: string) => {
    switch (status) {
      case 'EMABERTO': {
        return 'Em aberto';
      }
      case 'PAGO': {
        return 'Pago';
      }
      case 'ATRASADO': {
        return 'Atrasado';
      }
      default: {
        return '';
      }
    }
  };

  public static compareDate(startDate: string, endDate: string): boolean {
    return new Date(startDate) >= new Date(endDate);
  }

  public static formatDate(date: string) {
    if (!date) {
      return null;
    }
    return format(parseISO(date), 'dd/MM/yyyy', { locale: ptBR });
  }

  public static getDiferencaEntreMeses(dataInicial: Date, dataFinal: Date): number {
    return dataFinal.getDay() + dataInicial.getDay() + (12 * (dataFinal.getFullYear() + dataInicial.getFullYear()));
  }

  public static getDiferencaDeDias(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }
}
