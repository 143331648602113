import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  FormControl, FormLabel, Grid, Typography,
} from '@material-ui/core';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { State } from '../../../../interfaces';
import { SubstituicaoAdesivoState } from '../../../SubstituicaoAdesivoInterfaces';
import SubstituicaoAdesivoFormularioTrocaVeiculo from './troca-veiculo/SubstituicaoAdesivoFormularioTrocaVeiculo';
import { IMotivoSubstituicao } from '../../../../../services/requests/SubstituicaoAdesivo/obterMotivosSubstituicao/interface';
import { obterMotivosSubstituicao } from '../../../../../services/requests/SubstituicaoAdesivo/obterMotivosSubstituicao';
import { cadastrarSubstituicaoAdesivo } from '../../../../../services/requests/SubstituicaoAdesivo/cadastrarSubstituicaoAdesivo/index';

import { MotivosSubstituicaoAdesivo } from '../../../../../models/consts/MotivoSubstituicaoAdesivo';
import { MensagemErroPadrao } from '../../../../../components/ModalErro/ModalErroConstantes';
import { SubstituicaoAutocomplete, SubstituicaoInput } from '../../../shared/SubstituicaoComponentes';
import { obterClassNameBotaoConcluirTroca, obterClassNameFundoFormularioPrincipal } from '../SubstituicaoDadosEstilosCondicionais';

import GeneralActions from '../../../../actions';
import Actions from '../../../SubstituicaoAdesivoActions';

import './SubstituicaoFormulario.scss';
import './SubstituicaoFormularioResponsive.scss';


const SubstituicaoAdesivoFormulario: React.FC = () => {
  const {
    formularioInicialAberto,
    placaSelecionada,
    habilitarBotaoConcluirTroca,
    dadosSubstituicao,
  } = useSelector<State, SubstituicaoAdesivoState>(state => ({
    formularioInicialAberto: state.substituicaoAdesivo.formularioInicialAberto,
    placaSelecionada: state.substituicaoAdesivo.placaSelecionada,
    habilitarBotaoConcluirTroca: state.substituicaoAdesivo.habilitarBotaoConcluirTroca,
    dadosSubstituicao: state.substituicaoAdesivo.dadosSubstituicao,
  }));

  const [motivosSubstituicao, setMotivosSubstituicao] = useState<IMotivoSubstituicao[]>([]);
  const [mostrarFormularioTrocaVeiculo, setMostrarFormularioTrocaVeiculo] = useState<boolean>(false);
  const motivoSubstituicaoTrocaVeiculoId = MotivosSubstituicaoAdesivo.TrocaDeVeiculo.id;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleAlterarDadoSubstituicao = (prop: string, valor: any) => {
    const dados = dadosSubstituicao[prop];
    dados.valor = valor;

    dispatch(Actions.atualizarDadosSubstituicao(dados, prop));
  };

  const handleValidarConfirmacaoNumeroAdesivo = () => {
    if (dadosSubstituicao.numeroSerieTagNovaConfirmacao.valor !== dadosSubstituicao.numeroSerieTagNova.valor) {
      dispatch(Actions.atribuirMensagemAbrirModalErro('O número de adesivo da confirmação não pode ser diferente do informado no campo anterior.'));
    }
  };

  const handleDirecionarSucesso = () => history.push('/substituicao-adesivo-sucesso');

  const handleClickConcluirTroca = () => {
    dispatch(GeneralActions.iniciarRequisicao());

    const request: any = {
      adesaoAtualId: dadosSubstituicao.adesaoAtualId.valor,
      motivoSubstituicao: dadosSubstituicao.motivoSubstituicao.valor,
      numeroSerieTagAtual: dadosSubstituicao.numeroSerieTagAtual.valor,
      numeroSerieTagNova: dadosSubstituicao.numeroSerieTagNova.valor,
    };

    if (dadosSubstituicao.motivoSubstituicao.valor === motivoSubstituicaoTrocaVeiculoId) {
      const dadosVeiculo = dadosSubstituicao.veiculo;

      request.veiculo = {
        placa: dadosVeiculo.placa.valor,
        modelo: dadosVeiculo.modelo.valor,
        marca: dadosVeiculo.marca.valor,
        ano: dadosVeiculo.ano.valor,
        categoriaId: dadosVeiculo.categoriaId.valor,
      };
    }

    cadastrarSubstituicaoAdesivo(request)
      .then(() => handleDirecionarSucesso())
      .catch(error => dispatch(Actions.atribuirMensagemAbrirModalErro(error?.response?.data?.notificacoes || MensagemErroPadrao)))
      .finally(() => dispatch(GeneralActions.terminarRequisicao()));
  };


  useEffect(() => {
    const obterMotivosSubstituicaoAsync = async () => obterMotivosSubstituicao()
      .then(response => setMotivosSubstituicao(response?.data?.dados?.motivosSubstituicao))
      .catch(error => dispatch(Actions.atribuirMensagemAbrirModalErro(error?.response?.data?.notificacoes || MensagemErroPadrao)));

    obterMotivosSubstituicaoAsync();
  }, [dispatch]);


  useEffect(() => {
    const state = dadosSubstituicao.motivoSubstituicao;

    if (state.valor !== null && state.valor !== 0) state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicao(state, 'motivoSubstituicao'));

    if (state.valor === motivoSubstituicaoTrocaVeiculoId) setMostrarFormularioTrocaVeiculo(true);
    else setMostrarFormularioTrocaVeiculo(false);
  }, [dadosSubstituicao.motivoSubstituicao, dadosSubstituicao.motivoSubstituicao.valor, dispatch, motivoSubstituicaoTrocaVeiculoId]);

  useEffect(() => {
    const state = dadosSubstituicao.numeroSerieTagNova;

    if (state.valor !== '') state.valido = true;
    else state.valido = false;

    dispatch(Actions.atualizarDadosSubstituicao(state, 'numeroSerieTagNova'));
  }, [dadosSubstituicao.numeroSerieTagNova, dadosSubstituicao.numeroSerieTagNova.valor, dispatch]);

  useEffect(() => {
    const state = dadosSubstituicao.numeroSerieTagNova;
    const stateConfirmacao = dadosSubstituicao.numeroSerieTagNovaConfirmacao;

    if (state.valor === stateConfirmacao.valor) stateConfirmacao.valido = true;
    else stateConfirmacao.valido = false;

    dispatch(Actions.atualizarDadosSubstituicao(stateConfirmacao, 'numeroSerieTagNovaConfirmacao'));
  }, [dadosSubstituicao.numeroSerieTagNova, dadosSubstituicao.numeroSerieTagNova.valor, dadosSubstituicao.numeroSerieTagNovaConfirmacao, dadosSubstituicao.numeroSerieTagNovaConfirmacao.valor, dispatch]);

  useEffect(() => {
    if (dadosSubstituicao.motivoSubstituicao.valor !== motivoSubstituicaoTrocaVeiculoId
        && dadosSubstituicao.motivoSubstituicao.valido
        && dadosSubstituicao.numeroSerieTagNova.valido
        && dadosSubstituicao.numeroSerieTagNovaConfirmacao.valido) {
      dispatch(Actions.habilitarBotaoConcluirTroca(true));
    } else {
      dispatch(Actions.habilitarBotaoConcluirTroca(false));
    }
  }, [dadosSubstituicao.motivoSubstituicao.valido, dadosSubstituicao.motivoSubstituicao.valor, dadosSubstituicao.numeroSerieTagNova.valido, dadosSubstituicao.numeroSerieTagNovaConfirmacao.valido, dispatch, motivoSubstituicaoTrocaVeiculoId]);

  return (
    <React.Fragment>
      <Grid container spacing={0} justifyContent='center' className={obterClassNameFundoFormularioPrincipal(formularioInicialAberto, mostrarFormularioTrocaVeiculo)}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className='substituicao-formulario-fundo-ilustracao'>
            <span className='substituicao-ilustracao' />
          </span>
        </Grid>
        {formularioInicialAberto && (
          <React.Fragment>
            <Grid id='substituicao-formulario-grid-item-placa-selecionada' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-padding'>
              <Typography id='substituicao-formulario-placa-selecionada' variant='h3'>
                Placa: {placaSelecionada}
              </Typography>
            </Grid>
            <Grid id='substituicao-formulario-grid-item-preencher-campos' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-padding'>
              <Typography id='substituicao-formulario-subtitulo-continuar-processo'>
                Para dar continuidade no processo de troca de adesivo, <br id='substituicao-formulario-substituicao-br' /> preencha os campos abaixo
              </Typography>
            </Grid>
            <Grid id='substituicao-formulario-grid-item-motivo-troca' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-padding'>
              <FormControl className='substituicao-grid-fill-width'>
                <FormLabel className='substituicao-formulario-label'>Selecione o motivo da troca</FormLabel>
                <SubstituicaoAutocomplete
                  parameters={{
                    className: 'substituicao-formulario-autocomplete-motivo-troca',
                    getOptionLabel: (option: IMotivoSubstituicao) => option.descricao,
                    id: 'substituicao-adesivo-formulario-autocomplete-motivo-troca',
                    onChange: (_e, n: IMotivoSubstituicao) => handleAlterarDadoSubstituicao('motivoSubstituicao', n.motivoSubstituicaoTagId),
                    options: motivosSubstituicao,
                    noOptionsText: 'Não há motivos de substituição',
                    renderOption: (option: IMotivoSubstituicao) => (
                      <React.Fragment>
                        {option.descricao}
                      </React.Fragment>
                    ),
                  }}
                  placeholder='Selecione o motivo'
                />
              </FormControl>
            </Grid>
            <Grid id='substituicao-formulario-grid-item-nr-novo-adesivo' item xs={12} sm={6} md={6} lg={6} xl={6} className='substituicao-grid-flex substituicao-grid-padding'>
              <FormControl className='substituicao-grid-fill-width'>
                <FormLabel className='substituicao-formulario-label'>Número do novo adesivo*</FormLabel>
                <SubstituicaoInput
                  className='substituicao-formulario-input'
                  id='substituicao-formulario-numero-adesivo'
                  onBlur={() => handleAlterarDadoSubstituicao('numeroSerieTagNovaConfirmacao', '')}
                  onCopy={e => e.preventDefault()}
                  onPaste={e => e.preventDefault()}
                  onChange={e => handleAlterarDadoSubstituicao('numeroSerieTagNova', e.target.value.replace(/\D/g, ''))}
                  placeholder='Número do adesivo'
                  value={dadosSubstituicao.numeroSerieTagNova.valor}
                />
              </FormControl>
            </Grid>
            <Grid id='substituicao-formulario-grid-item-confirmar-nr-adesivo' item xs={12} sm={6} md={6} lg={6} xl={6} className='substituicao-grid-flex substituicao-grid-padding'>
              <FormControl className='substituicao-grid-fill-width'>
                <FormLabel className='substituicao-formulario-label'>Confirme o número do adesivo</FormLabel>
                <SubstituicaoInput
                  className='substituicao-formulario-input'
                  autoComplete='off'
                  id='substituicao-formulario-confirmacao-adesivo'
                  onCopy={e => e.preventDefault()}
                  onPaste={e => e.preventDefault()}
                  onBlur={handleValidarConfirmacaoNumeroAdesivo}
                  onChange={e => handleAlterarDadoSubstituicao('numeroSerieTagNovaConfirmacao', e.target.value.replace(/\D/g, ''))}
                  placeholder='Confirme o número anterior'
                  value={dadosSubstituicao.numeroSerieTagNovaConfirmacao.valor}
                />
              </FormControl>
            </Grid>
            <Grid id='substituicao-formulario-grid-item-aviso-qrcode' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-padding'>
              <Typography id='substituicao-formulario-aviso-qr-code' className='substituicao-formulario-label'>*O número de série do seu adesivo, encontra-se logo abaixo do QR Code.</Typography>
            </Grid>
            {
              mostrarFormularioTrocaVeiculo && <SubstituicaoAdesivoFormularioTrocaVeiculo />
            }
            <Grid id='substituicao-formulario-grid-item-concluir-troca' item xs={12} sm={12} md={12} lg={12} xl={12} className='substituicao-grid-flex substituicao-grid-justify-center substituicao-grid-padding'>
              <Button
                className={obterClassNameBotaoConcluirTroca(habilitarBotaoConcluirTroca)}
                disabled={!habilitarBotaoConcluirTroca}
                onClick={handleClickConcluirTroca}
                type='button'
                variant='contained'
                id='btnConcluirTroca'
              >
                Concluir troca
              </Button>
            </Grid>
          </React.Fragment>
        )
        }
      </Grid>
    </React.Fragment>
  );
};

export default memo(SubstituicaoAdesivoFormulario);
