import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';


const styles = StyleSheet.create({
  prestador: {
    border: '1pt solid #000000',
    display: 'flex',
  },
  legenda: {
    fontSize: '5px',
    alignSelf: 'baseline',
  },
  dadosEmpresa: {
    alignSelf: 'center',
    fontSize: '6px',
    marginRight: '160px',
    marginTop: '5px',
    marginBottom: '15px',
  },
  negrito: {
    fontFamily: 'Helvetica-Bold',
  },
});

const Prestador = () => (
  <View id='prestador' style={styles.prestador}>
    <Text style={styles.legenda}>
      Prestador de Serviço:
    </Text>
    <View style={styles.dadosEmpresa}>
      <Text style={styles.negrito}>CONECTCAR INSTITUICAO DE PAGAMENTO E SOL DE MOB. ELETRONICA S.A</Text>
      <Text>Av. Juscelino Kubscheck, 1830 - CEP: 04543-000 - São Paulo/SP</Text>
      <Text><span style={styles.negrito}>CNPJ: </span>16.577.631/0002-99</Text>
      <Text><span style={styles.negrito}>Inscrição Municipal: </span>5.650.060-2</Text>
    </View>
  </View>
);

export default Prestador;
