import * as React from 'react';
import { useSelector } from 'react-redux';

import { State } from '../../interfaces';

import './CadastroErroServidor.scss';

const iconeAlerta = require('../../../assets/images/icones/alerta_branco.svg');
const iconeFechar = require('../../../assets/images/icones/fechar_branco.svg');

export default () => {
  const erroServidor = useSelector<State, string>(state => state.cadastro.erroServidor);
  const [aberto, setAberto] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!erroServidor) return;
    setAberto(true);
  }, [erroServidor]);

  const handleClick = () => {
    setAberto(false);
  };

  return (
    <section className={`erro-servidor ${aberto ? 'aberto' : ''}`}>
      <img src={iconeAlerta} alt='erro' />
      <p>
        <strong>Algo deu errado! :(</strong>
        <span>{erroServidor}</span>
      </p>
      <button className='botao-fechar' type='button' onClick={handleClick}>
        <img src={iconeFechar} alt='fechar' />
      </button>
    </section>
  );
};
