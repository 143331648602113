// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icones/mais.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/icones/menos.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".campo-contador{margin-left:0;user-select:none;width:17em}.campo-contador input{text-align:center}.campo-contador .icone-mais{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");right:0px}.campo-contador .icone-menos{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.campo-contador .icone-mais,.campo-contador .icone-menos{background-position:center;background-repeat:no-repeat;bottom:2.2em;padding:.25em;content:\"\";cursor:pointer;display:flex;flex-direction:column;height:16px;outline:none;pointer-events:all;position:absolute;width:40px;z-index:1}.campo-contador .icone-mais.unico,.campo-contador .icone-menos.unico{padding:.25em 10em}.campo-contador .campo{margin-left:auto;width:inherit}", ""]);
// Exports
module.exports = exports;
