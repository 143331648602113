import React, { createContext,
  useContext,
  useCallback,
  useState } from 'react';

interface IEnderecoSelecionadoContexto {
    alterarEnderecoSelecionado: (identificadorEnderecoSelecionado: number) => void;
    identificadorEnderecoSelecionado: number;
}

const EnderecoSelecionadoContexto = createContext<IEnderecoSelecionadoContexto>({} as IEnderecoSelecionadoContexto);

const EnderecoSelecionadoProvider: React.FC = ({ children }) => {
  const [identificadorEnderecoSelecionado, setIdentificadorEnderecoSelecionado] = useState<number>(0);

  const alterarEnderecoSelecionado = useCallback((identificador: number) => {
    setIdentificadorEnderecoSelecionado(identificador);
  }, []);

  return (
    <EnderecoSelecionadoContexto.Provider
      value={{ identificadorEnderecoSelecionado, alterarEnderecoSelecionado }}
    >
      {children}
    </EnderecoSelecionadoContexto.Provider>
  );
};

const useEnderecoSelecionado = (): IEnderecoSelecionadoContexto => {
  const contexto = useContext(EnderecoSelecionadoContexto);

  return contexto;
};

export { EnderecoSelecionadoProvider, useEnderecoSelecionado };
