import {
  Box, Button, Fade, IconButton, Modal, Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import modalRecargaPixStyle from './ModalRecargaPixStyles';

interface Props {
  open: boolean,
  textoDoQrCode: string,
  qrCode: string,
  onCloseModal: any
}


const ModalRecargaPix = ({ open, onCloseModal, textoDoQrCode, qrCode } : Props) => {
  const modal = modalRecargaPixStyle();
  const [textoBotaoCodigo, setTextoBotaoCodigo] = React.useState('Copiar código');

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={modal.ModalRecargaPix}>
            <Box display='flex' justifyContent='end'>
              <IconButton id='btn-fechar-modal-pix' onClick={onCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <div className={modal.ModalRecargaPixTitulos}>
              <Typography
                variant='h6'
                component='h6'
              >
                Escaneie este código para pagar
              </Typography>
            </div>

            <div className={modal.ModalPixDescricoes}>
              <Typography>1. Acesse seu Internet Banking ou app de pagamentos</Typography>
              <Typography>2. Escolha pagar via Pix</Typography>
              <Typography>3. Escaneie o seguinte código: </Typography>
            </div>

            <div className={modal.ModalPixBoxQrCode}>
              <div className='pix-qrcode'>
                <img style={{ height: '158px', width: '158px' }} src={qrCode} alt='QrCode' />
              </div>
            </div>

            <div className={modal.ModalRecargaPixTitulos}>
              <Typography
                variant='h6'
                component='h6'
              >
                Ou copie este código para realizar o pagamento
              </Typography>
            </div>

            <div className={modal.ModalPixDescricoes}>
              <Typography>1. Acesse seu Internet Banking ou app de pagamentos</Typography>
              <Typography>2. Escolha pagar via Pix</Typography>
              <Typography>3. Copie e cole o seguinte código: </Typography>
            </div>

            <div className={modal.ModalPixBoxBotao}>
              <CopyToClipboard text={textoDoQrCode} onCopy={() => setTextoBotaoCodigo('Código copiado!')}>
                <Button
                  id='botao-copiar-codigo'
                  className={modal.BotaoCopiarCodigoPix}
                  type='button'
                >
                  {textoBotaoCodigo}
                </Button>
              </CopyToClipboard>
            </div>

            <div className={modal.ModalPixDescricoes}>
              <Typography>Assim que realizar o pagamento, o valor estará disponível para </Typography>
              <Typography>utilização em rodovias estaduais do Estado de São Paulo</Typography>
              <Typography>shoppings e estacionamentos.</Typography>
              <br />
              <Typography>Para uso nos demais pedágios, pedimos que aguarde por um</Typography>
              <Typography>período de até 6 horas.</Typography>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalRecargaPix;
