import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { State } from '../../interfaces';
import Actions from '../CadastroActions';
import { DadosEmpresa, Etapas, CadastroState } from '../CadastroInterfaces';
import CadastroCabecalho from '../CadastroCabecalho';
import CadastroResumo from '../CadastroResumo';
import '../Cadastro.scss';
import './CadastroCnpjInvalidoPage.scss';

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cnpj } = useSelector<State, DadosEmpresa>(state => state.cadastro.dadosEmpresa);
  const { etapaAtual } = useSelector<State, CadastroState>(state => state.cadastro);

  React.useEffect(() => {
    document.title = 'CNPJ inválido - Cadastro - ConectCar';
    if (etapaAtual < Etapas.Concluido) history.push('/cadastro/plano');
  }, [etapaAtual, history]);

  const redirecionarParaCadastroEmpresa = () => {
    dispatch(Actions.atribuirStatusCadastro(undefined));
    history.push('/cadastro/empresa');
  };

  return (
    <section id='cadastro-cnpjInvalido-page' className='cadastro-page'>
      <section className='conteudo'>
        <CadastroCabecalho esconderTituloCabecalho />
        <CadastroResumo />
        <section className='formulario'>
          <h1>Ops!</h1>
          <p>Não foi possível concluir seu cadastro :(</p>
          <div className='informacao-cnpj-invalido'>
            <p>
              Para se tornar um cliente ConectCar, sua empresa CNPJ
              <strong>&nbsp;{cnpj}</strong>
              {' '}
              precisa estar com a situação regular na Receita Federal.
            </p>
          </div>
          <button
            className='botao-vazio-borda-azul'
            type='button'
            onClick={redirecionarParaCadastroEmpresa}
          >
            Corrigir CNPJ
          </button>
        </section>
      </section>
    </section>
  );
};
