import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvioRecargaRequest, FormaPagamentoOnline, RecargaState } from '../../../RecargaInterfaces';
import MetodosPagamentoStyles from '../MetodosPagamentoStyles';
import Actions from '../../../RecargaActions';
import { State } from '../../../../interfaces';
import BotaoComplementoTitulo from '../../../Components/BotaoComplementoTitulo/BotaoTitulo';
import BotaoComplementoTituloStyles from '../../../Components/BotaoComplementoTitulo/BotaoTituloStyles';
import BoletoFlash from '../../../Components/BoletoFlash/BoletoFlash';
import SelectRecarga from '../../../Components/SelectValorRecarga/SelectRecarga';
import BoxPagamentoControlado from '../../../../../components/BoxPagamentoControlado';
import CarregarRetornoBoletoFlash from '../../../Acoes/PagBrasil/CarregarRetornoBoletoFlash';

const BoxBoletoFlash = () => {
  const {
    dadosRecargaBoletoFlash,
    documento,
    adesaoId,
    servicos,
    boxPagamento,
  } = useSelector<State, RecargaState>(state => ({
    dadosRecargaBoletoFlash: state.recarga.dadosRecargaBoletoFlash,
    documento: state.recarga.documento,
    adesaoId: state.recarga.adesaoId,
    servicos: state.recarga.servicos,
    boxPagamento: state.recarga.boxPagamento,
  }));

  const dispatch = useDispatch();
  const metodosPagamentoStyles = MetodosPagamentoStyles();
  const boletoFlashComplementos = BoletoFlash;

  const [valorBoletoFlash, setValorBoletoFlash] = React.useState(0);
  const [servicoRecargaId, setServicoRecargaId] = React.useState(0);

  const setBoxFlashOpen = () => {
    dispatch(Actions.atualizarStateAccordion('flash', !(boxPagamento.flash)));
  };

  const handlerValorBoletoFlashChange = (event) => {
    setValorBoletoFlash(event.target.value);
    setServicoRecargaId(event.currentTarget.id);
  };

  const configurarSelectBoletoFlash = () => (
    <SelectRecarga
      idSelect='Recarga_Input_Valor_BoletoFlash'
      possuiLimitacaoValor={false}
      setValorSelect={handlerValorBoletoFlashChange}
      valoresRecarga={servicos}
    />
  );

  const handlePagamentoBoletoFlash = () => {
    const invalido = valorBoletoFlash === 0 || servicoRecargaId === 0;

    if (!invalido) {
      const novaRecarga: EnvioRecargaRequest = {
        AdesaoId: adesaoId,
        FormaPagamentoOnline: FormaPagamentoOnline.PagBrasil,
        Documento: documento,
        ServicoRecargaId: servicoRecargaId,
        ValorDeCredito: valorBoletoFlash,
      };

      dispatch(Actions.carregarParametrosRecargaRequest(novaRecarga));
      dispatch(Actions.cadastrarRecarga());
    } else {
      dispatch(Actions.atualizarAvisoRecarga('erro', 'Favor selecionar um valor válido para o Boleto Flash.', true));
    }
  };

  return (
    <section id='section-boleto-flash'>
      <div>
        <BoxPagamentoControlado
          botaoValorTexto='Gerar Boleto'
          complementoDescricaoTitulo={boletoFlashComplementos.BoletoFlashImagens()}
          complementoTitulo={<BotaoComplementoTitulo texto='APENAS DIAS ÚTEIS' classe={BotaoComplementoTituloStyles().botaoApenasDiasUteis} />}
          descricaoTitulo={BoletoFlash.BoletoFlashDescricao()}
          id='Recarga_Rb_BoletoFlash'
          btnID='Recarga_Bt_BoletoFlashGerar'
          inputValor={configurarSelectBoletoFlash()}
          labelValor='Valor'
          logotipo={`${metodosPagamentoStyles.logotiposBaseCss} ${metodosPagamentoStyles.boletoFlashLogotipo}`}
          onButtonClick={handlePagamentoBoletoFlash}
          titulo='Boleto Flash'
          boxOpen={boxPagamento?.flash}
          setBoxOpen={setBoxFlashOpen}
        />
      </div>
      <div id='boleto-flash-pagamento-complementos'>
        {dadosRecargaBoletoFlash?.dados != null
          && <CarregarRetornoBoletoFlash />}
      </div>
    </section>
  );
};

export default BoxBoletoFlash;
