import * as React from 'react';
import { PropsBase } from '../CampoBase';
import CepApi from '../../../infrastructure/cep-api';
import AplicarMascara from '../../../infrastructure/mascara';
import Spinner from '../../Spinner';
import CampoComLink from '../CampoComLink';
import './CampoCep.scss';

interface Props extends PropsBase {
  onCepSearchSuccess: Function,
  onCepSearchError: Function,
}

const CampoCep = ({
  name,
  titulo,
  value,
  onChange,
  disabled,
  meiaColuna,
  mensagemValidacao,
  onCepSearchSuccess,
  onCepSearchError,
}: Props) => {
  const urlParaDescobrirMeuCep = 'http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm';
  const textoLinkParaDescobrirCep = 'Não sei meu cep';

  const [mostrarSpinner, setMostrarSpinner] = React.useState<boolean>(false);
  const buscarDadosEnderecoRelacionadoAoCep = async (valorCampo: string) => {
    try {
      if (valorCampo) {
        setMostrarSpinner(true);
        const { data } = await CepApi.consultarCep(valorCampo);
        onCepSearchSuccess(data.dados);
      }
    } catch (e) {
      onCepSearchError(e);
    } finally {
      setMostrarSpinner(false);
    }
  };

  const handleOnChangeCep = (nomeCampo: string, valorCampo: string) => {
    const valorComMascara = AplicarMascara(nomeCampo, valorCampo);
    onChange(nomeCampo, valorComMascara);
  };

  const handleOnClickLink = () => {
    window.open(urlParaDescobrirMeuCep, '_blank');
  };

  return (
    <CampoComLink
      name={name}
      titulo={titulo}
      value={value}
      disabled={disabled}
      meiaColuna={meiaColuna}
      mensagemValidacao={mensagemValidacao}
      onChange={event => handleOnChangeCep(event.target.name, event.target.value)}
      onBlur={event => buscarDadosEnderecoRelacionadoAoCep(event.target.value)}
      textoLink={textoLinkParaDescobrirCep}
      onClick={handleOnClickLink}
    >
      {mostrarSpinner ? (<Spinner />) : (null)}
    </CampoComLink>
  );
};

export default CampoCep;
