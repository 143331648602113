import {
  all, call, take, takeEvery, put, select,
} from 'redux-saga/effects';

import ExtratoApi from '../../infrastructure/extrato-api';
import { State } from '../interfaces';
import GeneralActions from '../actions';
import Actions, { Types } from './HomeActions';
import AutenticacaoActions, { Types as AutenticacaoTypes } from '../autenticacao/AutenticacaoActions';
import RecargaApi from '../../infrastructure/recarga-api';
import ClienteApi from '../../infrastructure/prospecto-api/cliente';
import { setAdesao } from '../../utils/possuiAdesao';

const obterClienteId = (state: State): number => state.autenticacao.clienteId;
const obterBearerToken = (state: State): string => state.autenticacao.bearerToken;
const obterDocumento = (state: State): string => state.autenticacao.documento;

function* atribuirDocumento() {
  yield put(GeneralActions.iniciarRequisicao());

  const documento = yield select(obterDocumento);
  const clienteId = yield select(obterClienteId);
  const token = yield select(obterBearerToken);

  yield put(Actions.atribuirDocumento(documento, clienteId, token));
  yield put(GeneralActions.terminarRequisicao());
}

function* obterSaldo() {
  yield put(GeneralActions.iniciarRequisicao());

  try {
    const bearerToken = yield select(obterBearerToken);

    const { data } = yield call(ExtratoApi.buscarSaldo, bearerToken);

    yield put(Actions.obterSaldo(data.dados[0].valor));
    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* obterDadosUltimaRecarga() {
  try {
    yield put(GeneralActions.iniciarRequisicao());
    const token = yield select(obterBearerToken);
    const { data } = yield call(RecargaApi.obterDadosUltimaRecarga, token);

    yield put(Actions.carregarDadosUltimaRecarga(data?.dados?.valores?.dados));

    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  }
}
function* possuiAdesoes() {
  try {
    yield put(GeneralActions.iniciarRequisicao());
    const token = yield select(obterBearerToken);
    const data = yield setAdesao(token);

    yield put(Actions.obterAdesoes(data));

    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* obterDadosPlanoCliente() {
  try {
    yield put(GeneralActions.iniciarRequisicao());

    const documento = yield select(obterDocumento);
    const { data } = yield call(ClienteApi.obterDadosCliente, documento);

    sessionStorage.setItem('CanalVendas', String(data?.dados?.salesChannelId));
    sessionStorage.setItem('PlanoId', String(data?.dados?.customerPlanData?.paymentPlanId));

    yield put(Actions.carregarDadosPlanoCliente(data?.dados?.customerPlanData));

    yield put(GeneralActions.terminarRequisicao());
  } catch (e) {
    yield put(GeneralActions.terminarRequisicao());
  }
}

function* inicializarHome() {
  yield put(AutenticacaoActions.requisitarToken());
  yield take(AutenticacaoTypes.AtribuirDadosToken);
  yield all([obterSaldo(), obterDadosPlanoCliente(), obterDadosUltimaRecarga(), atribuirDocumento(), possuiAdesoes()]);
}


export default function* () {
  yield takeEvery(Types.InicializarHome, inicializarHome);
}
