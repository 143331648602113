import * as React from 'react';
import './CampoBase.scss';

export interface PropsBase {
  name: string,
  titulo: string,
  value: any,
  disabled?: boolean,
  meiaColuna?: boolean,
  mensagemValidacao?: string,
  children?: React.ReactNode,
  classeExtra?: string,
  onChange: Function,
  onBlur?: Function,
}

interface Props extends PropsBase {
  type: string,
  maxDate?: Date
}

const defineMaxDate = (data: Date): string => {
  const month = data.getMonth() + 1;
  const day = data.getDate();
  const monthFormatted = month < 10 ? `0${month}` : month;
  const dayFormatted = day < 10 ? `0${day}` : day;

  return `${data.getFullYear()}-${monthFormatted}-${dayFormatted}`;
};

export default ({ name,
  titulo,
  value,
  disabled = false,
  type,
  meiaColuna,
  mensagemValidacao,
  children,
  classeExtra,
  onChange,
  onBlur,
  maxDate }: Props) => (
    <label className={`campo ${meiaColuna ? 'meia-coluna' : ''} ${classeExtra || ''}`}>
      <input
        data-testid={`campo-input-${name}`}
        name={name}
        title={titulo}
        type={type}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e)}
        onBlur={e => (onBlur ? onBlur(e) : () => {})}
        className={mensagemValidacao ? 'icone-alerta' : ''}
        max={type === 'date' ? defineMaxDate(maxDate) : null}
      />
      <span className={`titulo-campo ${value ? 'acima' : ''}`}>{titulo}</span>
      <span className='barra-inferior' />
      <span className={mensagemValidacao ? 'mensagem-validacao' : ''}>{mensagemValidacao}</span>
      {children}
    </label>
);
