import * as React from 'react';
import './CadastroCabecalho.scss';

import { Etapas } from '../CadastroInterfaces';
import Breadcrumb from './CadastroCabecalhoBreadcrumb';

const logo = require('../../../assets/images/logo.svg');

const iconeCaminhao = require('../../../assets/images/icones/caminhao.svg');
const iconePessoa = require('../../../assets/images/icones/pessoa.svg');
const iconeEmpresa = require('../../../assets/images/icones/empresa.svg');
const iconeSenha = require('../../../assets/images/icones/senha.svg');

interface Props {
  esconderTituloCabecalho?: boolean,
}

export default ({ esconderTituloCabecalho }: Props) => {
  const exibirCabecalho = () => (!esconderTituloCabecalho ? <h1>Cadastro</h1> : '');

  return (
    <header id='cadastro-cabecalho'>
      <div className='logo'>
        <img src={logo} alt='ConectCar' />
      </div>

      <div className='navegacao'>
        {exibirCabecalho()}

        <div className='breadcrumbs'>
          <Breadcrumb
            descricao='ConectCar Frotas'
            etapaRepresentada={Etapas.Plano}
            icone={iconeCaminhao}
          />

          <Breadcrumb
            descricao='Contato e Representante'
            etapaRepresentada={Etapas.Representante}
            icone={iconePessoa}
          />

          <Breadcrumb
            descricao='Dados de empresa'
            etapaRepresentada={Etapas.Empresa}
            icone={iconeEmpresa}
          />

          <Breadcrumb
            descricao='Senha'
            etapaRepresentada={Etapas.Senha}
            icone={iconeSenha}
            ehUltimo
          />
        </div>
      </div>
    </header>
  );
};
