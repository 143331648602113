import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import InitRedux from './init-redux';
import InitEnv from './init-env';

import Features from './features';

import { ContextoGlobal } from './hooks';

require('iframe-resizer');
require('jquery');

const Init = async () => {
  await InitEnv();
  const store = InitRedux();
  render(
    <Provider store={store}>
      <ContextoGlobal>
        <Features />
      </ContextoGlobal>
    </Provider>,
    document.getElementById('app'),
  );
};

Init();
