import * as React from 'react';
import { Snackbar } from '@material-ui/core';
import './Aviso.scss';

interface AvisoProps {
    open: boolean;
    handleClose: any;
    mensagem: string;
    variacao: string;
}


const Aviso = ({ open, handleClose, mensagem, variacao }: AvisoProps) => {
  const vertical = 'top';
  const horizontal = 'center';

  return (
    <div>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        ContentProps={{ className: variacao, 'aria-describedby': 'message-id' }}
        message={(
          <span id='message-id'>
            <div>{mensagem}</div>
          </span>
                )}
        onClose={handleClose}
        key={vertical + horizontal}
      />
    </div>
  );
};

export default Aviso;
