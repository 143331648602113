import { RespostaApi } from '../../../../infrastructure/ApiInterfaces';
import api from '../../../api';

import { IObterCidadeIdPorCodigoIbge } from './interface';

export const obterCidadeIdPorCodigoIbge = (
  codigoIbge: string,
): Promise<RespostaApi<IObterCidadeIdPorCodigoIbge>> => api.get(
  '/Cep/obterCidadeIdPorCodigoIbge',
  {
    params: { codigoIbge },
  },
);
