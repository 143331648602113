import axios from 'axios';

const urlbase = process.env.URL_API_MANAGEMENT;
const subscription = process.env.SUBSCRIPTION_API_MANAGEMENT;

export async function download(idArquivo: number, nome: string) {
  const token = sessionStorage.getItem('TOKEN_KEY');

  const url = `${urlbase}portalpj/v1/Arquivo?idArquivo=${idArquivo}`;

  const resposta = await axios.get(
    url,
    {
      headers:
        {
          'Ocp-Apim-Subscription-Key': subscription,
          Authorization: `Bearer ${token}`,
        },
      responseType: 'blob',
    },
  );

  const fileURL = URL.createObjectURL(resposta.data);
  const alink = document.createElement('a');

  alink.href = fileURL;
  alink.download = nome;
  alink.click();
  URL.revokeObjectURL(fileURL);
}
