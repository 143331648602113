import { Action } from '../interfaces';
import {
  CadastroSaldoMinimo,
  EnvioRecargaRequest,
  ResultadoApiPix, ResultadoApiRecarga, ResultadoApiRecargaIndex, ResultadoApiRecargaUltimaRecarga,
} from './RecargaInterfaces';

export enum Types {
  AtribuirDocumento = 'ATRIBUIR_DOCUMENTO',
  ObterSaldo = 'OBTER_SALDO',

  CadastrarAvisoSaldoMinimoAction = 'CADASTRAR_AVISO_SALDO_MINIMO',

  CadastrarRecargaPixAction = 'CADASTRAR_RECARGA_PIX',
  CadastrarRecargaAction = 'CADASTRAR_RECARGA',

  CarregarParametrosIndexAction = 'CARREGAR_PARAMETROS_INDEX',

  AtualizarErroUltimaRecarga = 'ATUALIZAR_ERRO_ULTIMA_RECARGA',
  CarregarDadosUltimaRecargaAction = 'CARREGAR_DADOS_ULTIMA_RECARGA',

  AtualizarErroBoletoItauAction = 'ATUALIZAR_ERRO_BOLETO_ITAU',
  CarregarBoletoItauAction = 'CARREGAR_BOLETO_ITAU',
  DescarregarBoletoItauAction = 'DESCARREGAR_BOLETO_ITAU',

  CarregarBoletoFlashAction = 'CARREGAR_BOLETO_FLASH',
  DescarregarBoletoFlashAction = 'DESCARREGAR_BOLETO_FLASH',

  CarregarTefAction = 'CARREGAR_TEF',
  DescarregarTefAction = 'DESCARREGAR_TEF',

  CarregarParametrosRecargaRequestAction = 'CARREGAR_PARAMETROS_RECARGA_REQUEST',
  CarregarParametrosAvisoSaldoMinimoAction = 'CARREGAR_PARAMETROS_AVISO_SALDO_MINIMO',

  CarregarParametrosModalPixAction = 'CARREGAR_PARAMETROS_MODAL_PIX',
  DescarregarParametrosModalPixAction = 'DESCARREGAR_PARAMETROS_MODAL_PIX',

  InicializarRecargaAction = 'INICIALIZAR_RECARGA',

  AtualizarAvisoRecargaAction = 'ATUALIZAR_AVISO_RECARGA',
  AtualizarErroAction = 'ATUALIZAR_ERRO',
  AtualizarStateAccordionAction = 'ATUALIZAR_STATE_ACCORDION'
}

export interface AtribuirDocumentoAction extends Action {
  documento: string,
  clienteId: string,
  token: string,
}

export interface AtualizarErroAction extends Action { action: any, visivel: boolean }

export interface AtualizarAvisoRecargaAction extends Action { level: string, texto: string, visivel: boolean }
export interface CarregarParametrosAvisoSaldoMinimo extends Action { aviso: CadastroSaldoMinimo }
export interface CadastrarAvisoSaldoMinimoAction extends Action { }
export interface AtualizarErroAvisoSaldoMinimoAction extends Action { erro: boolean }

export interface CadastrarRecargaAction extends Action { }

export interface CadastrarRecargaPixAction extends Action { }
export interface AtualizarErroPixAction extends Action { erro: boolean }
export interface CarregarParametrosModalPixAction extends Action { dadosPix: ResultadoApiPix }
export interface DescarregarParametrosModalPixAction extends Action { }

export interface AtualizarErroIndexAction extends Action { erro: boolean }
export interface CarregarParametrosIndexAction extends Action { dadosIndex: ResultadoApiRecargaIndex }

export interface AtualizarErroBoletoItauAction extends Action { erro: boolean }
export interface CarregarBoletoItauAction extends Action { dadosRecarga: ResultadoApiRecarga }
export interface DescarregarBoletoItauAction extends Action { }

export interface AtualizarErroTefAction extends Action { erro: boolean }
export interface CarregarTefAction extends Action { dadosRecarga: ResultadoApiRecarga }
export interface DescarregarTefAction extends Action { }

export interface AtualizarErroBoletoFlashAction extends Action { erro: boolean }
export interface AtualizarErroTrueBoletoFlashAction extends Action { }
export interface AtualizarErroFalseBoletoFlashAction extends Action { }
export interface CarregarBoletoFlashAction extends Action { dadosRecarga: ResultadoApiRecarga }
export interface DescarregarBoletoFlashAction extends Action { }

export interface CarregarParametrosRecargaRequest extends Action { recarga: EnvioRecargaRequest }

export interface AtualizarErroUltimaRecarga extends Action { erro: boolean }
export interface CarregarDadosUltimaRecargaAction extends Action { dadosUltimaRecarga: ResultadoApiRecargaUltimaRecarga }

export interface InicializarRecargaAction extends Action { }

export interface AtualizarStateAccordionAction extends Action { accordionId: string, accordionState: boolean }
export interface ObterSaldoAction extends Action { saldo: number }

export default {
  atribuirDocumento: (documento: string, clienteId: string, token: string): AtribuirDocumentoAction => ({ type: Types.AtribuirDocumento, documento, clienteId, token }),
  obterSaldo: (saldo: number): ObterSaldoAction => ({ type: Types.ObterSaldo, saldo }),

  cadastrarRecarga: () => ({ type: Types.CadastrarRecargaAction }),
  cadastrarRecargaPix: () => ({ type: Types.CadastrarRecargaPixAction }),

  cadastrarAvisoSaldoMinimo: () => ({ type: Types.CadastrarAvisoSaldoMinimoAction }),

  carregarParametrosIndex: (dadosIndex: ResultadoApiRecargaIndex) => ({ type: Types.CarregarParametrosIndexAction, dadosIndex }),

  carregarUltimaRecarga: (dadosUltimaRecarga: ResultadoApiRecargaUltimaRecarga) => ({ type: Types.CarregarDadosUltimaRecargaAction, dadosUltimaRecarga }),
  atualizarErroUltimaRecarga: (erro: boolean) => ({ type: Types.AtualizarErroUltimaRecarga, erro }),

  carregarBoletoItau: (dadosRecarga: ResultadoApiRecarga) => ({ type: Types.CarregarBoletoItauAction, dadosRecarga }),
  descarregarBoletoItau: () => ({ type: Types.DescarregarBoletoItauAction }),

  carregarTef: (dadosRecarga: ResultadoApiRecarga) => ({ type: Types.CarregarTefAction, dadosRecarga }),
  descarregarTef: () => ({ type: Types.DescarregarTefAction }),

  carregarBoletoFlash: (dadosRecarga: ResultadoApiRecarga) => ({ type: Types.CarregarBoletoFlashAction, dadosRecarga }),
  descarregarBoletoFlash: () => ({ type: Types.DescarregarBoletoFlashAction }),

  carregarParametrosModalPix: (dadosPix: ResultadoApiPix) => ({ type: Types.CarregarParametrosModalPixAction, dadosPix }),
  descarregarParametrosModalPix: () => ({ type: Types.DescarregarParametrosModalPixAction }),

  carregarParametrosRecargaRequest: (recarga: EnvioRecargaRequest) => ({ type: Types.CarregarParametrosRecargaRequestAction, recarga }),
  carregarParametrosAvisoSaldoMinimo: (aviso: CadastroSaldoMinimo) => ({ type: Types.CarregarParametrosAvisoSaldoMinimoAction, aviso }),

  inicializarRecarga: (): InicializarRecargaAction => ({ type: Types.InicializarRecargaAction }),

  atualizarAvisoRecarga: (level: string, texto: string, visivel: boolean): AtualizarAvisoRecargaAction => ({ type: Types.AtualizarAvisoRecargaAction, level, texto, visivel }),

  atualizarErro: (action: any, visivel: boolean) => ({ type: Types.AtualizarErroAction, action, visivel }),

  atualizarStateAccordion: (accordionId: string, accordionState: boolean) => ({ type: Types.AtualizarStateAccordionAction, accordionId, accordionState }),
};
