import { createStyles, makeStyles } from '@material-ui/core';

const BotaoTituloStyles = makeStyles(() => createStyles({
  botaoNovoTipoPagamento: {
    backgroundColor: '#298F7A',
    border: 'none',
    borderRadius: '12px',
    color: '#FFFFFF',
    fontWeight: 700,
    height: '22px',
    width: '54px',
    marginLeft: '8px',
  },
  botaoApenasDiasUteis: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #298F7A',
    borderRadius: '12px',
    color: '#298F7A',
    fontWeight: 700,
    height: '22px',
    width: '136px',
    marginLeft: '8px',
  },
}));

export default BotaoTituloStyles;
