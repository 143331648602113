import * as React from 'react';

import './SaldoGlobal.scss';

interface Props {
  documento: string,
  saldo: number,
}

const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
const SaldoGlobal = ({ saldo, documento }: Props) => (
  <section className='container-saldo'>
    <div id='saldo-global' className='home-page'>
      <div className='saldo2'>
        <span id='spnSaldoGlobal'>$ Saldo Global</span>
        <p className='pValor' id='pValor'>{saldo?.toLocaleString('pt-BR', formato)}</p>
        <p className='pCnpj'>
          CNPJ:{' '}
          {documento?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
        </p>
      </div>
    </div>
  </section>
);

export default SaldoGlobal;
