export const removerCaracteresNaoNumericos = (value: string) => (value ? value.replace(/[^0-9]/g, '') : '');

const mascararTelefone = (value: string, totalDigitos: number) => {
  let novoValue = removerCaracteresNaoNumericos(value);
  novoValue = novoValue.substring(0, totalDigitos);
  novoValue = novoValue.replace(/^(\d{2})(\d)/g, '($1) $2');
  novoValue = novoValue.replace(/(\d)(\d{4})$/, '$1-$2');
  return novoValue;
};

const mascararCep = (value: string) => {
  const tamanhoQueAtivaMascara = 5;
  const digitosAposTraco = 3;
  const quantidadePermitida = tamanhoQueAtivaMascara + digitosAposTraco;

  let novoValue = removerCaracteresNaoNumericos(value);

  if (novoValue.length > tamanhoQueAtivaMascara) { novoValue = `${novoValue.substring(0, tamanhoQueAtivaMascara)}-${novoValue.substring(tamanhoQueAtivaMascara, quantidadePermitida)}`; }

  return novoValue;
};

const mascararCpf = (value: string) => {
  const digitosCpf = 11;
  let novoValue = removerCaracteresNaoNumericos(value);
  novoValue = novoValue.substring(0, digitosCpf);
  novoValue = novoValue.replace(/(\d{3})(\d)/, '$1.$2');
  novoValue = novoValue.replace(/(\d{3})\x2E(\d{3})/, '$1.$2');
  novoValue = novoValue.replace(/(\d{3})\x2E(\d{3})(\d)/, '$1.$2.$3');
  novoValue = novoValue.replace(/(\d{3})\x2E(\d{3})\x2E(\d{3})(\d)/, '$1.$2.$3-$4');
  return novoValue;
};

const mascararCnpj = (value: string) => {
  const digitosCnpj = 14;
  let novoValue = removerCaracteresNaoNumericos(value);
  novoValue = novoValue.substring(0, digitosCnpj);
  novoValue = novoValue.replace(/(\d{2})(\d)/, '$1.$2');
  novoValue = novoValue.replace(/(\d{2})\x2E(\d{3})/, '$1.$2');
  novoValue = novoValue.replace(/(\d{2})\x2E(\d{3})(\d)/, '$1.$2.$3');
  novoValue = novoValue.replace(/(\d{2})\x2E(\d{3})\x2E(\d{3})(\d)/, '$1.$2.$3/$4');
  novoValue = novoValue.replace(/(\d{2})\x2E(\d{3})\x2E(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');
  novoValue = novoValue.replace(/(\d{2})\x2E(\d{3})\x2E(\d{3})\/(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  return novoValue;
};

const mascararDinheiro = (value: string) => {
  let valueAtualizado = this.removerCaracteresNaoNumericos(value);

  if (!valueAtualizado) return '';

  if (value.length > 2) valueAtualizado = `${valueAtualizado.substring(valueAtualizado.length - 2, 0)},${valueAtualizado.substring(valueAtualizado.length, valueAtualizado.length - 2)}`;

  const casasIniciais = 6;
  const adicionarPontoACadaCasas = 4;
  for (
    let contador = casasIniciais;
    valueAtualizado.length > contador;
    contador += adicionarPontoACadaCasas) {
    valueAtualizado = `${valueAtualizado.substring(valueAtualizado.length - contador, 0)}`
      + `.${valueAtualizado.substring(valueAtualizado.length, valueAtualizado.length - contador)}`;
  }
  return `R$ ${valueAtualizado}`;
};

const tratarCasasDecimais = (value: string) => {
  const indiceDecimal = value.indexOf('.') + 1;
  const decimais = indiceDecimal === 0 ? '' : value.substring(indiceDecimal);

  if (decimais.length < 2) {
    const inteiros = value.substring(0, indiceDecimal === 0 ? value.length : indiceDecimal);
    return inteiros + decimais.padEnd(2, '0');
  }
  return value;
};

export const formatarFaturamentoApi = (value: number) => {
  if (!value) return '';

  let valueString = (value || 0).toString();
  valueString = tratarCasasDecimais(valueString);
  valueString = mascararDinheiro(valueString);
  return valueString;
};

export default (name: string, value: string) => {
  switch (name) {
    case 'telefone':
      return mascararTelefone(value, 10);

    case 'celular':
      return mascararTelefone(value, 11);

    case 'numero':
      return removerCaracteresNaoNumericos(value).substring(0, 5);

    case 'cep':
      return mascararCep(value);

    case 'cpf':
      return mascararCpf(value);

    case 'cnpj':
      return mascararCnpj(value);

    case 'faturamentoMedioMensal':
      return mascararDinheiro(value);

    default:
      return value;
  }
};
