import { InputLabel } from '@material-ui/core';
import React, { ChangeEvent, memo } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useAvisoSaldo } from '../../Context/AvisoSaldoContexto';
import CheckboxInput from './Components/Checkbox/CheckboxInput';
import ContatoAdicionalItem from './Components/Contato/Adicional/ContatoAdicionalItem';
import ContatoPrincipalItem from './Components/Contato/Principal/ContatoPrincipalItem';
import ValorAvisoCustomInput from './Components/Inputs/ValorAvisoCustomInput';
import ButtonAdicionarEmail from './Components/Buttons/AdicionarEmail/ButtonAdicionarEmail';
import ButtonSalvarValorAviso from './Components/Buttons/SalvarValorAviso/ButtonSalvarValorAviso';
import ModalErro from '../../../../components/ModalErro/ModalErro';

import './AvisoSaldo.scss';

const AvisoSaldo: React.FC = () => {
  const { contatosAdicionais,
    flagReceberAvisoSaldo,
    mensagemErro,
    valorAviso,
    setMensagemErro,
    setValorAviso } = useAvisoSaldo();

  return (
    <div id='aviso-saldo-wrapper'>
      <ModalErro
        visivel={mensagemErro !== ''}
        handleClose={() => setMensagemErro('')}
        mensagem={mensagemErro}
      />

      <h2>Aviso de saldo</h2>

      <div id='aviso-saldo-checkbox-wrapper'>
        <CheckboxInput />
        <InputLabel style={{ color: '#4D5761' }}>Quero receber um aviso por e-mail sempre que meu saldo atingir um valor definido</InputLabel>
      </div>

      <div>
        <InputLabel className='aviso-saldo-label'>Assim que meu saldo chegar em</InputLabel>
        <div id='aviso-saldo-valor-wrapper'>
          <NumberFormat
            allowEmptyFormatting
            allowNegative={false}
            customInput={ValorAvisoCustomInput}
            decimalSeparator=','
            disabled={!flagReceberAvisoSaldo}
            isNumericString
            onChange={(e: ChangeEvent<HTMLInputElement>) => e.stopPropagation()}
            onValueChange={(values: NumberFormatValues) => setValorAviso(values.floatValue as number)}
            prefix='R$'
            thousandSeparator='.'
            value={valorAviso}
          />

          <ButtonSalvarValorAviso />
        </div>
      </div>

      <div>
        <InputLabel className='aviso-saldo-label'>Envie um e-mail para</InputLabel>
        <div className='aviso-saldo-contato-wrapper'>
          <div>
            <ContatoPrincipalItem />
          </div>
          <div className='aviso-saldo-contato-wrapper'>
            {contatosAdicionais.map(contato => (
              <ContatoAdicionalItem key={contato.contactClientId} contatoItem={contato} />
            ))}
          </div>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <ButtonAdicionarEmail />
      </div>
    </div>
  );
};

export default memo(AvisoSaldo);
