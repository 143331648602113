import React, { createContext,
  useCallback,
  useContext,
  useState } from 'react';

export interface IControleModalErroContexto {
    visivel: boolean;
    abrirModal: () => void;
    fecharModal: () => void;
    alterarMensagemCustomizada: (mensagem: string) => void;
    mensagemCustomizada: string;
}

export const ControleModalErroContexto = createContext<IControleModalErroContexto>(
    {} as IControleModalErroContexto,
);

const ControleModalErroProvider: React.FC = ({ children }) => {
  const [visivel, setVisivel] = useState<boolean>(false);
  const [mensagemCustomizada, setMensagemCustomizada] = useState<string>('');

  const abrirModal = useCallback(() => {
    setVisivel(true);
  }, []);

  const fecharModal = useCallback(() => {
    setVisivel(false);
  }, []);

  const alterarMensagemCustomizada = useCallback((mensagem: string) => {
    setMensagemCustomizada(mensagem);
  }, []);

  return (
    <ControleModalErroContexto.Provider
      value={{
        visivel,
        abrirModal,
        fecharModal,
        alterarMensagemCustomizada,
        mensagemCustomizada,
      }}
    >
      {children}
    </ControleModalErroContexto.Provider>
  );
};

const useControleModalErro = (): IControleModalErroContexto => {
  const contexto = useContext(ControleModalErroContexto);

  return contexto;
};

export { ControleModalErroProvider, useControleModalErro };
