import { Action } from '../interfaces';
import { ItemTabela, Ordenacao } from './ExtratoInterfaces';
import { DropdownItem } from '../../components/Inputs/Dropdown/DropdownInterface';

export enum Types {
  InicializarExtrato = 'INICIALIZAR_EXTRATO',
  AtribuirSaldo = 'ATRIBUIR_SALDO',
  AtribuirDocumento = 'ATRIBUIR_DOCUMENTO',
  BuscarExtrato = 'BUSCAR_EXTRATO',
  AtribuirTabela = 'ATRIBUIR_TABELA',
  AtribuirFiltros = 'ATRIBUIR_FILTROS',
  ModificarPagina = 'MODIFICAR_PAGINA',
  FiltrarPorPlacas = 'FILTRAR_POR_PLACAS',
  FiltrarPorPeriodo = 'FILTRAR_POR_PERIODO',
  FiltrarPorTipoTransacao = 'FILTRAR_POR_TIPO_TRANSACAO',
  FiltrarPorCentrosCusto = 'FILTRAR_POR_CENTROS_CUSTO',
  Ordenar = 'ORDENAR_EXTRATO',
  LimparFiltros = 'LIMPAR_FILTROS',
  ExportarExtrato = 'EXPORTAR_EXTRATO',
  ExportarExtratoPdf = 'EXPORTAR_EXTRATO_PDF',
  inicializarPainelOfertas = 'INICIALIZAR_PAINEL_OFERTAS',
  MensagemFiltro = 'MENSAGEM_ERRO_FILTRO'
}

export interface InicializarExtratoAction extends Action { }
export interface InicializarPainelOfertasAction extends Action { }
export interface AtribuirSaldoAction extends Action { saldo: number }
export interface AtribuirDocumentoAction extends Action { documento: string, clienteId: string, token: string }
export interface BuscarExtratoAction extends Action { }
export interface AtribuirTabelaAction extends Action { tabela: ItemTabela[], totalPaginas: number }
export interface AtribuirFiltrosAction extends Action { placas: string[], centrosCusto: number[] }
export interface FiltrarPorPlacasAction extends Action { placas: DropdownItem[] }
export interface FiltrarPorPeriodoAction extends Action { dataInicio: Date, dataFim: Date }
export interface FiltrarPorTiposTransacaoAction extends Action { tiposTransacao: DropdownItem[] }
export interface FiltrarPorCentrosCustoAction extends Action { centrosCusto: DropdownItem[] }
export interface OrdenarAction extends Action { ordenacao: Ordenacao, ordenarPor: string }
export interface ExportarExtratoAction extends Action { }
export interface ExportarExtratoPdfAction extends Action { }
export interface ModificarPaginaAction extends Action {
  registrosPorPagina: number,
  paginaSelecionada: number
}
export interface LimparFiltrosAction extends Action { }
export interface MensagemErroFiltroAction extends Action {
  mensagem: string
}

export default {
  inicializarExtrato: (): InicializarExtratoAction => ({ type: Types.InicializarExtrato }),

  InicializarPainelOfertas: (): InicializarPainelOfertasAction => ({ type: Types.inicializarPainelOfertas }),

  atribuirSaldo: (saldo: number): AtribuirSaldoAction => ({ type: Types.AtribuirSaldo, saldo }),

  atribuirDocumento: (documento: string, clienteId: string, token: string): AtribuirDocumentoAction => ({ type: Types.AtribuirDocumento, documento, clienteId, token }),

  buscarExtrato: (): BuscarExtratoAction => ({ type: Types.BuscarExtrato }),

  atribuirTabela: (
    tabela: ItemTabela[],
    totalPaginas: number,
  ): AtribuirTabelaAction => (
    {
      type: Types.AtribuirTabela,
      tabela,
      totalPaginas,
    }),

  atribuirFiltros: (placas: string[], centrosCusto: number[]): AtribuirFiltrosAction => (
    { type: Types.AtribuirFiltros, placas, centrosCusto }),

  filtrarPorPlacas: (placas: DropdownItem[]): FiltrarPorPlacasAction => (
    { type: Types.FiltrarPorPlacas, placas }),

  filtrarPorPeriodo: (dataInicio: Date, dataFim: Date): FiltrarPorPeriodoAction => (
    { type: Types.FiltrarPorPeriodo, dataInicio, dataFim }),

  filtrarPorTiposTransacao: (tiposTransacao: DropdownItem[]): FiltrarPorTiposTransacaoAction => (
    { type: Types.FiltrarPorTipoTransacao, tiposTransacao }),

  filtrarPorCentroCustos: (centrosCusto: DropdownItem[]): FiltrarPorCentrosCustoAction => (
    { type: Types.FiltrarPorCentrosCusto, centrosCusto }),

  ordenar: (ordenacao: Ordenacao, ordenarPor: string): OrdenarAction => (
    { type: Types.Ordenar, ordenacao, ordenarPor }),

  modificarPagina: (paginaSelecionada: number,
    registrosPorPagina: number): ModificarPaginaAction => (
    {
      type: Types.ModificarPagina,
      registrosPorPagina,
      paginaSelecionada,
    }),

  limparFiltros: () => ({ type: Types.LimparFiltros }),

  exportarExtrato: (): ExportarExtratoAction => ({ type: Types.ExportarExtrato }),

  exportarExtratoPdf: (): ExportarExtratoAction => ({ type: Types.ExportarExtratoPdf }),

  mensagemFiltro: (
    mensagem: string,
  ): MensagemErroFiltroAction => (
    { type: Types.MensagemFiltro, mensagem }
  ),
};
