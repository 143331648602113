import React from 'react';
import {
  Container, ImageList, ImageListItem, useMediaQuery, useTheme,
} from '@material-ui/core';
import './NovidadesPortal.scss';
import BannerRecarga from '../../assets/images/card-recarga-credito.svg';
import BannerPlanilhas from '../../assets/images/card-planilha-gestao.svg';
import BannerShell from '../../assets/images/card-descontos-abastecimentos.svg';

interface Props {
  possuiAdesao: boolean
}

const NovidadesPortal = ({ possuiAdesao }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      id='novidades-portal'
      className='container-novidades'
      disableGutters
    >
      <h2 className='titulo-novidades'>
        Fique por dentro das novidades no Portal Frotas ConectCar
      </h2>

      <ImageList className='list-novidades' cols={matches ? 3 : 1} gap={5} rowHeight='auto' style={matches ? {} : { justifyContent: 'center' }}>
        <ImageListItem id='link-cartao' component='a' href='recarga/cartao' className='item-novidades' cols={1} style={possuiAdesao ? {} : { display: 'none' }}>
          <img
            src={BannerRecarga}
            alt='card recarga crédito'
            className='img-novidades'
          />
        </ImageListItem>
        <ImageListItem id='link-arquivos' className='item-novidades' cols={1} onClick={() => { window.parent.location.href = `${(window as any).config.url_portal_cliente}/ArquivosPj`; }}>
          <img
            src={BannerPlanilhas}
            alt='card planilha gestão'
            className='img-novidades'
          />
        </ImageListItem>
        <ImageListItem component='a' href='https://lp.shellboxempresas.com.br/conectcar/' target='_blank' className='item-novidades' id='link-shell' cols={1}>
          <img
            src={BannerShell}
            alt='card descontos abastecimento'
            className='img-novidades'
          />
        </ImageListItem>
      </ImageList>
    </Container>
  );
};

export default NovidadesPortal;
