import { makeStyles } from '@material-ui/core';

const IconValor = require('../../../assets/images/icones/icon_valor.png');
const IconValorSelected = require('../../../assets/images/icones/icon_valor_selected.png');

const FaturaTabsStyle = makeStyles(() => ({
  indicator: {
    backgroundColor: 'transparent',
  },
  tabs: {
    "& button[aria-selected='true']": {
      border: '2px solid #FF6338',
    },
  },
  tab1: {
    width: 204,
    height: 90,
    border: '1px solid #BEC4CC',
    borderRadius: '8px 0px 0px 8px',
    textTransform: 'none',
    '&:hover': {
      color: '#FF6338',
      opacity: 10,
    },
    '&:mouseover': {
      color: '#',
      opacity: 10,
    },
    '&:selected': {
      color: '#FF6338',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#FF6338',
    },
  },
  tab2: {
    width: 204,
    height: 90,
    border: '1px solid #BEC4CC',
    borderRadius: '0px 8px 8px 0px',
    textTransform: 'none',
    minWidth: 72,
    '&:hover': {
      color: '#FF6338',
      opacity: 10,
    },
    '&$selected': {
      color: '#FF6338',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#FF6338',

    },
  },
  imagemIcon: {
    backgroundImage: `url(${IconValor})`,
    backgroundRepeat: 'no-repeat',
  },
  imagemIconSelected: {
    backgroundImage: `url(${IconValorSelected})`,
    backgroundRepeat: 'no-repeat',
  },
  labelTabs: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    color: '#BEC4CC',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
}));

export default FaturaTabsStyle;
