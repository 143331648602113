import PedidoApi from '../../infrastructure/pedido-api';
import ClienteApi from '../../infrastructure/prospecto-api/cliente';

export default {

  obterUltimoPedido: async (token: string) => {
    try {
      const resp = await PedidoApi.obterUltimoPedidoPorDocumento(token);
      return resp;
    } catch (error) {
    }
    return null;
  },

  obterDadosCliente: async (cnpj: string) => {
    try {
      const resp = await ClienteApi.obterDadosCliente(
        cnpj,
      );
      return resp;
    } catch (error) {

    }
    return null;
  },
};
