import React, { createContext,
  useContext,
  useCallback,
  useState } from 'react';
import { ICriarEnderecoClienteParametros } from '../../models/ICriarEnderecoCLienteParametros';

export interface IControleModalConfirmarPedidoContexto {
    visivel: boolean;
    dadosEndereco?: ICriarEnderecoClienteParametros;
    alterarVisualizacaoModal: (estado: boolean) => void;
    alterarDadosEndereco: (endereco: ICriarEnderecoClienteParametros) => void;
}

export const ControleModalConfirmarPedidoContexto = createContext<IControleModalConfirmarPedidoContexto>(
    {} as IControleModalConfirmarPedidoContexto,
);

const ControleModalConfirmarPedidoProvider: React.FC = ({ children }) => {
  const [visivel, setVisivel] = useState<boolean>(false);
  const [dadosEndereco, setDadosEndereco] = useState<ICriarEnderecoClienteParametros>();

  const alterarVisualizacaoModal = useCallback((estado: boolean) => {
    setVisivel(estado);
  }, []);

  const alterarDadosEndereco = useCallback((endereco: ICriarEnderecoClienteParametros) => {
    setDadosEndereco(endereco);
  }, []);

  return (
    <ControleModalConfirmarPedidoContexto.Provider
      value={{
        visivel,
        dadosEndereco,
        alterarDadosEndereco,
        alterarVisualizacaoModal,
      }}
    >
      {children}
    </ControleModalConfirmarPedidoContexto.Provider>
  );
};

const useControleModalConfirmarPedido = (): IControleModalConfirmarPedidoContexto => {
  const contexto = useContext(ControleModalConfirmarPedidoContexto);

  return contexto;
};

export { ControleModalConfirmarPedidoProvider, useControleModalConfirmarPedido };
