
import { RespostaApi } from '../../../../infrastructure/ApiInterfaces';
import api from '../../../api';
import { ICategoriasVeiculos } from './interface';


export const obterCategoriasVeiculos = (): Promise<RespostaApi<ICategoriasVeiculos>> => api.get(
  '/SubstituicaoTag/obterCategoriasVeiculos'
  ,
);
